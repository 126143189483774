import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const FirmSizesFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("diversity.buttons.select_firm_sizes")}
        filterKey="firm_sizes"
        filterValues={values.firm_sizes}
        defaultParams={defaultParams.firm_sizes}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

FirmSizesFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        firm_sizes: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default FirmSizesFilter;
