import * as Yup from "yup";
import moment from "moment";
import I18n from "../../../utils/i18n";

const CertificateValidation = Yup.object().shape({
    diversity_certificate_type_id: Yup.number().required(),
    number: Yup.string().max(255).required(),
    origin: Yup.mixed().required(I18n.t("errors.messages.diversity/certificate.origin_missed")),
    origin_date: Yup.date().required(),
    current_origin_date: Yup.date()
        .required()
        .min(Yup.ref("origin_date"))
        .when("expiration_date", (expirationDate, schema) => {
            if (expirationDate) {
                return schema.max(moment(expirationDate).subtract(1, "day"));
            } else {
                return schema;
            }
        }),
    expiration_date: Yup.date()
        .min(
            moment(),
            I18n.t("activerecord.errors.models.diversity/certificate.attributes.expiration_date.after_today")
        )
        .required(),
});

export { CertificateValidation };
