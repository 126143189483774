import React, { useEffect, useState } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WidgetCard from "../common/WidgetCard";
import { getClientSpecificStatistics } from "../../../../api/informationApi";
import I18n from "../../../../utils/i18n";

const ClientSpecificStatistics = ({ currency }) => {
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        getClientSpecificStatistics().then((response) => setStatistics(response.data));
    }, []);

    return (
        <WidgetCard
            title={I18n.t("home.diversity_analytics.charts.client_specific.title", { quarter: statistics?.quarter })}
            loading={!statistics}
        >
            <Row>
                <Col>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.requested", {
                            count: statistics?.stats.total_requests,
                        })}
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.firms", {
                            count: statistics?.stats.submitted_requests,
                        })}
                        <Badge variant="success">
                            {I18n.t("tables.diversity/participation.submitted_statuses.true")}
                        </Badge>
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.firms", {
                            count: statistics?.stats.total_requests - statistics?.stats.submitted_requests,
                        })}
                        <Badge variant="warning">
                            {I18n.t("tables.diversity/participation.submitted_statuses.false")}
                        </Badge>
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.client_specific.no_work_submitted", {
                            count: statistics?.stats.submitted_no_work_requests,
                        })}
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.rate", {
                            count: statistics?.stats.response_rate || 0,
                        })}
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.client_specific.total_fees", {
                            count: statistics?.stats.total_fees.toLocaleString(I18n.locale, {
                                style: "currency",
                                currency: currency,
                            }),
                        })}
                    </p>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.client_specific.total_hours", {
                            count: statistics?.stats.total_hours,
                        })}
                    </p>
                </Col>
                <Col>
                    <Pie
                        data={statistics?.data}
                        height={200}
                        options={{
                            responsive: false,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link to="/diversity/dashboards/client_specific">
                        {I18n.t("home.diversity_analytics.charts.client_specific.link")}
                    </Link>
                </Col>
            </Row>
        </WidgetCard>
    );
};

ClientSpecificStatistics.propTypes = {
    currency: PropTypes.string.isRequired,
};

export default ClientSpecificStatistics;
