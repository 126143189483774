import API, { buildFormData } from "../utils/api";

export function getFilters(type) {
    return API.get(`/diversity/${type}/filters`);
}

export function getFirmwideFilters() {
    return getFilters("firmwide");
}

export function getFirmwideMirrorFilters() {
    return getFilters("firmwide_mirror");
}

export function getClientSpecificFilters() {
    return getFilters("client_specific");
}

export function getClientSpecificUploadFilters() {
    return getFilters("client_specific_upload");
}

export function getClientSpecificMirrorFilters() {
    return getFilters("client_specific_mirror");
}

export function getClientSpecificProgressFilters() {
    return getFilters("client_specific_progress");
}

export function getClientSpecificReportsFilters() {
    return getFilters("client_specific_reports");
}

export function getClientSpecificReportsPartnershipStats() {
    return API.get("/diversity/client_specific_reports/quarterly_partnership_stats");
}

export function getFirmwideUploadFilters() {
    return getFilters("firmwide_upload");
}

export function getFirmwideFirm(params = {}) {
    return API.get("/diversity/firms/new", { params: params });
}

export function editClientSpecificKeyIndicators(params) {
    return API.get("/diversity/client_specific/key_indicators/edit", {
        params: { firm_id: params.firm, quarter_id: params.quarter },
    });
}

export function updateClientSpecificKeyIndicators(params) {
    return API.patch("/diversity/client_specific/key_indicators", params);
}

export function newFirmwideFirm() {
    return API.get("/diversity/firmwide/firms/new");
}

export function createFirmwideFirm(params) {
    return API.post("/diversity/firmwide/firms", params);
}

export function newClientSpecificFirm() {
    return API.get("/diversity/client_specific/firms/new");
}

export function createClientSpecificFirm(params) {
    return API.post("/diversity/client_specific/firms", params);
}

export function updateFirm(params) {
    return API.patch("/diversity/firms", { firm: params });
}

export function getFirmwideParticipation(page, column, order) {
    return API.get("/diversity/firmwide/participation", { params: { page, column, order } });
}

export function updateFirmwideParticipation(id, params) {
    return API.patch(`/diversity/firmwide/participation/${id}`, params);
}

export function updateFirmwideFirmEmployees(params) {
    let formData = new FormData();

    buildFormData(formData, params);

    return API.patch("/diversity/firmwide/employees", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function updateFirmwideCharts(params) {
    return API.patch("/diversity/firmwide/charts", { q: params });
}

export function updateFirmwideMirrorCharts(params) {
    return API.patch("/diversity/firmwide_mirror/charts", { q: params });
}

export function updateFirmwideUploadChart(params) {
    return API.patch(`/diversity/firmwide_upload/charts`, { q: params });
}

export function getClientSpecificFirmStats(quarter) {
    return API.get("/diversity/client_specific/stats", { params: { quarter } });
}

export function updateClientSpecificFirmEmployees(partnershipId, params) {
    let formData = new FormData();

    buildFormData(formData, params);

    return API.patch(`/diversity/client_specific/partnerships/${partnershipId}/employees`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function updateClientSpecificCharts(params) {
    return API.patch("/diversity/client_specific/charts", { q: params });
}

export function updateClientSpecificReportsCharts(params) {
    return API.patch("/diversity/client_specific_reports/charts", { q: params });
}

export function updateClientSpecificMirrorCharts(params) {
    return API.patch("/diversity/client_specific_mirror/charts", { q: params });
}

export function updateClientSpecificProgressCharts(params) {
    return API.patch("/diversity/client_specific_progress/charts", { q: params });
}

export function getDashboard(namespace, params) {
    return API.post(`/diversity/dashboards/${encodeURIComponent(namespace)}`, { q: params });
}

export function updateDashboard(namespace, params) {
    return API.patch(`/diversity/dashboards/${encodeURIComponent(namespace)}`, {
        ...params,
    });
}

export function getClientSpecificReportsChart(type, params) {
    return API.patch(`/diversity/client_specific_reports/charts/${type}`, { q: params });
}

export function getClientSpecificMirrorChart(type, params) {
    return API.patch(`/diversity/client_specific_mirror/charts/${type}`, { q: params });
}

export function getFirmwideRequests(organizationUid, params = {}) {
    return API.get("/diversity/firmwide/requests", {
        params: { ...params, organization_uid: organizationUid },
    });
}

export function getFirmwideArchive(page, column, order) {
    return API.get("/diversity/firmwide/quarterly_firm_stats", {
        params: { page: page, column: column, order: order },
    });
}

export function updateFirmStats(id, params = {}) {
    return API.patch(`/diversity/firmwide/quarterly_firm_stats/${id}`, params);
}

export function submitFirmwide(params = {}) {
    return API.post("/diversity/firmwide/quarterly_firm_stats", params);
}

export function getClientSpecificArchive(page, column, order) {
    return API.get("/diversity/client_specific/quarterly_partnership_stats", {
        params: { page: page, column: column, order: order },
    });
}

export function getClientSpecificRequests(organizationUid, params = {}) {
    return API.get("/diversity/client_specific/requests", {
        params: { ...params, organization_uid: organizationUid },
    });
}

export function cancelPartnership(partnershipId, params = {}) {
    return API.patch(`/diversity/client_specific/requests/${partnershipId}`, { params: params });
}

export function getClientSpecificPartnerships(page, column, order) {
    return API.get("/diversity/client_specific/partnerships", {
        params: { page: page, column: column, order: order },
    });
}

export function shareFirmwide(params) {
    return API.post(`/diversity/firmwide_upload/sharing`, params);
}

export function remindRequest(partnershipId) {
    return API.post(`/diversity/client_specific/requests/${partnershipId}/remind`);
}

export function globalRemindRequest(partnershipId, params) {
    return API.post(`/diversity/client_specific/requests/${partnershipId}/global_reminder`, params);
}

export function updateRequest(participationId, params) {
    return API.patch(`/diversity/firmwide/requests/${participationId}`, params);
}

export function getCertificates(uid) {
    return API.get(`/organizations/${uid}/diversity/certificates`);
}

export function deleteCertificates(id, uid) {
    return API.delete(`/organizations/${uid}/diversity/certificates/${id}`);
}

export function createCertificate(params) {
    let formData = new FormData();
    let uid = params.values.uid;

    buildFormData(formData, params.values);
    return API.post(`/organizations/${uid}/diversity/certificates`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function getCertificate(certificateId, uid) {
    return API.get(`/organizations/${uid}/diversity/certificates/${certificateId}`);
}

export function updatePrimaryCertificate(certificateId, uid, values) {
    return API.patch(`/organizations/${uid}/diversity/certificates/${certificateId}`, {
        diversity_certificate: values,
    });
}

export function updateCertificate(certificateId, uid, params) {
    let formData = new FormData();

    buildFormData(formData, params);

    return API.patch(`/organizations/${uid}/diversity/certificates/${certificateId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function getCertificateTypesForSelect(params = {}) {
    return API.get("/diversity/certificates/types", { params: params });
}

export function getCertificateTypes() {
    return API.get("/diversity/certificate_types");
}

export function getCertificateType(id) {
    return API.get(`/diversity/certificate_types/${id}`);
}

export function updateCertificateType(id, values) {
    return API.patch(`/diversity/certificate_types/${id}`, values);
}

export function createCertificateType(params) {
    return API.post("/diversity/certificate_types/", params);
}

export function deleteCertificateType(id) {
    return API.delete(`/diversity/certificate_types/${id}`);
}

export function sendAddRequestType(params) {
    return API.post("/diversity/type_requests", params);
}

export function sendReportCertificateIssue(params) {
    return API.post("/diversity/report_certificates", params);
}

export function getDiversityAnalytics() {
    return API.get("/diversity/analytics");
}

export function createNoWork(params) {
    return API.post("/diversity/client_specific/no_work", params);
}

export function updatePartnershipStats(id, params) {
    return API.patch(`/diversity/client_specific/quarterly_partnership_stats/${id}`, {
        quarterly_partnership_stats: params,
    });
}

export function createDeadline(params) {
    return API.post(`/diversity/deadlines`, params);
}

export function getDeadline() {
    return API.get(`/diversity/deadlines`);
}

export function generateFirmwideReport(params) {
    return API.post(`/diversity/firmwide/reports`, params);
}
