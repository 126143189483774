import API, { buildFormData } from "../utils/api";

export function getWhitelabels(page, column, order) {
    return API.get("/whitelabels", { params: { page: page, column: column, order: order } });
}

export function getWhitelabel(domain) {
    return API.get(`/whitelabels/${domain}`);
}

export function editWhitelabel(domain) {
    return API.get(`/whitelabels/${domain}/edit`);
}

export function updateWhitelabel(domain, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.patch(`/whitelabels/${domain}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}
