import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import { getFirmwideFirm, submitFirmwide } from "../../api/diversityApi";
import DiversityFirmwideUploadForm from "./components/DiversityFirmwideUploadForm";
import DiversityFirmwideUploadData from "./components/DiversityFirmwideUploadData";
import DiversityFirmwideUploadRequests from "./components/DiversityFirmwideUploadRequests";
import DiversityFirmwideUploadArchive from "./components/DiversityFirmwideUploadArchive";
import Loader from "../HomePage/components/common/Loader";

const DiversityFirmwideUploadPage = () => {
    const history = useHistory();

    const [firm, setFirm] = useState({
        id: null,
        submitted: false,
        partial_firmwide: false,
        auto_approve_enabled: false,
        auto_approve_comment: null,
        employees_count: 0,
        uploaded_at: null,
        diversity_quarter_id: null,
        year: null,
        number: null,
    });

    const [activeId, setActiveId] = useState("form");

    useEffect(() => {
        getFirmwideFirm()
            .then((response) => {
                setFirm((prevState) => ({ ...prevState, ...response.data }));
                setActiveId(response.data.submitted ? "requests" : "form");
            })
            .catch(() => history.push("/"));
    }, []);

    const SubmitNotification = () =>
        firm.submitted ? (
            <p className="text-primary mr-3">{I18n.t("diversity.firmwide_upload.submitted_notification")}</p>
        ) : (
            <p className="text-danger mr-3">{I18n.t("diversity.firmwide_upload.unsubmit_warning")}</p>
        );

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("diversity.firmwide_upload.title")}</h2>
                            </div>
                        </Container>
                    </Col>
                    <Container fluid className="py-3">
                        {firm.id ? (
                            <>
                                <Row>
                                    <Col>
                                        {firm.partial_firmwide && (
                                            <div
                                                className="mx-3 mb-3"
                                                dangerouslySetInnerHTML={{
                                                    __html: I18n.t("diversity.firmwide_upload.partial_firmwide_notice"),
                                                }}
                                            />
                                        )}
                                        <div className="d-inline">
                                            <div className="d-flex justify-content-end mx-3">
                                                {SubmitNotification()}
                                                <label className="mr-2">{I18n.t("common.links.unsubmit")}</label>
                                                <Form.Check
                                                    type="switch"
                                                    id="firmwide-submitted-switch"
                                                    checked={firm.submitted}
                                                    disabled={firm.employees_count === 0 && !firm.submitted}
                                                    onChange={(event) => {
                                                        submitFirmwide({
                                                            submitted: event.target.checked,
                                                        }).then((response) => {
                                                            setFirm((prevState) => ({
                                                                ...prevState,
                                                                ...response.data,
                                                            }));
                                                            setActiveId(event.target.checked ? "requests" : "form");
                                                        });
                                                    }}
                                                />
                                                <label>{I18n.t("common.links.submit")}</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Accordion activeKey={activeId}>
                                    <DiversityFirmwideUploadRequests
                                        firm={firm}
                                        setFirm={setFirm}
                                        activeId={activeId}
                                        setActiveId={setActiveId}
                                    />
                                    <DiversityFirmwideUploadData
                                        firm={firm}
                                        activeId={activeId}
                                        setActiveId={setActiveId}
                                    />
                                    <DiversityFirmwideUploadForm
                                        firm={firm}
                                        setFirm={setFirm}
                                        activeId={activeId}
                                        setActiveId={setActiveId}
                                    />
                                    <DiversityFirmwideUploadArchive
                                        firm={firm}
                                        setFirm={setFirm}
                                        activeId={activeId}
                                        setActiveId={setActiveId}
                                    />
                                </Accordion>
                            </>
                        ) : (
                            <Loader />
                        )}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default DiversityFirmwideUploadPage;
