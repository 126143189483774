import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

const DeadlineToolTip = () => {
    const DATE_FORMAT = "MM-DD-YYYY";
    const periodBegin = moment().quarter(moment().quarter()).startOf("quarter").format(DATE_FORMAT);
    const periodEnd = moment().quarter(moment().quarter()).endOf("quarter").format(DATE_FORMAT);
    const toolTip = `${periodBegin} to ${periodEnd} Reporting Period Submission Deadline`;
    const popoverId = `deadline-tooltip-${moment().unix()}`;

    return (
        <OverlayTrigger
            placement="left"
            trigger={["hover", "focus"]}
            overlay={
                <Popover id={popoverId}>
                    <Popover.Content dangerouslySetInnerHTML={{ __html: toolTip }} />
                </Popover>
            }
        >
            <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mt-1" />
        </OverlayTrigger>
    );
};

export default DeadlineToolTip;
