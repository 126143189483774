import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import _map from "lodash/map";
import _isFunction from "lodash/isFunction";
import _isEmpty from "lodash/isEmpty";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import _sortBy from "lodash/sortBy";
import I18n from "../../utils/i18n";

const FilteredChartsExportButton = ({
    canvasRefs,
    buildHeader,
    buildFiltersData = null,
    exportedFileName,
    buttonType,
    buttonClass,
    headerLabel,
    chartParams,
}) => {
    let pdfFile;
    let pageHeight;
    const leftIndent = 50;
    const lineInterval = 26;
    const chartLabelLeftIndent = 80;
    const rightIndent = 30;
    const defaultTopIndent = 30;
    const defaultFont = "Helvetica";
    let previousYLocation = defaultTopIndent;

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const fileName = `${exportedFileName}_${moment().format("MM-DD-YYYY_hh-mm-ss")}.pdf`;

    const buildTextRow = (content, customLeftIndent) => {
        pdfFile.text(customLeftIndent ? customLeftIndent : leftIndent, previousYLocation, content);
        previousYLocation = previousYLocation + lineInterval;
    };

    const moveToNextPage = () => {
        pdfFile.addPage();
        previousYLocation = defaultTopIndent;
    };

    const buildDefaultHeader = () => {
        buildTextRow(headerLabel + " " + moment().format("MM/DD/YYYY"));
    };

    const exportElement = ([title, chart]) => {
        if (!_isEmpty(chart)) {
            switch (chart.tagName) {
                case "TEXT_LIST":
                    return _map(chart.data, (item) => {
                        buildTextRow(item, chartLabelLeftIndent);
                    });
                case "TABLE":
                    return exportTable(title, chart.content);
                case "TEXT":
                    return buildTextRow(chart.data);
                default:
                    return exportChart(title, chart.content);
            }
        }
    };

    const exportTable = (title, chart) => {
        pageHeight - previousYLocation < 300 ? moveToNextPage() : null;

        buildTextRow(title, chartLabelLeftIndent);
        pdfFile.autoTable({
            html: chart,
            useCss: chart.dataset.useCss,
            startY: previousYLocation,
        });
        previousYLocation = pdfFile.lastAutoTable.finalY + lineInterval;
    };

    const exportChart = (title, chart) => {
        const imgData = chart.element.toDataURL("image/png", 1.0);
        const imgProps = pdfFile.getImageProperties(imgData);
        const imgWidth = pdfFile.internal.pageSize.getWidth() - (leftIndent + rightIndent);
        const imgSizeMultiplier = imgWidth / imgProps.width;
        const imgHeight = imgProps.height * imgSizeMultiplier;

        pageHeight - 3 * lineInterval - previousYLocation - imgHeight < 0 && moveToNextPage();

        buildTextRow(title, chartLabelLeftIndent);
        pdfFile.addImage(imgData, "PNG", leftIndent, previousYLocation, imgWidth, imgHeight);
        previousYLocation = previousYLocation + imgHeight + lineInterval;
    };

    useEffect(() => {
        setIsInitialLoading(true);
        setTimeout(() => setIsInitialLoading(false), 3500);
    }, [chartParams]);

    const exportToPdf = () => {
        pdfFile = new jsPDF("p", "pt");
        pageHeight = pdfFile.internal.pageSize.getHeight();
        previousYLocation = defaultTopIndent;

        if (headerLabel) {
            pdfFile.setFont(defaultFont, "bold");
            pdfFile.setFontSize(20);
            buildDefaultHeader();
        } else {
            previousYLocation = buildHeader(pdfFile, { previousYLocation, leftIndent, lineInterval, defaultFont });
        }

        if (buildFiltersData && _isFunction(buildFiltersData)) {
            pdfFile.setFontSize(16);
            pdfFile.setFont(defaultFont, "normal");
            previousYLocation = buildFiltersData(pdfFile, previousYLocation);
        }

        pdfFile.setFont(defaultFont, "bold");
        pdfFile.setFontSize(10);

        _map(
            _sortBy(Object.entries(canvasRefs.current), ([, chart]) => chart.position),
            exportElement
        );

        pdfFile.save(fileName);
        setIsLoading(false);
    };

    const onClick = async () => {
        setIsLoading(true);

        await setTimeout(() => exportToPdf(), 10);
    };

    return (
        <Button variant={buttonType} onClick={onClick} className={buttonClass} disabled={isInitialLoading || isLoading}>
            {isLoading && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
            )}
            {I18n.t("home.export")}
        </Button>
    );
};

FilteredChartsExportButton.propTypes = {
    canvasRefs: PropTypes.object.isRequired,
    buildHeader: PropTypes.func,
    buildFiltersData: PropTypes.func,
    exportedFileName: PropTypes.string.isRequired,
    buttonType: PropTypes.string,
    buttonClass: PropTypes.string,
    headerLabel: PropTypes.string,
    chartParams: PropTypes.object,
};

export default FilteredChartsExportButton;
