import React, { useEffect, useState } from "react";
import { Col, Container, Card, Accordion, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import OrganizationTable from "./OrganizationTable";
import OrganizationsActions from "./common/OrganizationsActions";
import OrganizationFilter from "./OrganizationFilter";
import { getPermissions } from "../../../api/organizationApi";
import I18n from "../../../utils/i18n";
import TopBar from "./TopBar";

const OrganizationSection = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const currentUser = useSelector((state) => state.currentUser.resource);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const filterData = JSON.parse(window.localStorage.getItem(`organizations-filter-${currentActor.uid}`)) || {};
    const [applyFilter, setApplyFilter] = useState(!_isEmpty(filterData) ? filterData.filter : {});

    const onSubmit = (values) => setApplyFilter(values.filter);

    const initValues = {
        filter: {
            name_or_id_or_descendants_name_or_id_cont: applyFilter.name_or_id_or_descendants_name_or_id_cont || "",
            whitelabel_id_in: applyFilter.whitelabel_id_in || [],
            users_email_cont: applyFilter.users_email_cont || "",
            permissions_name_in: applyFilter.permissions_name_in || [],
            organization_permissions_created_at_gteq: applyFilter.organization_permissions_created_at_gteq || null,
            organization_permissions_created_at_lteq: applyFilter.organization_permissions_created_at_lteq || null,
        },
    };

    useEffect(() => {
        getPermissions().then((response) => setPermissions(_map(response.data, (permission) => permission.name)));
    }, []);

    return (
        <>
            <TopBar applyFilter={applyFilter} />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                                <h2>{I18n.t("sidebar.links.manage.organizations")}</h2>
                            </div>
                            <Accordion defaultActiveKey="organizationsPage">
                                <Card className="border-0 overflow-visible">
                                    <Accordion.Collapse eventKey="organizationsPage">
                                        <Card.Body>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={initValues}
                                                onSubmit={onSubmit}
                                            >
                                                {({ values, handleSubmit, handleReset, setFieldValue }) => (
                                                    <FormikForm as={Form}>
                                                        <OrganizationsActions
                                                            openFilter={openFilter}
                                                            setOpenFilter={setOpenFilter}
                                                            setApplyFilter={setApplyFilter}
                                                            currentUser={currentUser}
                                                            currentActor={currentActor}
                                                            values={values}
                                                            form={{ handleSubmit, handleReset, setFieldValue }}
                                                        />
                                                        <div className="position-relative">
                                                            <OrganizationFilter
                                                                openFilter={openFilter}
                                                                values={values}
                                                                currentUser={currentUser}
                                                                form={{ setFieldValue }}
                                                                permissions={permissions}
                                                            />
                                                            <OrganizationTable applyFilter={applyFilter} />
                                                        </div>
                                                    </FormikForm>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default OrganizationSection;
