import React from "react";
import PropTypes from "prop-types";
import FiltersBar from "../../../common/components/DiversityPage/FiltersBar";
import { QuarterFilter, FirmFilter } from "../../../common/components/DiversityPage/filters";

const DiversityKeyIndicatorsFiltersBar = ({ setParams, getFilters }) => (
    <FiltersBar setChartParams={setParams} filterTypes={[QuarterFilter, FirmFilter]} getFilters={getFilters} />
);

DiversityKeyIndicatorsFiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setParams: PropTypes.func.isRequired,
};

export default DiversityKeyIndicatorsFiltersBar;
