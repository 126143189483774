import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import I18n from "../../utils/i18n";
import PublicDomainsTable from "./components/PublicDomainsTable";
import PublicDomainsSearch from "./components/PublicDomainsSearch";

const PublicDomainsPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="top-bar">
                <Col>
                    <Container fluid className="py-3">
                        <Row>
                            <Col>
                                <div className="search-input m-1">
                                    <PublicDomainsSearch />
                                </div>
                            </Col>
                            <Col className="text-right pl-0" md="auto">
                                <Link to="/public_domains/new" className="text-white btn btn-primary m-1">
                                    {I18n.t("common.links.create")}
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("sidebar.links.manage.public_domains")}</h2>
                            </div>
                            <PublicDomainsTable />
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default PublicDomainsPage;
