import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import _join from "lodash/join";
import PropTypes from "prop-types";
import _compact from "lodash/compact";
import I18n from "../../../../utils/i18n";
import DisplayField from "../content/DisplayField";

const IconWithField = ({ icon, title, value }) => (
    <Row>
        <div className="col col-auto pr-1">
            <span className="fa-2x text-secondary">
                <FontAwesomeIcon icon={icon} />
            </span>
        </div>
        <Col>
            <DisplayField title={title} value={value} />
        </Col>
    </Row>
);

const ContactInfo = ({ location: { country, address_line1, address_line2, state, zip_code, city, phone, email } }) => {
    const address = _join(_compact([address_line1, address_line2, city, zip_code, state?.name, country.name]), " ");
    return (
        <Row>
            <Col>
                <IconWithField
                    icon={faMapMarkerAlt}
                    title={I18n.t("companies.profiles.text.address")}
                    value={address}
                />
            </Col>
            <Col>
                <IconWithField icon={faPhone} title={I18n.t("activerecord.attributes.user.phone")} value={phone} />
            </Col>
            <Col>
                <IconWithField icon={faEnvelope} title={I18n.t("diversity.clients.steps.firm_email")} value={email} />
            </Col>
        </Row>
    );
};

IconWithField.propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string,
    value: PropTypes.string,
};

ContactInfo.propTypes = {
    location: PropTypes.shape({
        location_type_name: PropTypes.string.isRequired,
        location_type_required: PropTypes.bool.isRequired,
        address_line1: PropTypes.string,
        address_line2: PropTypes.string,
        country: PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string,
        }),
        state: PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
        }),
        zip_code: PropTypes.string,
        city: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
};

export default ContactInfo;
