import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import BodyStep from "../common/BodyStep";
import I18n from "../../../utils/i18n";
import { finishWizard } from "../../../api/organizationApi";

const FinishStep = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setIsSubmitting(true);
        finishWizard().finally(() => setIsSubmitting(false));
    }, []);

    return isSubmitting ? (
        <div className="page-wrapper">
            <div id="main-loader">
                <Spinner animation="border" role="status" variant="primary" />
            </div>
        </div>
    ) : (
        <BodyStep text={I18n.t("wizard.steps.finish.text")} title={I18n.t("wizard.steps.finish.title")} />
    );
};

export default FinishStep;
