import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import _isNull from "lodash/isNull";
import I18n from "../../../../utils/i18n";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import ButtonCard from "./ButtonCard";

const SourceTypeStep = () => {
    const { setCurrentStep, onHide, eventType, setEventType, setFilterValue } = useContext(CreateProjectWizardContext);
    const currentStepIndex = 0;

    const translationScope = "home.create_event_wizard.steps.source_type";
    return (
        <>
            <Modal.Header className="border-0 pb-1">
                <Modal.Title className="mb-3">
                    <div className="mb-2 text-2xl">{I18n.t("title", { scope: translationScope })}</div>
                    <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                        {I18n.t("tip", { scope: translationScope })}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex justify-content-around">
                    <ButtonCard
                        text={I18n.t("buttons.general_sourcing.title", { scope: translationScope })}
                        tip={I18n.t("buttons.general_sourcing.tip", { scope: translationScope })}
                        clickHandler={() => {
                            setFilterValue((oldValue) => ({ ...oldValue, legal: false }));
                            setEventType("general");
                        }}
                        active={eventType === "general"}
                    />
                    <ButtonCard
                        text={I18n.t("buttons.legal_sourcing.title", { scope: translationScope })}
                        tip={I18n.t("buttons.legal_sourcing.tip", { scope: translationScope })}
                        clickHandler={() => {
                            setFilterValue((oldValue) => ({ ...oldValue, legal: true }));
                            setEventType("legal");
                        }}
                        active={eventType === "legal"}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer className="pt-0 d-flex justify-content-between border-0">
                <Button variant="outline-secondary" onClick={onHide}>
                    {I18n.t("home.create_event_wizard.buttons.cancel")}
                </Button>
                <Button
                    disabled={_isNull(eventType)}
                    variant="primary"
                    className="text-white"
                    onClick={() => setCurrentStep(currentStepIndex + 1)}
                >
                    {I18n.t("home.create_event_wizard.buttons.proceed")}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default SourceTypeStep;
