import React from "react";
import PropTypes from "prop-types";
import FirmwideMirrorChart from "./FirmwideMirrorChart";

const DiverseScore = ({ inProgress, canvasRefs, chartOptions, chartData, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="diverse_score"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        }}
    />
);

DiverseScore.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default DiverseScore;
