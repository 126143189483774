import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import _map from "lodash/map";
import I18n from "../../../../../utils/i18n";
import IndeterminateCheck from "../../../../../common/components/IndeterminateCheck";

const ListsSelectNode = ({ list, counter, onSelect, isChecked, getFilteredLists }) => {
    const getCount = useCallback((list) => (counter || {})[list.id] || 0, [counter]);

    const labelFor = (list) => {
        const count = getCount(list);

        return I18n.t("companies.profiles.sections.filters.counter", {
            name: list.name,
            count: count,
        });
    };

    return (
        <Accordion>
            <div className={classNames({ "ml-4": list.depth > 0 })}>
                <Accordion.Toggle as="div" className="d-inline-block" eventKey={list.id}>
                    <div className="form-check-inline d-on-hover">
                        <IndeterminateCheck
                            className="multiselect-filter d-inline-block text-truncate w-100"
                            type="checkbox"
                            id={`multiselect-filter-lists-${list.id}`}
                            checked={isChecked(list)}
                            onChange={(event) => onSelect(event, list)}
                            label={labelFor(list)}
                        />
                        {list.children.length > 0 && (
                            <FontAwesomeIcon icon={faChevronDown} className="align-baseline ml-2 arrow" size="xs" />
                        )}
                    </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={list.id}>
                    <div>
                        {_map(getFilteredLists(list.children), (subList) => (
                            <ListsSelectNode
                                key={subList.id}
                                list={subList}
                                counter={counter}
                                isChecked={isChecked}
                                onSelect={onSelect}
                                getFilteredLists={getFilteredLists}
                            />
                        ))}
                    </div>
                </Accordion.Collapse>
            </div>
        </Accordion>
    );
};

ListsSelectNode.propTypes = {
    list: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        name: PropTypes.string.isRequired,
        depth: PropTypes.number.isRequired,
        children: PropTypes.arrayOf(PropTypes.object),
    }),
    counter: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    isChecked: PropTypes.func.isRequired,
    getFilteredLists: PropTypes.func.isRequired,
};

export default ListsSelectNode;
