import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import _has from "lodash/has";
import { useSelector } from "react-redux";
import I18n from "../../../../../utils/i18n";

const SuccessResponce = ({ result, scope }) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);

    return (
        <>
            {I18n.t(`companies.lists.sections.export.${scope}.result.success`)}
            <br />
            <a
                href={`//${currentWhitelabel.hosts.bid}${result.link}/participation`}
                className="btn btn-link text-secondary"
            >
                {I18n.t("companies.lists.sections.export.common.link")}
            </a>
        </>
    );
};

SuccessResponce.propTypes = {
    result: PropTypes.shape({
        link: PropTypes.string.isRequired,
    }).isRequired,
    scope: PropTypes.string.isRequired,
};

const FailedResponce = ({ result, scope }) => (
    <>
        {I18n.t(`companies.lists.sections.export.${scope}.result.error`)}
        {_has(result, "errors") && (
            <>
                <br />
                {result.errors}
            </>
        )}
    </>
);

FailedResponce.propTypes = {
    result: PropTypes.shape({
        errors: PropTypes.string,
    }),
    scope: PropTypes.string.isRequired,
};

const ResultStep = ({ children, result, cancelButton, scope }) => (
    <>
        <Modal.Body>
            {children}

            <Modal.Body>
                <p>
                    {_has(result, "link") ? (
                        <SuccessResponce result={result} scope={scope} />
                    ) : (
                        <FailedResponce result={result} scope={scope} />
                    )}
                </p>
            </Modal.Body>
        </Modal.Body>
        <Modal.Footer>{cancelButton}</Modal.Footer>
    </>
);

ResultStep.propTypes = {
    children: PropTypes.node.isRequired,
    result: PropTypes.object.isRequired,
    cancelButton: PropTypes.node.isRequired,
    scope: PropTypes.string.isRequired,
};

export default ResultStep;
