import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getClientSpecificMirrorFilters, updateClientSpecificMirrorCharts } from "../../api/diversityApi";
import FiltersBar from "./components/FiltersBar";
import ExportButton from "../../common/components/DiversityPage/ExportButton";
import ClientSpecificMirrorCharts from "../../utils/clientSpecificMirrorCharts.js";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";

const DiversityClientSpecificMirrorPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const [chartParams, setChartParams] = useState({});
    const [filter, setFilter] = useState({});
    const [changedFilters, setChangedFilters] = useState({});
    const canvasRefs = useRef({});

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <Row>
                        <Col className="mr-auto">
                            {!_isEmpty(filter) && !_isEmpty(chartParams) && (
                                <ExportButton
                                    canvasRefs={canvasRefs}
                                    title="sidebar.links.diversity.client_specific_mirror"
                                    filter={filter}
                                    chartParams={chartParams}
                                    changedFilters={changedFilters}
                                />
                            )}
                        </Col>
                        <Col className="col-auto">
                            <FiltersBar
                                setChartParams={setChartParams}
                                getFilters={getClientSpecificMirrorFilters}
                                setFilter={setFilter}
                                setChangedFilters={setChangedFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="scrollable">
                <div className="overflow-auto bg-white">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={ClientSpecificMirrorCharts}
                        updateCharts={updateClientSpecificMirrorCharts}
                        channelName="Diversity::ClientSpecificMirror::ChartsChannel"
                        namespace="client_specific_mirror"
                    />
                </div>
            </div>
        </div>
    );
};

export default DiversityClientSpecificMirrorPage;
