import {
    CountOverTimeChart,
    CountOverTimeTable,
    FeesOverTimeChart,
    FeesOverTimeTable,
    HoursOverTimeChart,
    HoursOverTimeTable,
    TotalCountChart,
    TotalFeesChart,
    TotalHoursChart,
    MiniStatsBar,
    TotalFeesByFirmChart,
    FeesPercentsByFirmChart,
    TotalHoursByFirmChart,
    HoursPercentsByFirmChart,
    TotalCountByFirmChart,
    CountPercentsByFirmChart,
} from "../pages/DiversityClientSpecificProgressPage/components/charts";

const ClientSpecificProgressCharts = [
    [{ type: "mini_stats_bar", component: MiniStatsBar }],
    [
        { type: "fees_over_time", component: FeesOverTimeChart },
        { type: "total_fees", component: TotalFeesChart },
    ],
    [{ type: "fees_over_time_table", component: FeesOverTimeTable, classNameForViewMode: "d-none" }],
    [
        { type: "hours_over_time", component: HoursOverTimeChart },
        { type: "total_hours", component: TotalHoursChart },
    ],
    [{ type: "hours_over_time_table", component: HoursOverTimeTable, classNameForViewMode: "d-none" }],
    [
        { type: "count_over_time", component: CountOverTimeChart },
        { type: "total_count", component: TotalCountChart },
    ],
    [{ type: "count_over_time_table", component: CountOverTimeTable, classNameForViewMode: "d-none" }],
    [
        { type: "total_fees_by_firm", component: TotalFeesByFirmChart },
        { type: "fees_percents_by_firm", component: FeesPercentsByFirmChart },
    ],
    [
        { type: "total_hours_by_firm", component: TotalHoursByFirmChart },
        { type: "hours_percents_by_firm", component: HoursPercentsByFirmChart },
    ],
    [
        { type: "total_count_by_firm", component: TotalCountByFirmChart },
        { type: "count_percents_by_firm", component: CountPercentsByFirmChart },
    ],
];

export default ClientSpecificProgressCharts;
