import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _toString from "lodash/toString";
import _includes from "lodash/includes";
import _lowerCase from "lodash/lowerCase";
import _range from "lodash/range";
import _every from "lodash/every";
import _union from "lodash/union";
import _without from "lodash/without";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _flatMap from "lodash/flatMap";
import { useField } from "formik";
import { Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import IndeterminateCheck from "../../../../../common/components/IndeterminateCheck";
import I18n from "../../../../../utils/i18n";
import ListsSelectNode from "./ListsSelectNode";

const NUMBER_OF_VISIBLE_ELEMENTS = 3;

const ListsSelectMenu = ({ lists, sharedLists, fetching, counter }) => {
    const [, meta, helper] = useField("companies_lists");
    const [searchValue, setSearchValue] = useState("");

    const isSearchMatched = useCallback(
        (value) => (searchValue.length >= 3 ? _includes(_lowerCase(value), _lowerCase(searchValue)) : true),
        [searchValue],
    );
    const onSearch = useCallback((event) => setSearchValue(event.target.value), [setSearchValue]);

    const onClear = useCallback(() => {
        setSearchValue("");
        helper.setValue([]);
    }, [helper.setValue, setSearchValue]);

    const getFilteredLists = useCallback(
        (lists) => _filter(lists, (list) => isSearchMatched(list.name) || getFilteredLists(list.children).length > 0),
        [isSearchMatched],
    );

    const isChecked = useCallback((list) => _includes(meta.value, _toString(list.id)), [meta.value]);

    const areChecked = useCallback(
        (lists) => _every(lists, (list) => (list.children.length > 0 ? areChecked(list.children) : isChecked(list))),
        [isChecked],
    );

    const areChildrenIndeterminate = useCallback(
        (lists) => _some(lists, (list) => areChildrenIndeterminate(list.children) || isChecked(list)),
        [isChecked],
    );

    const areIndeterminate = useCallback(
        (lists) => areChildrenIndeterminate(lists) && !areChecked(lists),
        [areChecked, areChildrenIndeterminate],
    );

    const getListsIds = (lists) => _flatMap(lists, (list) => [_toString(list.id), ...getListsIds(list.children)]);

    const onSelect = useCallback(
        (event, list) => {
            helper.setValue(
                event.target.checked
                    ? _union(meta.value, [_toString(list.id)])
                    : _without(meta.value, _toString(list.id)),
            );
        },
        [helper.setValue, meta.value],
    );

    const onSelectAll = useCallback(
        (event) => {
            const values = getListsIds(lists.concat(sharedLists));
            helper.setValue(event.target.checked ? _union(meta.value, values) : _without(meta.value, ...values));
        },
        [lists, sharedLists, helper.setValue, meta.value],
    );

    return (
        <div className="lists-select-menu">
            <div className="d-flex align-items-center justify-content-between">
                <strong>{I18n.t("companies.profiles.sections.filters.companies_lists")}</strong>
                <small className="text-muted cursor-pointer" onClick={onClear}>
                    {I18n.t("common.clear")}
                </small>
            </div>
            {fetching ? (
                <Form className="mt-2">
                    {_map(_range(NUMBER_OF_VISIBLE_ELEMENTS), (item) => (
                        <Skeleton key={`loader-companies_lists-${item}`} />
                    ))}
                </Form>
            ) : (
                <Form className="mt-2">
                    <Form.Control
                        placeholder={I18n.t("common.placeholders.search")}
                        onChange={onSearch}
                        value={searchValue}
                        className="mb-2"
                    />
                    <IndeterminateCheck
                        type="checkbox"
                        name="companies_lists"
                        id="companies_lists-all"
                        label={I18n.t("companies.profiles.sections.filters.counter", {
                            name: I18n.t("common.select_all"),
                            count: counter?.total,
                        })}
                        onChange={onSelectAll}
                        className="mb-2"
                        checked={areChecked(lists.concat(sharedLists))}
                        indeterminate={areIndeterminate(lists.concat(sharedLists))}
                    />
                    <div className="checkbox-list">
                        {_map(getFilteredLists(lists), (list) => (
                            <ListsSelectNode
                                key={list.id}
                                list={list}
                                counter={counter}
                                onSelect={onSelect}
                                isChecked={isChecked}
                                getFilteredLists={getFilteredLists}
                            />
                        ))}
                    </div>

                    <div className="checkbox-list mt-3 text-muted">
                        {_map(getFilteredLists(sharedLists), (list) => (
                            <ListsSelectNode
                                key={list.id}
                                list={list}
                                counter={counter}
                                onSelect={onSelect}
                                isChecked={isChecked}
                                getFilteredLists={getFilteredLists}
                            />
                        ))}
                    </div>
                </Form>
            )}
        </div>
    );
};

ListsSelectMenu.propTypes = {
    lists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.object),
        }),
    ),
    sharedLists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.object),
        }),
    ),
    fetching: PropTypes.bool,
    counter: PropTypes.object,
};

export default ListsSelectMenu;
