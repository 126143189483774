import React, { useEffect, useState } from "react";
import { Col, Container, InputGroup, Row, Form, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import FiltersSection from "./components/FiltersSection";
import ProfilesSection from "./components/ProfilesSection";
import PinContext from "./components/FiltersSection/PinContext";
import { getCompaniesProfiles, getCompaniesFilters } from "../../api/companiesApi";

const CompanyProfilesPage = () => {
    const [searchValue, setSearchValue] = useState({ temp: "", applied: "" });
    const [filterElements, setFilterElements] = useState({
        max_employees: 0,
        max_sales: 0,
        industries: [],
        locations: [],
        location_types: [],
        certificates: [],
        companies_lists: [],
        shared_companies_lists: [],
        badges: [],
    });
    const [filters, setFilters] = useState({});
    const [filterSettings, setFilterSettings] = useState({ industries: [], locations: [] });
    const [profiles, setProfiles] = useState([]);
    const [filterCounter, setFilterCounter] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [recordsCount, setRecordsCount] = useState(0);
    const [filterElementsFetching, setFilterElementsFetching] = useState(true);
    const [profilesFetching, setProfilesFetching] = useState(true);
    const [searchPerforming, setSearchPerforming] = useState(false);
    const [sort, setSort] = useState({ column: "updated_at", order: "desc" });
    const currentActor = useSelector((state) => state.currentActor.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentActor.organization.permissions.company_profile_permission) {
            history.push("/");
        }
    }, []);

    const getProfiles = (params, callback) => {
        setProfilesFetching(true);
        getCompaniesProfiles(params)
            .then(({ data: { records, page_count, records_count, sizes } }) => {
                setProfiles(records);
                setFilterCounter(sizes);
                setPageCount(page_count);
                setRecordsCount(records_count);
            })
            .finally(() => {
                typeof callback === "function" && callback();
                setProfilesFetching(false);
            });
    };

    const getFilterElements = () => {
        setFilterElementsFetching(true);
        getCompaniesFilters()
            .then(({ data }) => {
                setFilterElements(data);
                setFilterSettings(data.personal_setting);
            })
            .finally(() => setFilterElementsFetching(false));
    };

    const handleSearchChange = (event) => setSearchValue({ ...searchValue, temp: event.target.value });

    const applyFilters = (values) => {
        getProfiles({ term: searchValue.applied, filters: values, sort, page: 1 }, () => {
            setPage(1);
            setFilters(values);
        });
    };

    const applySort = (callback) => {
        setSort((prevState) => {
            const values = callback(prevState);
            getProfiles({ term: searchValue.applied, sort: values, filters, page: 1 }, () => setPage(1));
            return values;
        });
    };

    const goToPage = ({ selected }) => {
        const newPage = selected + 1;
        getProfiles({ term: searchValue.applied, filters, sort, page: newPage }, () => setPage(newPage));
    };

    const onSearch = () => {
        setSearchPerforming(true);
        getProfiles({ term: searchValue.temp, filters, sort, page: 1 }, () => {
            setSearchValue({ ...searchValue, applied: searchValue.temp });
            setPage(1);
            setSearchPerforming(false);
        });
    };

    useEffect(() => {
        getFilterElements();
        getProfiles({ term: searchValue.applied, filters, sort });
    }, []);

    return (
        <div id="company-profiles">
            <div className="top-bar">
                <Col>
                    <Container fluid className="py-3">
                        <Row>
                            <Col>
                                <div className="search-input">
                                    <InputGroup>
                                        <Form.Control
                                            placeholder={I18n.t("common.placeholders.search")}
                                            value={searchValue.temp}
                                            onChange={handleSearchChange}
                                            onKeyDown={(event) => event.code === "Enter" && onSearch()}
                                        />
                                        <InputGroup.Append className="bg-white">
                                            <Button
                                                variant="outline-primary"
                                                onClick={onSearch}
                                                disabled={searchPerforming}
                                            >
                                                <span>{I18n.t("common.links.search")}</span>
                                                {searchPerforming ? (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="ml-2"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSearch} className="ml-2" />
                                                )}
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto">
                    <Container fluid className="my-3">
                        <Row>
                            <Col sm={4}>
                                <PinContext.Provider value={[filterSettings, setFilterSettings]}>
                                    <FiltersSection
                                        filterElementsFetching={filterElementsFetching}
                                        filterElements={filterElements}
                                        applyFilters={applyFilters}
                                        filterCounter={filterCounter}
                                    />
                                </PinContext.Provider>
                            </Col>
                            <Col sm={8}>
                                <ProfilesSection
                                    profilesFetching={profilesFetching}
                                    profiles={profiles}
                                    pageCount={pageCount}
                                    recordsCount={recordsCount}
                                    goToPage={goToPage}
                                    page={page}
                                    sort={sort}
                                    applySort={applySort}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default CompanyProfilesPage;
