import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import _ from "lodash";
import I18n from "../../../utils/i18n";
import Loader from "./common/Loader";
import EventsMessages from "./content/EventsMessages";
import { getMessages } from "../../../api/informationApi";

const Messages = () => {
    const [messagingData, setMessagingData] = useState([]);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);

        getMessages()
            .then((response) => {
                setFetching(false);
                setMessagingData(response.data);
            })
            .catch(() => {
                setMessagingData([]);
                setFetching(false);
            });
    }, []);

    return (
        <>
            <h3>{I18n.t("home.messages.header")}</h3>
            <Card className="mb-2">
                <Card.Body>
                    {fetching ? (
                        <Loader />
                    ) : !_.isEmpty(messagingData) ? (
                        messagingData.map((project, index) => <EventsMessages messagingData={project} key={index} />)
                    ) : (
                        <div className="pt-4 px-4 pb-4">{I18n.t("home.messages.empty")}</div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default Messages;
