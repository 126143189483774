import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import { getKLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";
import { currencyChartLabel } from "../../../../utils/calculateTotal";

const TotalFeesByRoleChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="total_fees_by_role"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => getKLabelForValue(value),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.total_fees_by_role.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        tooltipItem.value === "NaN"
                            ? ""
                            : `${data.datasets[tooltipItem.datasetIndex].label}: ${currencyChartLabel(
                                  tooltipItem.value,
                              )}`,
                },
            },
        }}
    />
);

TotalFeesByRoleChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default TotalFeesByRoleChart;
