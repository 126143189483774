import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import DashboardForm from "../../common/components/DashboardPage/DashboardForm";
import ClientSpecificReportsCharts from "../../utils/clientSpecificReportsCharts";
import I18n from "../../utils/i18n";
import { getClientSpecificReportsFilters } from "../../api/diversityApi";
import DiversityClientSpecificMarketingPage from "../DiversityClientSpecificMarketingPage";

const DiversityClientSpecificReportsDashboardPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);

    if (!currentActor.organization.permissions.diversity_client_viewer_permission) {
        return <DiversityClientSpecificMarketingPage />;
    }

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3" />
            </div>

            <DashboardForm
                backlink="/diversity/dashboards/client_specific_reports"
                chartsRow={ClientSpecificReportsCharts}
                namespace="diversity/client_specific_reports"
                description={I18n.t("diversity.charts.client_specific_reports_dashboard.description")}
                getFilters={getClientSpecificReportsFilters}
                channelName="Diversity::ClientSpecificReports::ChartsChannel"
            />
        </div>
    );
};

export default DiversityClientSpecificReportsDashboardPage;
