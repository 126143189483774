import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import { ScoreByFirmChart, DifferenceFromGoalChart, GoalsVsActualChart } from "./components/charts";
import KeyIndicatorsTable from "./components/charts/KeyIndicatorTable";

const DiversityClientSpecificMarketingPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentActor.organization.permissions.diversity_firmwide_viewer_permission) {
            history.push("/");
        }
    }, []);

    return (
        <div id="client-specific-marketing-dashboard" className="scrollable full-scrollable overflow-auto bg-white">
            <Container fluid className="py-3">
                <Row className="justify-content-between align-items-center pt-3 pb-2 mb-3">
                    <Col>
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: I18n.t("diversity.dashboards.client_specific_marketing.title"),
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} xl={6}>
                        <KeyIndicatorsTable />
                    </Col>
                    <Col lg={12} xl={6}>
                        <ScoreByFirmChart />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} xl={6}>
                        <DifferenceFromGoalChart />
                    </Col>
                    <Col lg={12} xl={6}>
                        <GoalsVsActualChart />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DiversityClientSpecificMarketingPage;
