import React, { useEffect, useState } from "react";
import { Accordion, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import I18n from "../../utils/i18n";
import Loader from "../HomePage/components/common/Loader";
import { getFirmwideFirm } from "../../api/diversityApi";
import DiversityClientSpecificUploadData from "./components/DiversityClientSpecificUploadData";
import DiversityClientSpecificUploadArchive from "./components/DiversityClientSpecificUploadArchive";
import DiversityClientSpecificUploadRequests from "./components/DiversityClientSpecificUploadRequests";

const DiversityClientSpecificUploadPage = () => {
    const history = useHistory();

    const [firm, setFirm] = useState({
        id: null,
        submitted: false,
        employees_count: 0,
        last_changed_at: moment().unix(),
    });

    const [activeId, setActiveId] = useState("data");

    useEffect(() => {
        getFirmwideFirm()
            .then((response) => {
                setFirm({ ...response.data, last_changed_at: moment().unix() });
            })
            .catch(() => history.push("/"));
    }, []);

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("diversity.client_specific_upload.title")}</h2>
                            </div>
                        </Container>
                    </Col>
                    <Container fluid className="py-3">
                        {firm.id ? (
                            <>
                                <Accordion activeKey={activeId}>
                                    <DiversityClientSpecificUploadData
                                        firm={firm}
                                        setActiveId={setActiveId}
                                        activeId={activeId}
                                    />
                                    <DiversityClientSpecificUploadRequests
                                        firm={firm}
                                        setFirm={setFirm}
                                        setActiveId={setActiveId}
                                        activeId={activeId}
                                    />
                                    <DiversityClientSpecificUploadArchive
                                        setFirm={setFirm}
                                        firm={firm}
                                        setActiveId={setActiveId}
                                        activeId={activeId}
                                    />
                                </Accordion>
                            </>
                        ) : (
                            <Loader />
                        )}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default DiversityClientSpecificUploadPage;
