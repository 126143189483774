import React, { useState } from "react";
import { ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPen } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import RequestActions from "./RequestActions";
import Summary from "./Summary";
import I18n from "../../../../utils/i18n";
import BackLink from "../../../../common/components/BackLink";
import AddCompanyToListsModal from "../../../CompanyListsPage/components/AddCompanyToListsModal";
import RegularAddToListButton from "../../../CompanyListsPage/components/RegularAddToListButton";
import SolidAddToListButton from "../../../CompanyListsPage/components/SolidAddToListButton";
import AddToProjectAction from "../../../CompanyProfilesPage/components/AddToProjectActions";

const Logo = ({ path, name }) => {
    const initials = () => name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
    return (
        <div className="without-avatar w-100 img-raised rounded-circle img-fluid">
            {path ? (
                <div className="avatar avatar-round h-100 w-100">
                    <div className="avatar-image avatar-image-round" style={{ backgroundImage: `url("${path}")` }} />
                </div>
            ) : (
                <div className="letters text-uppercase">{initials() ? initials().join("") : " "}</div>
            )}
        </div>
    );
};

const WebsiteLink = ({ website_url }) =>
    website_url ? (
        <a href={website_url.startsWith("http") ? website_url : `//${website_url}`} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLink} className="mr-1" />
            {website_url}
        </a>
    ) : (
        ""
    );

const Header = ({ values, uid, currentUser, currentActor }) => {
    const isEditable =
        (!_isEmpty(currentUser) && (currentUser.superadmin || currentUser.admin)) ||
        (!_isEmpty(currentActor) &&
            (currentActor.super_manager || currentActor.profile_manager) &&
            currentActor.organization.uid === uid);
    const [showAddListModal, setShowAddListModal] = useState(false);

    return (
        <Row className="mb-2">
            <Col sm={1}>
                <BackLink linkName={I18n.t("common.links.search")} backPath="/companies/profiles" />
            </Col>
            <Col>
                <Card>
                    <div
                        className="cover position-relative d-flex align-items-end justify-content-end "
                        style={{ backgroundImage: `url("${values.banner}")` }}
                    >
                        {values.abilities.diversity_provider_permission && <RequestActions values={values} />}
                    </div>
                    <Card.Body className="pb-3">
                        <Container fluid>
                            <Row className="mb-2">
                                <Col sm={2}>
                                    <div className="logo-avatar position-relative h-100">
                                        <Logo name={values.name} path={values.logo} />
                                    </div>
                                </Col>
                                <Col sm={5}>
                                    <h2>{values.name}</h2>
                                    <WebsiteLink website_url={values.website_url} />
                                </Col>
                                <Col sm={5} className="d-flex justify-content-end align-items-start pr-0">
                                    <div className="d-flex">
                                        <AddToProjectAction organizationId={values.id} />
                                        <ButtonGroup>
                                            {currentActor.organization.uid !== uid && (
                                                <>
                                                    {values.present_in_lists ? (
                                                        <SolidAddToListButton
                                                            onClick={() => setShowAddListModal(true)}
                                                        />
                                                    ) : (
                                                        <RegularAddToListButton
                                                            onClick={() => setShowAddListModal(true)}
                                                        />
                                                    )}
                                                    <AddCompanyToListsModal
                                                        organizationUid={uid}
                                                        onClose={() => setShowAddListModal(false)}
                                                        show={showAddListModal}
                                                    />
                                                </>
                                            )}
                                            {isEditable && (
                                                <Link
                                                    to={`/organizations/${uid}`}
                                                    className="btn btn-outline-secondary"
                                                >
                                                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                                                    {I18n.t("common.links.edit")}
                                                </Link>
                                            )}
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Summary values={values} />
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={1} />
        </Row>
    );
};

Logo.propTypes = {
    path: PropTypes.string,
    name: PropTypes.string.isRequired,
};

WebsiteLink.propTypes = {
    website_url: PropTypes.string,
};

Header.propTypes = {
    values: PropTypes.shape({
        id: PropTypes.number,
        banner: PropTypes.string,
        logo: PropTypes.string,
        name: PropTypes.string.isRequired,
        website_url: PropTypes.string,
        present_in_lists: PropTypes.bool.isRequired,
        abilities: PropTypes.shape({
            diversity_provider_permission: PropTypes.bool.isRequired,
        }).isRequired,
    }),
    uid: PropTypes.string.isRequired,
    currentActor: PropTypes.shape({
        super_manager: PropTypes.bool.isRequired,
        profile_manager: PropTypes.bool.isRequired,
        organization: PropTypes.shape({
            uid: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    currentUser: PropTypes.shape({
        superadmin: PropTypes.bool.isRequired,
        admin: PropTypes.bool.isRequired,
    }),
};

export default Header;
