import _merge from "lodash/merge";
import { SET_ACTOR, UPDATE_ACTOR, START_FETCHING, STOP_FETCHING } from "./types";

const DEFAULT_STATE = { resource: {}, fetching: true };

const currentActorReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_ACTOR:
            return { ...state, resource: action.payload };
        case UPDATE_ACTOR:
            return _merge({}, state, action.payload);
        case START_FETCHING:
            return { ...state, fetching: true };
        case STOP_FETCHING:
            return { ...state, fetching: false };
        default:
            return state;
    }
};

export default currentActorReducer;
