import React from "react";
import PropTypes from "prop-types";

const Paragraph = (props) => (
        <div className="mb-4 mx-3">
            <h3>{props.title}</h3>
            {props.children}
        </div>
    );

Paragraph.propTypes = {
    title: PropTypes.string,
    children: PropTypes.object,
};

export default Paragraph;
