import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Spinner, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { ShowPassword } from "../../common/components";
import { acceptInvitation } from "../../api/deviseApi";
import { setAlert } from "../../state/alert/alertActions";
import I18n from "../../utils/i18n";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AcceptInvitationPage = () => {
    const token = useQuery().get("invitation_token");
    const dispatch = useDispatch();

    const setCommonErrors = (errors) => {
        const alert = _.map(
            errors,
            (messages, key) => `${I18n.t(key, { scope: "activerecord.attributes.user" })} ${messages.join("\n")}`,
        ).join("\n");
        dispatch(setAlert({ text: alert, variant: "danger" }));
    };

    const onSubmit = (values, { setSubmitting }) => {
        acceptInvitation({ user: { ...values.user, invitation_token: token } })
            .then(() => {
                window.location = "/";
            })
            .catch((error) => {
                setCommonErrors(error.response.data.errors);
                setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        user: Yup.object().shape({
            password: Yup.string()
                .min(8)
                .max(128)
                .matches(/.*[0-9]/, I18n.t("errors.messages.password_complexity.digit.one"))
                .matches(/.*[a-z]/, I18n.t("errors.messages.password_complexity.lower.one"))
                .matches(/.*[!@#$%^&*()_+=\-<>,.\\{}[\]]/, I18n.t("errors.messages.password_complexity.symbol.one"))
                .matches(/.*[A-Z]/, I18n.t("errors.messages.password_complexity.upper.one"))
                .required(),
            password_confirmation: Yup.string().oneOf(
                [Yup.ref("password"), null],
                I18n.t("errors.messages.same_as_new_password"),
            ),
        }),
    });

    return (
        <div className="form-wrapper bg-white p-4 rounded">
            <Formik
                initialValues={{ user: { password: "", password_confirmation: "" } }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, handleSubmit, handleChange, isSubmitting, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group>
                            <h2>{I18n.t("forms.invitation.title")}</h2>
                        </Form.Group>
                        <Form.Group>
                            <ShowPassword
                                placeholder={I18n.t("activerecord.attributes.user.password")}
                                name="user[password]"
                                onChange={handleChange}
                                value={values.user.password}
                                isInvalid={errors.user && errors.user.password}
                            />
                        </Form.Group>
                        <Form.Group>
                            <ShowPassword
                                placeholder={I18n.t("activerecord.attributes.user.password_confirmation")}
                                name="user[password_confirmation]"
                                onChange={handleChange}
                                value={values.user.password_confirmation}
                                isInvalid={errors.user && errors.user.password_confirmation}
                            />
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" className="text-white" disabled={isSubmitting}>
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2"
                                    />
                                )}
                                {I18n.t("forms.invitation.submit")}
                            </Button>
                        </Form.Group>
                        <div className="text-right">
                            <Link to="/users/invitation/new">
                                {I18n.t("devise.shared.links.didn_t_receive_invitation_instructions")}
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AcceptInvitationPage;
