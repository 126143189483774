import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import FirmwideMirrorChart from "./FirmwideMirrorChart";
import I18n from "../../../../utils/i18n";

const ComingAndGoingChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="coming_and_going"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        tooltipItem.value === "NaN"
                            ? ""
                            : `${data.datasets[tooltipItem.datasetIndex].label}: ${_round(
                                  tooltipItem.value,
                                  1
                              ).toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

ComingAndGoingChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default ComingAndGoingChart;
