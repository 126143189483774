import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificMirrorChart from "./DiversityClientSpecificMirrorChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const AttributesPercentsByClientChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        type="attributes_percents_by_client"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t(
                                "diversity.axises.client_specific_mirror.attributes_percents_by_client.y_axis"
                            ),
                        },
                    },
                ],
            },
        }}
    />
);

AttributesPercentsByClientChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default AttributesPercentsByClientChart;
