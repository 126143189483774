import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "../../../../common/components/DiversityPage/filters/common/MultipleChoiceFilter";

const IndustriesFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("tiered_diversity.filters.industries.title")}
        filterKey="industries"
        filterValues={values.industries}
        defaultParams={defaultParams.industries}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

IndustriesFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        industries: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default IndustriesFilter;
