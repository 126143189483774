import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import SourceTypeStep from "./SourceTypeStep";
import MethodStep from "./MethodStep";
import TableStep from "./TableStep";
import OptionsStep from "./OptionsStep";
import IndustryPickerStep from "./IndustryPickerStep";

const CreateProjectWizard = ({ show, onHide }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [eventType, setEventType] = useState(null);
    const [createMethod, setCreateMethod] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [filterValue, setFilterValue] = useState({
        legal: null,
        status: ["over", "scoring"],
        name_or_project_name_or_industry_name_or_user_display_name_cont: "",
    });
    const STEPS = [SourceTypeStep, MethodStep, TableStep, OptionsStep, IndustryPickerStep];

    useEffect(() => {
        if (show) {
            setEventType(null);
            setCreateMethod(null);
            setCurrentStep(0);
        }
    }, [show]);

    return (
        <CreateProjectWizardContext.Provider
            value={{
                currentStep: currentStep,
                setCurrentStep: setCurrentStep,
                eventType: eventType,
                setEventType: setEventType,
                createMethod: createMethod,
                setCreateMethod: setCreateMethod,
                onHide: onHide,
                selectedEvent: selectedEvent,
                setSelectedEvent: setSelectedEvent,
                filterValue: filterValue,
                setFilterValue: setFilterValue,
            }}
        >
            <Modal show={show} onHide={onHide} centered size={currentStep === 2 || currentStep === 1 ? "xl" : "lg"}>
                <div className="p-5">{React.createElement(STEPS[currentStep])}</div>
            </Modal>
        </CreateProjectWizardContext.Provider>
    );
};
CreateProjectWizard.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default CreateProjectWizard;
