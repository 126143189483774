import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import Select from "react-select";
import { useFormikContext } from "formik";
import I18n from "../../../../utils/i18n";
import currencies from "../../../../utils/currencies";
import AvatarContainer from "../../AvatarContainer";

const GeneralSection = ({ errors, values }) => {
    const { handleChange, setFieldValue, setFieldTouched } = useFormikContext();
    return (
        <>
            <Row>
                <Col>
                    <Form.Group controlId="organization_banner">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.banner")}</Form.Label>
                        <AvatarContainer inputName="organization[banner]" classNamePostfix="rect" />
                        <Form.Control.Feedback type="invalid">{errors.banner}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Form.Group controlId="organization_logo">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.logo")}</Form.Label>
                        <AvatarContainer inputName="organization[logo]" />
                        <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={9}>
                    <Form.Group controlId="organization_name">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.name")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="organization[name]"
                            onChange={handleChange}
                            value={values.name || ""}
                            isInvalid={errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="organization_description">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.description")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="organization[description]"
                            onChange={handleChange}
                            value={values.description || ""}
                            isInvalid={errors.description}
                        />
                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="organization_website_url">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.website_url")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="organization[website_url]"
                            onChange={handleChange}
                            value={values.website_url || ""}
                            isInvalid={errors.website_url}
                        />
                        <Form.Control.Feedback type="invalid">{errors.website_url}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="organization_currency_id">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.currency_id")}</Form.Label>
                        <Select
                            name="organization[currency_id]"
                            className={classNames("react-select", { "is-invalid": errors.country })}
                            classNamePrefix="react-select"
                            options={currencies}
                            getOptionLabel={(currency) =>
                                currency.iso_code === "XXX"
                                    ? currency.name
                                    : `${currency.symbol} (${currency.iso_code} (${currency.name}))`
                            }
                            getOptionValue={(currency) => currency.id}
                            onChange={(currency) => {
                                setFieldValue("organization[currency_id]", currency.id);
                            }}
                            onBlur={() => setFieldTouched("organization[currency_id]")}
                            value={currencies.find((currency) => currency.id === values.currency_id)}
                        />
                        <Form.Control.Feedback type="invalid">{errors.currency_id}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

GeneralSection.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default GeneralSection;
