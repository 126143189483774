import React from "react";
import PropTypes from "prop-types";
import _compact from "lodash/compact";
import I18n from "../../utils/i18n";

const FormattedScore = ({ jbScore, totalScore }) => (
    <div>
        <small className="text-muted">
            {_compact([
                jbScore && I18n.t("companies.profiles.sections.profiles.jb_score"),
                totalScore && I18n.t("companies.profiles.sections.profiles.total_score"),
            ]).join("/")}
        </small>
        <div>{_compact([jbScore, totalScore]).join("/")}</div>
    </div>
);

FormattedScore.propTypes = {
    jbScore: PropTypes.string,
    totalScore: PropTypes.string,
};

export default FormattedScore;
