import React from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik, getIn } from "formik";
import { useDispatch } from "react-redux";
import I18n from "../../../../utils/i18n";
import { updateList } from "../../../../api/listsApi";
import { setAlert } from "../../../../state/alert/alertActions";

const RenameListModal = ({ show, onClose, list, setReloadLists, setSectionTitle }) => {
    const dispatch = useDispatch();
    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        updateList(list.id, values)
            .then(() => {
                dispatch(setAlert({ variant: "info", translationKey: "common.alerts.successful_rename" }));
                setSectionTitle(values.companies_list.name);
                resetForm();
                setReloadLists((prevState) => !prevState);
            })
            .catch(() => setAlert({ variant: "danger", translationKey: "common.alerts.something_went_wrong" }))
            .finally(() => {
                setSubmitting(false);
                onClose();
            });
    };

    const handleClose = (handleReset) => {
        handleReset();
        onClose();
    };

    const validationSchema = Yup.object().shape({
        companies_list: Yup.object().shape({
            name: Yup.string().max(255).min(3).required(),
        }),
    });

    return (
        <Formik
            initialValues={{ companies_list: { name: list.name } }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ values, errors, isSubmitting, handleSubmit, handleReset, handleChange }) => (
                <Modal show={show} onHide={() => handleClose(handleReset)}>
                    <Modal.Header closeButton>{I18n.t("companies.lists.modals.rename_list.title")}</Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control
                                as="input"
                                name="companies_list.name"
                                onChange={handleChange}
                                value={values.companies_list.name}
                                isInvalid={getIn(errors, "companies_list.name")}
                            />
                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, "companies_list.name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(handleReset)}>
                            {I18n.t("common.links.cancel")}
                        </Button>
                        <Button disabled={isSubmitting} variant="primary" className="text-white" onClick={handleSubmit}>
                            <Spinner hidden={!isSubmitting} as="span" animation="border" size="sm" className="mr-2" />
                            {I18n.t("companies.lists.buttons.rename_list")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

RenameListModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setReloadLists: PropTypes.func.isRequired,
    setSectionTitle: PropTypes.func.isRequired,
    list: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
};

export default RenameListModal;
