import React, { useState, useEffect } from "react";
import { FormikProvider, getIn, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Button, Col, Container, Spinner } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _keys from "lodash/keys";
import _cloneDeep from "lodash/cloneDeep";
import _map from "lodash/map";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faUserLock } from "@fortawesome/pro-solid-svg-icons";
import ChartsWrapper from "./componets/ChartsWrapper";
import { getDashboard, updateDashboard } from "../../../api/diversityApi";
import I18n from "../../../utils/i18n";
import { getDefaultParamsForFilter } from "../../../utils/getDefaultParamsFor";
import useChannel from "../../../utils/channels/useChannel";

Yup.addMethod(Yup.array, "oneMandatoryForDisplay", (message, rootPath, setting) =>
    Yup.array().test("oneShown", "", (value, context) => {
        const errors = [];

        if (_isEmpty(value)) {
            return true;
        }

        if (_every(value, { settings: { [setting]: false } })) {
            errors.push(
                context.createError({
                    path: rootPath,
                    message: message,
                }),
            );
        }

        if (!_isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    }),
);

const DashboardForm = ({ namespace, chartsRow, backlink, description, getFilters, channelName }) => {
    const [inProgress, setInProgress] = useState(false);
    const [charts, setCharts] = useState({});
    const [chartParams, setChartParams] = useState({});
    const [chartData, setChartData] = useState({});
    const [chartsSubscribed, setChartsSubscribed] = useState(false);
    const [chartInProgress, setChartInProgress] = useState({});

    const history = useHistory();

    useChannel({
        channelParams: { channel: channelName },
        onReceived: ({ type, payload }) => {
            setChartData((prevState) => ({ ...prevState, ...{ [type]: payload } }));
            setChartInProgress((prevState) => ({ ...prevState, ...{ [type]: false } }));
        },
        onConnected: () => setChartsSubscribed(true),
    });

    useEffect(() => {
        getFilters()
            .then((response) => {
                const fetchedParams = getDefaultParamsForFilter(response.data);
                setChartParams(_cloneDeep(fetchedParams));
            })
            .catch(() => history.push("/"));
    }, []);

    useEffect(() => {
        if (!_isEmpty(chartParams) && chartsSubscribed) {
            setInProgress(true);
            setChartInProgress({});

            getDashboard(namespace, chartParams)
                .then((response) => setCharts(response.data))
                .finally(() => setInProgress(false));
        }
    }, [chartParams, chartsSubscribed]);

    const form = useFormik({
        initialValues: charts,
        enableReinitialize: true,
        onSubmit: (values) => {
            updateDashboard(namespace, values).finally(() => history.push(backlink));
        },
        validationSchema: Yup.object().shape({
            charts_attributes: Yup.array().oneMandatoryForDisplay(
                I18n.t("errors.messages.chart.must_be_shown"),
                "charts_attributes",
                "show_to_super_and_diversity_manager",
            ),
        }),
    });

    return (
        _keys(charts).length > 0 && (
            <FormikProvider value={form}>
                <div className="d-flex flex-column scrollable">
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Container fluid className="py-3">
                            <p>{description}</p>
                            <p>
                                <FontAwesomeIcon icon={faUserEdit} className="mr-1" />
                                {I18n.t(
                                    "diversity.charts.client_specific_dashboard.show_to_super_and_diversity_manager",
                                )}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faUserLock} className="mr-1" />
                                {I18n.t("diversity.charts.client_specific_dashboard.show_to_user")}
                            </p>
                            <ChartsWrapper
                                visibleCharts={_map(charts.charts_attributes || [], "name")}
                                chartsRow={chartsRow}
                                chartData={chartData}
                                chartInProgress={chartInProgress}
                                inProgress={inProgress}
                            />
                        </Container>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button variant="secondary" className="text-white" href={backlink}>
                                            {I18n.t("common.links.cancel")}
                                        </Button>
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            onClick={form.handleSubmit}
                                            disabled={!_isEmpty(form.errors)}
                                        >
                                            {form.isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                        <span className="text-danger ml-2">
                                            {getIn(form.errors, "charts_attributes")}
                                        </span>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </div>
            </FormikProvider>
        )
    );
};

DashboardForm.propTypes = {
    chartData: PropTypes.object,
    namespace: PropTypes.string.isRequired,
    chartsRow: PropTypes.array.isRequired,
    backlink: PropTypes.string,
    description: PropTypes.string.isRequired,
    getFilters: PropTypes.func.isRequired,
    channelName: PropTypes.string.isRequired,
};

export default DashboardForm;
