import API from "../utils/api";

export function getNegotiations(params) {
    return API.get("/widgets/negotiations", { params: params });
}

export function getActorNegotiations(params) {
    return API.get("/widgets/actor_negotiations", { params: params });
}

export function getMessages() {
    return API.get("/widgets/messaging");
}

export function getReports(params) {
    return API.get("/widgets/reports", {
        params: params,
    });
}

export function getFirmwideRequests() {
    return API.get("/widgets/diversity/firmwide/requests");
}

export function getClientSpecificRequests() {
    return API.get("/widgets/diversity/client_specific/requests");
}

export function getFirmwideStatistics() {
    return API.get("/widgets/diversity/firmwide/statistics");
}

export function getClientSpecificStatistics() {
    return API.get("/widgets/diversity/client_specific/statistics");
}
