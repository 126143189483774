import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import UploadForm from "./UploadForm";

const FileCell = ({ row: { original }, firmStats, updateData }) => {
    const [showModal, setShowModal] = useState(false);

    return original.submitted ? (
        <div className="d-flex flex-column">
            <a
                href={`/diversity/client_specific/quarters/${original.diversity_quarter_id}/partnerships/${original.diversity_partnership_id}/employees/edit`}
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon icon={faDownload} size="lg" />
            </a>
        </div>
    ) : (
        <>
            <div className="d-flex flex-column">
                <Link to="#" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                </Link>
            </div>
            <Modal
                className="client-specific-upload-form"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                        {I18n.t(`diversity.client_specific_upload.form.title`, { client_name: original.client_name })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadForm
                        firmStats={firmStats}
                        partnershipStats={original}
                        setPartnershipStats={(newPartnershipStats) => updateData(original.id, newPartnershipStats)}
                        setShowFormModal={setShowModal}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

FileCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            diversity_quarter_id: PropTypes.number.isRequired,
            diversity_partnership_id: PropTypes.number.isRequired,
            client_name: PropTypes.string.isRequired,
            submitted: PropTypes.bool.isRequired,
        }),
    }),
    firmStats: PropTypes.shape({
        submitted: PropTypes.bool.isRequired,
    }),
    updateData: PropTypes.func.isRequired,
};

export default FileCell;
