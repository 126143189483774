import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import DisplayField from "../content/DisplayField";
import I18n from "../../../../utils/i18n";

const Ownership = ({ values }) => (
    <>
        <Row>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.women_owned")}
                    value={values.women_owned ? I18n.t("common.links.text_yes") : I18n.t("common.links.text_no")}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.minority_owned")}
                    value={values.minority_owned ? I18n.t("common.links.text_yes") : I18n.t("common.links.text_no")}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.ethnicity")}
                    value={
                        values.ethnicity
                            ? I18n.t(`activerecord.attributes.company_structure.ethnicities.${values.ethnicity}`)
                            : I18n.t("common.links.text_no")
                    }
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.lgbtq_owned")}
                    value={values.lgbtq_owned ? I18n.t("common.links.text_yes") : I18n.t("common.links.text_no")}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.veteran_owned")}
                    value={values.veteran_owned ? I18n.t("common.links.text_yes") : I18n.t("common.links.text_no")}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.differently_abled_owned")}
                    value={
                        values.differently_abled_owned
                            ? I18n.t("common.links.text_yes")
                            : I18n.t("common.links.text_no")
                    }
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.disadvantaged_business")}
                    value={
                        values.disadvantaged_business ? I18n.t("common.links.text_yes") : I18n.t("common.links.text_no")
                    }
                />
            </Col>
        </Row>
    </>
);

Ownership.propTypes = {
    values: PropTypes.shape({
        women_owned: PropTypes.bool.isRequired,
        minority_owned: PropTypes.bool.isRequired,
        ethnicity: PropTypes.string,
        lgbtq_owned: PropTypes.bool.isRequired,
        veteran_owned: PropTypes.bool.isRequired,
        differently_abled_owned: PropTypes.bool.isRequired,
        disadvantaged_business: PropTypes.bool.isRequired,
    }),
};

export default Ownership;
