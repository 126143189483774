import React from "react";
import { Redirect } from "react-router-dom";
import qs from "qs";
import PrivateRoute from "./PrivateRoute";
import AppLoader from "./AppLoader";

const ExternalRoute = () => (
    <PrivateRoute
        path="/external/:app/:name"
        renderer={(routeProps) => {
            const redirectTo = qs.parse(routeProps.location.search, { ignoreQueryPrefix: true }).redirect_to;

            if (!redirectTo) {
                return <Redirect to="/" />;
            }

            window.location = `//${redirectTo}`;
            return <AppLoader />;
        }}
    />
);

export default ExternalRoute;
