import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Card, Col, Row, Table } from "react-bootstrap";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import _round from "lodash/round";
import I18n from "../../../utils/i18n";
import DIVERSITY_CURRENT_TITLE_VALUES from "../../../utils/diversityCurrentTitles";
import AccordionTitle from "../../../common/components/AccordionTitle";
import { getClientSpecificFirmStats, getClientSpecificUploadFilters } from "../../../api/diversityApi";
import DiversityClientSpecificUploadFiltersBar from "./DiversityFirmwideUploadFiltersBar";

const DiversityClientSpecificUploadData = ({ firm, activeId, setActiveId }) => {
    const [clients, setClients] = useState([]);
    const [chartParams, setChartParams] = useState({});

    useEffect(() => {
        getClientSpecificFirmStats(chartParams.quarter).then((response) => setClients(response.data.clients));
    }, [firm.last_changed_at, chartParams.quarter]);

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                title={I18n.t("diversity.client_specific_upload.data.title")}
                eventKey="data"
                activeId={activeId}
                toggleActive={setActiveId}
            />
            <Accordion.Collapse eventKey="data">
                <Card.Body>
                    <Row>
                        <Col className="mr-auto" />
                        <Col className="col-auto">
                            <DiversityClientSpecificUploadFiltersBar
                                getFilters={getClientSpecificUploadFilters}
                                setChartParams={setChartParams}
                            />
                        </Col>
                    </Row>
                    {map(clients, (client) => (
                        <Row key={client.id}>
                            <h3 className="text-uppercase">
                                {`${client.name} ${client.quarter_name} (${I18n.t(
                                    `tables.diversity/participation.submitted_statuses.${client.submitted}`
                                )})`}
                            </h3>
                            <Table bordered className="text-center">
                                <thead>
                                    <tr>
                                        <th scope="column" />
                                        {map(DIVERSITY_CURRENT_TITLE_VALUES, (currentTitle) => (
                                            <th scope="column" key={currentTitle}>
                                                {I18n.t(`diversity.current_titles.${currentTitle}`)}
                                            </th>
                                        ))}
                                        <th scope="column">{I18n.t("diversity.columns.total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{I18n.t("diversity.columns.hours")}</th>
                                        {map(DIVERSITY_CURRENT_TITLE_VALUES, (currentTitle) => (
                                            <td scope="row" key={currentTitle}>
                                                {_round(parseFloat(client.hours[currentTitle] || 0)).toLocaleString(
                                                    I18n.locale
                                                )}
                                            </td>
                                        ))}
                                        <td scope="row">
                                            {_round(
                                                sumBy(Object.values(client.hours), (value) => parseFloat(value))
                                            ).toLocaleString(I18n.locale)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{I18n.t("diversity.columns.fees")}</th>
                                        {map(DIVERSITY_CURRENT_TITLE_VALUES, (currentTitle) => (
                                            <td scope="row" key={currentTitle}>
                                                {_round(parseFloat(client.fees[currentTitle] || 0)).toLocaleString(
                                                    I18n.locale
                                                )}
                                            </td>
                                        ))}
                                        <td scope="row">
                                            {_round(
                                                sumBy(Object.values(client.fees), (value) => parseFloat(value))
                                            ).toLocaleString(I18n.locale)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{I18n.t("diversity.columns.number_of_attorneys")}</th>
                                        {map(DIVERSITY_CURRENT_TITLE_VALUES, (currentTitle) => (
                                            <td scope="row" key={currentTitle}>
                                                {(client.counts[currentTitle] || 0).toLocaleString(I18n.locale)}
                                            </td>
                                        ))}
                                        <td scope="row">
                                            {sumBy(Object.values(client.counts)).toLocaleString(I18n.locale)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    ))}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

DiversityClientSpecificUploadData.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        last_changed_at: PropTypes.number.isRequired,
    }),
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DiversityClientSpecificUploadData;
