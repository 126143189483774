import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import { getLatestPeriodRange } from "../../../utils/diversityQuarters";
import AccordionTitle from "../../../common/components/AccordionTitle";
import FileContainer from "../../../common/components/FileContainer";
import { updateFirmwideFirmEmployees } from "../../../api/diversityApi";
import useChannel from "../../../utils/channels/useChannel";

const DiversityFirmwideUploadForm = ({ firm, setFirm, activeId, setActiveId }) => {
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState([]);
    const currentActor = useSelector((state) => state.currentActor.resource);

    useChannel({
        channelParams: { channel: "Diversity::Firmwide::EmployeesChannel" },
        onReceived: ({ type, payload }) => {
            if (type === "finished") {
                setFirm((prevState) => ({
                    ...prevState,
                    ...payload.firm,
                    uploaded_at: moment().unix(),
                }));
                setSubmissionErrors(payload.errors);
                setSubmissionInProgress(false);
                payload.errors[0] || setActiveId("data");
            }
        },
        readyToSubscribe: !firm.submitted,
    });

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                title={I18n.t("diversity.firmwide_upload.form.title")}
                eventKey="form"
                activeId={activeId}
                toggleActive={setActiveId}
            />
            <Accordion.Collapse eventKey="form">
                <Card.Body>
                    <Row className="mb-3">
                        <Col>
                            <h2>{getLatestPeriodRange()}</h2>
                            <p>{I18n.t("diversity.firmwide_upload.form.first_step")}</p>
                            <Button
                                variant="primary"
                                className="text-white"
                                href="/diversity/firmwide/employees/new"
                                target="_blank"
                            >
                                {I18n.t("diversity.firmwide_upload.form.buttons.download_template")}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>{I18n.t("diversity.firmwide_upload.form.second_step")}</p>
                            <Button
                                variant="primary"
                                className="text-white"
                                href="/diversity/firmwide/employees/edit"
                                target="_blank"
                            >
                                {I18n.t("diversity.firmwide_upload.form.buttons.generate_current_state")}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>{I18n.t("diversity.firmwide_upload.form.third_step")}</p>

                            {firm.submitted ? (
                                <p className="text-primary">
                                    {I18n.t("diversity.firmwide_upload.submitted_notification")}
                                </p>
                            ) : submissionInProgress ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only" />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {submissionErrors &&
                                        submissionErrors.map((submissionError, index) => (
                                            <p key={index} className="text-danger">
                                                {submissionError}
                                            </p>
                                        ))}
                                    <FileContainer
                                        disabled={!(currentActor.super_manager || currentActor.diversity_manager)}
                                        accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                        onSuccess={(acceptedFiles) => {
                                            setSubmissionInProgress(true);
                                            setSubmissionErrors([]);

                                            updateFirmwideFirmEmployees({
                                                file: acceptedFiles[0],
                                            }).catch(() => {
                                                setSubmissionErrors([I18n.t("diversity.excel.errors.common")]);
                                            });
                                        }}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3 font-weight-bold">
                        <Col>
                            <p>{I18n.t("diversity.firmwide_upload.form.notice")}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

DiversityFirmwideUploadForm.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
    }),
    setFirm: PropTypes.func.isRequired,
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadForm;
