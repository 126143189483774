import API from "../utils/api";

export function getTemplates(params) {
    return API.get("/widgets/templates", { params: params });
}

export function cloneFromTemplate(template_id) {
    return API.post(`/widgets/templates/${template_id}/clone`);
}

export function addFavorite(template_id) {
    return API.post(`/widgets/templates/${template_id}/favorites`);
}

export function removeFavorite(template_id) {
    return API.delete(`/widgets/templates/${template_id}/favorites`);
}
