import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import UsersTable from "./content/UsersTable";

const Users = ({ uid }) => (
        <Card className="mb-2 border-top-0">
            <Card.Body>
                <UsersTable organizationUid={uid} />
            </Card.Body>
        </Card>
    );

Users.propTypes = { uid: PropTypes.string.isRequired };

export default Users;
