import API from "../utils/api";

export function getTemplates(params) {
    return API.get("/templates", { params: params });
}

export function getCloneOptionsForTemplate(template_id) {
    return API.get(`/templates/${template_id}/clone/new`);
}

export function cloneTemplate(template_id, params) {
    return API.post(`/templates/${template_id}/clone`, params);
}

export function addFavorite(template_id) {
    return API.post(`/templates/${template_id}/favorites`);
}

export function removeFavorite(template_id) {
    return API.delete(`/templates/${template_id}/favorites`);
}
