import { createContext } from "react";

export const CreateProjectWizardContext = createContext({
    currentStep: null,
    setCurrentStep: null,
    eventType: null,
    setEventType: null,
    createMethod: null,
    setCreateMethod: null,
    onHide: null,
    selectedEvent: null,
    setSelectedEvent: null,
    filterValue: {},
    setFilterValue: null,
    exportCompanies: [],
    negotiationParams: {},
    setNegotiationParams: null,
});
