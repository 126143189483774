import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const FirmsFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
        <MultipleChoiceFilter
            label={I18n.t("diversity.buttons.select_firm")}
            filterKey="firms"
            filterValues={values.firms}
            defaultParams={defaultParams.firms}
            params={params}
            setParams={setParams}
            applyFilters={applyFilters}
        />
    );

FirmsFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        firms: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default FirmsFilter;
