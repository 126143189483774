import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { getDiversityAnalytics } from "../../../api/diversityApi";
import I18n from "../../../utils/i18n";

const DiversityGlobalAnalytics = ({ currency }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fwAnalytics, setFwAnalytics] = useState({});
    const [csAnalytics, setCsAnalytics] = useState({});
    const [feesTotal, setFeesTotal] = useState(0.0);

    useEffect(() => {
        setIsLoading(true);
        getDiversityAnalytics()
            .then((response) => {
                setFwAnalytics(response.data.fw_analytics);
                setCsAnalytics(response.data.cs_analytics);
                setFeesTotal(parseFloat(response.data.cs_analytics.fees_total));
            })
            .finally(() => setIsLoading(false));
    }, []);

    return isLoading ? (
        <div className="d-flex justify-content-center">
            <Spinner as="span" animation="border" role="status" aria-hidden="true" />
        </div>
    ) : (
        <>
            <div>
                {I18n.t("home.diversity_analytics.fw_analytics", {
                    quarter: fwAnalytics?.quarter,
                    year: fwAnalytics?.year,
                    firms_count: fwAnalytics?.firms_count,
                    attorneys_count: fwAnalytics?.attorneys_count,
                })}
            </div>
            <div>
                {I18n.t("home.diversity_analytics.cs_analytics", {
                    fee_total: feesTotal.toLocaleString(I18n.locale, {
                        style: "currency",
                        currency: currency,
                    }),
                    firms_count: csAnalytics?.firms_count,
                })}
            </div>
            <hr />
        </>
    );
};

DiversityGlobalAnalytics.propTypes = {
    currency: PropTypes.string.isRequired,
};

export default DiversityGlobalAnalytics;
