import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "react-bootstrap";
import _isNull from "lodash/isNull";
import RequestModal from "../modals/RequestModal";
import I18n from "../../../../utils/i18n";

const RequestActions = ({ values }) => {
    const [showRequest, setShowRequest] = useState(false);
    const [type, setType] = useState(undefined);

    return (
        <>
            <ButtonGroup className="m-3">
                {values.abilities.diversity_firmwide_viewer_permission &&
                    values.abilities.super_or_diversity_manager &&
                    values.participation?.status !== "approved" && (
                        <Button
                            variant="primary"
                            className="text-white"
                            onClick={() => {
                                setType("firmwide");
                                setShowRequest(true);
                            }}
                            disabled={values.participation?.status === "requested"}
                        >
                            {I18n.t("companies.profiles.actions.request_firmwide")}
                        </Button>
                    )}
                {values.abilities.diversity_client_viewer_permission &&
                    values.abilities.super_or_diversity_manager &&
                    !values.partnership?.submitted && (
                        <Button
                            variant="primary"
                            className="text-white"
                            onClick={() => {
                                setType("client_specific");
                                setShowRequest(true);
                            }}
                            disabled={
                                (values.partnership && values.partnership.submitted) ||
                                !(
                                    _isNull(values.available_request_after_cancel) ||
                                    values.available_request_after_cancel
                                )
                            }
                        >
                            {I18n.t("companies.profiles.actions.request_client_specific")}
                        </Button>
                    )}
            </ButtonGroup>

            {type && (
                <RequestModal
                    show={showRequest}
                    onClose={() => setShowRequest(false)}
                    type={type}
                    organization_id={values.id}
                />
            )}
        </>
    );
};

RequestActions.propTypes = {
    values: PropTypes.shape({
        id: PropTypes.number.isRequired,
        abilities: PropTypes.shape({
            diversity_firmwide_viewer_permission: PropTypes.bool.isRequired,
            diversity_client_viewer_permission: PropTypes.bool.isRequired,
            super_or_diversity_manager: PropTypes.bool.isRequired,
        }).isRequired,
        partnership: PropTypes.shape({
            submitted: PropTypes.bool,
            canceled_at: PropTypes.string,
        }),
        participation: PropTypes.shape({
            status: PropTypes.string,
        }),
        available_request_after_cancel: PropTypes.bool,
    }),
};

export default RequestActions;
