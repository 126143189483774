import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../utils/i18n";
import Reports from "./components/Reports";
import Messages from "./components/Messages";
import { DismissibleAlert } from "../../common/components";
import { setAlert } from "../../state/alert/alertActions";
import DiversityAnalytics from "./components/DiversityAnalytics";
import Templates from "./components/Templates";
import Negotiations from "./components/Negotiations";

const HomePage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const currentActor = useSelector((state) => state.currentActor.resource);
    if (isEmpty(currentActor)) return <Redirect to="/select_organizations" />;
    const isOrganizationPresent = !_isEmpty(currentActor.organization);

    return (
        <>
            <DismissibleAlert
                show={alert.show}
                variant={alert.variant}
                message={alert.message}
                onClose={() => setAlert({ show: false })}
            />

            <div className="col-12 scrollable full-scrollable">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                        <h2>{I18n.t("applications.launchpad")}</h2>
                    </div>
                    {isOrganizationPresent && currentActor.organization.permissions.supplier_permission && (
                        <>
                            <Negotiations isCustomer={currentActor.organization.permissions.customer_permission} />
                            {currentActor.organization.permissions.customer_permission && <Templates />}

                            <Messages />
                        </>
                    )}

                    <DiversityAnalytics currentUser={currentUser} currentActor={currentActor} />

                    {isOrganizationPresent && currentActor.organization.permissions.customer_permission && <Reports />}
                </div>
            </div>
        </>
    );
};

HomePage.propTypes = {
    currentActor: PropTypes.shape({
        fetching: PropTypes.bool.isRequired,
        resource: PropTypes.object,
    }),
};

export default HomePage;
