import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import FileContainer from "../../../common/components/FileContainer";
import { updateClientSpecificFirmEmployees } from "../../../api/diversityApi";
import useChannel from "../../../utils/channels/useChannel";

const DiversityClientSpecificUploadForm = ({ firm, setFirm, partnership, setPartnership, setShowFormModal }) => {
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState([]);
    const currentActor = useSelector((state) => state.currentActor.resource);

    useChannel({
        channelParams: {
            channel: "Diversity::ClientSpecific::EmployeesChannel",
        },
        onReceived: ({ type, payload }) => {
            if (type === "finished") {
                setPartnership(partnership.id, payload.partnership);
                setFirm((prevState) => ({
                    ...prevState,
                    last_changed_at: moment().unix(),
                }));
                setSubmissionErrors(payload.errors);
                setSubmissionInProgress(false);
                payload.errors[0] || setShowFormModal(false);
            }
        },
        readyToSubscribe: !partnership.stats?.submitted,
    });

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.first_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href={`/diversity/client_specific/partnerships/${partnership.id}/employees/new`}
                        target="_blank"
                    >
                        {I18n.t("diversity.client_specific_upload.form.buttons.download_template")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.second_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href={`/diversity/client_specific/partnerships/${partnership.id}/employees/edit`}
                        target="_blank"
                    >
                        {I18n.t("diversity.client_specific_upload.form.buttons.generate_current_state")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.third_step")}</p>

                    {partnership.stats?.submitted || !firm.submitted ? (
                        <>
                            {partnership.stats?.submitted && (
                                <p className="text-primary">
                                    {I18n.t("diversity.client_specific_upload.submitted_notification")}
                                </p>
                            )}
                            {firm.submitted || (
                                <p className="text-primary">
                                    {I18n.t("diversity.client_specific_upload.firmwide_submitted_notification")}
                                </p>
                            )}
                        </>
                    ) : submissionInProgress ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only" />
                            </div>
                        </div>
                    ) : (
                        <>
                            <FileContainer
                                disabled={!(currentActor.super_manager || currentActor.diversity_manager)}
                                accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                onSuccess={(acceptedFiles) => {
                                    setSubmissionInProgress(true);
                                    setSubmissionErrors([]);

                                    updateClientSpecificFirmEmployees(partnership.id, {
                                        file: acceptedFiles[0],
                                    }).catch(() => {
                                        setSubmissionErrors([I18n.t("diversity.excel.errors.common")]);
                                    });
                                }}
                            />
                            <p className="mt-3">{I18n.t("diversity.client_specific_upload.form.fourth_step")}</p>
                            <div className="overflow-auto mt-3">
                                {submissionErrors.map((submissionError, index) => (
                                    <p key={index} className="text-danger">
                                        {submissionError}
                                    </p>
                                ))}
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
};

DiversityClientSpecificUploadForm.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
    }),
    setFirm: PropTypes.func.isRequired,
    partnership: PropTypes.shape({
        id: PropTypes.number.isRequired,
        stats: PropTypes.shape({ submitted: PropTypes.bool.isRequired }),
    }),
    setPartnership: PropTypes.func.isRequired,
    setShowFormModal: PropTypes.func.isRequired,
};

export default DiversityClientSpecificUploadForm;
