import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _keys from "lodash/keys";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _zipObject from "lodash/zipObject";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import I18n from "../../../utils/i18n";
import { getDefaultParamsForFilter } from "../../../utils/getDefaultParamsFor";

const FiltersBar = ({
    filterTypes,
    setChartParams,
    getFilters,
    children,
    setFilter,
    setChangedFilters,
    loadedAt = null,
}) => {
    const [diversityValues, setDiversityValues] = useState({});
    const [defaultParams, setDefaultParams] = useState({});
    const [params, setParams] = useState({});
    const history = useHistory();

    useEffect(() => {
        getFilters()
            .then((response) => {
                const fetchedParams = getDefaultParamsForFilter(response.data);
                setDiversityValues(response.data);
                setFilter && setFilter(response.data);
                setDefaultParams(_cloneDeep(fetchedParams));
                setParams(_cloneDeep(fetchedParams));
                setChartParams(_cloneDeep(fetchedParams));
            })
            .catch(() => history.push("/"));
    }, [loadedAt]);

    const compareWithDefault = (defaultCompareParams, compareParams) =>
        _map(_keys(defaultCompareParams), (key) => {
            switch (compareParams[key] ? compareParams[key].constructor : null) {
                case Array:
                    return !_isEqual(compareParams[key].sort(), defaultCompareParams[key].sort());
                case Object:
                    return !compareWithDefault(defaultCompareParams[key], compareParams[key]).every((v) => v === false);
                default:
                    return defaultCompareParams[key] !== compareParams[key];
            }
        });

    const applyFilters = () => {
        setChartParams(_cloneDeep(params));
        setChangedFilters &&
            setChangedFilters(
                _zipObject(
                    _keys(defaultParams),
                    compareWithDefault(params, getDefaultParamsForFilter(diversityValues)),
                ),
            );
    };

    const clearFilters = () => {
        setParams(_cloneDeep(defaultParams));
        setChartParams(_cloneDeep(defaultParams));
        setChangedFilters && setChangedFilters({});
    };

    if (_isEmpty(params) || _isEmpty(diversityValues)) {
        return <></>;
    }

    return (
        <>
            {_map(filterTypes, (filterType, index) =>
                React.createElement(filterType, {
                    key: index,
                    params: params,
                    setParams: setParams,
                    values: diversityValues,
                    defaultParams: defaultParams,
                    applyFilters: applyFilters,
                }),
            )}
            <div className="d-inline-flex m-1">
                <Button variant="secondary" className="text-light" onClick={clearFilters}>
                    {I18n.t("common.clear")}
                </Button>
            </div>
            {children}
        </>
    );
};

FiltersBar.propTypes = {
    filterTypes: PropTypes.array.isRequired,
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
    children: PropTypes.node,
    setFilter: PropTypes.func,
    setChangedFilters: PropTypes.func,
    loadedAt: PropTypes.string,
};

export default FiltersBar;
