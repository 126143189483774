import React, { useEffect, useState } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WidgetCard from "../common/WidgetCard";
import { getFirmwideStatistics } from "../../../../api/informationApi";
import I18n from "../../../../utils/i18n";

const StatusLabel = ({ status, color, count }) => (
    <p>
        {I18n.t("home.diversity_analytics.charts.common.firms", {
            count: count,
        })}
        <Badge variant={color}>{I18n.t(`activerecord.attributes.diversity/participation.statuses.${status}`)}</Badge>
    </p>
);

const FirmwideStatistics = () => {
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        getFirmwideStatistics().then((response) => setStatistics(response.data));
    }, []);

    return (
        <WidgetCard title={I18n.t("home.diversity_analytics.charts.firmwide.title")} loading={!statistics}>
            <Row>
                <Col>
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.requested", {
                            count: statistics?.stats.total,
                        })}
                    </p>
                    <StatusLabel count={statistics?.stats.reviewed} status="reviewed" color="warning" />
                    <StatusLabel count={statistics?.stats.approved} status="approved" color="success" />
                    <StatusLabel count={statistics?.stats.rejected} status="rejected" color="danger" />
                    <StatusLabel count={statistics?.stats.requested} status="requested" color="light" />
                    <p>
                        {I18n.t("home.diversity_analytics.charts.common.rate", { count: statistics?.stats.rate || 0 })}
                    </p>
                </Col>
                <Col>
                    <Pie
                        data={statistics?.data}
                        height={200}
                        options={{
                            responsive: false,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <Link to="/diversity/dashboards/firmwide">
                            {I18n.t("home.diversity_analytics.charts.firmwide.link")}
                        </Link>
                    </p>
                </Col>
            </Row>
        </WidgetCard>
    );
};

StatusLabel.propTypes = {
    status: PropTypes.string,
    count: PropTypes.number,
    color: PropTypes.string,
};

export default FirmwideStatistics;
