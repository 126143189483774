import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import FileContainer from "../../../common/components/FileContainer";
import { updateFirmwideFirmEmployees } from "../../../api/diversityApi";
import useChannel from "../../../utils/channels/useChannel";

const UploadForm = ({ firmStats, title }) => {
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState([]);
    const currentActor = useSelector((state) => state.currentActor.resource);

    useChannel({
        channelParams: { channel: "Diversity::Firmwide::EmployeesChannel", quarter_id: firmStats.diversity_quarter_id },
        onReceived: ({ type, payload }) => {
            if (type === "finished") {
                setSubmissionErrors(payload.errors);
                setSubmissionInProgress(false);
            }
        },
        readyToSubscribe: !firmStats.submitted,
    });

    return (
        <Card.Body>
            <Row className="mb-3">
                <Col>
                    <h2>{title}</h2>
                    <p>{I18n.t("diversity.firmwide_upload.form.first_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href="/diversity/firmwide/employees/new"
                        target="_blank"
                    >
                        {I18n.t("diversity.firmwide_upload.form.buttons.download_template")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.firmwide_upload.form.second_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href="/diversity/firmwide/employees/edit"
                        target="_blank"
                    >
                        {I18n.t("diversity.firmwide_upload.form.buttons.generate_current_state")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.firmwide_upload.form.third_step")}</p>

                    {submissionInProgress ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only" />
                            </div>
                        </div>
                    ) : (
                        <>
                            {submissionErrors &&
                                submissionErrors.map((submissionError, index) => (
                                    <p key={index} className="text-danger">
                                        {submissionError}
                                    </p>
                                ))}
                            <FileContainer
                                disabled={
                                    firmStats.submitted ||
                                    !(currentActor.super_manager || currentActor.diversity_manager)
                                }
                                accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                onSuccess={(acceptedFiles) => {
                                    setSubmissionInProgress(true);
                                    setSubmissionErrors([]);

                                    updateFirmwideFirmEmployees({
                                        file: acceptedFiles[0],
                                        quarter_id: firmStats.diversity_quarter_id,
                                    }).catch(() => {
                                        setSubmissionErrors([I18n.t("diversity.excel.errors.common")]);
                                    });
                                }}
                            />
                        </>
                    )}
                </Col>
            </Row>
            <Row className="mb-3 font-weight-bold">
                <Col>
                    <p>{I18n.t("diversity.firmwide_upload.form.notice")}</p>
                </Col>
            </Row>
        </Card.Body>
    );
};

UploadForm.propTypes = {
    title: PropTypes.string.isRequired,
    firmStats: PropTypes.shape({
        diversity_quarter_id: PropTypes.number,
        submitted: PropTypes.bool.isRequired,
    }),
};

export default UploadForm;
