import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import I18n from "../../../utils/i18n";

const PublicDomainsFields = ({ values, errors, handleChange }) => (
        <Form>
            <Form.Group controlId="public_domain_domain">
                <Form.Label>{I18n.t("activerecord.attributes.public_domain.domain")}</Form.Label>
                <Form.Control
                    type="text"
                    name="public_domain[domain]"
                    onChange={handleChange}
                    value={values.public_domain.domain || ""}
                    isInvalid={get(errors, "public_domain.domain") || get(errors, "domain")}
                />
                <Form.Control.Feedback type="invalid">
                    {get(errors, "public_domain.domain") || get(errors, "domain")}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="public_domain_website_url">
                <Form.Label>{I18n.t("activerecord.attributes.public_domain.website_url")}</Form.Label>
                <Form.Control
                    type="text"
                    name="public_domain[website_url]"
                    onChange={handleChange}
                    value={values.public_domain.website_url || ""}
                    isInvalid={get(errors, "public_domain.website_url") || get(errors, "website_url")}
                />
                <Form.Control.Feedback type="invalid">
                    {get(errors, "public_domain.website_url") || get(errors, "website_url")}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );

PublicDomainsFields.propTypes = {
    values: PropTypes.shape({
        public_domain: PropTypes.shape({
            domain: PropTypes.string.isRequired,
            website_url: PropTypes.string,
        }),
    }),
    errors: PropTypes.shape({
        public_domain: PropTypes.object,
    }),
    handleChange: PropTypes.func.isRequired,
};

export default PublicDomainsFields;
