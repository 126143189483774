import React from "react";
import PropTypes from "prop-types";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const ScoreByFirmTableChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        chartOptions={chartOptions}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="score_by_firm_table"
        chartType="table"
    />
);

ScoreByFirmTableChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default ScoreByFirmTableChart;
