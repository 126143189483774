import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import _isNull from "lodash/isNull";
import * as Yup from "yup";
import I18n from "../../utils/i18n";
import FormDiversityCertificate from "../../common/components/FormDiversityCertificate";
import { getCertificate, updateCertificate } from "../../api/diversityApi";
import { setAlert } from "../../state/alert/alertActions";
import { CertificateValidation } from "../../common/components/DiversityPage/CertificateValidationSchema";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import EditPageTitle from "../../common/components/EditPageTitle";

const EditDiversityCertificatePage = () => {
    const { certificateId, uid } = useParams();
    const [certificate, setCertificate] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getCertificate(certificateId, uid)
            .then((response) => setCertificate(response.data))
            .catch(() => {
                history.push("/");
                dispatch(setAlert({ translationKey: "common.alerts.something_went_wrong", variant: "danger" }));
            });
    }, []);

    const backPath = `/organizations/${uid}/diversityCertificates`;

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        updateCertificate(certificateId, uid, values)
            .then(() => {
                setSubmitting(false);
                resetForm(values);
                history.push(backPath);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
            })
            .catch((error) => {
                history.push(`/organizations/${uid}/diversity/certificates/${certificateId}/edit`);
                setErrors(error.response.data.errors);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        !_isNull(certificate) && (
            <Formik
                initialValues={certificate}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    diversity_certificate: CertificateValidation,
                })}
            >
                {(form) => (
                    <>
                        <TopBarWithBackLink
                            backPath={backPath}
                            linkName={I18n.t("forms.organization.edit.tabs.diversity_certificates")}
                        />
                        <NavigationPrompt when={form.dirty} />
                        <div className="scrollable d-flex flex-column">
                            <div className="flex-grow-1 overflow-auto bg-white">
                                <Col>
                                    <Container fluid className="py-3">
                                        <EditPageTitle
                                            resource={I18n.t("companies.profiles.sections.filters.certificates")}
                                        />

                                        <FormikForm as={Form}>
                                            <FormDiversityCertificate
                                                form={form}
                                                certificateId={certificateId}
                                                organizationUid={uid}
                                            />
                                        </FormikForm>
                                    </Container>
                                </Col>
                            </div>
                            <div className="bottom-navigation p-2">
                                <Col>
                                    <Container fluid>
                                        <div className="d-flex justify-content-between">
                                            <div className="form-actions">
                                                <Button
                                                    className="ml-2 text-white"
                                                    variant="primary"
                                                    disabled={form.isSubmitting}
                                                    onClick={form.handleSubmit}
                                                >
                                                    {form.isSubmitting && (
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="mr-2"
                                                        />
                                                    )}
                                                    {I18n.t("common.links.save")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Container>
                                </Col>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        )
    );
};

export default EditDiversityCertificatePage;
