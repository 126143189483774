import React from "react";
import { Container } from "react-bootstrap";
import CopyrightContainer from "./CopyrightContainer";

const FooterContainer = () => (
    <footer id="footer" className="py-3">
        <Container>
            <CopyrightContainer />
        </Container>
    </footer>
);

export default FooterContainer;
