import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _isUndefined from "lodash/isUndefined";
import I18n from "../../../../utils/i18n";

const MiniStatsBar = ({ chartData, canvasRefs }) => {
    const title = I18n.t("diversity.charts.client_specific_progress.mini_stats_bar", chartData);
    if (_isUndefined(chartData)) return <></>;

    let quarters = [...chartData.selected_quarters];
    const quartersPerLine = 7;
    const selectedQuarters = [
        I18n.t("diversity.charts.client_specific_progress.selected_periods", {
            selected_quarters: quarters.splice(0, quartersPerLine),
        }),
    ];
    while (quarters.length > 0) {
        selectedQuarters.push(quarters.splice(0, quartersPerLine).join(", "));
    }
    canvasRefs.current["mini_stats_bar"] = {
        data: [title, ...selectedQuarters],
        position: 0,
        tagName: "TEXT_LIST",
    };

    return (
        <>
            <div key="title">{title}</div>
            {_map(selectedQuarters, (quarter, index) => (
                <div key={`quarter-${index}`}>{quarter}</div>
            ))}
        </>
    );
};

MiniStatsBar.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    canvasRefs: PropTypes.object,
};

export default MiniStatsBar;
