import React from "react";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import I18n from "../../../../utils/i18n";

const KeyIndicatorsTable = () => (
    <Card className="mt-3">
        <Card.Body>
            <Card.Title as="h4">{I18n.t("diversity.charts.client_specific_marketing.key_indicator_table")}</Card.Title>
            <Table responsive bordered className="diversity-table">
                <thead>
                    <tr>
                        <th scope="col" rowSpan={3} />
                        <th scope="col" />
                        <th scope="col" colSpan={5}>
                            {I18n.t("diversity.key_indicators.hours_distribution_goal")}
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">{I18n.t("diversity.columns.attributes")}</th>
                        <th scope="col">{I18n.t("diversity.columns.current_percentage")}</th>
                        <th scope="col">{I18n.t("diversity.columns.current_points")}</th>
                        <th scope="col">{I18n.t("diversity.columns.possible_points")}</th>
                        <th scope="col">{I18n.t("diversity.columns.direction")}</th>
                        <th scope="col">{I18n.t("diversity.columns.goal")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row" rowSpan={8}>
                            {I18n.t("diversity.groups.race")}
                        </th>
                        <th scope="row">{I18n.t("diversity.attributes.race.white")}</th>
                        <td scope="row">10.35</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="max" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{I18n.t("diversity.attributes.race.african_american/black")}</th>
                        <td scope="row">53.56</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{I18n.t("diversity.attributes.race.alaska_native/american_indian")}</th>
                        <td scope="row">0.39</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{I18n.t("diversity.attributes.race.asian")}</th>
                        <td scope="row">12.62</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{I18n.t("diversity.attributes.race.hispanic/latin_x")}</th>
                        <td scope="row">4.54</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            {I18n.t("diversity.attributes.race.native_hawaiian/other_pacific_islander")}
                        </th>
                        <td scope="row">0.00</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{I18n.t("diversity.attributes.race.multiracial")}</th>
                        <td scope="row">5.72</td>
                        <td scope="row">0.00</td>
                        <td scope="row">
                            <Form.Control />
                        </td>
                        <td scope="row">
                            <Form.Control as="select" value="min" custom>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                            </Form.Control>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <Form.Control />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card.Body>
    </Card>
);

export default KeyIndicatorsTable;
