import React  from "react";
import PropTypes from "prop-types";
import { Badge, Dropdown, Form } from "react-bootstrap";
import I18n from "../../../../../utils/i18n";

const SingleChoiceFilter = ({ filterKey, filterValues, label, params, setParams, defaultParams, applyFilters }) => {
    const componentParam = params[filterKey];

    const updateParams = (value) => {
        setParams({ ...params, [filterKey]: value });
    };

    const badgeCount = () => (componentParam === defaultParams ? 0 : 1);

    return (
        <Dropdown as="div" className="d-inline-flex">
            <Dropdown.Toggle variant="primary" className="text-light m-1">
                {label}
                {badgeCount() > 0 && (
                  <Badge variant="light" className="mx-1">
                      {badgeCount()}
                  </Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="dropdown-submenu">
                    {filterValues.map(
                        (filterValue) =>
                            (
                                <div className="dropdown-item" key={filterValue.id}>
                                    <Form.Check
                                        custom
                                        name={filterKey}
                                        type="radio"
                                        id={`${filterKey}-${filterValue.id}`}
                                        label={filterValue.label}
                                        checked={componentParam === filterValue.id.toString()}
                                        onChange={() => updateParams(filterValue.id.toString())}
                                    />
                                </div>
                            )
                    )}
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <Dropdown.Item onClick={applyFilters} className="text-center">
                        {I18n.t("diversity.buttons.submit")}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

SingleChoiceFilter.propTypes = {
    filterKey: PropTypes.string.isRequired,
    filterValues: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    defaultParams: PropTypes.string,
    applyFilters: PropTypes.func.isRequired,
};

export default SingleChoiceFilter;
