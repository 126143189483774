import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import _includes from "lodash/includes";
import _values from "lodash/values";
import { useFormikContext } from "formik";
import OwnershipInputs from "../inputs/OwnershipInputs";
import I18n from "../../../../utils/i18n";

const OwnershipSection = ({ values, errors }) => {
    const { handleChange, setFieldValue, setFieldTouched } = useFormikContext();
    const isActiveTogglers = _includes(_values(values), true);
    const [showOwnership, setShowOwnership] = useState(isActiveTogglers);

    return (
        <Row>
            <Col>
                <Form.Group>
                    <h3>{I18n.t("forms.organization.edit.sections.ownership")}</h3>
                </Form.Group>

                <Row className="flex-grow-1">
                    <Col>
                        <FormLabel>{I18n.t("wizard.steps.company.text")}</FormLabel>
                    </Col>
                </Row>

                <Form.Group controlId="show_ownership">
                    <Form.Check
                        type="switch"
                        name="show_ownership"
                        onChange={(event) => setShowOwnership(event.target.checked)}
                        checked={showOwnership}
                    />
                    {showOwnership && !isActiveTogglers && (
                        <Form.Control.Feedback type="invalid">
                            {I18n.t("wizard.steps.company.global_error")}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                {showOwnership && (
                    <OwnershipInputs
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        ownerType="organization"
                    />
                )}

                <Form.Group className="d-flex-inline align-items-center font-weight-bold">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2 info-icon" />
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.ownership_text")}</Form.Label>
                </Form.Group>
            </Col>
        </Row>
    );
};

OwnershipSection.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default OwnershipSection;
