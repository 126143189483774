import React from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import I18n from "../../utils/i18n";
import FormUserPersonalDetails from "../../common/components/FormUserPersonalDetails";
import { createActor } from "../../api/actorApi";
import { setAlert } from "../../state/alert/alertActions";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import NewPageTitle from "../../common/components/NewPageTitle";

const validationSchema = Yup.object().shape({
    actor: Yup.object().shape({
        user_attributes: Yup.object().shape({
            email: Yup.string().email().required(),
            first_name: Yup.string().max(255).required(),
            last_name: Yup.string().max(255).required(),
            second_email: Yup.string().email().nullable(),
        }),
    }),
});

const NewUserPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { organization_uid } = useParams();
    const backPath = `/organizations/${organization_uid}/users`;

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createActor({ values: values.actor, organization_uid: organization_uid })
            .then(() => {
                resetForm(values);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                history.push(backPath);
            })
            .catch((error) => {
                setErrors(error.response.data.errors);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={{}} enableReinitialize={true} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(form) => (
                <>
                    <TopBarWithBackLink backPath={backPath} linkName={I18n.t("forms.organization.edit.tabs.users")} />
                    <NavigationPrompt when={form.dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <NewPageTitle resource={I18n.t("tables.users.user")} />

                                    <FormikForm as={Form}>
                                        <FormUserPersonalDetails form={form} />
                                    </FormikForm>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                type="button"
                                                className="ml-2"
                                                variant="outline-secondary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleReset}
                                            >
                                                {I18n.t("common.links.cancel")}
                                            </Button>
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleSubmit}
                                            >
                                                {form.isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default NewUserPage;
