import React, { useState } from "react";
import { Container, Col, Accordion } from "react-bootstrap";
import I18n from "../../utils/i18n";
import DataSection from "./components/DataSection";
import DetailsSection from "./components/DetailsSection";

const TieredDiversityDashboardPage = () => {
    const [activeId, setActiveId] = useState("data");

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                                <h2>{I18n.t("tiered_diversity.dashboard.title")}</h2>
                            </div>
                        </Container>
                    </Col>
                    <Container fluid className="py-3">
                        <Accordion activeKey={activeId}>
                            <DataSection activeId={activeId} setActiveId={setActiveId} />
                            <DetailsSection activeId={activeId} setActiveId={setActiveId} />
                        </Accordion>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default TieredDiversityDashboardPage;
