import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityFirmwideChart from "./DiversityFirmwideChart";

const AverageClientsPerPartnerChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="average_clients_per_partner"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            legend: { display: false },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: { beginAtZero: true },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.average_clients_per_partner.x_axis"),
                        },
                    },
                ],
            },
        }}
    />
);

AverageClientsPerPartnerChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default AverageClientsPerPartnerChart;
