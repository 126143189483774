import qs from "qs";
import API from "../utils/api";

export function getPublicDomains(params) {
    return API.get(
        `/public_domains/?${qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false,
        })}`
    );
}

export function createPublicDomain(params) {
    return API.post("/public_domains", params);
}

export function getPublicDomain(id) {
    return API.get(`/public_domains/${id}`);
}

export function updatePublicDomain(id, params) {
    return API.patch(`/public_domains/${id}`, params);
}

export function deletePublicDomain(id) {
    return API.delete(`/public_domains/${id}`);
}
