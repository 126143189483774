import React from "react";
import { Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import BackLink from "./BackLink";

const TopBarWithBackLink = ({ backPath, linkName, children }) => (
    <div className="top-bar">
        <Col>
            <Container fluid>
                <div className="py-3 d-flex align-items-center">
                    <Col className="col-auto">{backPath && <BackLink linkName={linkName} backPath={backPath} />}</Col>
                    {children}
                </div>
            </Container>
        </Col>
    </div>
);

TopBarWithBackLink.propTypes = {
    backPath: PropTypes.string,
    linkName: PropTypes.string,
    children: PropTypes.node,
};

export default TopBarWithBackLink;
