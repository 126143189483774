import React from "react";
import { faBalanceScale, faBullhorn, faBusinessTime, faCalendarCheck } from "@fortawesome/pro-solid-svg-icons";
import { faPauseCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import I18n from "../../../../../utils/i18n";

const StatusCell = ({ row: { original } }) => {
    const icons = {
        Live: faBusinessTime,
        Confirmation: faCalendarCheck,
        Published: faBullhorn,
        Scoring: faBalanceScale,
        Paused: faPauseCircle,
    };
    const icon = icons[original.status];
    return (
        <>
            {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
            {I18n.t(original.status.toLowerCase(), { scope: "home.negotiations.status" })}
        </>
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    }),
};

export default StatusCell;
