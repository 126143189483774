import React from "react";
import I18n from "../../../../utils/i18n";
import ChartComponent from "../../../../common/components/DiversityPage/ChartComponent";

const ScoreByFirmChart = () => (
    <ChartComponent
        title={I18n.t("diversity.charts.client_specific_marketing.score_by_firm")}
        type="bar"
        data={{
            labels: ["Firm 1", "Firm 2", "Firm 3", "Firm 4"],
            datasets: [
                {
                    data: [25, 24, 25, 17],
                    label: I18n.t("diversity.scores.firmwide_score"),
                    backgroundColor: "rgba(105,147,166,0.9)",
                    borderColor: "rgba(105,147,166,1)",
                    borderWidth: 1,
                },
                {
                    data: [26, 21, 13, 3],
                    label: I18n.t("diversity.scores.matter_leadership_score"),
                    backgroundColor: "rgba(28,93,121,0.9)",
                    borderColor: "rgba(28,93,121,1)",
                    borderWidth: 1,
                },
                {
                    data: [16, 6, 0, 16],
                    label: I18n.t("diversity.scores.credit_distribution_score"),
                    backgroundColor: "rgba(80,164,82,0.9)",
                    borderColor: "rgba(80,164,82,1)",
                    borderWidth: 1,
                },
                {
                    data: [10, 12, 12, 0],
                    label: I18n.t("diversity.scores.hours_distribution_score"),
                    backgroundColor: "rgba(255,66,1,0.9)",
                    borderColor: "rgba(255,66,1,1)",
                    borderWidth: 1,
                },
                {
                    data: [60, 57, 66, 35],
                    label: I18n.t("diversity.scores.fees_distribution_score"),
                    backgroundColor: "rgba(254,112,0,0.9)",
                    borderColor: "rgba(254,112,0,1)",
                    borderWidth: 1,
                },
            ],
        }}
        options={{
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.score_by_firm.y_axis"),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataLabel = data.datasets[tooltipItem.datasetIndex].label;
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString(
                            I18n.locale
                        );
                        return `${dataLabel}: ${value}`;
                    },
                },
            },
        }}
    />
);

export default ScoreByFirmChart;
