import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import _isNumber from "lodash/isNumber";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificMirrorChart from "./DiversityClientSpecificMirrorChart";
import { calculateTotal } from "../../../../utils/calculateTotal";

const DiversityOfHours = ({ inProgress, canvasRefs, chartOptions, chartData }) => (
    <DiversityClientSpecificMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        type="diversity_of_hours"
        chartType="doughnut"
        chartOptions={{
            ...chartOptions,
            legend: { position: "right" },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataLabel = data.labels[tooltipItem.index];
                        let value = _round(
                            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                        ).toLocaleString(I18n.locale);
                        return `${dataLabel}: ${value}`;
                    },
                },
            },
            plugins: {
                doughnutlabel: {
                    labels: [
                        {
                            text: (chart) => {
                                let total = calculateTotal(chart);
                                if (_isNumber(total))
                                    return `${I18n.t("diversity.columns.hours")} ${_round(total).toLocaleString(
                                        I18n.locale,
                                    )}`;
                                else return "";
                            },
                            font: { size: 18 },
                            color: "black",
                        },
                    ],
                },
                datalabels: {
                    formatter: (_, context) => {
                        let dataLabel = context.chart.data.labels[context.dataIndex];
                        let value = context.chart.data.datasets[context.datasetIndex].data[
                            context.dataIndex
                        ].toLocaleString(I18n.locale);
                        return `${dataLabel}: ${value}`;
                    },
                },
            },
        }}
        chartUseDataLabels={true}
    />
);

DiversityOfHours.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default DiversityOfHours;
