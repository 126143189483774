import _forEach from "lodash/forEach";
import _round from "lodash/round";
import I18n from "./i18n";

export function calculateTotal(chart) {
    let data = chart.config.data.datasets[0].data;
    let legendItem = chart.legend.legendItems;
    let total = 0;
    if (data.length > 0) {
        _forEach(data, (value, index) => {
            if (index === legendItem[index].index) {
                !legendItem[index].hidden && (total += parseFloat(value));
            }
        });
        return total;
    }
}

export function currencyChartLabel(value) {
    return `$${_round(value).toLocaleString(I18n.locale)}`;
}
