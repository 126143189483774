import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import I18n from "../../../../../utils/i18n";

const CertificateName = ({ id, name, description, primary, source }) => {
    const displayedName = primary
        ? I18n.t("companies.profiles.paragraphs.certificates.primary_name", { name: name })
        : name;
    const fontWeightClass = classNames("align-middle text-truncate certificate-name", { "font-weight-bold": primary });

    if (description == null && source == null) {
        return <span className={fontWeightClass}>{displayedName}</span>;
    } else {
        return (
            <OverlayTrigger
                placement="left"
                trigger={["hover", "focus"]}
                overlay={
                    <Tooltip id={`certificate-tooltip-${id}`}>
                        {I18n.t("companies.profiles.paragraphs.certificates.tooltip", {
                            description: description || "",
                            source: source || "",
                        })}
                    </Tooltip>
                }
            >
                <span className={fontWeightClass} title={displayedName}>
                    {displayedName}
                </span>
            </OverlayTrigger>
        );
    }
};

CertificateName.propTypes = {
    id: PropTypes.number.isRequired,
    primary: PropTypes.bool.isRequired,
    description: PropTypes.string,
    source: PropTypes.string,
    name: PropTypes.string,
};

export default CertificateName;
