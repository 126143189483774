import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import _cloneDeep from "lodash/cloneDeep";
import I18n from "../../../../utils/i18n";
import { getMyLists } from "../../../../api/listsApi";
import { filterBy } from "../filterBy";

const ListSearchSection = ({
    searchValue,
    setSearchValue,
    isLoading,
    setOwnLists,
    setSharedLists,
    setIsloading,
    reloadLists,
}) => {
    const [fullLists, setFullLists] = useState({ own: [], shared: [] });
    const handleSearchChange = (event) => setSearchValue(event.target.value);

    useEffect(() => {
        if (searchValue.length < 3) {
            setOwnLists(fullLists.own);
            setSharedLists(fullLists.shared);
            return;
        }

        let clonedLists = _cloneDeep(fullLists);

        setOwnLists(filterBy(clonedLists.own, searchValue));
        setSharedLists(filterBy(clonedLists.shared, searchValue));
    }, [searchValue]);

    useEffect(() => {
        setIsloading(true);
        getMyLists()
            .then((response) => setFullLists(response.data))
            .finally(() => setIsloading(false));
    }, [reloadLists]);

    return (
        <div className="search-input">
            <Form.Control
                placeholder={I18n.t("common.placeholders.search")}
                value={searchValue}
                onChange={handleSearchChange}
                disabled={isLoading}
            />
        </div>
    );
};

ListSearchSection.propTypes = {
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setOwnLists: PropTypes.func.isRequired,
    setSharedLists: PropTypes.func.isRequired,
    setIsloading: PropTypes.func.isRequired,
    reloadLists: PropTypes.bool.isRequired,
};

export default ListSearchSection;
