import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityFirmwideChart from "./DiversityFirmwideChart";

const LeadershipRolesChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="leadership_roles"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.leadership_roles.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        }}
    />
);

LeadershipRolesChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default LeadershipRolesChart;
