import React from "react";
import PropTypes from "prop-types";
import _lowerCase from "lodash/lowerCase";
import ConfirmAction from "./ConfirmAction";
import I18n from "../../utils/i18n";

const DeactivateAction = ({ show, onHide, resource, onSubmit, loading }) => {
    const formattedResource = _lowerCase(resource);
    return (
        <ConfirmAction
            show={show}
            onHide={onHide}
            onSubmit={onSubmit}
            confirm="common.actions.deactivate.action"
            title={I18n.t("common.actions.deactivate.title", { resource: formattedResource })}
            text={I18n.t("common.actions.deactivate.body", { resource: formattedResource })}
            loading={loading}
        />
    );
};

DeactivateAction.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default DeactivateAction;
