import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";

const CurrentSignInAtCell = ({ row: { original } }) => original.current_sign_in_at || I18n.t("tables.users.never");

CurrentSignInAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            current_sign_in_at: PropTypes.string,
        }),
    }),
};

export default CurrentSignInAtCell;
