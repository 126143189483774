import API from "../utils/api";

export function getMyLists() {
    return API.get("/companies/lists");
}

export function createNewList(params) {
    return API.post("/companies/lists", params);
}

export function updateList(listId, params) {
    return API.patch(`/companies/lists/${listId}`, params);
}

export function getListCompanies(listId, params) {
    return API.get(`/companies/lists/${listId}`, { params });
}

export function getListForCompany(companyUid) {
    return API.get(`/companies/my_lists/companies/${companyUid}`);
}

export function addCompanyToLists(params) {
    return API.post("/companies/my_lists/companies", params);
}

export function getOrganizations(params) {
    return API.get("/companies/my_lists/organizations", { params });
}

export function removeCompanyFromList(listId, companyUid) {
    return API.delete(`/companies/my_lists/companies/${companyUid}`, { params: { list_id: listId } });
}

export function getActors(params) {
    return API.get("/companies/my_lists/actors", { params });
}

export function getActor(actorUid) {
    return API.get(`/companies/my_lists/actors/${actorUid}`);
}

export function shareList(params) {
    return API.post("/companies/my_lists/shares", params);
}

export function deleteList(listId) {
    return API.delete(`/companies/lists/${listId}`);
}

export function cloneList(params) {
    return API.post("/companies/my_lists/clone", params);
}

export function getNegotiations(params) {
    return API.get("/companies/my_lists/export/negotiations", { params });
}

export function getPreview(params) {
    return API.get("/companies/my_lists/export/preview", { params });
}

export function createNegotiation(params) {
    return API.post("/companies/my_lists/export/negotiations", params);
}

export function updateNegotiation(negotiationId, params) {
    return API.patch(`/companies/my_lists/export/negotiations/${negotiationId}`, params);
}
