import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import TableAttributes from "../content/TableAttributes";
import I18n from "../../../../utils/i18n";

const SalesAndEmployees = ({ values }) => (
        <Row>
            <Col className="pl-0">
                <TableAttributes
                    title={I18n.t("activerecord.models.sales_indicator")}
                    scope={values.sales_indicators_attributes}
                    prefix="$"
                />
            </Col>
            <Col className="pl-0">
                <TableAttributes
                    title={I18n.t("activerecord.models.employees_indicator")}
                    scope={values.employees_indicators_attributes}
                />
            </Col>
        </Row>
    );

SalesAndEmployees.propTypes = {
    values: PropTypes.shape({
        sales_indicators_attributes: PropTypes.array,
        employees_indicators_attributes: PropTypes.array,
    }),
};

export default SalesAndEmployees;
