import * as Yup from "yup";
import _ from "lodash";
import I18n from "../../../../utils/i18n";

Yup.addMethod(Yup.array, "sum", function (sum, attribute, idValue, groupByValues, whenValues, message) {
    return this.test(function (values) {
        let errors = [];
        const idValues = _.map(values, idValue);
        const groupedValues = _.groupBy(values, (value) => [value.key_indicator, value.diversity_group].join("_"));

        _.each(groupedValues, (groupedValue) => {
            if (_.every(groupedValue, whenValues) && _.sumBy(groupedValue, attribute) > sum) {
                _.each(groupedValue, (value) => {
                    value[attribute] &&
                        errors.push(
                            this.createError({
                                path: `${this.path}[${idValues.indexOf(value.id)}].${attribute}`,
                                message,
                            })
                        );
                });
            }
        });

        if (!_.isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return errors;
    });
});

const KeyIndicatorsFormSchema = Yup.object().shape({
    client: Yup.object().shape({
        attrition_weight: Yup.number().required().min(0).max(100),
        hiring_and_promotions_weight: Yup.number().required().min(0).max(100),
        leadership_weight: Yup.number().required().min(0).max(100),
        overall_weight: Yup.number().required().min(0).max(100),
    }),
    key_indicators: Yup.array()
        .of(
            Yup.object().shape({
                possible_points: Yup.number().when("goal", {
                    is: (value) => !!value,
                    then: Yup.number().required().integer().min(0),
                    otherwise: Yup.number().nullable().integer().min(0),
                }),
                goal: Yup.number().min(0).max(100).nullable(),
            })
        )
        .sum(
            100,
            "goal",
            "id",
            ["diversity_status", "key_indicator"],
            ["direction", "min"],
            I18n.t("errors.messages.sum_equal_to", { count: 100 })
        ),
});

export default KeyIndicatorsFormSchema;
