import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

const TooltipComponent = ({
    content,
    placement = "right",
    trigger = ["hover", "focus"],
    size = null,
    isLink = true,
    icon = null
}) => (
    <OverlayTrigger
        placement={placement}
        trigger={trigger}
        overlay={
            <Popover>
                <Popover.Content dangerouslySetInnerHTML={{ __html: content }} />
            </Popover>
        }
    >
        {React.createElement(
            isLink ? "a" : "span",
            { role: "button", tabIndex: 0 },
            <FontAwesomeIcon icon={icon || faQuestionCircle} className="ml-1 text-secondary" size={size} />,
        )}
    </OverlayTrigger>
);

TooltipComponent.propTypes = {
    content: PropTypes.string.isRequired,
    placement: PropTypes.string,
    trigger: PropTypes.array,
    size: PropTypes.string,
    isLink: PropTypes.bool,
    icon: PropTypes.object,
};

export default TooltipComponent;
