import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import _flatMap from "lodash/flatMap";
import _map from "lodash/map";
import { Link, useHistory } from "react-router-dom";
import I18n from "../../../utils/i18n";
import Table, { SelectFilter } from "../../../common/components/Table";
import { getRequestsSuppliers } from "../../../api/tieredDiversityApi";

const StatusCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        <div
            className={classNames("badge p-1", {
                "badge-success": original.status === "completed",
                "badge-secondary": original.status === "not_completed",
                "badge-light": original.status === "not_started",
            })}
        >
            {I18n.t(`tiered_diversity.dashboard.suppliers.buttons.statuses.${original.status}`)}
        </div>
    </div>
);

const CertificateNameCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {original.certificate_id && (
            <Link target="_blank" to={original.certificate_origin_download_path}>
                {original.certificate_name}
            </Link>
        )}
    </div>
);

const SubPartnershipsStatusCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        <div
            className={classNames("badge p-1", {
                "badge-success": original.sub_partnerships_status === "completed",
                "badge-secondary": original.sub_partnerships_status === "not_completed",
                "badge-light": original.sub_partnerships_status === "not_started",
            })}
        >
            {I18n.t(`tiered_diversity.dashboard.suppliers.buttons.statuses.${original.sub_partnerships_status}`)}
        </div>
    </div>
);

const CreatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.created_at)}</div>
);

const SuppliersTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.supplier_name"),
                accessor: "supplier_name",
                Filter: SelectFilter,
                filter: "equals",
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/month.quarter_name"),
                accessor: "quarter_name",
                Filter: SelectFilter,
                filter: "equals",
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.certificate.name"),
                accessor: "certificate_name",
                Cell: CertificateNameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.sub_partnerships_status"),
                accessor: "sub_partnerships_status",
                Cell: SubPartnershipsStatusCell,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/monthly_partnership_stats.created_at"),
                accessor: "created_at",
                Cell: CreatedAtCell,
            },
        ],
        []
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchIdRef = useRef(0);
    const history = useHistory();

    const fetchData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;

        setLoading(true);
        getRequestsSuppliers()
            .then((response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(
                        _flatMap(response.data, (supplier) =>
                            _map(supplier.quarterly_partnership_stats, (quarterlyPartnershipStat) => ({
                                    ...supplier,
                                    ...quarterlyPartnershipStat,
                                }))
                        )
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                error.response.status === 403 && history.push("/");
            });
    }, []);

    return (
        <Card.Body>
            <Row>
                <Col>
                    <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        pageCount={-1}
                        loading={loading}
                        initialSortBy={[{ id: "quarter_name", desc: true }]}
                        manualSortBy={false}
                    />
                </Col>
            </Row>
        </Card.Body>
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    }),
};

CertificateNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            certificate_id: PropTypes.number,
            certificate_name: PropTypes.string,
            certificate_origin_download_path: PropTypes.string,
        }),
    }),
};

SubPartnershipsStatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            sub_partnerships_status: PropTypes.string.isRequired,
        }),
    }),
};

CreatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
        }),
    }),
};

export default SuppliersTable;
