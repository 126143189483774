import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEnvelope, faMapMarkerAlt, faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import _compact from "lodash/compact";
import _map from "lodash/map";
import * as countries from "country-flag-icons/react/3x2";
import I18n from "../../../../utils/i18n";
import IndustriesList from "../general/IndustriesList";

const TypeLocations = ({ typeLocationName, typeLocations }) => {
    const [open, setOpen] = useState(typeLocations[0].location_type_required);

    return (
        <Accordion activeKey={open ? typeLocationName : ""}>
            <Accordion.Toggle
                as="div"
                className="d-inline-block pl-2"
                eventKey={typeLocationName}
                onClick={() => setOpen((prevState) => !prevState)}
            >
                <h3>
                    <FontAwesomeIcon
                        icon={open ? faChevronUp : faChevronDown}
                        className="align-baseline mr-2 arrow"
                        size="xs"
                    />
                    {typeLocations[0].location_type_required
                        ? typeLocationName
                        : `${typeLocationName} (${typeLocations.length})`}
                </h3>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={typeLocationName}>
                <>
                    {_map(typeLocations, (typeLocation, index) => (
                        <Card
                            key={index}
                            className={classNames("mb-4 mt-2", {
                                "font-weight-bold": typeLocation.location_type_required,
                            })}
                        >
                            <Card.Body>
                                <Row className="d-flex">
                                    <Col>
                                        <h3>
                                            <span>
                                                {React.createElement(countries[typeLocation.country.code], {
                                                    style: { width: "1.5rem", height: "1.5rem" },
                                                })}
                                            </span>
                                            <small className="text-muted ml-1">
                                                {I18n.t("activerecord.models.location")}
                                            </small>
                                        </h3>
                                        {typeLocation.city && <p>{`${typeLocation.city},`}</p>}
                                        <p>{typeLocation.country.name}</p>
                                    </Col>
                                    <Col>
                                        <h3>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                                            <small className="text-muted ml-1">
                                                {I18n.t("activerecord.attributes.location.address")}
                                            </small>
                                        </h3>
                                        <p>
                                            {_compact([typeLocation.address_line1, typeLocation.address_line2]).join(
                                                " ",
                                            )}
                                        </p>
                                        <p>
                                            {_compact([
                                                _compact([typeLocation.city, typeLocation.state?.name]).join(", "),
                                                typeLocation.zip_code,
                                            ]).join(" ")}
                                        </p>
                                    </Col>

                                    <Col>
                                        <h3>
                                            <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
                                            <small className="text-muted ml-1">
                                                {I18n.t("activerecord.attributes.location.phone")}
                                            </small>
                                        </h3>
                                        {typeLocation.phone && <p className="text-break">{typeLocation.phone}</p>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <h3>
                                            <small className="text-muted ml-1">
                                                {I18n.t("activerecord.models.industry.one")}
                                            </small>
                                        </h3>
                                        <IndustriesList industries={typeLocation.industries} />
                                    </Col>
                                    <Col md={4}>
                                        <h3>
                                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                                            <small className="text-muted ml-1">
                                                {I18n.t("activerecord.attributes.location.email")}
                                            </small>
                                        </h3>
                                        {typeLocation.email && <p className="text-break">{typeLocation.email}</p>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                </>
            </Accordion.Collapse>
        </Accordion>
    );
};

TypeLocations.propTypes = {
    typeLocationName: PropTypes.string.isRequired,
    typeLocations: PropTypes.arrayOf(
        PropTypes.shape({
            location_type_name: PropTypes.string.isRequired,
            location_type_required: PropTypes.bool.isRequired,
            address_line1: PropTypes.string,
            address_line2: PropTypes.string,
            country: PropTypes.shape({
                code: PropTypes.string.isRequired,
                name: PropTypes.string,
            }),
            state: PropTypes.shape({
                code: PropTypes.string,
                name: PropTypes.string,
            }),
            zip_code: PropTypes.string,
            city: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            industries: PropTypes.arrayOf(
                PropTypes.shape({
                    primary: PropTypes.bool.isRequired,
                    name: PropTypes.string.isRequired,
                    area: PropTypes.string.isRequired,
                    focus: PropTypes.string.isRequired,
                    specialty: PropTypes.string,
                }),
            ),
        }),
    ),
};

export default TypeLocations;
