import React from "react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../../../../utils/i18n";

const CancelButton = ({ setCurrentStep, handleClose }) => {
    const { handleReset } = useFormikContext();

    return (
        <Button
            variant="secondary"
            className="ml-2"
            onClick={() => {
                setCurrentStep(0);
                handleClose(handleReset);
            }}
        >
            {I18n.t("common.links.cancel")}
        </Button>
    );
};

CancelButton.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CancelButton;
