import React from "react";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";
import PageTitle from "./PageTitle";

const NewPageTitle = ({ resource }) => <PageTitle text={I18n.t("common.titles.new", { resource: resource })} />;

NewPageTitle.propTypes = {
    resource: PropTypes.string,
};

export default NewPageTitle;
