import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import { getQuestionnaireStats } from "../../../api/industrySpecificApi";

const IndustrySpecificQuestions = ({ uid }) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [stats, setStats] = useState({ total_questions: 0, total_answered_questions: 0 });

    useEffect(() => {
        getQuestionnaireStats(uid).then((response) => {
            setStats(response.data);
        });
    }, [uid]);

    return (
        <Card className="mb-2 border-top-0">
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <h3>{I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.title")}</h3>

                    <Link
                        to={{
                            pathname: "/external/bid/industry_specific_questionnaire_respondents",
                            search: `?redirect_to=${currentWhitelabel.hosts.bid}/industry_specific/questionnaire/respondents/${uid}`,
                        }}
                        className="text-white btn btn-primary m-1 ml-2"
                    >
                        {I18n.t("forms.organization.edit.tabs.industry_specific_questions.view")}
                    </Link>
                </div>

                <ul className="list-unstyled">
                    <li>
                        <strong>
                            {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.total", {
                                count: stats.total_questions,
                            })}
                        </strong>
                    </li>
                    <li>
                        {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.answered", {
                            count: stats.total_answered_questions,
                        })}
                    </li>
                    <li>
                        {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.not_answered", {
                            count: stats.total_questions - stats.total_answered_questions,
                        })}
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
};

IndustrySpecificQuestions.propTypes = {
    uid: PropTypes.string.isRequired,
};

export default IndustrySpecificQuestions;
