import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

const BackLink = ({ backPath, linkName }) => (
    <Link to={backPath} className="btn btn-link text-secondary text-nowrap">
        <FontAwesomeIcon icon={faChevronLeft} /> {linkName}
    </Link>
);

BackLink.propTypes = {
    backPath: PropTypes.string,
    linkName: PropTypes.string,
};

export default BackLink;
