import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import KeyIndicatorsTableHeader from "./KeyIndicatorsTableHeader";
import KeyIndicatorsTableBody from "./KeyIndicatorsTableBody";

const KeyIndicatorsTable = ({ formik, currentGoals, partialFirmwide }) => (
        <Table responsive bordered size="sm" className="diversity-table">
            <thead>
                <KeyIndicatorsTableHeader />
            </thead>
            <tbody>
                <KeyIndicatorsTableBody formik={formik} currentGoals={currentGoals} partialFirmwide={partialFirmwide} />
            </tbody>
        </Table>
    );

KeyIndicatorsTable.propTypes = {
    formik: PropTypes.object.isRequired,
    currentGoals: PropTypes.object,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default KeyIndicatorsTable;
