import React from "react";
import { useParams } from "react-router-dom";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { createAttorneyCase } from "../../api/legalApi";
import LegalCaseForm from "../../common/components/LegalCaseForm";

const NewLegalCasePage = () => {
    const { organization_uid: organizationUid, attorney_id: attorneyId } = useParams();

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/attorneys`}
                linkName={I18n.t("forms.organization.edit.tabs.attorneys")}
            />
            <div className="scrollable d-flex flex-column">
                <LegalCaseForm
                    organizationUid={organizationUid}
                    attorneyId={attorneyId}
                    initialValues={{
                        name: "",
                        venue: "",
                        judge: "",
                        start_date: null,
                        end_date: null,
                        matter_description: "",
                        attorneys_contribution: "",
                        opposing_counsel: "",
                        outcome_summary: "",
                        trial_experience: false,
                        case_role_attributes: {
                            legal_role_id: null,
                            description: "",
                        },
                    }}
                    createOrUpdateLegalCase={(organizationUid, attorneyId, _caseId, params) =>
                        createAttorneyCase(organizationUid, attorneyId, params)
                    }
                />
            </div>
        </>
    );
};

export default NewLegalCasePage;
