import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import I18n from "../../../../utils/i18n";

const Header = () => (
        <Row>
            <Col xs={2}>
                <h3>
                    <Badge variant="secondary">{I18n.t("home.reports.labels.per_month")}</Badge>
                </h3>
            </Col>
            <Col xs={8} />
            <Col xs={2} className="text-right">
                <h3>
                    <Badge variant="secondary">{I18n.t("home.reports.labels.total")}</Badge>
                </h3>
            </Col>
        </Row>
    );

export default Header;
