import { SET_ACTOR, START_FETCHING, STOP_FETCHING, UPDATE_ACTOR } from "./types";
import { getCurrentActor } from "../../api/sessionApi";
import { setCurrentOrganization } from "../../api/loginApi";

export function fetchCurrentActor() {
    return (dispatch) => {
        dispatch(startFetching());

        getCurrentActor()
            .then((response) => {
                dispatch(setCurrentActor(response.data));
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function setCurrentActorByOrganization(organizationId, afterActorSetCallback) {
    return (dispatch) => {
        setCurrentOrganization(organizationId).then((response) => {
            dispatch(setCurrentActor(response.data));
            afterActorSetCallback();
        });
    };
}

export function startFetching() {
    return { type: START_FETCHING };
}

export function stopFetching() {
    return { type: STOP_FETCHING };
}

export function setCurrentActor(actor) {
    return { type: SET_ACTOR, payload: actor };
}

export function updateCurrentActor(actor) {
    return { type: UPDATE_ACTOR, payload: actor };
}
