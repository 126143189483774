import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DIVERSITY_KEY_INDICATORS_VALUES } from "../../../../utils/diversityKeyIndicators";
import { calculateDiversityCurrentPointsFor, parseDiversityValue } from "../../../../utils/diversityCalculators";
import I18n from "../../../../utils/i18n";

const KeyIndicatorsTableOverallRow = ({ values, currentGoals, partialFirmwide }) => {
    const groupedValues = _.groupBy(values, "key_indicator");

    return (
        <tr className="font-weight-bold">
            <th scope="row" colSpan={2}>
                {I18n.t("diversity.groups.overall")}
            </th>
            {_.map(DIVERSITY_KEY_INDICATORS_VALUES, (keyIndicator, columnIndex) => {
                const currentPoints = _.sumBy(groupedValues[keyIndicator], (groupedValue) =>
                    calculateDiversityCurrentPointsFor(
                        keyIndicator,
                        groupedValue.goal,
                        groupedValue.possible_points,
                        groupedValue.direction,
                        currentGoals[groupedValue.id],
                        partialFirmwide
                    )
                );

                const possiblePoints = _.sumBy(groupedValues[keyIndicator], (groupedValue) =>
                    parseDiversityValue(groupedValue.possible_points)
                );

                const currentGoal = (currentPoints / (possiblePoints === 0 ? 1 : possiblePoints)) * 100;

                return (
                    <React.Fragment key={columnIndex}>
                        <td scope="row">
                            {currentGoal ? `${currentGoal.toFixed(2)}%` : I18n.t("common.not_available")}
                        </td>
                        <td scope="row">{currentGoal ? currentPoints.toFixed(2) : I18n.t("common.not_available")}</td>
                        <td scope="row">{possiblePoints.toFixed(2)}</td>
                        <td scope="row" />
                        <td scope="row" />
                    </React.Fragment>
                );
            })}
        </tr>
    );
};

KeyIndicatorsTableOverallRow.propTypes = {
    values: PropTypes.array.isRequired,
    currentGoals: PropTypes.object.isRequired,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default KeyIndicatorsTableOverallRow;
