import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const IndeterminateCheck = (props) => {
    const { id = null, label = null, checked = false, indeterminate = false, disabled = false, ...otherProps } = props;

    return (
        <Form.Check custom>
            <Form.Check.Input
                id={id}
                ref={(ref) => {
                    if (ref) {
                        ref.checked = checked;
                        ref.indeterminate = indeterminate;
                        ref.disabled = disabled;
                    }
                }}
                {...otherProps}
            />
            <Form.Check.Label className="w-100" htmlFor={id}>
                {label}
            </Form.Check.Label>
        </Form.Check>
    );
};

IndeterminateCheck.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default IndeterminateCheck;
