import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import { useField } from "formik";
import I18n from "../../../utils/i18n";

const ActivityLogsFilterActorName = ({ onChange }) => {
    const [field] = useField("q[actor_name]");

    return (
        <Form.Group as={Col}>
            <Form.Label htmlFor="q_actor_name">{I18n.t("activerecord.attributes.activity/log.actor_name")}</Form.Label>
            <Form.Control
                type="text"
                id="q_actor_name"
                name="q[actor_name]"
                value={field.value || ""}
                onChange={onChange}
            />
        </Form.Group>
    );
};

ActivityLogsFilterActorName.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ActivityLogsFilterActorName;
