import {
    ScoreByFirmChart,
    ScoreByFirmSizeChart,
    AttributesPercentsByCompositionChart,
    AttributesByHiredDateChart,
    AttributesByFirmChart,
    AttributesByPracticeAreaChart,
    AttributesPercentsByFirmChart,
    AverageYearsCurrentRoleChart,
    EligiblePromotedByDemographicsChart,
    AverageClientsPerPartnerChart,
    LeadershipRolesChart,
    LeadershipDiversityChart,
    DiversityOfAttritionChart,
    AverageTimeBeforeExitChart,
    ScoreByPracticeAreaChart,
    MiniStatsBar,
} from "../pages/DiversityFirmwidePage/components/charts/index";

const FirmwideCharts = [
    [{ type: "mini_stats_bar", component: MiniStatsBar }],
    [
        { type: "score_by_firm_size", component: ScoreByFirmSizeChart },
        { type: "score_by_firm", component: ScoreByFirmChart },
    ],
    [
        { type: "attributes_percents_by_composition", component: AttributesPercentsByCompositionChart },
        { type: "attributes_by_hired_date", component: AttributesByHiredDateChart },
    ],
    [
        { type: "attributes_by_firm", component: AttributesByFirmChart },
        { type: "attributes_percents_by_firm", component: AttributesPercentsByFirmChart },
    ],
    [
        { type: "average_years_current_role", component: AverageYearsCurrentRoleChart },
        { type: "eligible_promoted_by_demographics", component: EligiblePromotedByDemographicsChart },
    ],
    [
        { type: "average_clients_per_partner", component: AverageClientsPerPartnerChart },
        { type: "leadership_roles", component: LeadershipRolesChart },
    ],
    [
        { type: "leadership_diversity", component: LeadershipDiversityChart },
        { type: "diversity_of_attrition", component: DiversityOfAttritionChart },
    ],
    [{ type: "average_time_before_exit", component: AverageTimeBeforeExitChart }],
    [{ type: "attributes_by_practice_area", component: AttributesByPracticeAreaChart }],
    [{ type: "score_by_practice_area", component: ScoreByPracticeAreaChart }],
];

export default FirmwideCharts;
