import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Form, Button, Spinner, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import _filter from "lodash/filter";
import { useDispatch } from "react-redux";
import _includes from "lodash/includes";
import I18n from "../../../../utils/i18n";
import { getOrganizations, updateList } from "../../../../api/listsApi";
import { setAlert } from "../../../../state/alert/alertActions";

const AddCompaniesToListModal = ({ listId, show, onClose, setData, setPagesCount, setReloadLists }) => {
    const [organizations, setOrganizations] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const initialValues = {
        companies_list: { organization_ids: [] },
    };

    const filteredOrgs = () =>
        searchTerm.length > 2
            ? _filter(organizations, (org) => _includes(org.name.toLowerCase(), searchTerm.toLowerCase()))
            : organizations;

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        updateList(listId, values)
            .then(({ data: { records, page_count } }) => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_add", variant: "info" }));
                setData(records);
                setPagesCount(page_count);
                setReloadLists((prevState) => !prevState);
                resetForm();
                onClose();
            })
            .catch(() => {
                dispatch(setAlert({ translationKey: "common.alerts.error", variant: "danger" }));
            })
            .finally(() => setSubmitting(false));
    };
    const handleClose = (handleReset) => {
        handleReset();
        onClose();
    };

    const validationSchema = Yup.object().shape({
        companies_list: Yup.object().shape({
            organization_ids: Yup.array().required(),
        }),
    });

    useEffect(() => {
        if (show) {
            setSearchTerm("");
            setLoading(true);
            getOrganizations({ list_id: listId })
                .then(({ data }) => setOrganizations(data))
                .finally(() => setLoading(false));
        }
    }, [show, listId]);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
                <Modal show={show} onHide={() => handleClose(handleReset)}>
                    <Modal.Header closeButton>{I18n.t("companies.lists.modals.add_companies.title")}</Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Card>
                                <Card.Header>
                                    <Form.Control
                                        type="text"
                                        placeholder={I18n.t("companies.lists.placeholders.search")}
                                        name="search"
                                        value={searchTerm}
                                        onChange={({ target: { value } }) => setSearchTerm(value)}
                                    />
                                </Card.Header>

                                <Card.Body className="overflow-y-auto max-height-25">
                                    <Spinner
                                        hidden={!loading}
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        className="mr-2"
                                    />

                                    {!loading &&
                                        filteredOrgs().map((org) => (
                                            <Form.Check
                                                custom
                                                key={org.id}
                                                id={org.id}
                                                type="checkbox"
                                                name="companies_list.organization_ids"
                                                onChange={(event) => {
                                                    const { checked } = event.target;
                                                    if (checked) {
                                                        setFieldValue("companies_list.organization_ids", [
                                                            ...values.companies_list.organization_ids,
                                                            org.id,
                                                        ]);
                                                    } else {
                                                        setFieldValue(
                                                            "companies_list.organization_ids",
                                                            _filter(
                                                                values.companies_list.organization_ids,
                                                                (id) => id !== org.id,
                                                            ),
                                                        );
                                                    }
                                                }}
                                                value={org.id}
                                                disabled={org.exists}
                                                checked={
                                                    org.exists ||
                                                    _includes(values.companies_list.organization_ids, org.id)
                                                }
                                                label={org.name}
                                            />
                                        ))}
                                </Card.Body>
                                <Card.Footer>
                                    {I18n.t("companies.lists.sections.companies.selected", {
                                        count: values.companies_list.organization_ids.length,
                                        total: _filter(organizations, ["exists", false]).length,
                                    })}
                                </Card.Footer>
                            </Card>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(handleReset)}>
                            {I18n.t("common.links.cancel")}
                        </Button>

                        <Button
                            disabled={isSubmitting || loading || values.companies_list.organization_ids.length === 0}
                            variant="primary"
                            className="text-white"
                            onClick={handleSubmit}
                        >
                            <Spinner hidden={!isSubmitting} as="span" animation="border" size="sm" className="mr-2" />
                            {I18n.t("common.links.add")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

AddCompaniesToListModal.propTypes = {
    listId: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    setPagesCount: PropTypes.func.isRequired,
    setReloadLists: PropTypes.func.isRequired,
};

export default AddCompaniesToListModal;
