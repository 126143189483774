import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";
import _includes from "lodash/includes";

export function filterBy(array, searchPattern) {
    _map(array, (element) => {
        if (!_isEmpty(element.children)) {
            element.children = filterBy(element.children, searchPattern);
        }
    });

    return _filter(
        array,
        (list) => _includes(list.name.toLowerCase(), searchPattern.toLowerCase()) || !_isEmpty(list.children)
    );
}
