import React from "react";
import { Col, Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import classNames from "classnames";
import I18n from "../../../utils/i18n";

const ActivityLogsFilterCreatedAt = () => {
    const [fromField, fromMeta] = useField("q[created_at_from]");
    const [toField, toMeta] = useField("q[created_at_to]");
    const { setFieldValue } = useFormikContext();
    const format = "MM-DD-YYYY hh:mm a";

    return (
        <Form.Group as={Col}>
            <Form.Label>{I18n.t("activerecord.attributes.activity/log.created_at")}</Form.Label>
            <Form.Row>
                <Form.Group as={Col}>
                    <DateTimePicker
                        useCurrent={false}
                        className={classNames("flex-grow-1", { "is-invalid": fromMeta.error })}
                        format={format}
                        onChange={(event) => setFieldValue("q[created_at_from]", event.date?.toISOString())}
                        date={fromField.value}
                    />
                    <Form.Control.Feedback type="invalid">{fromMeta.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                    <DateTimePicker
                        useCurrent={false}
                        className={classNames("flex-grow-1", { "is-invalid": toMeta.error })}
                        format={format}
                        onChange={(event) => setFieldValue("q[created_at_to]", event.date?.toISOString())}
                        date={toField.value}
                    />
                    <Form.Control.Feedback type="invalid">{toMeta.error}</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
        </Form.Group>
    );
};

export default ActivityLogsFilterCreatedAt;
