import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSearch } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import I18n from "../../../../utils/i18n";
import FavoriteButtons from "./FavoriteButtons";

const ActionButtons = ({ eventId, isFavorite = false, showFavoriteButtons = false, currentWhitelabel }) => {
    const baseUrl = `//${currentWhitelabel.hosts.bid}`;

    return (
        <>
            {showFavoriteButtons && <FavoriteButtons id={eventId} isFavorite={isFavorite} />}
            <span
                title={I18n.t("home.create_event_wizard.templates_table.buttons.preview.tip")}
                className="action-button ml-2"
            >
                <Link to={`${baseUrl}/setup/${eventId}`} target="_blank">
                    <FontAwesomeIcon className="fa-icon-blue" icon={faFileSearch} />
                </Link>
            </span>
        </>
    );
};

ActionButtons.propTypes = {
    eventId: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool,
    showFavoriteButtons: PropTypes.bool,
    currentWhitelabel: PropTypes.shape({
        hosts: PropTypes.shape({
            bid: PropTypes.string.isRequired,
        }),
    }),
};

export default ActionButtons;
