import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import SingleChoiceFilter from "./common/SingleChoiceFilter.js.erb";

const HireDateFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <SingleChoiceFilter
        label={I18n.t("diversity.buttons.select_hire_date")}
        filterKey="hire_date"
        filterValues={values.hire_date}
        defaultParams={defaultParams.hire_date}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

HireDateFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        hire_date: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default HireDateFilter;
