import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Dropdown, Form } from "react-bootstrap";
import ReactBootstrapSlider from "react-bootstrap-slider/dist/react-bootstrap-slider";
import _some from "lodash/some";
import I18n from "../../../../utils/i18n";

const ScoreFilter = ({ params, setParams, values, defaultParams, applyFilters }) => {
    const filterValues = values.score;
    const scoreValues = values.range_score || { min: 0, max: 1 };
    const [rangeValue, setRangeValue] = useState([0, 1]);

    useEffect(() => {
        setRangeValue([scoreValues.min, scoreValues.max]);
    }, [scoreValues.min, scoreValues.max]);

    const updateParams = (event) => {
        let value = event.currentTarget.name;

        if (value === "range") {
            value = rangeValue.join("-");
        }

        setParams({ ...params, score: value });
    };

    const isChecked = (key) => (_some(filterValues, ["id", params.score]) ? key === params.score : key === "range");
    const badgeCount = () => (params.score === defaultParams.score ? 0 : 1);

    return (
        <Dropdown as="div" className="d-inline-flex">
            <Dropdown.Toggle variant="primary" className="text-light m-1">
                {I18n.t("diversity.buttons.select_score")}
                {badgeCount() > 0 && (
                    <Badge variant="light" className="mx-1">
                        {badgeCount()}
                    </Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="dropdown-submenu">
                    {filterValues.map((filterValue, index) => (
                        <div className="dropdown-item" key={index}>
                            <Form.Check
                                custom
                                name={filterValue.id}
                                type="radio"
                                id={`custom-radio-${filterValue.id}`}
                                label={filterValue.label}
                                checked={isChecked(filterValue.id)}
                                onChange={updateParams}
                            />
                            <Form />
                        </div>
                    ))}
                    <div className="dropdown-item">
                        <ReactBootstrapSlider
                            value={rangeValue}
                            name="range"
                            min={scoreValues.min}
                            max={scoreValues.max}
                            slideStop={(event) => {
                                setRangeValue(event.target.value);
                                setParams({ ...params, score: event.target.value.join("-") });
                            }}
                            disabled={isChecked("range") ? "" : "disabled"}
                        />
                    </div>
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <Dropdown.Item onClick={applyFilters} className="text-center">
                        {I18n.t("diversity.buttons.submit")}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

ScoreFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        score: PropTypes.array.isRequired,
        range_score: PropTypes.object.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default ScoreFilter;
