import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import get from "lodash/get";
import * as Yup from "yup";
import I18n from "../../../utils/i18n";

const ACTIONS = {
    approved: "approve",
    rejected: "reject",
    reviewed: "review",
};

const DiversityFirmwideUploadActionModal = ({ status, comment, show, onSubmit, onHide }) => {
    const action = ACTIONS[status];

    const validationSchema = Yup.object().shape({
        participation: Yup.object().shape({
            status: Yup.string().required(),
            comment: Yup.string().max(5000),
        }),
    });

    return (
        <Formik
            initialValues={{
                participation: {
                    status: status,
                    comments_attributes: [{ text: comment || "" }],
                },
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, handleChange, handleReset, handleSubmit, isSubmitting }) => (
                <Modal
                    size="mb"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={() => {
                        handleReset();
                        onHide();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                            {I18n.t(`tables.diversity/participation.titles.${action}`)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="participation_comment">
                                <Form.Control
                                    as="textarea"
                                    name="participation[comments_attributes][0][text]"
                                    placeholder={I18n.t("tables.diversity/participation.comment")}
                                    onChange={handleChange}
                                    value={values.participation.comments_attributes[0].text}
                                    isInvalid={get(errors, "participation.comment") || get(errors, "comment")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {get(errors, "participation.comment") || get(errors, "comment")}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                handleReset();
                                onHide();
                            }}
                        >
                            {I18n.t("common.links.cancel")}
                        </Button>
                        <Button
                            variant={status === "rejected" ? "danger" : "primary"}
                            className="text-white"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                        >
                            {isSubmitting && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2"
                                />
                            )}
                            {I18n.t(`tables.diversity/participation.actions.${action}`)}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

DiversityFirmwideUploadActionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    comment: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadActionModal;
