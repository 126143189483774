import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _map from "lodash/map";
import _join from "lodash/join";
import Table from "../../../common/components/Table";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import { getCertificateTypes, deleteCertificateType } from "../../../api/diversityApi";
import DeleteAction from "../../../common/components/DeleteAction";

const UsesCountCell = ({ row: { original } }) => (
    <div>
        <span>{I18n.t("tables.diversity/certificate_types.active_count", { count: original.active_count })}</span>
        <br />
        <span>
            <small>
                {I18n.t("tables.diversity/certificate_types.expired_count", { count: original.expired_count })}
            </small>
        </span>
    </div>
);

const OwnershipCell = ({ row: { original } }) => (
    <div>
        <span>
            {_join(
                _map(original.ownership.ownership_types, (type) =>
                    type === "minority_owned"
                        ? `${I18n.t("activerecord.attributes.company_structure.minority_owned")}: ${I18n.t(
                              `activerecord.attributes.company_structure.ethnicities.${original.ownership.ethnicity}`,
                          )}`
                        : I18n.t(`activerecord.attributes.company_structure.${type}`),
                ),
                ", ",
            )}
        </span>
    </div>
);

const UpdatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.updated_at)}</div>
);

const SourceUrlCell = ({ row: { original } }) =>
    original.source && (
        <div className="d-flex flex-column mw-300-px">
            <a className="text-truncate" href={`//${original.source}`} target="_blank" rel="noreferrer">
                {original.source}
            </a>
        </div>
    );

const ActionsCell = ({ row: { original }, reloadTable }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDelete = () => {
        deleteCertificateType(original.id).then(() => {
            setShowDeleteModal(false);
            reloadTable();
        });
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as={ThreeDotsToggle} />
            <Dropdown.Menu size="sm" title="">
                <Dropdown.Item as={Link} to={`/diversity/certificate_types/${original.id}/edit`}>
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                    {I18n.t("common.links.edit")}
                </Dropdown.Item>
                {original.active_count === 0 && original.expired_count === 0 && (
                    <Dropdown.Item as={Link} to="#" onClick={() => setShowDeleteModal(true)}>
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        {I18n.t("common.links.delete")}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
            <DeleteAction
                show={showDeleteModal}
                onSubmit={onDelete}
                onHide={() => setShowDeleteModal(false)}
                resource={I18n.t("activerecord.attributes.diversity/certificate_type.name")}
            />
        </Dropdown>
    );
};

const NameCell = ({ row: { original } }) => <div className="text-truncate mw-300-px">{original.name}</div>;

const DiversityCertificateTypesTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.diversity/certificate_type.name"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/certificate_type.source"),
                accessor: "source",
                Cell: SourceUrlCell,
            },
            {
                Header: I18n.t("activerecord.attributes.company_structure.ownerships"),
                id: "ownership",
                Cell: OwnershipCell,
                isSorted: false,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/certificate_type.uses_count"),
                id: "uses_count",
                Cell: UsesCountCell,
                isSorted: false,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/certificate_type.updated_at"),
                accessor: "updated_at",
                Cell: UpdatedAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} reloadTable={reloadTable} />,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reloadedAt, setReloadedAt] = useState(new Date());
    const reloadTable = () => setReloadedAt(new Date());
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);

        getCertificateTypes().then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data);
                setLoading(false);
            }
        });
    }, [reloadedAt]);

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={-1}
            loading={loading}
            manualSortBy={false}
            initialSortBy={[{ id: "name", desc: false }]}
        />
    );
};

UsesCountCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            active_count: PropTypes.number.isRequired,
            expired_count: PropTypes.number.isRequired,
        }),
    }),
};

OwnershipCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            ownership: PropTypes.shape({
                ownership_types: PropTypes.array.isRequired,
                ethnicity: PropTypes.string,
            }),
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            active_count: PropTypes.number.isRequired,
            expired_count: PropTypes.number.isRequired,
        }),
    }),
    reloadTable: PropTypes.func.isRequired,
};

UpdatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string.isRequired,
        }),
    }),
};

SourceUrlCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            source: PropTypes.string,
        }),
    }),
};

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
};

export default DiversityCertificateTypesTable;
