import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { Formik, Form as FormikForm } from "formik";
import Select from "react-select";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import I18n from "../../utils/i18n";
import { signInAs } from "../../api/signInAsSessionApi";
import { setAlert } from "../../state/alert/alertActions";

const SingInAsPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.resource);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [organizations, setOrganizations] = useState([]);

    const onSubmit = (values, { setSubmitting }) => {
        signInAs(values)
            .then((response) => {
                _.isArray(response.data) ? setOrganizations(response.data) : (window.location = "/");
                setSubmitting(false);
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(
                    setAlert({ translationKey: "forms.sign_in_as.wrong_email_or_organization", variant: "danger" }),
                );
            });
    };

    if (!(currentUser.superadmin || (currentUser.admin && currentWhitelabel.allow_sign_in_as))) {
        history.push("/");
    }

    return (
        <Container className="h-100">
            <Row className="h-100">
                <Col md={3} />
                <Col md={6} className="d-flex flex-column justify-content-center">
                    <div className="form-wrapper bg-white p-4 rounded">
                        <Formik initialValues={{ organization_id: "", email: "" }} onSubmit={onSubmit}>
                            {({ values, isSubmitting, handleChange, setFieldValue }) => (
                                <FormikForm as={Form}>
                                    <Form.Group className="border-bottom">
                                        <h2>{I18n.t("forms.sign_in_as.title")}</h2>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="email"
                                            placeholder={I18n.t("activerecord.attributes.user.email")}
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                    </Form.Group>
                                    {!_.isEmpty(organizations) && (
                                        <Form.Group>
                                            <Select
                                                name="organization_id"
                                                options={organizations}
                                                placeholder={I18n.t(
                                                    "forms.select_organization.inputs.select_placeholder",
                                                )}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                onChange={(option) => setFieldValue("organization_id", option.value)}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group className="text-center">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="text-white"
                                            disabled={
                                                isSubmitting || !(_.isEmpty(organizations) || values.organization_id)
                                            }
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("forms.sign_in.submit")}
                                        </Button>
                                    </Form.Group>
                                </FormikForm>
                            )}
                        </Formik>
                    </div>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
    );
};

export default SingInAsPage;
