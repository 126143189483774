import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import _map from "lodash/map";
import _intersection from "lodash/intersection";
import _includes from "lodash/includes";
import _without from "lodash/without";
import classNames from "classnames";
import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import useChannel from "../../../utils/channels/useChannel";
import I18n from "../../../utils/i18n";

const STAT_CHART = "mini_stats_bar";

const ViewDashboardPage = ({
    updateCharts,
    chartsRoute,
    channelName,
    namespace,
    currentActor,
    chartParams,
    canvasRefs,
    showDashboard = true,
}) => {
    const [visibleCharts, setVisibleCharts] = useState([]);
    const [chartData, setChartData] = useState({});
    const [chartInProgress, setChartInProgress] = useState({});
    const [chartsSubscribed, setChartsSubscribed] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    useChannel({
        channelParams: { channel: channelName },
        onReceived: ({ type, payload }) => {
            setChartData((prevState) => ({ ...prevState, ...{ [type]: payload } }));
            setChartInProgress((prevState) => ({ ...prevState, ...{ [type]: false } }));
        },
        onConnected: () => setChartsSubscribed(true),
    });

    useEffect(() => {
        if (!_isEmpty(chartParams) && chartsSubscribed) {
            setInProgress(true);
            setChartInProgress({});

            updateCharts(chartParams)
                .then(({ data }) => setVisibleCharts(data))
                .finally(() => setInProgress(false));
        }
    }, [chartParams, chartsSubscribed]);

    return (
        !_isEmpty(chartParams) && (
            <Container fluid className="py-3">
                {showDashboard && (currentActor.super_manager || currentActor.diversity_manager) && (
                    <Row className="justify-content-end">
                        <Col className="col-auto ">
                            <Link
                                to={`/diversity/dashboards/${namespace}/edit`}
                                className="btn btn-light"
                                title={I18n.t("common.links.edit")}
                            >
                                <FontAwesomeIcon icon={faCog} />
                            </Link>
                        </Col>
                    </Row>
                )}
                {_without(visibleCharts, STAT_CHART).length < 1
                    ? I18n.t("diversity.charts.client_specific_dashboard.empty_content")
                    : _map(chartsRoute, (row, rowIndex) => (
                          <Row key={rowIndex}>
                              {_map(row, (column, columnIndex) => {
                                  const colWidth =
                                      _intersection(visibleCharts, _map(row, "type")).length === row.length
                                          ? row.length
                                          : 1;
                                  return (
                                      _includes(visibleCharts, column.type) && (
                                          <Col
                                              key={columnIndex}
                                              className={classNames(column?.classNameForViewMode, {
                                                  "mb-3": rowIndex > 0,
                                              })}
                                              lg={12}
                                              xl={12 / colWidth}
                                          >
                                              {React.createElement(column.component, {
                                                  key: columnIndex,
                                                  canvasRefs: canvasRefs,
                                                  chartData: (chartData[column.type] || {}).data,
                                                  chartOptions: (chartData[column.type] || {}).options,
                                                  inProgress:
                                                      inProgress ||
                                                      _isUndefined(chartInProgress[column.type]) ||
                                                      chartInProgress[column.type],
                                              })}
                                          </Col>
                                      )
                                  );
                              })}
                          </Row>
                      ))}
            </Container>
        )
    );
};

ViewDashboardPage.propTypes = {
    updateCharts: PropTypes.func.isRequired,
    chartsRoute: PropTypes.array.isRequired,
    channelName: PropTypes.string.isRequired,
    namespace: PropTypes.string,
    currentActor: PropTypes.shape({
        super_manager: PropTypes.bool,
        diversity_manager: PropTypes.bool,
        organization: PropTypes.shape({
            uid: PropTypes.string,
        }),
    }),
    chartParams: PropTypes.object,
    canvasRefs: PropTypes.object,
    showDashboard: PropTypes.bool,
};

export default ViewDashboardPage;
