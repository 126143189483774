import React, { useContext, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import _isNull from "lodash/isNull";
import I18n from "../../../../utils/i18n";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import TemplatesTable from "./TemplatesTable";
import NegotiationsTable from "./NegotiationsTable";

const TableStep = () => {
    const { setCurrentStep, onHide, createMethod, selectedEvent, setSelectedEvent, setFilterValue, filterValue } =
        useContext(CreateProjectWizardContext);
    const currentStepIndex = 2;
    const translationScope = "home.create_event_wizard.steps.table_step";

    useEffect(() => setSelectedEvent(null), [createMethod]);

    return (
        <>
            <Modal.Header className="border-0 pb-1">
                <Modal.Title className="mb-3">
                    <Link className="text-dark" to="#" onClick={() => setCurrentStep(currentStepIndex - 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                        {I18n.t("home.create_event_wizard.buttons.back")}
                    </Link>
                    <div className="mt-4 mb-2 text-2xl">
                        {I18n.t(`${createMethod}.title`, { scope: translationScope })}
                    </div>
                    <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                        {I18n.t(`${createMethod}.tip`, { scope: translationScope })}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex justify-content-end">
                    <Form.Row>
                        <Form.Control
                            type="text"
                            name="filter[name]"
                            placeholder={I18n.t("search_placeholder", { scope: translationScope })}
                            onChange={(event) =>
                                setFilterValue((oldValues) => ({
                                    ...oldValues,
                                    name_or_project_name_or_industry_name_or_user_display_name_cont: event.target.value,
                                }))
                            }
                            value={filterValue.name_or_project_name_or_industry_name_or_user_display_name_cont}
                        />
                    </Form.Row>
                </div>
                {createMethod === "from_template" && <TemplatesTable />}
                {createMethod === "from_previous" && <NegotiationsTable />}
            </Modal.Body>

            <Modal.Footer className="pt-0 border-0 d-flex justify-content-between">
                <Button variant="outline-secondary" onClick={onHide}>
                    {I18n.t("home.create_event_wizard.buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    className="text-white"
                    onClick={() => setCurrentStep(currentStepIndex + 1)}
                    disabled={_isNull(selectedEvent)}
                >
                    {I18n.t("home.create_event_wizard.buttons.proceed")}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default TableStep;
