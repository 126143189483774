import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { getIn, useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";
import { FILE_INVALID_TYPE, FILE_TOO_LARGE, FILE_TOO_SMALL, TOO_MANY_FILES } from "react-dropzone/src/utils";
import classNames from "classnames";
import { FormControl, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import I18n from "../../utils/i18n";

const AvatarContainer = ({
    inputName = "file",
    accept = ["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/x-ms-bmp", "image/gif"],
    maxFiles = 1,
    maxSize = 5242880,
    minSize = 0,
    classNamePostfix = "round",
    previewUrl = null,
}) => {
    const { values, setFieldValue, setFieldError, errors } = useFormikContext();
    const file = getIn(values, inputName);

    const validationMessages = {
        [FILE_INVALID_TYPE]: I18n.t("errors.messages.allowed_file_content_types", {
            types: accept.map((type) => I18n.t(`dropzone.formats.${type}`)).join(", "),
        }),
        [FILE_TOO_LARGE]: I18n.t("errors.messages.file_size_is_less_than", { count: maxSize / (1024 * 1024) }),
        [FILE_TOO_SMALL]: I18n.t("errors.messages.file_size_is_greater_than", { count: minSize }),
        [TOO_MANY_FILES]: I18n.t("errors.messages.too_many_files"),
    };
    const preview = () => {
        if (file !== "" && !_.isNull(previewUrl)) return previewUrl;

        return _.isString(file) ? file : (file && URL.createObjectURL(file)) || "";
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        maxFiles: maxFiles,
        multiple: false,
        onDrop: (acceptedFiles, fileRejections) => {
            acceptedFiles[0] && setFieldValue(inputName, acceptedFiles[0]);

            if (fileRejections[0]) {
                setFieldError(
                    inputName,
                    fileRejections[0].errors.map((error) => validationMessages[error.code]).join("; "),
                );
            }
        },
    });

    return (
        <div
            className={classNames("d-flex justify-content-center", {
                "is-invalid": getIn(errors, inputName),
            })}
        >
            <Card
                className={classNames("drop-area", `avatar-placeholder avatar-placeholder-${classNamePostfix}`, {
                    "border-0": file,
                })}
                {...getRootProps()}
            >
                <FormControl {...getInputProps({ name: inputName })} />
                {file ? (
                    <div className={`avatar avatar-${classNamePostfix} h-100 w-100`}>
                        <div
                            className={`avatar-image avatar-image-${classNamePostfix}`}
                            style={{ backgroundImage: `url("${preview()}")` }}
                        />
                    </div>
                ) : (
                    <Card.Body>
                        <Card.Text>{I18n.t("dropzone.message")}</Card.Text>
                    </Card.Body>
                )}
            </Card>
            {file && (
                <div className="button-wrapper">
                    <Button
                        variant="danger"
                        className="remove-file"
                        onClick={() => {
                            setFieldValue(inputName, "");
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            )}
        </div>
    );
};

AvatarContainer.propTypes = {
    inputName: PropTypes.string,
    accept: PropTypes.array,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    maxFiles: PropTypes.number,
    classNamePostfix: PropTypes.string,
    previewUrl: PropTypes.string,
};

export default AvatarContainer;
