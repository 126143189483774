import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import OrganizationTable from "../../../../pages/OrganizationsPage/components/OrganizationTable";

const SubOrganizationsTab = ({ parentUid }) => (
    <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
        <OrganizationTable organizationUid={parentUid} isSubOrganizations />
    </Container>
);

SubOrganizationsTab.propTypes = {
    parentUid: PropTypes.string.isRequired,
};

export default SubOrganizationsTab;
