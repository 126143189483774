import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonCard = ({ text, tip, clickHandler, active = false }) => (
    <div
        className={classNames("flex flex-col p-8 mb-5 mr-3 border rounded-3xl bg-white tracking-wide", {
            "shadow-lg border-blue-500": active,
            "shadow-sm border-grey-10": !active,
        })}
        role="button"
        onClick={clickHandler}
    >
        <div className="text-center text-nowrap font-semibold tracking-wide text-base">{text}</div>
        <div className="text-center text-sm">{tip}</div>
    </div>
);

ButtonCard.propTypes = {
    text: PropTypes.string.isRequired,
    tip: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    active: PropTypes.bool,
};
export default ButtonCard;
