import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const FormRow = ({ label, display, error, children }) => (
        display && (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                {children}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>
        )
    );

FormRow.propTypes = {
    label: PropTypes.string,
    display: PropTypes.bool,
    error: PropTypes.string,
    children: PropTypes.node,
};

FormRow.defaultProps = {
    label: null,
    display: true,
    error: null,
};

export default FormRow;
