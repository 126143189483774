import React, { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import WhitelabelTable from "./components/WhitelabelTable";

const WhitelabelsPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="top-bar">
                <Col>
                    <Container fluid>
                        <div className="py-3" />
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("sidebar.links.manage.whitelabels")}</h2>
                            </div>
                            <WhitelabelTable />
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default WhitelabelsPage;
