import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Dropdown, Form } from "react-bootstrap";
import * as _ from "lodash";
import I18n from "../../../../../utils/i18n";

const MultipleChoiceFilter = ({ filterKey, filterValues, defaultParams, label, params, setParams, applyFilters }) => {
    const componentParams = params[filterKey] || [];
    const [searchCriteria, setSearchCriteria] = useState("");

    const updateParams = (event, value) => {
        const checked = event.currentTarget.checked;

        checked ? componentParams.push(value) : _.remove(componentParams, (element) => value === element);
        setParams({ ...params, [filterKey]: componentParams });
    };

    const selectAll = (event) => {
        const checked = event.currentTarget.checked;
        const options = checked ? filterValues.map((filterValue) => filterValue.id) : [];

        setParams({ ...params, [filterKey]: options });
    };

    const isChecked = (value) => _.includes(componentParams, value);

    const isShown = (value) => {
        const criteria = searchCriteria.toLowerCase().trim();

        if (criteria.length) {
            return value.toLowerCase().trim().indexOf(criteria) >= 0;
        } else {
            return true;
        }
    };

    const badgeCount = () => (defaultParams.length === componentParams.length ? -1 : componentParams.length);

    return (
        <Dropdown as="div" className="d-inline-flex">
            <Dropdown.Toggle variant="primary" className="text-light m-1">
                {label}
                {badgeCount() >= 0 && (
                    <Badge variant="light" className="mx-1">
                        {badgeCount()}
                    </Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="dropdown-item">
                    <Form.Control
                        size="sm"
                        onChange={(event) => setSearchCriteria(event.currentTarget.value)}
                        value={searchCriteria}
                    />
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    {filterValues.map(
                        (filterValue, index) =>
                            isShown(filterValue.label) && (
                                <div className="dropdown-item" key={index}>
                                    <Form.Check
                                        custom
                                        name={filterKey}
                                        type="checkbox"
                                        id={`${filterKey}-${filterValue.id}`}
                                        label={filterValue.label}
                                        checked={isChecked(filterValue.id)}
                                        onChange={(event) => updateParams(event, filterValue.id)}
                                    />
                                </div>
                            ),
                    )}
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <div className="dropdown-item">
                        <Form.Check
                            custom
                            type="checkbox"
                            id={`selectAllCheckbox-${filterKey}`}
                            label={I18n.t("common.select_all")}
                            checked={_.isEqual(
                                componentParams.sort(),
                                filterValues.map((filterValue) => filterValue.id).sort(),
                            )}
                            onChange={selectAll}
                        />
                    </div>
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <Dropdown.Item onClick={applyFilters} className="text-center">
                        {I18n.t("diversity.buttons.submit")}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

MultipleChoiceFilter.propTypes = {
    filterKey: PropTypes.string.isRequired,
    filterValues: PropTypes.array.isRequired,
    defaultParams: PropTypes.array,
    label: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default MultipleChoiceFilter;
