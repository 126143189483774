import React, { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import _includes from "lodash/includes";
import _isUndefined from "lodash/isUndefined";
import _map from "lodash/map";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChartSettings from "./ChartSettings";

const HIDDEN_CHART = ["mini_stats_bar"];

const ChartsWrapper = ({ visibleCharts, chartsRow, chartData, inProgress, chartInProgress }) => {
    const canvasRefs = useRef({});

    return (
        <>
            {_map(chartsRow, (row, rowIndex) => (
                <Row key={rowIndex}>
                    {_map(
                        row,
                        (column, columnIndex) =>
                            !_includes(HIDDEN_CHART, column.type) &&
                            _includes(visibleCharts, column.type) && (
                                <Col
                                    key={columnIndex}
                                    className={classNames({ "mb-3": rowIndex > 0 })}
                                    lg={12}
                                    xl={12 / row.length}
                                >
                                    <Card>
                                        <Card.Body>
                                            <ChartSettings type={column.type} />

                                            {React.createElement(column.component, {
                                                key: columnIndex,
                                                canvasRefs: canvasRefs,
                                                chartData: (chartData[column.type] || {}).data,
                                                chartOptions: (chartData[column.type] || {}).options,
                                                inProgress:
                                                    inProgress ||
                                                    _isUndefined(chartInProgress[column.type]) ||
                                                    chartInProgress[column.type],
                                            })}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                    )}
                </Row>
            ))}
        </>
    );
};

ChartsWrapper.propTypes = {
    visibleCharts: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    chartInProgress: PropTypes.object.isRequired,
    chartsRow: PropTypes.array.isRequired,
};

export default ChartsWrapper;
