import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _, { isEmpty } from "lodash";
import I18n from "../../utils/i18n";
import Loader from "../../pages/HomePage/components/common/Loader";
import ChartComponent from "./DiversityPage/ChartComponent";

const TieredDiversityChart = ({ params, type, chartType, chartData, chartOptions, getChart }) => {
    const [inProgress, setInProgress] = useState(false);
    const [remoteChartData, setRemoteChartData] = useState({ datasets: [], labels: [] });
    const [remoteChartOptions, setRemoteChartOptions] = useState({});

    useEffect(() => {
        setInProgress(true);

        getChart(type, params).then((response) => {
            setRemoteChartData(response.data.data);
            setRemoteChartOptions(response.data.options);
            setInProgress(false);
        });
    }, [params]);

    if (inProgress || isEmpty(chartData || remoteChartData)) {
        return <Loader />;
    }

    return (
        <ChartComponent
            title={I18n.t(`tiered_diversity.charts.${type}.title`, {
                defaultValue: I18n.t(`tiered_diversity.charts.${type}`),
            })}
            subtitle={I18n.t(`tiered_diversity.charts.${type}.subtitle`, { defaultValue: "" })}
            tooltip={I18n.t(`tiered_diversity.tooltips.${type}`)}
            type={chartType}
            data={chartData || remoteChartData}
            options={_.merge(chartOptions, remoteChartOptions)}
        />
    );
};

TieredDiversityChart.propTypes = {
    params: PropTypes.object,
    type: PropTypes.string.isRequired,
    chartType: PropTypes.string.isRequired,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    getChart: PropTypes.func,
};

export default TieredDiversityChart;
