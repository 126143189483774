import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import MessageContainer from "../common/MessageContainer";

const EventsMessages = ({ messagingData }) => (
        <div className="mb-3">
            <Row>
                <Col>
                    <div className="text-left">
                        <h3>{messagingData.project_name}</h3>
                    </div>
                </Col>
                <Col>
                    <div className="text-right">
                        <h3>{messagingData.messages_count}</h3>
                    </div>
                </Col>
            </Row>
            {messagingData.messages.map((message, index) => (
                <MessageContainer key={index} message={message} />
            ))}
        </div>
    );

EventsMessages.propTypes = {
    messagingData: PropTypes.shape({
        project_name: PropTypes.string,
        messages_count: PropTypes.string,
        messages: PropTypes.array,
    }),
};

export default EventsMessages;
