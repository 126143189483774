import { SET_USER, START_FETCHING, STOP_FETCHING } from "./types";
import { getCurrentUser } from "../../api/sessionApi";
import { login } from "../../api/loginApi";
import { setAlert } from "../alert/alertActions";

export function fetchCurrentUser() {
    return (dispatch) => {
        dispatch(startFetching());

        getCurrentUser()
            .then((response) => {
                dispatch(setCurrentUser(response.data));
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function loginUser(values, errorCallback) {
    return (dispatch) => login(values)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                dispatch(setAlert({ text: error.response.data.error, variant: "danger" }));
                errorCallback();
            });
}

export function startFetching() {
    return { type: START_FETCHING };
}

export function stopFetching() {
    return { type: STOP_FETCHING };
}

export function setCurrentUser(user) {
    return { type: SET_USER, payload: user };
}
