import React from "react";
import PropTypes from "prop-types";
import DiversityFirmwideChart from "./DiversityFirmwideChart";
import I18n from "../../../../utils/i18n";

const AttributesPercentsByCompositionChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="attributes_percents_by_composition"
        chartType="polarArea"
        chartOptions={{
            ...chartOptions,
            scale: {
                ticks: {
                    callback: (value) => `${value} %`,
                },
            },
            tooltips: {
                callbacks: {
                    label: (item, data) =>
                        `${data.labels[item.index]}: ${item.yLabel} % | ${data.datasets[
                            item.datasetIndex
                        ].alternativeData[item.index].toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

AttributesPercentsByCompositionChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default AttributesPercentsByCompositionChart;
