import React from "react";
import PropTypes from "prop-types";

const DisplayField = ({ title, value }) => (
    <div>
        <small className="text-muted">{title}</small>
        <p className="text-break">{value}</p>
    </div>
);

DisplayField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

export default DisplayField;
