import _ from "lodash";
import { DIVERSITY_KEY_INDICATORS_MAPPING, DIVERSITY_KEY_INDICATORS_VALUES } from "./diversityKeyIndicators";

export const calculateDiversityCurrentPointsFor = (
    keyIndicator,
    goal,
    possiblePoints,
    direction,
    currentGoal,
    partialFirmwide
) => {
    let currentPoints;

    if (
        partialFirmwide &&
        (keyIndicator === "firmwide_diversity_goal" || keyIndicator === "partner_promotion_firmwide_diversity_goal")
    ) {
        return 0;
    }

    if (direction === "min") {
        currentPoints =
            parseDiversityValue(goal) === 0
                ? parseDiversityValue(possiblePoints) * 2
                : (currentGoal / parseDiversityValue(goal)) * parseDiversityValue(possiblePoints);
    } else {
        currentPoints = 0;

        if (currentGoal === 0) {
            if (
                keyIndicator === "firmwide_diversity_goal" ||
                keyIndicator === "partner_promotion_firmwide_diversity_goal"
            ) {
                currentPoints = parseDiversityValue(possiblePoints) * 2;
            }
        } else {
            currentPoints = (parseDiversityValue(goal) / currentGoal) * parseDiversityValue(possiblePoints);
        }
    }

    return currentPoints > possiblePoints * 2 ? possiblePoints * 2 : currentPoints;
};

export const calculateMatterBasedScore = (values, parentKeyIndicator, childKeyIndicators) =>
    _.sumBy(childKeyIndicators.length ? childKeyIndicators : [parentKeyIndicator], (keyIndicator) =>
        calculateSumBy(values[keyIndicator], "possible_points")
    );

export const calculateMatterBasedWeight = (values, parentKeyIndicator, childKeyIndicators, sumValue) => {
    const value =
        (calculateMatterBasedScore(values, parentKeyIndicator, childKeyIndicators) / parseDiversityValue(sumValue)) *
        100;

    return _.isNaN(value) ? 0 : value;
};

export const calculateOverallFirmwideScore = (keyIndicators, currentGoals, partialFirmwide) => {
    const groupedKeyIndicators = _.groupBy(keyIndicators, "key_indicator");

    return _.sumBy(DIVERSITY_KEY_INDICATORS_MAPPING["firmwide_goal"], (keyIndicator) =>
        _.sumBy(groupedKeyIndicators[keyIndicator], (groupedValue) =>
            calculateDiversityCurrentPointsFor(
                keyIndicator,
                groupedValue.goal,
                groupedValue.possible_points,
                groupedValue.direction,
                currentGoals[groupedValue.id],
                partialFirmwide
            )
        )
    );
};

export const calculateMatterBasedCurrentPoints = (keyIndicators, currentGoals, partialFirmwide) => {
    const groupedKeyIndicators = _.groupBy(keyIndicators, "key_indicator");

    return _.sumBy(
        _.without(
            DIVERSITY_KEY_INDICATORS_VALUES,
            "firmwide_diversity_goal",
            "partner_promotion_firmwide_diversity_goal"
        ),
        (keyIndicator) =>
            _.sumBy(groupedKeyIndicators[keyIndicator], (groupedValue) =>
                calculateDiversityCurrentPointsFor(
                    keyIndicator,
                    groupedValue.goal,
                    groupedValue.possible_points,
                    groupedValue.direction,
                    currentGoals[groupedValue.id],
                    partialFirmwide
                )
            )
    );
};

export const calculateTotalScore = (keyIndicators, currentGoals, scores, weights, partialFirmwide) => (
        calculateMatterBasedCurrentPoints(keyIndicators, currentGoals, partialFirmwide) +
        (calculateOverallFirmwideScore(keyIndicators, currentGoals, partialFirmwide) *
            parseDiversityValue(weights.overall_weight)) /
            100.0 +
        (scores.attrition_score * parseDiversityValue(weights.attrition_weight)) / 100.0 +
        (scores.hiring_and_promotions_score * parseDiversityValue(weights.hiring_and_promotions_weight)) / 100.0 +
        (scores.leadership_score * parseDiversityValue(weights.leadership_weight)) / 100.0
    );

export const calculateSumBy = (values, attribute) => _.sumBy(values, (value) => parseDiversityValue(value[attribute]));

export const parseDiversityValue = (rawValue) => {
    const value = _.isString(rawValue) ? parseFloat(rawValue) : rawValue;
    return _.isNumber(value) && !_.isNaN(value) ? value : 0;
};
