import {
    DiversityOfFees,
    DiversityOfHours,
    TotalFeesByRoleChart,
    MatterLeadRolesByAttributeChart,
    CreditByAttributeChart,
    AttributesByClientChart,
    AttributesPercentsByClientChart,
    FeesAttributesByClientChart,
    FeesAttributesPercentsByClientChart,
    HoursAttributesByClientChart,
    HoursAttributesPercentsByClientChart,
    HoursAttributesByRole,
    DiversityOfAttributes,
    AttributesByRole,
} from "../pages/DiversityClientSpecificMirrorPage/components/charts";

const ClientSpecificMirrorCharts = [
    [
        { type: "diversity_of_fees", component: DiversityOfFees, position: 1 },
        { type: "total_fees_by_role", component: TotalFeesByRoleChart, position: 2 },
    ],
    [
        { type: "fees_attributes_by_client", component: FeesAttributesByClientChart, position: 3 },
        { type: "fees_attributes_percents_by_client", component: FeesAttributesPercentsByClientChart, position: 4 },
    ],
    [
        { type: "diversity_of_hours", component: DiversityOfHours, position: 5 },
        { type: "hours_attributes_by_role", component: HoursAttributesByRole, position: 6 },
    ],
    [
        { type: "hours_attributes_by_client", component: HoursAttributesByClientChart, position: 7 },
        { type: "hours_attributes_percents_by_client", component: HoursAttributesPercentsByClientChart, position: 8 },
    ],
    [
        { type: "diversity_of_attributes", component: DiversityOfAttributes, position: 9 },
        { type: "attributes_by_role", component: AttributesByRole, position: 10 },
    ],
    [
        { type: "attributes_by_client", component: AttributesByClientChart, position: 11 },
        { type: "attributes_percents_by_client", component: AttributesPercentsByClientChart, position: 12 },
    ],
    [
        { type: "matter_lead_roles_by_attribute", component: MatterLeadRolesByAttributeChart, position: 13 },
        { type: "credit_by_attribute", component: CreditByAttributeChart, position: 14 },
    ],
];
export default ClientSpecificMirrorCharts;
