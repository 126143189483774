import React, { useEffect, useState } from "react";
import { Button, Card, Modal, FormControl, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import _isNaN from "lodash/isNaN";
import I18n from "../../utils/i18n";
import { editTwoFactorAuthentication, updateTwoFactorAuthentication } from "../../api/deviseApi";
import Loader from "../HomePage/components/common/Loader";

const VerificationStep = ({ setCurrentStep, setOtpBackupCodes }) => {
    const [otpSecret, setOtpSecret] = useState("");
    const [otpProvisioningUri, setOtpProvisioningUri] = useState("");
    const [otpSecretModalShow, setOtpSecretModalShow] = useState(false);
    const [otpAttempt, setOtpAttempt] = useState("");
    const [otpAttemptInvalid, setOtpAttemptInvalid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        editTwoFactorAuthentication().then((response) => {
            setOtpSecret(response.data.otp_secret);
            setOtpProvisioningUri(response.data.otp_provisioning_uri);
        });
    }, []);

    if (otpSecret.length === 0) {
        return <Loader />;
    }

    return (
        <Card className="text-dark">
            <Card.Body>
                <Modal
                    show={otpSecretModalShow}
                    onHide={() => setOtpSecretModalShow(false)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                            Two-factor secret
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{otpSecret}</Modal.Body>
                </Modal>

                <p>
                    {I18n.t("forms.two_factor_authentication.steps.verification.scan_image_start")}
                    <a href="#" onClick={() => setOtpSecretModalShow(true)}>
                        {I18n.t("forms.two_factor_authentication.steps.verification.text_code")}
                    </a>
                    {I18n.t("forms.two_factor_authentication.steps.verification.scan_image_end")}
                </p>
                <div className="d-inline-block border rounded p-3 mb-2">
                    <QRCode value={otpProvisioningUri} />
                </div>
                <h3>{I18n.t("forms.two_factor_authentication.steps.verification.enter_code.title")}</h3>
                <p>{I18n.t("forms.two_factor_authentication.steps.verification.enter_code.description")}</p>

                <FormControl
                    className="w-25"
                    type="text"
                    placeholder={I18n.t("activerecord.attributes.user.otp_attempt")}
                    value={otpAttempt}
                    onChange={(event) => {
                        setOtpAttempt(event.target.value);
                        setOtpAttemptInvalid(false);
                    }}
                    isInvalid={otpAttemptInvalid}
                />
                {otpAttemptInvalid && (
                    <FormControl.Feedback type="invalid">{I18n.t("errors.messages.invalid")}</FormControl.Feedback>
                )}
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="primary"
                    className="text-light m-1"
                    onClick={() => {
                        setIsSubmitting(true);

                        updateTwoFactorAuthentication(otpAttempt)
                            .then((response) => {
                                setOtpBackupCodes(response.data.otp_backup_codes);
                                setCurrentStep((prevState) => prevState + 1);
                            })
                            .catch(() => {
                                setOtpAttemptInvalid(true);
                            })
                            .finally(() => setIsSubmitting(false));
                    }}
                    disabled={otpAttempt.length !== 6 || _isNaN(parseInt(otpAttempt)) || isSubmitting}
                >
                    {isSubmitting && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                    )}
                    {I18n.t("common.links.continue")}
                </Button>
            </Card.Footer>
        </Card>
    );
};

VerificationStep.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
    setOtpBackupCodes: PropTypes.func.isRequired,
};

export default VerificationStep;
