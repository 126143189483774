import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _findIndex from "lodash/findIndex";
import _toString from "lodash/toString";
import _get from "lodash/get";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import I18n from "../../../../../utils/i18n";

const CheckboxList = ({ elements, inputName, field, value, withPin = false, pinning, pinned, handlePin, counter }) =>
    _map(elements, (element) => (
        <div
            className={classNames("d-on-hover d-inline-flex align-items-center w-100", {
                "font-weight-bold": withPin && _includes(pinned, _toString(element.id)),
            })}
            key={`${inputName}-${element.id}`}
        >
            <Form.Check custom>
                <Form.Check.Input
                    className="multiselect-filter d-inline-block text-truncate"
                    {...field}
                    type="checkbox"
                    id={`${inputName}-${element.id}`}
                    value={element.id}
                    checked={_findIndex(value, (item) => item === _toString(element.id)) >= 0}
                />
                <Form.Check.Label htmlFor={`${inputName}-${element.id}`} className="w-100">
                    {I18n.t("companies.profiles.sections.filters.counter", {
                        name: element.name,
                        count: _get(counter, element.id) || 0,
                    })}
                </Form.Check.Label>
            </Form.Check>
            {withPin &&
                (pinning === element.id ? (
                    <FontAwesomeIcon icon={faCircleNotch} className="align-baseline ml-2 fa-spin" />
                ) : (
                    <FontAwesomeIcon
                        icon={faThumbtack}
                        className={classNames("align-baseline ml-2 cursor-pointer", {
                            "hover-hide": !_includes(pinned, _toString(element.id)),
                            "text-secondary": !_includes(pinned, _toString(element.id)),
                        })}
                        size="xs"
                        onClick={() => handlePin(inputName, element.id, !_includes(pinned, _toString(element.id)))}
                    />
                ))}
        </div>
    ));

CheckboxList.propTypes = {
    field: PropTypes.object.isRequired,
    inputName: PropTypes.string.isRequired,
    elements: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    withPin: PropTypes.bool,
    pinning: PropTypes.string,
    pinned: PropTypes.array,
    handlePin: PropTypes.func,
    counter: PropTypes.object,
};

export default CheckboxList;
