import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Container, Spinner, Tab, Tabs } from "react-bootstrap";
import { Formik } from "formik";
import _isEmpty from "lodash/isEmpty";
import validationSchema from "./validationSchema";
import I18n from "../../../utils/i18n";
import {
    GeneralInfoTab,
    SettingsTab,
    PermissionsTab,
    SubOrganizationsTab,
    UsersTable,
    DiversityCertificatesTable,
    SubscriptionsTab,
    LocationsTab,
    AttorneysTable,
} from "./tabs";
import NavigationPrompt from "../NavigationPrompt";
import NewPageTitle from "../NewPageTitle";
import PageTitle from "../PageTitle";
import IndustrySpecificQuestionsTab from "./tabs/IndustrySpecificQuestionsTab";

const OrganizationForm = ({ uid, title, onSubmit, initialValues, tab, showEditOrganization }) => {
    const FORM_ACTIONS_TABS = ["generalInfo", "settings", "permissions"];
    const currentUser = useSelector((state) => state.currentUser.resource);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const showSubOrganizationTab = currentUser.admin || currentUser.superadmin || currentActor.super_manager;
    const showUserAndCertificateTab = showSubOrganizationTab || currentActor.profile_manager;
    const showAttorneysTab = showUserAndCertificateTab && initialValues.organization?.legal;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleChange, setFieldValue, values, isSubmitting, handleSubmit, errors, dirty }) => (
                <div className="d-flex flex-column scrollable">
                    <NavigationPrompt when={dirty} />
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Col>
                            <Container fluid className="py-3">
                                {_isEmpty(uid) ? <NewPageTitle resource={title} /> : <PageTitle text={title} />}

                                <Tabs
                                    justify
                                    defaultActiveKey="generalInfo"
                                    id="uncontrolled-tab-example"
                                    activeKey={tab.key}
                                    onSelect={(key) => tab.setKey(key)}
                                >
                                    <Tab
                                        eventKey="generalInfo"
                                        title={I18n.t("forms.organization.edit.tabs.general_info")}
                                    >
                                        <GeneralInfoTab />
                                    </Tab>
                                    {showEditOrganization && (
                                        <Tab
                                            eventKey="locations"
                                            title={I18n.t("forms.organization.edit.tabs.locations")}
                                        >
                                            <LocationsTab organizationUid={uid} />
                                        </Tab>
                                    )}
                                    {showEditOrganization && (
                                        <Tab
                                            eventKey="industrySpecificQuestions"
                                            title={I18n.t(
                                                "forms.organization.edit.tabs.industry_specific_questions.title",
                                            )}
                                        >
                                            <IndustrySpecificQuestionsTab organizationUid={uid} />
                                        </Tab>
                                    )}
                                    <Tab eventKey="settings" title={I18n.t("forms.organization.edit.tabs.settings")}>
                                        <SettingsTab
                                            errors={errors?.organization || {}}
                                            enableWhitelabel={_isEmpty(uid) && currentUser.superadmin}
                                        />
                                    </Tab>
                                    {showEditOrganization && (
                                        <Tab
                                            eventKey="subscriptions"
                                            title={I18n.t("forms.organization.edit.tabs.subscriptions.title")}
                                        >
                                            <SubscriptionsTab organizationUid={uid} />
                                        </Tab>
                                    )}
                                    {currentUser.superadmin && (
                                        <Tab
                                            eventKey="permissions"
                                            title={I18n.t("forms.organization.edit.tabs.permissions")}
                                        >
                                            <PermissionsTab
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                values={values.organization.organization_permissions_attributes}
                                            />
                                        </Tab>
                                    )}
                                    {showEditOrganization && showSubOrganizationTab && (
                                        <Tab
                                            eventKey="subOrganizations"
                                            title={I18n.t("forms.organization.edit.tabs.sub_organizations")}
                                        >
                                            <SubOrganizationsTab parentUid={uid} />
                                        </Tab>
                                    )}
                                    {showEditOrganization && showUserAndCertificateTab && (
                                        <Tab eventKey="users" title={I18n.t("forms.organization.edit.tabs.users")}>
                                            <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
                                                <UsersTable organizationUid={uid} />
                                            </Container>
                                        </Tab>
                                    )}
                                    {showEditOrganization && showUserAndCertificateTab && (
                                        <Tab
                                            eventKey="diversityCertificates"
                                            title={I18n.t("forms.organization.edit.tabs.diversity_certificates")}
                                        >
                                            <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
                                                <DiversityCertificatesTable setFieldValue={setFieldValue} />
                                            </Container>
                                        </Tab>
                                    )}
                                    {showEditOrganization && showAttorneysTab && (
                                        <Tab
                                            eventKey="attorneys"
                                            title={I18n.t("forms.organization.edit.tabs.attorneys")}
                                        >
                                            <AttorneysTable organizationUid={uid} />
                                        </Tab>
                                    )}
                                </Tabs>
                            </Container>
                        </Col>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    {FORM_ACTIONS_TABS.indexOf(tab.key) >= 0 && (
                                        <div className="form-actions">
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Container>
                        </Col>
                    </div>
                </div>
            )}
        </Formik>
    );
};

OrganizationForm.propTypes = {
    uid: PropTypes.string,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    showDiversity: PropTypes.bool,
    showEditOrganization: PropTypes.bool,
    tab: PropTypes.shape({
        key: PropTypes.string.isRequired,
        setKey: PropTypes.func.isRequired,
    }),
};

export default OrganizationForm;
