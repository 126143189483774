import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { sanitizeText } from "../../../../utils/sanitizeText";
import I18n from "../../../../utils/i18n";

const Description = ({ value }) => {
    const text = sanitizeText(value);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(!open);

    return (
        <>
            <div className={classNames({ "short-description": !open })} dangerouslySetInnerHTML={text} />

            <div
                onClick={handleOpen}
                className={classNames("mt-2 rotate-arrow cursor-pointer", { "opposite-collapsed": open })}
            >
                <small className="d-flex align-items-center text-primary">
                    {open ? I18n.t("common.links.less") : I18n.t("common.links.more")}
                    <FontAwesomeIcon icon={faChevronDown} className="ml-2 align-baseline arrow" size="xs" />
                </small>
            </div>
        </>
    );
};

Description.propTypes = {
    value: PropTypes.string,
};

export default Description;
