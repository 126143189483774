import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Form, Button, Spinner, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import _filter from "lodash/filter";
import { useDispatch } from "react-redux";
import _includes from "lodash/includes";
import { Row, Col } from "react-bootstrap";
import _map from "lodash/map";
import I18n from "../../../../utils/i18n";
import { getActors, shareList } from "../../../../api/listsApi";
import { setAlert } from "../../../../state/alert/alertActions";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";

const ShareListModal = ({ listId, show, onClose, setCurrentList }) => {
    const [actors, setActors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const initialValues = {
        companies_shares: { actor_ids: [] },
    };

    const filteredActors = () =>
        searchTerm.length > 2
            ? _filter(actors, (actor) => _includes(actor.user_email.toLowerCase(), searchTerm.toLowerCase()))
            : actors;

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        shareList({ ...values, list_id: listId })
            .then(({ data: { viewers } }) => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_add", variant: "info" }));
                setCurrentList((prevList) => ({ ...prevList, viewers: [...prevList.viewers, ...viewers] }));
                resetForm();
                onClose();
            })
            .catch(() => {
                dispatch(setAlert({ translationKey: "common.alerts.error", variant: "danger" }));
            })
            .finally(() => setSubmitting(false));
    };
    const handleClose = (handleReset) => {
        handleReset();
        onClose();
    };

    const availableActors = useMemo(() => _filter(filteredActors(), ["exists", false]), [filteredActors().length]);

    const validationSchema = Yup.object().shape({
        companies_shares: Yup.object().shape({
            actor_ids: Yup.array().required(),
        }),
    });

    useEffect(() => {
        if (show) {
            setSearchTerm("");
            setLoading(true);
            getActors({ list_id: listId })
                .then(({ data }) => setActors(data))
                .finally(() => setLoading(false));
        }
    }, [show, listId]);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
                <Modal show={show} onHide={() => handleClose(handleReset)}>
                    <Modal.Header closeButton>{I18n.t("companies.lists.modals.share_list.title")}</Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col sm={1} className="mt-2">
                                            <IndeterminateCheck
                                                id="select-all-actors"
                                                type="checkbox"
                                                checked={
                                                    availableActors.length > 0 &&
                                                    values.companies_shares.actor_ids.length === availableActors.length
                                                }
                                                indeterminate={
                                                    values.companies_shares.actor_ids.length > 0 &&
                                                    values.companies_shares.actor_ids.length < availableActors.length
                                                }
                                                onChange={(event) => {
                                                    const { checked } = event.target;
                                                    const ids = _map(availableActors, "id");
                                                    setFieldValue("companies_shares.actor_ids", checked ? ids : []);
                                                }}
                                            />
                                        </Col>
                                        <Col sm={11}>
                                            <Form.Control
                                                type="text"
                                                placeholder={I18n.t("companies.lists.placeholders.search")}
                                                name="search"
                                                value={searchTerm}
                                                onChange={({ target: { value } }) => setSearchTerm(value)}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Header>

                                <Card.Body className="overflow-y-auto max-height-25">
                                    <Spinner
                                        hidden={!loading}
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        className="mr-2"
                                    />

                                    {!loading &&
                                        filteredActors().map((actor) => (
                                            <Form.Check
                                                custom
                                                key={actor.id}
                                                id={actor.id}
                                                type="checkbox"
                                                name="companies_shares.actor_ids"
                                                onChange={(event) => {
                                                    const { checked } = event.target;
                                                    if (checked) {
                                                        setFieldValue("companies_shares.actor_ids", [
                                                            ...values.companies_shares.actor_ids,
                                                            actor.id,
                                                        ]);
                                                    } else {
                                                        setFieldValue(
                                                            "companies_shares.actor_ids",
                                                            _filter(
                                                                values.companies_shares.actor_ids,
                                                                (id) => id !== actor.id,
                                                            ),
                                                        );
                                                    }
                                                }}
                                                value={actor.id}
                                                disabled={actor.exists}
                                                checked={
                                                    actor.exists ||
                                                    _includes(values.companies_shares.actor_ids, actor.id)
                                                }
                                                label={actor.user_email}
                                            />
                                        ))}
                                </Card.Body>
                                <Card.Footer>
                                    {I18n.t("companies.lists.sections.companies.selected", {
                                        count: values.companies_shares.actor_ids.length,
                                        total: _filter(actors, ["exists", false]).length,
                                    })}
                                </Card.Footer>
                            </Card>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(handleReset)}>
                            {I18n.t("common.links.cancel")}
                        </Button>

                        <Button
                            disabled={isSubmitting || loading || values.companies_shares.actor_ids.length === 0}
                            variant="primary"
                            className="text-white"
                            onClick={handleSubmit}
                        >
                            <Spinner hidden={!isSubmitting} as="span" animation="border" size="sm" className="mr-2" />
                            {I18n.t("common.links.add")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

ShareListModal.propTypes = {
    listId: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setCurrentList: PropTypes.func.isRequired,
};

export default ShareListModal;
