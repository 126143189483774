import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import _isNull from "lodash/isNull";
import moment from "moment";
import { newClientSpecificFirm, createClientSpecificFirm, getDeadline } from "../../../api/diversityApi";
import AddFirmModalHeader from "./AddFirmModalHeader";
import AddFirmModalBody from "./AddFirmModalBody";
import { StepperFooter, StepperHeader } from "../../../common/components/Stepper";
import I18n from "../../../utils/i18n";
import AddFirmStep from "../../../common/components/Steps/AddFirmStep";
import CommentStep from "../../../common/components/Steps/CommentStep";
import ResultStep from "../../../common/components/Steps/ResultStep";
import { validationSchema, initFirm } from "../../../utils/commonSteps";
import Loader from "../../HomePage/components/common/Loader";

const STEPS = [
    {
        title: I18n.t("diversity.clients.steps.existing_firm"),
        key: "existing_organization",
    },
    { title: I18n.t("diversity.clients.steps.new_firm"), key: "new_organization" },
    { title: I18n.t("diversity.clients.steps.send"), key: "send_to_organization" },
    { title: I18n.t("diversity.clients.steps.result"), key: "result" },
];

const AddFirmModal = ({ show, onHide }) => {
    const [inProgress, setInProgress] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [deadlineMessage, setDeadlineMessage] = useState(null);
    const [result, setResult] = useState(null);

    const onShow = () => {
        setInProgress(true);

        newClientSpecificFirm()
            .then((response) => setOrganizations(response.data))
            .finally(() => setInProgress(false));
        getDeadline().then((response) => {
            let deadlineDate = response.data?.deadline_at;

            if (_isNull(deadlineDate)) {
                setDeadlineMessage(I18n.t("diversity.labels.no_deadline"));
            } else {
                setDeadlineMessage(
                    I18n.t("diversity.labels.deadline_exist", {
                        count: moment(deadlineDate).diff(
                            moment().quarter(moment().quarter()).startOf("quarter"),
                            "days"
                        ),
                    })
                );
            }
        });
    };

    const onSubmit = (values) => {
        createClientSpecificFirm(values).then((response) => setResult(response.data.errors));
    };

    const onClose = (handleReset) => {
        setCurrentStep(0);
        handleReset();
        onHide();
    };

    return (
        <Formik
            initialValues={{ organizations: [], new_organizations: [initFirm], comment: "" }}
            onSubmit={onSubmit}
            validationSchema={validationSchema("new_organizations")}
        >
            {({ values, handleSubmit, handleChange, handleReset, setFieldValue, handleBlur, errors }) => (
                <Modal
                    id="add-firm-modal"
                    show={show}
                    onHide={() => {
                        handleReset();
                        onHide();
                    }}
                    onShow={onShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <AddFirmModalHeader />

                    <Modal.Body>
                        <StepperHeader steps={STEPS} currentStep={currentStep} />

                        {inProgress ? (
                            <Loader />
                        ) : (
                            <Form noValidate onSubmit={handleSubmit}>
                                {STEPS[currentStep].key === "existing_organization" && (
                                    <AddFirmModalBody
                                        handleChange={handleChange}
                                        values={values}
                                        organizations={organizations}
                                    />
                                )}

                                {STEPS[currentStep].key === "new_organization" && (
                                    <AddFirmStep
                                        values={values.new_organizations}
                                        title={I18n.t("diversity.surveys.add_firm_modal.new_firm_text")}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        field="new_organizations"
                                        initFirm={initFirm}
                                    />
                                )}

                                {STEPS[currentStep].key === "send_to_organization" && (
                                    <CommentStep
                                        values={values.comment}
                                        title={I18n.t("diversity.surveys.add_firm_modal.comment_text")}
                                        setFieldValue={setFieldValue}
                                        deadlineMessage={deadlineMessage}
                                    />
                                )}
                                {STEPS[currentStep].key === "result" && (
                                    <ResultStep result={result} scope="diversity.clients.add_firm_modal" />
                                )}
                            </Form>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <StepperFooter
                            setStep={setCurrentStep}
                            textSubmit={I18n.t("diversity.clients.steps.send")}
                            onSubmit={() => onSubmit(values, { handleReset })}
                            steps={STEPS}
                            currentStep={currentStep}
                            onClose={() => onClose(handleReset)}
                            requiredValue={
                                values.organizations[0] ||
                                (values.new_organizations[0].email && values.new_organizations[0].name)
                            }
                            disabled={inProgress}
                        />
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

AddFirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default AddFirmModal;
