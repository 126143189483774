import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import { useField } from "formik";
import I18n from "../../../utils/i18n";

const ActivityLogsFilterRecordName = ({ onChange }) => {
    const [field] = useField("q[record_name]");

    return (
        <Form.Group as={Col}>
            <Form.Label htmlFor="q_record_name">
                {I18n.t("activerecord.attributes.activity/log.record_name")}
            </Form.Label>
            <Form.Control
                type="text"
                id="q_record_name"
                name="q[record_name]"
                value={field.value || ""}
                onChange={onChange}
            />
        </Form.Group>
    );
};

ActivityLogsFilterRecordName.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ActivityLogsFilterRecordName;
