import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import _isUndefined from "lodash/isUndefined";
import { getOrganizationUsers } from "../../../../api/organizationApi";
import Table from "../../../../common/components/Table";
import { NameWithAvatarCell, RoleName, CurrentSignInAtCell } from "../../../../common/components/Table/cells";
import I18n from "../../../../utils/i18n";

const UsersTable = ({ organizationUid }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("tables.users.user"),
                accessor: "name",
                Cell: NameWithAvatarCell,
            },
            {
                Header: I18n.t("tables.users.role"),
                accessor: "manager",
                Cell: RoleName,
            },
            {
                Header: I18n.t("activerecord.attributes.user.current_sign_in_at"),
                accessor: "current_sign_in_at",
                Cell: CurrentSignInAtCell,
            },
        ],
        []
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "id", desc: false };

        if (_isUndefined(organizationUid)) {
            setData([]);
            setPageCount(0);
        } else {
            setLoading(true);

            getOrganizationUsers(organizationUid, pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then(
                (response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                }
            );
        }
    }, []);

    return <Table columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />;
};

UsersTable.propTypes = { organizationUid: PropTypes.string };

export default UsersTable;
