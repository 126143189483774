import React from "react";
import { Col, Form } from "react-bootstrap";
import { DatePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";

const DATE_FORMAT = "MM-DD-YYYY";

const PermissionDate = ({ setFieldValue, value }) => (
        <>
            <Col>
                <Form.Group>
                    <Form.Label>{I18n.t("activerecord.attributes.organization.permission_set_from")}</Form.Label>
                    <DatePicker
                        format={DATE_FORMAT}
                        useCurrent={false}
                        className="flex-grow-1"
                        onChange={(event) =>
                            setFieldValue(
                                "filter[organization_permissions_created_at_gteq]",
                                event.date ? moment(event.date).startOf("day").format() : ""
                            )
                        }
                        date={value.organization_permissions_created_at_gteq}
                        maxDate={moment()}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>{I18n.t("activerecord.attributes.organization.permission_set_to")}</Form.Label>
                    <DatePicker
                        format={DATE_FORMAT}
                        useCurrent={false}
                        className="flex-grow-1"
                        onChange={(event) =>
                            setFieldValue(
                                "filter[organization_permissions_created_at_lteq]",
                                event.date ? moment(event.date).startOf("day").format() : ""
                            )
                        }
                        date={value.organization_permissions_created_at_lteq}
                        maxDate={moment()}
                    />
                </Form.Group>
            </Col>
        </>
    );

PermissionDate.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    value: PropTypes.shape({
        organization_permissions_created_at_gteq: PropTypes.string,
        organization_permissions_created_at_lteq: PropTypes.string,
    }),
};

export default PermissionDate;
