import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import { generateFirmwideReport } from "../../../api/diversityApi";
import useChannel from "../../../utils/channels/useChannel";

const ExportXlsxButton = ({ allQuarters = [], disabled = false }) => {
    const [selectedQuarters, setSelectedQuarters] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [generationId, setGenerationId] = useState(null);

    const onSelect = (event, value) => {
        if (event.currentTarget.checked) {
            setSelectedQuarters((prevState) => [...prevState, value]);
        } else {
            setSelectedQuarters((prevState) => prevState.filter((quarter) => quarter !== value));
        }
    };

    const selectAll = (event) =>
        setSelectedQuarters(event.currentTarget.checked ? allQuarters.map((quarter) => quarter.id) : []);

    const isChecked = (value) => selectedQuarters.includes(value);
    const isAllChecked = () => allQuarters.length === selectedQuarters.length;
    const isShown = (value) => {
        const criteria = searchCriteria.toLowerCase().trim();

        if (criteria.length) {
            return value.toLowerCase().trim().indexOf(criteria) >= 0;
        } else {
            return true;
        }
    };

    const onSubmit = useCallback(() => {
        setIsGenerating(true);
        setGenerationId(null);

        generateFirmwideReport({ format: "xlsx", quarter_ids: selectedQuarters }).catch(() => {
            setIsGenerating(false);
        });
    }, [selectedQuarters]);

    useChannel({
        channelParams: {
            channel: "Diversity::Firmwide::ReportsChannel",
            format: "excel",
        },
        onReceived: ({ type, id }) => {
            if (type === "finished") {
                setGenerationId(id);
            }

            setIsGenerating(false);
        },
    });

    return (
        <div>
            <Dropdown as="div">
                <Dropdown.Toggle
                    variant="primary"
                    className="text-light m-1 btn-block"
                    disabled={disabled || isGenerating}
                >
                    {isGenerating && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                    )}
                    {I18n.t("home.export")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="dropdown-item">
                        <Form.Control
                            size="sm"
                            onChange={(event) => setSearchCriteria(event.currentTarget.value)}
                            value={searchCriteria}
                        />
                    </div>
                    <Dropdown.Divider />
                    <div className="dropdown-submenu">
                        {allQuarters.map(
                            (quarter, index) =>
                                isShown(quarter.label) && (
                                    <div className="dropdown-item" key={index}>
                                        <Form.Check
                                            custom
                                            name="export-quarters[]"
                                            type="checkbox"
                                            id={`export-quarters-${quarter.id}`}
                                            label={quarter.label}
                                            checked={isChecked(quarter.id)}
                                            onChange={(event) => onSelect(event, quarter.id)}
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                    <Dropdown.Divider />
                    <div className="dropdown-submenu">
                        <div className="dropdown-item">
                            <Form.Check
                                custom
                                type="checkbox"
                                id="selectAllCheckbox-export-quarters"
                                label={I18n.t("common.select_all")}
                                checked={isAllChecked()}
                                onChange={selectAll}
                            />
                        </div>
                    </div>
                    <Dropdown.Divider />
                    <div className="dropdown-submenu">
                        <Dropdown.Item
                            onClick={onSubmit}
                            disabled={selectedQuarters.length === 0 || isGenerating}
                            className="text-center"
                        >
                            {I18n.t("diversity.buttons.submit")}
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            {generationId && (
                <a
                    href={`/diversity/firmwide/reports/${generationId}?format=xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {I18n.t("common.links.download")}
                </a>
            )}
        </div>
    );
};

ExportXlsxButton.propTypes = {
    allQuarters: PropTypes.array,
    disabled: PropTypes.bool,
};

export default ExportXlsxButton;
