import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";

const MessageContainer = ({ message }) => (
        <a className="text-reset text-decoration-none" href={message.link}>
            <div className={`mt-1 pl-3 message ${message.kind}`}>
                <Row>
                    <Col>
                        <div className="text-left">
                            <strong className="mr-2">{message.author_name}</strong>
                            <div className={`dot dot-sm ${message.kind}`} />
                            <em>{message.type}</em>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-right">{moment(message.created_at).fromNow()}</div>
                    </Col>
                </Row>
                <Row>
                    <Col>{message.content}</Col>
                </Row>
            </div>
        </a>
    );

MessageContainer.propTypes = {
    message: PropTypes.shape({
        link: PropTypes.string,
        kind: PropTypes.string,
        author_name: PropTypes.string,
        type: PropTypes.string,
        created_at: PropTypes.string,
        content: PropTypes.string,
    }),
};

export default MessageContainer;
