import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import _find from "lodash/find";
import _compact from "lodash/compact";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import _includes from "lodash/includes";
import _toString from "lodash/toString";
import Avatar from "../../../../common/components/Avatar";
import I18n from "../../../../utils/i18n";
import ThreeDotsToggle from "../../../../common/components/Table/ThreeDotsToggle";
import ConfirmAction from "../../../../common/components/ConfirmAction";
import FormattedScore from "../../../../common/components/FormattedScore";

const ProfileCard = ({
    id,
    uid,
    currency_id,
    name,
    logo,
    updated_at,
    jb_score,
    total_score,
    company_structure,
    industries,
    locations,
    companyIds,
    handleCheckboxChange,
    handleRemoveFromList,
    hideActions,
}) => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const primaryIndustry = _find(industries, ["primary", true]);
    const requiredLocation = _find(locations, ["location_type_required", true]);
    const [removeModalShow, setRemoveModalShow] = useState(false);

    return (
        <Row>
            <Col sm={1}>
                <Form.Check
                    custom
                    className="align-vertical-center"
                    type="checkbox"
                    id={`list-${id}`}
                    checked={_includes(companyIds, id)}
                    onChange={(event) => handleCheckboxChange(event, id)}
                />
            </Col>
            <Col sm={11}>
                <Card className="mb-3">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col sm={2} className="d-flex align-items-center justify-content-center">
                                    <Avatar preview={logo} name={name} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <h3>
                                            {currentActor.organization.permissions.company_profile_permission ? (
                                                <Link to={`/companies/profiles/${uid}`}>{name}</Link>
                                            ) : (
                                                name
                                            )}
                                        </h3>
                                        {requiredLocation && (
                                            <div>
                                                {_compact([
                                                    requiredLocation.city,
                                                    requiredLocation.state?.name,
                                                    requiredLocation.country?.name,
                                                ]).join(" ")}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col sm={2} />
                                <Col sm={2}>
                                    {(jb_score || total_score) && (
                                        <FormattedScore
                                            jbScore={_toString(jb_score)}
                                            totalScore={_toString(total_score)}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} />
                                <Col sm={2}>
                                    {company_structure.doing_business_as && (
                                        <div>
                                            <small className="text-muted">
                                                {I18n.t("activerecord.attributes.company_structure.doing_business_as")}
                                            </small>
                                            <div>{company_structure.doing_business_as}</div>
                                        </div>
                                    )}
                                </Col>
                                <Col sm={4}>
                                    {primaryIndustry && (
                                        <div>
                                            <small className="text-muted">
                                                {I18n.t("activerecord.models.industry.one")}
                                            </small>
                                            <div>{primaryIndustry.name}</div>
                                        </div>
                                    )}
                                </Col>
                                <Col sm={2}>
                                    {company_structure && currency_id && (
                                        <div>
                                            <small className="text-muted">
                                                {I18n.t("activerecord.models.sales_indicator")}
                                            </small>
                                            <div>
                                                {company_structure.sales.toLocaleString(I18n.locale, {
                                                    style: "currency",
                                                    currency: currency_id,
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col sm={2}>
                                    {company_structure && (
                                        <div>
                                            <small className="text-muted">
                                                {I18n.t("activerecord.models.employees_indicator")}
                                            </small>
                                            <div>{company_structure.employees}</div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                    <Card.Footer className="text-muted d-flex">
                        <span className="mr-auto">
                            {I18n.t("companies.profiles.text.updated", { date: moment(updated_at).fromNow() })}
                        </span>
                        {!hideActions && (
                            <span>
                                <Dropdown>
                                    <Dropdown.Toggle as={ThreeDotsToggle} />
                                    <Dropdown.Menu size="sm">
                                        <Dropdown.Item onClick={() => setRemoveModalShow(true)}>
                                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                            {I18n.t("companies.lists.actions.remove_from_list")}
                                        </Dropdown.Item>
                                        <ConfirmAction
                                            show={removeModalShow}
                                            onHide={() => setRemoveModalShow(false)}
                                            onSubmit={() =>
                                                handleRemoveFromList({
                                                    uid: uid,
                                                    id: id,
                                                    setModalShow: setRemoveModalShow,
                                                })
                                            }
                                            confirm="common.links.text_ok"
                                            title={I18n.t("tables.lists.remove_company_from_list.title")}
                                            text={I18n.t("tables.lists.remove_company_from_list.text")}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        )}
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

ProfileCard.propTypes = {
    handleCheckboxChange: PropTypes.func.isRequired,
    handleRemoveFromList: PropTypes.func.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number.isRequired,
    uid: PropTypes.string.isRequired,
    currency_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    logo: PropTypes.string,
    jb_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideActions: PropTypes.bool.isRequired,
    company_structure: PropTypes.shape({
        doing_business_as: PropTypes.string,
        employees: PropTypes.number,
        sales: PropTypes.number,
    }),
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            area: PropTypes.string,
        }),
    ),
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            country: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            state: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            city: PropTypes.string,
            location_type_required: PropTypes.bool,
        }),
    ),
};

export default ProfileCard;
