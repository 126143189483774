import React, { useState } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { createNoWork } from "../../../api/diversityApi";

const NoWorkCell = ({ row: { original }, updateData }) => {
    const [value, setValue] = useState(original.no_work);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Form.Check
            type="checkbox"
            onChange={(event) => {
                setIsLoading(true);
                createNoWork({
                    no_work: event.target.checked,
                    partnership_id: original.diversity_partnership_id,
                    quarter_id: original.diversity_quarter_id,
                })
                    .then((response) => {
                        setValue(event.target.checked);
                        updateData(original.id, response.data);
                    })
                    .finally(() => setIsLoading(false));
            }}
            checked={value}
            disabled={isLoading || original.submitted}
        />
    );
};

NoWorkCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            no_work: PropTypes.bool.isRequired,
            id: PropTypes.number.isRequired,
            diversity_partnership_id: PropTypes.number.isRequired,
            diversity_quarter_id: PropTypes.number.isRequired,
            submitted: PropTypes.bool.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

export default NoWorkCell;
