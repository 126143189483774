import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Container } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import FormRow from "./FormRow";
import I18n from "../../utils/i18n";
import OwnershipInputs from "./OrganizationForm/inputs/OwnershipInputs";

const FormDiversityCertificateType = () => {
    const [fieldDescription, metaDescription] = useField("diversity_certificate_type[description]");
    const [fieldName, metaName] = useField("diversity_certificate_type[name]");
    const [fieldSource, metaSource] = useField("diversity_certificate_type[source]");
    const { errors, values, setFieldValue, handleChange, setFieldTouched } = useFormikContext();

    return (
        <Container fluid className="mt-3 pt-2">
            <Row>
                <Col md={12}>
                    <FormRow label={I18n.t("activerecord.attributes.diversity/certificate_type.name")}>
                        <Form.Control
                            type="text"
                            {...fieldName}
                            value={fieldName.value || ""}
                            isInvalid={!!metaName.error}
                        />
                        <Form.Control.Feedback type="invalid">{metaName.error}</Form.Control.Feedback>
                    </FormRow>
                    <FormRow label={I18n.t("activerecord.attributes.diversity/certificate_type.description")}>
                        <Form.Control
                            className="min-height-150"
                            as="textarea"
                            type="text"
                            {...fieldDescription}
                            value={fieldDescription.value || ""}
                            isInvalid={!!metaDescription.error}
                        />
                        <Form.Control.Feedback type="invalid">{metaDescription.error}</Form.Control.Feedback>
                    </FormRow>
                    <FormRow label={I18n.t("activerecord.attributes.diversity/certificate_type.source")}>
                        <Form.Control
                            type="text"
                            {...fieldSource}
                            value={fieldSource.value || ""}
                            isInvalid={!!metaSource.error}
                        />
                        <Form.Control.Feedback type="invalid">{metaSource.error}</Form.Control.Feedback>
                    </FormRow>
                    {values.diversity_certificate_type?.ownership_attributes && (
                        <FormRow label={I18n.t("activerecord.attributes.company_structure.ownerships")}>
                            <OwnershipInputs
                                errors={errors?.diversity_certificate_type?.ownership_attributes}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                values={values.diversity_certificate_type.ownership_attributes}
                                setFieldTouched={setFieldTouched}
                                ownerType="diversity_certificate_type"
                            />
                        </FormRow>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

FormDiversityCertificateType.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        values: PropTypes.object,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
    }),
    certificateTypeId: PropTypes.string,
};

export default FormDiversityCertificateType;
