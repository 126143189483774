import React from "react";
import PropTypes from "prop-types";
import { Range, getTrackBackground } from "react-range";
import classNames from "classnames";
import I18n from "../../../../utils/i18n";

const STEP = 0.1;
const MIN = 0;
const MAX = 100;
const SECONDARY_COLOR = "#8298b0";
const PRIMARY_COLOR = "#0551EB";

const TwoRangeSelector = ({ values, setValues, step = STEP, min = MIN, max = MAX }) => (
    <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={setValues}
        renderTrack={
            ({ props, children }) => (
                /* eslint-disable react/prop-types */
                <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={props.style}
                    className="range"
                >
                    <div
                        ref={props.ref}
                        className="track"
                        style={{
                            background: getTrackBackground({
                                values,
                                colors: [SECONDARY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR],
                                min: min,
                                max: max,
                            }),
                        }}
                    >
                        {children}
                    </div>
                </div>
            )
            /* eslint-enable react/prop-types */
        }
        renderThumb={({ index, props, isDragged }) => (
            <div {...props} className="thumb">
                <div className="value">{values[index].toLocaleString(I18n.locale)}</div>
                <div className={classNames("dot", { dragged: isDragged })} />
            </div>
        )}
    />
);

TwoRangeSelector.propTypes = {
    values: PropTypes.array.isRequired,
    setValues: PropTypes.func.isRequired,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
};

export default TwoRangeSelector;
