import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import FirmwideStatistics from "./content/FirmwideStatistics";
import ClientSpecificStatistics from "./content/ClientSpecificStatistics";

const DiversityCharts = ({ currency, showFirmwideStat, showClientSpecificStat }) => (
        <Row>
            {showFirmwideStat && (
                <Col>
                    <FirmwideStatistics />
                </Col>
            )}
            {showClientSpecificStat && (
                <Col>
                    <ClientSpecificStatistics currency={currency} />
                </Col>
            )}
        </Row>
    );

DiversityCharts.propTypes = {
    currency: PropTypes.string.isRequired,
    showFirmwideStat: PropTypes.bool.isRequired,
    showClientSpecificStat: PropTypes.bool.isRequired,
};

export default DiversityCharts;
