import React from "react";
import { Container, Col, FormLabel, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { sanitizeText } from "../../../utils/sanitizeText";

const BodyStep = ({ title, text, pro_tip, title_tip, children }) => (
    <Container fluid className="py-3 body-step-with-footer overflow-auto">
        <Row className="pt-3 pb-2 mb-3 border-bottom">
            <Col>
                <h2>{title}</h2>
            </Col>
        </Row>
        <Row className="flex-grow-1">
            <Col>
                <FormLabel>{text}</FormLabel>
            </Col>
        </Row>
        {title_tip && (
            <Row className="flex-grow-1 mt-1 mb-1">
                <Col>
                    <FormLabel>{title_tip}</FormLabel>
                </Col>
            </Row>
        )}

        {children}

        {pro_tip && (
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={sanitizeText(pro_tip)}></div>
                </Col>
            </Row>
        )}
    </Container>
);

BodyStep.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    pro_tip: PropTypes.string,
    title_tip: PropTypes.string,
    children: PropTypes.node,
};

export default BodyStep;
