import API, { createApi } from "../utils/api";

export function login(params) {
    const apiWithoutInterceptors = createApi();
    return apiWithoutInterceptors.post("/users/sign_in", params);
}

export function logout() {
    return API.get("/users/sign_out");
}

export function getSelectOrganizations() {
    return API.get("/select_organizations");
}

export function setCurrentOrganization(organization_id) {
    return API.post("/select_organizations", { organization_id });
}
