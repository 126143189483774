import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import DiversityClientSpecificMarketingPage from "../DiversityClientSpecificMarketingPage";
import DashboardForm from "../../common/components/DashboardPage/DashboardForm";
import ClientSpecificCharts from "../../utils/clientSpecificCharts";
import I18n from "../../utils/i18n";
import { getClientSpecificFilters } from "../../api/diversityApi";

const DiversityClientSpecificDashboardPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);

    if (!currentActor.organization.permissions.diversity_client_viewer_permission) {
        return <DiversityClientSpecificMarketingPage />;
    }

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3" />
            </div>

            <DashboardForm
                backlink="/diversity/dashboards/client_specific"
                chartsRow={ClientSpecificCharts}
                namespace="diversity/client_specific"
                description={I18n.t("diversity.charts.client_specific_dashboard.description")}
                getFilters={getClientSpecificFilters}
                channelName="Diversity::ClientSpecific::ChartsChannel"
            />
        </div>
    );
};

export default DiversityClientSpecificDashboardPage;
