import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Formik, useField } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import I18n from "../../utils/i18n";
import { sendAddRequestType } from "../../api/diversityApi";
import { setAlert } from "../../state/alert/alertActions";

const CertificateTypeRequestModal = ({ show, onHide, setShowModal }) => {
    const dispatch = useDispatch();
    const [field, meta] = useField("type_requests[message]");

    const onSubmit = (values, { setSubmitting }) => {
        sendAddRequestType(values)
            .then(() => {
                dispatch(
                    setAlert({
                        translationKey: "diversity.certificate_types.mailer.add_type_request.successfully_sent",
                        variant: "info",
                    }),
                );
                setSubmitting(false);
                setShowModal(false);
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(
                    setAlert({
                        translationKey: "diversity.certificate_types.mailer.add_type_request.sending_failed",
                        variant: "danger",
                    }),
                );
            });
    };

    const validationSchema = Yup.object().shape({
        type_requests: Yup.object().shape({
            message: Yup.string().max(5000).required(),
        }),
    });

    return (
        <Formik
            initialValues={{ type_requests: { message: "" } }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleChange, handleReset, handleSubmit, isSubmitting }) => (
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={() => {
                        handleReset();
                        onHide();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{I18n.t("diversity.certificates.send_type_request_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    {...field}
                                    placeholder={I18n.t("diversity.certificates.send_type_request_modal.placeholder")}
                                    onChange={handleChange}
                                    value={field.value}
                                    isInvalid={!!meta.error}
                                    className="min-height-150"
                                />
                                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                handleReset();
                                onHide();
                            }}
                        >
                            {I18n.t("diversity.certificates.send_type_request_modal.cancel")}
                        </Button>
                        <Button variant="primary" className="text-white" disabled={isSubmitting} onClick={handleSubmit}>
                            {isSubmitting && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2"
                                />
                            )}
                            {I18n.t("diversity.certificates.send_type_request_modal.send")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

CertificateTypeRequestModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
};

export default CertificateTypeRequestModal;
