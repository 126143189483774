import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import _filter from "lodash/filter";
import _map from "lodash/map";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import WidgetCard from "../common/WidgetCard";
import Table from "../../../../common/components/Table";
import ActionsCell from "../../../../common/components/FirmwideRequest/ActionsCell";
import NameCell from "../../../../common/components/FirmwideRequest/NameCell";
import { getFirmwideRequests } from "../../../../api/informationApi";
import { getFirmwideFirm } from "../../../../api/diversityApi";
import I18n from "../../../../utils/i18n";

const StatusCell = ({ row: { original } }) => (
    <div className="d-flex align-items-start">
        <div
            className={classNames("badge", "p-1", {
                "badge-warning": original.status === "reviewed",
                "badge-light": original.status === "requested",
            })}
        >
            {I18n.t(`activerecord.attributes.diversity/participation.statuses.${original.status}`)}
        </div>
    </div>
);

const SubmittedCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {original.submitted
            ? I18n.t("diversity.surveys.add_firm_modal.submitted")
            : I18n.t("diversity.surveys.add_firm_modal.not_submitted")}
    </div>
);

const FirmwideRequests = () => {
    const [firm, setFirm] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);

    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.diversity/participation.diversity_firm_id"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: "(UN)Submitted",
                accessor: "submitted",
                Cell: SubmittedCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} firm={firm} containerRef={containerRef} />,
            },
        ],
        [firm]
    );

    const fetchData = useCallback(() => {
        setLoading(true);

        getFirmwideRequests().then((response) => {
            setData(response.data.records);
            setLoading(false);
        });
    }, []);

    const updateData = (dataIndex, newData) => {
        setData((prev) =>
            _filter(
                _map(prev, (oldData, index) => (dataIndex === index ? newData : oldData)),
                (request) => request.status === "reviewed" || request.status === "requested"
            )
        );
    };

    useEffect(() => {
        getFirmwideFirm().then((response) => setFirm(response.data));
    }, []);

    return (
        <WidgetCard
            loading={!firm}
            title={I18n.t("home.diversity_analytics.requests.firmwide.title")}
            containerRef={containerRef}
        >
            {!loading && _isEmpty(data) && <p>{I18n.t("home.diversity_analytics.requests.firmwide.empty_data")}</p>}
            <Table
                loading={loading}
                data={data}
                fetchData={fetchData}
                updateData={updateData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "status", desc: true }]}
                manualSortBy={false}
            />
        </WidgetCard>
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    }),
};

SubmittedCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            submitted: PropTypes.bool,
        }),
    }),
};

export default FirmwideRequests;
