import React from "react";
import PropTypes from "prop-types";
import Avatar from "../../Avatar";

const NameWithAvatarCell = ({ row: { original } }) => (
    <>
        <Avatar preview={original.avatar} size="sm" name={original.name} />
        <div className="d-flex flex-column ml-3">
            {original.name}
            <small className="text-muted">{original.email}</small>
        </div>
    </>
);

NameWithAvatarCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string.isRequired,
            avatar: PropTypes.string,
            email: PropTypes.string.isRequired,
        }),
    }),
};

export default NameWithAvatarCell;
