import API, { buildFormData } from "../utils/api";

export function uploadSuppliers(params) {
    let formData = new FormData();

    buildFormData(formData, params);

    return API.post("/tiered_diversity/uploads", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function createPartnerships(params) {
    return API.post("/tiered_diversity/partnerships", params);
}

export function getDashboardPartnerships(page, column, order, from, to, statuses) {
    return API.get("/tiered_diversity/dashboard/partnerships", { params: { page, column, order, from, to, statuses } });
}

export function getDashboardFilters() {
    return API.get("/tiered_diversity/dashboard/filters");
}

export function getDashboardChartTypes() {
    return API.get("/tiered_diversity/dashboard/charts");
}

export function getDashboardChart(type, params) {
    return API.patch(`/tiered_diversity/dashboard/charts/${type}`, { q: params });
}

export function getRequestsSuppliers() {
    return API.get("/tiered_diversity/requests/suppliers");
}

export function getRequestsCustomers() {
    return API.get("/tiered_diversity/requests/customers");
}

export function updateSubPartnershipRequest(partnershipId, quarter_name, params) {
    return API.patch(`/tiered_diversity/partnerships/${partnershipId}/sub_partnership_requests/${quarter_name}`, {
        sub_partnership_requests: params,
    });
}

export function updatePartnership(id, params) {
    return API.patch(`/tiered_diversity/partnerships/${id}`, params);
}

export function newPaymentsCheckout() {
    return API.get("/tiered_diversity/payments/checkout/new");
}

export function editPartnershipCertificate(id) {
    return API.get(`/tiered_diversity/partnerships/${id}/certificate/edit`);
}

export function updatePartnershipCertificate(id, params) {
    let formData = new FormData();

    buildFormData(formData, params);

    return API.patch(`/tiered_diversity/partnerships/${id}/certificate`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function deletePartnershipCertificate(id) {
    return API.delete(`/tiered_diversity/partnerships/${id}/certificate`);
}

export function buildPartnershipsExport(params) {
    return API.post(`/tiered_diversity/dashboard/partnerships_export`, params);
}
