import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const ClientsFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
        <MultipleChoiceFilter
            label={I18n.t("diversity.buttons.select_clients")}
            filterKey="clients"
            filterValues={values.clients}
            defaultParams={defaultParams.clients}
            params={params}
            setParams={setParams}
            applyFilters={applyFilters}
        />
    );

ClientsFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        clients: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default ClientsFilter;
