import React from "react";
import PropTypes from "prop-types";
import FiltersBar from "../../common/components/DiversityPage/FiltersBar";
import {
    QuarterFilter,
    AttributesFilter,
    FirmsFilter,
    FirmSizesFilter,
    HireDateFilter,
    LeadershipsFilter,
    RolesFilter,
    ScoreFilter,
    StatusesFilter,
    PracticeAreasFilter,
    LocationsFilter,
} from "../../common/components/DiversityPage/filters";

const DiversityClientSpecificFiltersBar = ({ setChartParams, getFilters, setFilter, setChangedFilters }) => (
    <FiltersBar
        filterTypes={[
            QuarterFilter,
            FirmsFilter,
            FirmSizesFilter,
            RolesFilter,
            AttributesFilter,
            StatusesFilter,
            LeadershipsFilter,
            HireDateFilter,
            ScoreFilter,
            PracticeAreasFilter,
            LocationsFilter,
        ]}
        getFilters={getFilters}
        setChartParams={setChartParams}
        setFilter={setFilter}
        setChangedFilters={setChangedFilters}
    />
);

DiversityClientSpecificFiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setChangedFilters: PropTypes.func.isRequired,
};

export default DiversityClientSpecificFiltersBar;
