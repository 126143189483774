import moment from "moment";
import map from "lodash/map";
import first from "lodash/first";
import last from "lodash/last";
import ceil from "lodash/ceil";
import I18n from "./i18n";

const DATE_FORMAT = "MM-DD-YYYY";
const QUARTER_FORMAT = "[Q]Q[']YYYY";

export const convertMomentToQuarterFormat = (value) => value.format(QUARTER_FORMAT);

export const getLatestQuarterRange = () => {
    const range = first(getLatestRange({ count: 1 }));
    return getFormattedRange(range, range);
};

export const getLatestPeriodRange = () => {
    const ranges = getLatestRange({ count: 4 });
    const startRange = last(ranges);
    const endRange = first(ranges);
    return getFormattedRange(startRange, endRange);
};

export const getFormattedRange = (startRange, endRange) => {
    const startDate = moment({ year: startRange.quarterYear, month: startRange.quarterNumber * 3 - 3, day: 1 }).format(
        DATE_FORMAT
    );
    const endDate = moment({ year: endRange.quarterYear, month: endRange.quarterNumber * 3 - 1, day: 1 })
        .add(1, "month")
        .subtract(1, "day")
        .format(DATE_FORMAT);

    return I18n.t("diversity.quarters.date_range", {
        start_date: startDate,
        end_date: endDate,
    });
};

const getLatestRange = ({ count }) => {
    let quarterNumber = ceil((moment().month() + 1) / 3.0);
    let quarterYear = moment().year();

    return map([...Array(count)], () => {
        quarterNumber = quarterNumber - 1;

        if (quarterNumber === 0) {
            quarterNumber = 4;
            quarterYear = quarterYear - 1;
        }

        return { quarterYear: quarterYear, quarterNumber: quarterNumber };
    });
};
