import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import Table from "./Table";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import { ActionButtonsCell, CellInput } from "./Cells";
import useDebounce from "../../../../utils/useDebounce";
import { getNegotiations } from "../../../../api/negotiationApi";

const NegotiationsTable = () => {
    const { selectedEvent, eventType, filterValue } = useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const debouncedFilter = useDebounce(filterValue, 1500);
    const perPage = 5;

    const columns = useMemo(
        () => [
            {
                accessor: "id",
                isSorted: false,
                Cell: CellInput,
            },
            {
                Header: I18n.t("home.create_event_wizard.negotiations_table.columns.organization_name"),
                id: "organization_name",
                accessor: "organization_name",
            },
            {
                Header: I18n.t("home.create_event_wizard.negotiations_table.columns.project_name"),
                accessor: "project_name",
            },
            {
                Header: I18n.t("home.create_event_wizard.negotiations_table.columns.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("home.create_event_wizard.templates_table.columns.type"),
                accessor: "type",
            },
            {
                Header: I18n.t("home.create_event_wizard.negotiations_table.columns.owner"),
                id: "owner_name",
                accessor: "owner_name",
            },
            {
                id: "actionButtons",
                isSorted: false,
                Cell: (props) => <ActionButtonsCell {...props} currentWhitelabel={currentWhitelabel} />,
            },
        ],
        [selectedEvent],
    );

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const sort = sortBy[0] || { id: "id", desc: true };
            const index = pageIndex + 1;
            setLoading(true);

            getNegotiations({
                q: {
                    page: index,
                    order_by: sort.id,
                    ordering: sort.desc ? "desc" : "asc",
                    ...debouncedFilter,
                },
            })
                .then(({ data: { records, pages_count } }) => {
                    setData(records);
                    setPageCount(pages_count);
                })
                .catch(() => setData([]))
                .finally(() => setLoading(false));
        },
        [debouncedFilter.name_or_project_name_or_industry_name_or_user_display_name_cont, eventType],
    );

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            perPage={perPage}
        />
    );
};

export default NegotiationsTable;
