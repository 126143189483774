import React from "react";
import PropTypes from "prop-types";
import DiversityClientSpecificReportsChart from "./DiversityClientSpecificReportsChart";

const GoalStrengthsTable = ({ inProgress, canvasRefs, chartOptions, chartData, position }) => (
    <DiversityClientSpecificReportsChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        chartOptions={chartOptions}
        position={position}
        type="goal_strengths"
        chartType="table"
    />
);

GoalStrengthsTable.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default GoalStrengthsTable;
