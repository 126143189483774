import React from "react";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../../utils/i18n";

const ResultStep = ({ result, scope }) => (
        <Modal.Body>
            {_isEmpty(result) ? (
                <p>{I18n.t(`${scope}.successful`)}</p>
            ) : (
                <>
                    <p>{I18n.t("diversity.clients.steps.errors.common")}</p>
                    <ul>
                        {_map(result, (error, index) => (
                            <li key={index} className="text-danger">
                                {error}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </Modal.Body>
    );

ResultStep.propTypes = {
    result: PropTypes.arrayOf(PropTypes.string),
    scope: PropTypes.string.isRequired,
};

export default ResultStep;
