import React from "react";
import PropTypes from "prop-types";
import DiversityFirmwideChart from "./DiversityFirmwideChart";

const MiniStatsBar = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="mini_stats_bar"
        chartType="text"
        chartOptions={chartOptions}
    />
);

MiniStatsBar.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default MiniStatsBar;
