import * as Yup from "yup";
import I18n from "../../../utils/i18n";

export default Yup.object().shape({
    public_domain: Yup.object().shape({
        domain: Yup.string()
            .max(255)
            .required()
            .matches(/([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/, I18n.t("errors.messages.invalid")),
        website_url: Yup.string()
            .max(255)
            .nullable()
            .matches(/(www.)([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/, I18n.t("errors.messages.invalid")),
    }),
});
