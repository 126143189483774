import React from "react";
import _ from "lodash";
import I18n from "../../../../utils/i18n";
import { DIVERSITY_KEY_INDICATORS_MAPPING } from "../../../../utils/diversityKeyIndicators";

const KeyIndicatorsTableHeader = () => (
        <React.Fragment>
            <tr>
                <th scope="col" rowSpan={3} />
                <th scope="col" rowSpan={2} />
                {_.map(DIVERSITY_KEY_INDICATORS_MAPPING, (childKeyIndicators, parentKeyIndicator) => (
                    <th
                        key={parentKeyIndicator}
                        scope="col"
                        rowSpan={childKeyIndicators.length ? 1 : 2}
                        colSpan={childKeyIndicators.length ? 10 : 5}
                    >
                        {I18n.t(`diversity.key_indicators.${parentKeyIndicator}`)}
                    </th>
                ))}
            </tr>
            <tr>
                {_.map(_.flatten(_.values(DIVERSITY_KEY_INDICATORS_MAPPING)), (childKeyIndicator) => (
                    <th key={childKeyIndicator} scope="col" colSpan={5}>
                        {I18n.t(`diversity.key_indicators.${childKeyIndicator}`)}
                    </th>
                ))}
            </tr>
            <tr>
                <th scope="col">{I18n.t("diversity.columns.attributes")}</th>

                {_.map(DIVERSITY_KEY_INDICATORS_MAPPING, (childKeyIndicators, parentKeyIndicator) =>
                    _.map(childKeyIndicators.length ? childKeyIndicators : [parentKeyIndicator], (keyIndicator) => (
                        <React.Fragment key={keyIndicator}>
                            <th scope="col">{I18n.t("diversity.columns.current_percentage")}</th>
                            <th scope="col">{I18n.t("diversity.columns.current_points")}</th>
                            <th scope="col">{I18n.t("diversity.columns.possible_points")}</th>
                            <th scope="col">{I18n.t("diversity.columns.direction")}</th>
                            <th scope="col">{I18n.t("diversity.columns.goal")}</th>
                        </React.Fragment>
                    ))
                )}
            </tr>
        </React.Fragment>
    );

export default KeyIndicatorsTableHeader;
