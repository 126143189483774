import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import _map from "lodash/map";
import _lowerCase from "lodash/lowerCase";
import ConfirmAction from "./ConfirmAction";
import DeactivateAction from "./DeactivateAction";
import { deactivateActor } from "../../api/actorApi";
import I18n from "../../utils/i18n";
import { getActor } from "../../api/listsApi";

const DeactivateActor = ({ currentActor, modalShow, setModalShow, actors, setReloadTable }) => {
    const resource = _lowerCase(I18n.t("tables.users.user"));
    const [newListOwnerId, setNewListOwnerId] = useState(actors[0]?.id);
    const [listsPresent, setListsPresent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = () => {
        let params = listsPresent ? { new_list_owner_id: newListOwnerId } : {};

        deactivateActor(currentActor.uid, params).then(() => {
            setModalShow(false);
            setReloadTable((prevState) => !prevState);
        });
    };

    useEffect(() => {
        if (!modalShow) return;
        setIsLoading(true);
        getActor(currentActor.uid)
            .then(({ data: { lists_present } }) => setListsPresent(lists_present))
            .finally(() => setIsLoading(false));
    }, [modalShow]);

    if (listsPresent) {
        return (
            <ConfirmAction
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSubmit={onSubmit}
                confirm="common.actions.deactivate.action"
                title={I18n.t("common.actions.deactivate.title", { resource: resource })}
                loading={isLoading}
            >
                <p>{I18n.t("common.actions.deactivate.body", { resource: resource })}</p>
                {actors.length > 0 ? (
                    <>
                        <p>{I18n.t("common.actions.deactivate.body_with_lists")}</p>
                        <Form.Control
                            as="select"
                            custom
                            onChange={(event) => setNewListOwnerId(event.target.value)}
                            value={newListOwnerId}
                            disabled={isLoading}
                        >
                            {_map(actors, (actor) => (
                                <option key={actor.email} value={actor.id}>
                                    {actor.email} {actor.name}
                                </option>
                            ))}
                        </Form.Control>
                    </>
                ) : (
                    <p className="text-danger">{I18n.t("common.actions.deactivate.no_actors_to_move_lists")}</p>
                )}
            </ConfirmAction>
        );
    } else {
        return (
            <DeactivateAction
                show={modalShow}
                onSubmit={onSubmit}
                onHide={() => setModalShow(false)}
                resource={resource}
                disabled={isLoading}
                loading={isLoading}
            />
        );
    }
};

DeactivateActor.propTypes = {
    currentActor: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    modalShow: PropTypes.bool.isRequired,
    setModalShow: PropTypes.func.isRequired,
    setReloadTable: PropTypes.func.isRequired,
    actors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
};
export default DeactivateActor;
