import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { editOrganizationAttorney, updateOrganizationAttorney } from "../../api/legalApi";
import LegalAttorneyForm from "../../common/components/LegalAttorneyForm";
import Loader from "../HomePage/components/common/Loader";

const EditLegalAttorneyPage = () => {
    const { organization_uid: organizationUid, id: attorneyId } = useParams();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        editOrganizationAttorney(organizationUid, attorneyId).then((response) => setInitialValues(response.data));
    }, [organizationUid, attorneyId]);

    if (_isEmpty(initialValues)) {
        return <Loader />;
    }

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/attorneys`}
                linkName={I18n.t("forms.organization.edit.tabs.attorneys")}
            />
            <div className="scrollable d-flex flex-column">
                <LegalAttorneyForm
                    organizationUid={organizationUid}
                    attorneyId={attorneyId}
                    initialValues={initialValues}
                    createOrUpdateLegalAttorney={updateOrganizationAttorney}
                />
            </div>
        </>
    );
};

export default EditLegalAttorneyPage;
