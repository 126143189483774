import React from "react";
import PropTypes from "prop-types";

const NameCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.name}</div>;

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    }),
};

export default NameCell;
