import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useFormikContext } from "formik";
import _flatten from "lodash/flatten";
import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _omit from "lodash/omit";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";
import { editOrganization } from "../../api/organizationApi";

const CopyIndustryFromOrganization = ({ organizationUid, field }) => {
    const [isLoading, setLoading] = useState(false);
    const { setFieldValue, values } = useFormikContext();

    const loadIndustry = useCallback(() => {
        setLoading(true);
        editOrganization(organizationUid, {}).then((response) => {
            const fromOrganization = _map(response.data.industry_relations_attributes, (industry) =>
                _omit(industry, ["id", values[field].length > 0 && "primary"]),
            );

            const destroyedOrganizationIndustries = _map(
                _filter(
                    values[field],
                    (industry) =>
                        industry._destroy === true &&
                        _includes(_map(fromOrganization, "industry_id"), industry.industry_id),
                ),
                (industry) => ({ ...industry, _destroy: false }),
            );

            const fieldValue = _flatten([destroyedOrganizationIndustries, values[field], fromOrganization]);

            setFieldValue(field, _uniqBy(fieldValue, "industry_id"));
            setLoading(false);
        });
    }, [organizationUid]);

    return (
        <Button variant="outline-primary" className="mb-2" onClick={loadIndustry} disabled={isLoading}>
            {isLoading && <Spinner size="sm" className="mr-2" animation="border" />}
            {I18n.t("activerecord.attributes.location.actions.copy")}
        </Button>
    );
};

CopyIndustryFromOrganization.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
};

export default CopyIndustryFromOrganization;
