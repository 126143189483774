import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Dropdown, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _isUndefined from "lodash/isUndefined";
import _map from "lodash/map";
import _join from "lodash/join";
import Table from "../../Table";
import DeleteAction from "../../DeleteAction";
import ThreeDotsToggle from "../../Table/ThreeDotsToggle";
import I18n from "../../../../utils/i18n";
import { getCertificates, deleteCertificates, updatePrimaryCertificate } from "../../../../api/diversityApi";

const ActionsCell = ({ row: { original }, reloadTable }) => {
    const [modalShow, setModalShow] = useState(false);
    const { uid } = useParams();

    const onSubmit = () => {
        deleteCertificates(original.id, uid).then(() => {
            setModalShow(false);
            reloadTable();
        });
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} to={`/organizations/${uid}/diversity/certificates/${original.id}/edit`}>
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    {!original.primary ? (
                        <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            {I18n.t("common.links.delete")}
                        </Dropdown.Item>
                    ) : (
                        <></>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <DeleteAction
                onSubmit={onSubmit}
                show={modalShow}
                onHide={() => setModalShow(false)}
                resource={I18n.t("activerecord.models.diversity/certificate")}
            />
        </>
    );
};

const CertificateStateCell = ({ row: { original } }) => (
    <Badge variant={original.active ? "success" : "danger"} className="px-3">
        {original.active
            ? I18n.t("tables.certificates.state_value.active")
            : I18n.t("tables.certificates.state_value.expired")}
    </Badge>
);

const PrimaryCell = ({ row: { original }, reloadTable, setLoading, setFieldValue }) => {
    const { uid } = useParams();
    const changePrimary = (event) => {
        setLoading(true);
        updatePrimaryCertificate(original.id, uid, { primary: event.target.checked })
            .then((response) => {
                _map(response.data?.ownership_attributes, (value, key) =>
                    setFieldValue(`organization[ownership_attributes][${key}]`, value),
                );
                reloadTable();
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <Form.Check
            custom
            id={original.id}
            type="radio"
            value="update_primary"
            checked={original.primary}
            disabled={!original.active}
            onChange={changePrimary}
        />
    );
};
const OwnershipCell = ({ row: { original } }) => (
    <div>
        <span>
            {_join(
                _map(original.ownership.ownership_types, (type) =>
                    type === "minority_owned"
                        ? `${I18n.t("activerecord.attributes.company_structure.minority_owned")}: ${I18n.t(
                              `activerecord.attributes.company_structure.ethnicities.${original.ownership.ethnicity}`,
                          )}`
                        : I18n.t(`activerecord.attributes.company_structure.${type}`),
                ),
                ", ",
            )}
        </span>
    </div>
);

const OriginDateCell = ({ row: { original } }) => moment(original.origin_date).format("DD MMM YYYY");
const CurrentOriginDateCell = ({ row: { original } }) => moment(original.current_origin_date).format("DD MMM YYYY");
const ExpirationDateCell = ({ row: { original } }) => moment(original.expiration_date).format("DD MMM YYYY");

const DiversityCertificatesTable = ({ setFieldValue }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("tables.certificates.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("activerecord.attributes.company_structure.ownerships"),
                Cell: OwnershipCell,
                isSorted: false,
            },
            {
                Header: I18n.t("tables.certificates.number"),
                accessor: "number",
            },
            {
                Header: I18n.t("tables.certificates.origin_date"),
                accessor: "origin_date",
                Cell: OriginDateCell,
            },
            {
                Header: I18n.t("tables.certificates.current_origin_date"),
                accessor: "current_origin_date",
                Cell: CurrentOriginDateCell,
            },
            {
                Header: I18n.t("tables.certificates.expiration_date"),
                accessor: "expiration_date",
                Cell: ExpirationDateCell,
            },
            {
                Header: I18n.t("tables.certificates.state"),
                accessor: "state",
                Cell: CertificateStateCell,
            },
            {
                Header: I18n.t("tables.certificates.primary"),
                accessor: "primary",
                Cell: (props) => (
                    <PrimaryCell
                        {...props}
                        reloadTable={reloadTable}
                        setLoading={setLoading}
                        setFieldValue={setFieldValue}
                    />
                ),
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} reloadTable={reloadTable} />,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reloadedAt, setReloadedAt] = useState(new Date());
    const { uid } = useParams();
    const fetchIdRef = useRef(0);
    const reloadTable = () => setReloadedAt(new Date());
    const fetchData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;

        if (_isUndefined(uid)) {
            setData([]);
        } else {
            setLoading(true);

            getCertificates(uid).then((response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(response.data);
                    setLoading(false);
                }
            });
        }
    }, [reloadedAt]);

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={-1}
            initialSortBy={[{ id: "name", desc: true }]}
            manualSortBy={false}
        />
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            primary: PropTypes.bool,
        }),
    }),
    reloadTable: PropTypes.func.isRequired,
};

CertificateStateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            active: PropTypes.bool.isRequired,
        }),
    }),
};

PrimaryCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            primary: PropTypes.bool.isRequired,
            id: PropTypes.number.isRequired,
            active: PropTypes.bool.isRequired,
        }),
    }),
    reloadTable: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

OriginDateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            origin_date: PropTypes.string.isRequired,
        }),
    }),
};

CurrentOriginDateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            current_origin_date: PropTypes.string.isRequired,
        }),
    }),
};

ExpirationDateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            expiration_date: PropTypes.string.isRequired,
        }),
    }),
};

DiversityCertificatesTable.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
};

OwnershipCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            ownership: PropTypes.shape({
                ownership_types: PropTypes.array.isRequired,
                ethnicity: PropTypes.string,
            }),
        }),
    }),
};

export default DiversityCertificatesTable;
