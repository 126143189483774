import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import I18n from "../../../utils/i18n";

const SolidAddToListButton = ({ onClick }) => (
    <Link to="#" className="btn btn-outline-secondary" onClick={onClick}>
        <FontAwesomeIcon icon={faBookmark} className="mr-2" />
        {I18n.t("companies.lists.buttons.add_to_list")}
    </Link>
);

SolidAddToListButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default SolidAddToListButton;
