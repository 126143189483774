import React from "react";
import { Spinner } from "react-bootstrap";
import I18n from "../../../../utils/i18n";

const Loader = () => (
        <div className="section bg-light widget-body">
            <div className="d-flex align-items-center justify-content-center h-100">
                <Spinner animation="border" role={status}>
                    <span className="sr-only">{I18n.t("home.loading")}</span>
                </Spinner>
            </div>
        </div>
    );

export default Loader;
