import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import _flatMap from "lodash/flatMap";
import _map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faCheck } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { useSelector } from "react-redux";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import ConfirmAction from "../../../common/components/ConfirmAction";
import I18n from "../../../utils/i18n";
import Table, { SelectFilter } from "../../../common/components/Table";
import { getRequestsCustomers, updateSubPartnershipRequest } from "../../../api/tieredDiversityApi";

const StatusCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        <div
            className={classNames("badge p-1", {
                "badge-success": original.status === "completed",
                "badge-secondary": original.status === "not_completed",
                "badge-light": original.status === "not_started",
            })}
        >
            {I18n.t(`tiered_diversity.dashboard.suppliers.buttons.statuses.${original.status}`)}
        </div>
    </div>
);

const CreatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.created_at)}</div>
);

const ActionsCell = ({ row: { original, index }, updateData, currentActor }) => {
    const [modalShow, setModalShow] = useState(false);

    const onAccept = () => {
        updateSubPartnershipRequest(original.id, original.quarter_name, { approved_at: moment() }).then((response) => {
            updateData(index, response.data);
            setModalShow(false);
        });
    };

    return (
        (currentActor.super_manager || currentActor.sd_manager) && (
            <>
                {(original.status === "not_started" ||
                    (!original.approved_at && original.status !== "not_started")) && (
                    <Dropdown>
                        <Dropdown.Toggle as={ThreeDotsToggle} />
                        <Dropdown.Menu size="sm" title="">
                            {original.status === "not_started" && (
                                <Dropdown.Item as={Link} to="/tiered_diversity/partnerships/new">
                                    <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
                                    {I18n.t("tiered_diversity.requests.customers.buttons.upload")}
                                </Dropdown.Item>
                            )}
                            {!original.approved_at && original.status !== "not_started" && (
                                <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                    {I18n.t("tiered_diversity.requests.customers.buttons.approve")}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <ConfirmAction
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onSubmit={onAccept}
                    confirm="tiered_diversity.requests.customers.buttons.approve"
                    title={I18n.t("tiered_diversity.requests.customers.confirmations.approve.title")}
                    text={I18n.t("tiered_diversity.requests.customers.confirmations.approve.text")}
                />
            </>
        )
    );
};

const CustomersTable = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/month.quarter_name"),
                accessor: "quarter_name",
                Filter: SelectFilter,
                filter: "equals",
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.customer_name"),
                accessor: "customer_name",
                Filter: SelectFilter,
                filter: "equals",
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/monthly_partnership_stats.created_at"),
                accessor: "created_at",
                Cell: CreatedAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} updateData={updateData} currentActor={currentActor} />,
            },
        ],
        [],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchIdRef = useRef(0);
    const history = useHistory();

    const updateData = (rowIndex, newRow) => {
        setData((oldRows) => oldRows.map((oldRow, index) => (index === rowIndex ? { ...oldRow, ...newRow } : oldRow)));
    };

    const fetchData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;

        setLoading(true);

        getRequestsCustomers()
            .then((response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(
                        _flatMap(response.data, (supplier) =>
                            _map(supplier.quarterly_sub_partnership_requests, (quarterlySubPartnershipRequest) => ({
                                ...supplier,
                                ...quarterlySubPartnershipRequest,
                            })),
                        ),
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                error.response.status === 403 && history.push("/");
            });
    }, []);

    return (
        <Card.Body>
            <Row>
                <Col>
                    <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        pageCount={-1}
                        loading={loading}
                        initialSortBy={[{ id: "quarter_name", desc: true }]}
                        manualSortBy={false}
                    />
                </Col>
            </Row>
        </Card.Body>
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    }),
};

CreatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            quarter_name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            approved_at: PropTypes.string,
        }),
        index: PropTypes.number.isRequired,
    }),
    updateData: PropTypes.func.isRequired,
    currentActor: PropTypes.object,
};

export default CustomersTable;
