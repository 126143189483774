import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import { Col, Spinner, Form, Button } from "react-bootstrap";
import { loginUser } from "../../state/currentUser/currentUserActions";
import { ShowPassword } from "../../common/components";
import I18n, { updateLocale } from "../../utils/i18n";
import { twoFactorAuthentication } from "../../api/deviseApi";
import { getSsoIdentityProvider } from "../../api/ssoApi";

const SignInPage = ({ currentUser, loginUser }) => {
    const [locale, setLocale] = useState(I18n.locale);
    const ssoForm = useRef();
    const [ssoProvider, setSsoProvider] = useState({ name: "", domain: "" });
    const [ssoRequiredForLogin, setSsoRequiredForLogin] = useState(true);
    const [otpRequiredForLogin, setOtpRequiredForLogin] = useState(false);

    const changeLocale = (event) => {
        const locale = event.currentTarget.value;
        setLocale(locale);
        updateLocale(locale);
    };

    const onSubmit = (values, { setSubmitting }) => {
        if (ssoRequiredForLogin) {
            const domain = values.user.email.substring(values.user.email.indexOf("@") + 1);

            getSsoIdentityProvider(domain)
                .then((response) => {
                    setSsoProvider(response.data);
                    ssoForm.current.submit();
                })
                .catch(() => {
                    setSsoRequiredForLogin(false);
                    setSubmitting(false);
                });
        } else if (otpRequiredForLogin) {
            loginUser(values, () => setSubmitting(false));
        } else {
            twoFactorAuthentication(values.user).then((response) => {
                setOtpRequiredForLogin(response.data.otp_required_for_login);

                if (response.data.otp_required_for_login) {
                    setSubmitting(false);
                } else {
                    loginUser(values, () => setSubmitting(false));
                }
            });
        }
    };

    return (
        <>
            <div className="form-wrapper bg-white p-4 rounded">
                <Formik
                    initialValues={{
                        user: {
                            email: currentUser.resource.email,
                            password: currentUser.resource.password || "",
                            whitelabel_id: currentUser.resource.whitelabel_id || "",
                            otp_attempt: "",
                        },
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, handleChange, isSubmitting }) => (
                        <FormikForm as={Form}>
                            {otpRequiredForLogin ? (
                                <>
                                    <Form.Group>
                                        <h2>{I18n.t("activerecord.attributes.user.otp_required_for_login")}</h2>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder={I18n.t("activerecord.attributes.user.otp_attempt")}
                                            name="user[otp_attempt]"
                                            onChange={handleChange}
                                            value={values.user.otp_attempt}
                                        />
                                    </Form.Group>
                                    <Form.Group className="text-center">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="text-white"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("forms.sign_in.submit")}
                                        </Button>
                                    </Form.Group>
                                </>
                            ) : (
                                <>
                                    <Form.Group>
                                        <h2>{I18n.t("forms.sign_in.title")}</h2>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Row>
                                            <Form.Label column sm={3}>
                                                {I18n.t("forms.common.language")}
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Form.Control as="select" custom onChange={changeLocale} value={locale}>
                                                    <option value="en-US">US English</option>
                                                    <option value="es-MX">Español (México)</option>
                                                    <option value="zh-CN">简化中国 (Chinese - Simplified)</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="email"
                                            placeholder={I18n.t("activerecord.attributes.user.email")}
                                            name="user[email]"
                                            onChange={(event) => {
                                                handleChange(event);
                                                setSsoRequiredForLogin(true);
                                            }}
                                            value={values.user.email}
                                        />
                                    </Form.Group>
                                    {ssoRequiredForLogin || (
                                        <>
                                            <Form.Group>
                                                <ShowPassword
                                                    placeholder={I18n.t("activerecord.attributes.user.password")}
                                                    name="user[password]"
                                                    onChange={handleChange}
                                                    value={values.user.password}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    id="remember-me-checkbox"
                                                    label={I18n.t("activerecord.attributes.user.remember_me")}
                                                    name="user[remember_me]"
                                                    onChange={handleChange}
                                                    value={values.user.remember_me}
                                                />
                                            </Form.Group>
                                        </>
                                    )}

                                    <Form.Group className="text-center">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="text-white"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("forms.sign_in.submit")}
                                        </Button>
                                    </Form.Group>
                                    {ssoRequiredForLogin || (
                                        <div className="text-right">
                                            <Link to="/users/password/new">
                                                {I18n.t("devise.shared.links.forgot_your_password")}
                                            </Link>
                                        </div>
                                    )}
                                </>
                            )}
                        </FormikForm>
                    )}
                </Formik>

                <Form ref={ssoForm} action={`/users/auth/${ssoProvider.name}`} method="POST">
                    <input
                        type="hidden"
                        name="authenticity_token"
                        value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                    />
                </Form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    loginUser: (values, errorCallback) => dispatch(loginUser(values, errorCallback)),
});

SignInPage.propTypes = {
    currentUser: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
