import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { StepperHeader, StepperFooter } from "../../../common/components/Stepper";
import AddFirmStep from "../../../common/components/Steps/AddFirmStep";
import CommentStep from "../../../common/components/Steps/CommentStep";
import ResultStep from "../../../common/components/Steps/ResultStep";
import I18n from "../../../utils/i18n";
import { shareFirmwide } from "../../../api/diversityApi";
import { validationSchema, initFirm } from "../../../utils/commonSteps";

const STEPS = [
    { title: I18n.t("diversity.clients.steps.new_firm"), key: "new_firm" },
    { title: I18n.t("diversity.clients.steps.send"), key: "send_to_firm" },
    { title: I18n.t("diversity.clients.steps.result"), key: "result" },
];

const DiversityFirmwideUploadShareModal = ({ show, handleClose, setReloadTable }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [result, setResult] = useState(null);

    const onSubmit = (values) => {
        shareFirmwide(values).then((response) => {
            setResult(response.data);
            setReloadTable((prevState) => !prevState);
        });
    };

    const onClose = (handleReset) => {
        setCurrentStep(0);
        handleReset();
        handleClose();
    };

    return (
        <Formik
            initialValues={{ firms: [initFirm], comment: "" }}
            onSubmit={onSubmit}
            validationSchema={validationSchema("firms")}
        >
            {({ values, errors, handleSubmit, setFieldValue, handleBlur, handleReset }) => (
                <Modal
                    id="add-firm-modal"
                    show={show}
                    onHide={() => {
                        handleReset();
                        setCurrentStep(0);
                        handleClose();
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {I18n.t("diversity.firmwide_upload.requests.share_modal.title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <StepperHeader currentStep={currentStep} setStep={setCurrentStep} steps={STEPS} />

                        <Form noValidate onSubmit={handleSubmit}>
                            {STEPS[currentStep].key === "new_firm" && (
                                <AddFirmStep
                                    values={values.firms}
                                    title={I18n.t("diversity.firmwide_upload.requests.share_modal.new_firm_text")}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    field="firms"
                                    initFirm={initFirm}
                                />
                            )}
                            {STEPS[currentStep].key === "send_to_firm" && (
                                <CommentStep
                                    values={values.comment}
                                    title={I18n.t("diversity.firmwide_upload.requests.share_modal.send_text")}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                            {STEPS[currentStep].key === "result" && (
                                <ResultStep result={result} scope="diversity.firmwide_upload.requests.share_modal" />
                            )}
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <StepperFooter
                            setStep={setCurrentStep}
                            textSubmit={I18n.t("diversity.clients.steps.send")}
                            onSubmit={handleSubmit}
                            steps={STEPS}
                            currentStep={currentStep}
                            errors={errors}
                            onClose={() => onClose(handleReset)}
                            requiredValue={values.firms[0].email && values.firms[0].name}
                        />
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

DiversityFirmwideUploadShareModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setReloadTable: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadShareModal;
