import React from "react";
import PropTypes from "prop-types";
import _isNumber from "lodash/isNumber";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificProgressChart from "./DiversityClientSpecificProgressChart";
import { calculateTotal, currencyChartLabel } from "../../../../utils/calculateTotal";

const TotalFeesChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificProgressChart
        inProgress={inProgress}
        chartData={chartData}
        canvasRefs={canvasRefs}
        type="total_fees"
        chartType="doughnut"
        chartOptions={{
            ...chartOptions,
            legend: { position: "right" },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataLabel = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return `${dataLabel}: ${currencyChartLabel(value)}`;
                    },
                },
            },
            plugins: {
                doughnutlabel: {
                    labels: [
                        {
                            text: (chart) => {
                                let total = calculateTotal(chart);
                                if (_isNumber(total))
                                    return `${I18n.t("diversity.columns.fees")} ${currencyChartLabel(total)}`;
                                else return "";
                            },
                            font: { size: 18 },
                            color: "black",
                        },
                    ],
                },
                datalabels: {
                    formatter: (_, context) => {
                        let dataLabel = context.chart.data.labels[context.dataIndex];
                        let value = context.chart.data.datasets[context.datasetIndex].data[
                            context.dataIndex
                        ].toLocaleString(I18n.locale);
                        return `${dataLabel}: ${currencyChartLabel(value)}`;
                    },
                },
            },
        }}
        chartUseDataLabels={true}
    />
);

TotalFeesChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default TotalFeesChart;
