import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import SingleChoiceFilter from "./common/SingleChoiceFilter.js.erb";

const FirmFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
        <SingleChoiceFilter
            label={I18n.t("diversity.buttons.select_firm")}
            filterKey="firm"
            filterValues={values.firm}
            defaultParams={defaultParams.firm}
            params={params}
            setParams={setParams}
            applyFilters={applyFilters}
        />
    );

FirmFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        firm: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.shape({
        firm: PropTypes.string.isRequired,
    }),
    applyFilters: PropTypes.func.isRequired,
};

export default FirmFilter;
