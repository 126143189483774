import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import Steps from "rc-steps";
import IntroStep from "./IntroStep";
import VerificationStep from "./VerificationStep";
import RecoveryStep from "./RecoveryStep";
import ActivationStep from "./ActivationStep";
import I18n from "../../utils/i18n";

const EditTwoFactorAuthenticationPage = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [otpBackupCodes, setOtpBackupCodes] = useState([]);

    const setStatus = (stepIndex) => (stepIndex === currentStep ? "process" : "wait");
    const setDescription = (step, stepIndex) => (stepIndex === currentStep ? step : "");

    return (
        <div>
            <div className="top-bar">
                <Col>
                    <Container fluid className="py-3" />
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3 container-md">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("activerecord.attributes.user.otp_required_for_login")}</h2>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Steps
                                    currentStep={currentStep}
                                    items={[
                                        {
                                            title: (
                                                <h2>
                                                    {I18n.t("forms.two_factor_authentication.steps.intro.step_title")}
                                                </h2>
                                            ),
                                            description: setDescription(
                                                <IntroStep setCurrentStep={setCurrentStep} />,
                                                0,
                                            ),
                                            status: setStatus(0),
                                        },
                                        {
                                            title: (
                                                <h2>
                                                    {I18n.t(
                                                        "forms.two_factor_authentication.steps.verification.step_title",
                                                    )}
                                                </h2>
                                            ),
                                            description: setDescription(
                                                <VerificationStep
                                                    setCurrentStep={setCurrentStep}
                                                    setOtpBackupCodes={setOtpBackupCodes}
                                                />,
                                                1,
                                            ),
                                            status: setStatus(1),
                                        },
                                        {
                                            title: (
                                                <h2>
                                                    {I18n.t(
                                                        "forms.two_factor_authentication.steps.recovery.step_title",
                                                    )}
                                                </h2>
                                            ),
                                            description: setDescription(
                                                <RecoveryStep
                                                    setCurrentStep={setCurrentStep}
                                                    otpBackupCodes={otpBackupCodes}
                                                />,
                                                2,
                                            ),
                                            status: setStatus(2),
                                        },
                                        {
                                            title: (
                                                <h2>
                                                    {I18n.t(
                                                        "forms.two_factor_authentication.steps.activation.step_title",
                                                    )}
                                                </h2>
                                            ),
                                            description: setDescription(
                                                <ActivationStep setCurrentStep={setCurrentStep} />,
                                                3,
                                            ),
                                            status: setStatus(3),
                                        },
                                    ]}
                                    direction="vertical"
                                />
                            </div>
                        </Container>
                    </Col>
                </div>
            </div>
        </div>
    );
};

export default EditTwoFactorAuthenticationPage;
