import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faFile } from "@fortawesome/pro-solid-svg-icons";

const ICON = {
    charts: faFile,
    messages: faEnvelopeOpen,
};

const EmptyData = ({ label, type_icon }) => (
    <div className="section mt-3 bg-light widget-body">
        <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <h1>
                <FontAwesomeIcon icon={ICON[type_icon]} />
            </h1>
            {label}
        </div>
    </div>
);

EmptyData.propTypes = {
    label: PropTypes.string,
    type_icon: PropTypes.string,
};

export default EmptyData;
