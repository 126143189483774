import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import TypeLocations from "./content/TypeLocations";

const Locations = ({ locations }) => {
    const groupedLocations = _groupBy(locations, "location_type_name");

    return (
        <Card className="mb-2 border-top-0">
            <Card.Body>
                {_map(groupedLocations, (typeLocations, typeLocationName) => (
                    <TypeLocations
                        key={typeLocationName}
                        typeLocationName={typeLocationName}
                        typeLocations={typeLocations}
                    />
                ))}
            </Card.Body>
        </Card>
    );
};

Locations.propTypes = {
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            location_type_name: PropTypes.string.isRequired,
            location_type_required: PropTypes.bool.isRequired,
            address_line1: PropTypes.string,
            address_line2: PropTypes.string,
            country: PropTypes.shape({
                code: PropTypes.string.isRequired,
                name: PropTypes.string,
            }),
            state: PropTypes.shape({
                code: PropTypes.string,
                name: PropTypes.string,
            }),
            zip_code: PropTypes.string,
            city: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
        })
    ),
};

export default Locations;
