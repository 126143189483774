import React from "react";
import { Row, Col } from "react-bootstrap";
import FirmwideRequests from "./content/FirmwideRequests";
import ClientSpecificRequests from "./content/ClientSpecificRequests";

const DiversityRequests = () => (
    <Row>
        <Col>
            <FirmwideRequests />
        </Col>
        <Col>
            <ClientSpecificRequests />
        </Col>
    </Row>
);

export default DiversityRequests;
