import qs from "qs";
import API, { buildFormData } from "../utils/api";

export function editOrganization(organizationUid, values, duns = null) {
    return API.get(`/organizations/${organizationUid}/edit`, {
        params: { ...(duns && { duns }), ...(values && { values }) },
    });
}

export function updateOrganization(organizationUid, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.patch(`/organizations/${organizationUid}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function getNewOrganization({ parent }, values) {
    return API.get(`/organizations/new`, {
        params: { ...(parent && { parent }), ...(values && { values }) },
    });
}

export function createOrganization(values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.post(`/organizations`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function getOrganizations(organization, page, column, order, q) {
    const params = { ...(organization && { organization }), page, column, order, q };

    return API.get(
        `/organizations?${qs.stringify(params, {
            arrayFormat: "brackets",
        })}`
    );
}

export function getOrganizationUsers(organizationUid, page, column, order) {
    return API.get("/actors", { params: { organization_uid: organizationUid, page, column, order } });
}

export function deactivateOrganization(organizationUid) {
    return API.delete(`/organizations/${organizationUid}`);
}

export function getOrganizationsBy(values) {
    return API.get("/autocomplete/organizations", { params: values });
}

export function getPermissions() {
    return API.get("/permissions");
}

export function getOrganizationForWizard() {
    return API.get("/organizations/wizard");
}

export function finishWizard() {
    return API.post("/organizations/wizard");
}
