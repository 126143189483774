import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const LeadershipsFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("diversity.buttons.select_leaderships")}
        filterKey="leaderships"
        filterValues={values.leaderships}
        defaultParams={defaultParams.leaderships}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

LeadershipsFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        leaderships: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default LeadershipsFilter;
