import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./state/store";
import ContentContainer from "./common/containers/ContentContainer";

function App() {
    return (
        <Provider store={store}>
            <CookiesProvider defaultSetOptions={{ secure: true, sameSite: "strict" }}>
                <ContentContainer />
            </CookiesProvider>
        </Provider>
    );
}

document.addEventListener("DOMContentLoaded", () => {
    render(<App />, document.getElementById("root"));
});
