import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import I18n from "../../../../utils/i18n";
import Table from "../../../../common/components/Table";
import { getActorNegotiations } from "../../../../api/informationApi";
import StatusCell from "./components/StatusCell";

const RespondTable = ({ activeId }) => {
    const [negotiations, setNegotiations] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);

    useEffect(() => setPage(1), [activeId]);

    const fetchData = useCallback(
        ({ sortBy }) => {
            const sort = sortBy[0] || { id: "bidder_status", desc: false };
            setIsFetching(true);

            const params = {
                page: page,
                q: {
                    order_by: sort.id,
                    ordering: sort.desc ? "desc" : "asc",
                },
            };

            getActorNegotiations(params)
                .then(({ data: { records, page_count } }) => {
                    setNegotiations(records);
                    setPagesCount(page_count);
                })
                .catch(() => setNegotiations([]))
                .finally(() => setIsFetching(false));
        },
        [page, activeId],
    );
    const goToPage = ({ selected }) => setPage(selected + 1);

    const columns = useMemo(
        () => [
            {
                Header: I18n.t("home.negotiations.columns.organization_name"),
                accessor: "organization_name",
                Cell: ({ row: { original } }) => original.organization_name,
            },
            {
                Header: I18n.t("home.negotiations.columns.project_name"),
                accessor: "name",
                Cell: ({ row: { original } }) => original.name,
            },
            {
                Header: I18n.t("home.negotiations.columns.status"),
                accessor: "bidder_status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("home.negotiations.columns.due"),
                accessor: "end_time",
                Cell: DateCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: ButtonCell,
            },
        ],
        [],
    );

    return (
        <>
            {_isEmpty(negotiations) && <div className="pt-4 px-4 pb-4">{I18n.t("home.negotiations.no_data")}</div>}
            <Table
                loading={isFetching}
                data={negotiations || []}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(negotiations)}
                initialSortBy={[{ id: "bidder_status", desc: false }]}
                manualSortBy
            />

            {pagesCount > 1 && (
                <div className="pagination justify-content-center mb-3">
                    <ReactPaginate
                        previousLabel="«"
                        nextLabel="»"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pagesCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={goToPage}
                        containerClassName="pagination pagination-sm mb-0"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                </div>
            )}
        </>
    );
};

const ButtonCell = ({
    row: {
        original: { id, action },
    },
}) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);

    return (
        <Button variant="outline-action" href={`//${currentWhitelabel.hosts.bid}/${action[1]}/${id}`} className="mr-2">
            {I18n.t(`home.negotiations.respond.actions.${action[0]}`)}
        </Button>
    );
};

const DateCell = ({
    row: {
        original: { end_time },
    },
}) => <div className="d-flex flex-column">{I18n.l("time.formats.short", end_time)}</div>;

ButtonCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            action: PropTypes.array.isRequired,
        }),
    }),
};

DateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            end_time: PropTypes.string.isRequired,
        }),
    }),
};

RespondTable.propTypes = {
    activeId: PropTypes.string.isRequired,
};

export default RespondTable;
