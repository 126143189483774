import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import SourceTypeStep from "../../../../HomePage/components/CreateProjectWizard/SourceTypeStep";
import MethodStep from "../../../../HomePage/components/CreateProjectWizard/MethodStep";
import TableStep from "../../../../HomePage/components/CreateProjectWizard/TableStep";
import OptionsStep from "../../../../HomePage/components/CreateProjectWizard/OptionsStep";
import IndustryPickerStep from "../../../../HomePage/components/CreateProjectWizard/IndustryPickerStep";
import { CreateProjectWizardContext } from "../../../../HomePage/components/CreateProjectWizard/CreateProjectWizardContext";
import ParticipantsStep from "../../../../HomePage/components/CreateProjectWizard/ParticipantsStep";

const AddCompaniesToNewNegotiation = ({ show, handleClose, companyIds }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [eventType, setEventType] = useState(null);
    const [createMethod, setCreateMethod] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [filterValue, setFilterValue] = useState({
        legal: null,
        status: ["over", "scoring"],
        name_cont: "",
    });
    const [negotiationParams, setNegotiationParams] = useState({});
    const STEPS = [SourceTypeStep, MethodStep, TableStep, OptionsStep, IndustryPickerStep, ParticipantsStep];

    useEffect(() => {
        if (show) {
            setEventType(null);
            setCreateMethod(null);
            setCurrentStep(0);
        }
    }, [show]);
    return (
        <CreateProjectWizardContext.Provider
            value={{
                currentStep: currentStep,
                setCurrentStep: setCurrentStep,
                eventType: eventType,
                setEventType: setEventType,
                createMethod: createMethod,
                setCreateMethod: setCreateMethod,
                onHide: handleClose,
                selectedEvent: selectedEvent,
                setSelectedEvent: setSelectedEvent,
                filterValue: filterValue,
                setFilterValue: setFilterValue,
                exportCompanies: companyIds,
                setNegotiationParams: setNegotiationParams,
                negotiationParams: negotiationParams,
            }}
        >
            <Modal
                show={show}
                onHide={handleClose}
                centered
                size={currentStep === 2 || currentStep === 1 ? "xl" : "lg"}
            >
                <div className="p-5">{React.createElement(STEPS[currentStep])}</div>
            </Modal>
        </CreateProjectWizardContext.Provider>
    );
};

AddCompaniesToNewNegotiation.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AddCompaniesToNewNegotiation;
