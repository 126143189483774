import React from "react";
import { useParams } from "react-router-dom";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { createOrganizationAttorney } from "../../api/legalApi";
import LegalAttorneyForm from "../../common/components/LegalAttorneyForm";

const NewLegalAttorneyPage = () => {
    const { organization_uid: organizationUid } = useParams();

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/attorneys`}
                linkName={I18n.t("forms.organization.edit.tabs.attorneys")}
            />
            <div className="scrollable d-flex flex-column">
                <LegalAttorneyForm
                    organizationUid={organizationUid}
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        title: "",
                        graduation_year: null,
                    }}
                    createOrUpdateLegalAttorney={(organizationUid, _attorneyId, params) =>
                        createOrganizationAttorney(organizationUid, params)
                    }
                />
            </div>
        </>
    );
};

export default NewLegalAttorneyPage;
