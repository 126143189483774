import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import FileContainer from "../../../common/components/FileContainer";
import { updateClientSpecificFirmEmployees } from "../../../api/diversityApi";
import useChannel from "../../../utils/channels/useChannel";

const UploadForm = ({ firmStats, partnershipStats, setShowFormModal, setPartnershipStats }) => {
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState([]);
    const currentActor = useSelector((state) => state.currentActor.resource);

    useChannel({
        channelParams: {
            channel: "Diversity::ClientSpecific::EmployeesChannel",
            quarter_id: partnershipStats.diversity_quarter_id,
        },
        onReceived: ({ type, payload }) => {
            if (type === "finished") {
                setSubmissionErrors(payload.errors);
                setSubmissionInProgress(false);
                payload.errors[0] || setShowFormModal(false);
                setPartnershipStats({
                    ...partnershipStats,
                    uploaded: payload.partnership.client_information_count > 0,
                });
            }
        },
        readyToSubscribe: !partnershipStats.submitted,
    });

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.first_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href={`/diversity/client_specific/partnerships/${partnershipStats.diversity_partnership_id}/employees/new`}
                        target="_blank"
                    >
                        {I18n.t("diversity.client_specific_upload.form.buttons.download_template")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.second_step")}</p>
                    <Button
                        variant="primary"
                        className="text-white"
                        href={`/diversity/client_specific/quarters/${partnershipStats.diversity_quarter_id}/partnerships/${partnershipStats.diversity_partnership_id}/employees/edit`}
                        target="_blank"
                    >
                        {I18n.t("diversity.client_specific_upload.form.buttons.generate_current_state")}
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <p>{I18n.t("diversity.client_specific_upload.form.third_step")}</p>

                    {submissionInProgress ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only" />
                            </div>
                        </div>
                    ) : (
                        <FileContainer
                            disabled={
                                !firmStats.submitted ||
                                partnershipStats.submitted ||
                                !(currentActor.super_manager || currentActor.diversity_manager)
                            }
                            accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                            onSuccess={(acceptedFiles) => {
                                setSubmissionInProgress(true);
                                setSubmissionErrors([]);

                                updateClientSpecificFirmEmployees(partnershipStats.diversity_partnership_id, {
                                    file: acceptedFiles[0],
                                    quarter_id: partnershipStats.diversity_quarter_id,
                                }).catch(() => {
                                    setSubmissionErrors([I18n.t("diversity.excel.errors.common")]);
                                });
                            }}
                        />
                    )}

                    <p className="mt-3">{I18n.t("diversity.client_specific_upload.form.fourth_step")}</p>
                    <div className="overflow-auto mt-3">
                        {submissionErrors.map((submissionError, index) => (
                            <p key={index} className="text-danger">
                                {submissionError}
                            </p>
                        ))}
                    </div>
                </Col>
            </Row>
        </>
    );
};

UploadForm.propTypes = {
    firmStats: PropTypes.shape({
        submitted: PropTypes.bool.isRequired,
    }),
    partnershipStats: PropTypes.shape({
        diversity_quarter_id: PropTypes.number.isRequired,
        diversity_partnership_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
    }),
    setShowFormModal: PropTypes.func.isRequired,
    setPartnershipStats: PropTypes.func.isRequired,
};

export default UploadForm;
