import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _compact from "lodash/compact";
import _uniq from "lodash/uniq";
import I18n from "../../../utils/i18n";
import FilteredChartsExportButton from "../FilteredChartsExportButton";

const ExportButton = ({ canvasRefs, title, filter, chartParams, changedFilters, buttonClass = "" }) => {
    const headerLabel = I18n.t(title);
    const fileName = headerLabel.split(" ").join("_");
    const leftIndent = 50;
    const lineInterval = 26;
    const workspaceWidth = 500;
    const multiSelectFilterKeys = [
        "clients",
        "roles",
        "statuses",
        "leaderships",
        "practice_areas",
        "locations",
        "quarters",
    ];
    const singleSelectFilterKeys = ["hire_date", "score", "quarter"];

    const filterSection = (pdfFile, previousYLocation) =>
        _isEmpty(changedFilters) ? previousYLocation : buildFiltersData(pdfFile, previousYLocation);

    const buildFiltersData = (pdfFile, previousYLocation) => {
        var filterPreviousYLocation = previousYLocation;

        const buildTextRow = (content) => {
            pdfFile.text(leftIndent, filterPreviousYLocation, content);
            filterPreviousYLocation = filterPreviousYLocation + lineInterval;
        };

        const buildSplitedText = (content) => {
            _map(pdfFile.splitTextToSize(content, workspaceWidth), buildTextRow);
        };

        const fetchSelectedFirms = () => {
            changedFilters.firms &&
                buildSplitedText(
                    I18n.t("diversity.buttons.select_firm") +
                        ": " +
                        _uniq(
                            _map(
                                filter.firms.filter((option) => chartParams.firms.includes(option.id)),
                                "label",
                            ),
                        ).join(", "),
                );
        };

        const buildMultiselectFilterRow = (type) => {
            const filterName = I18n.t(`diversity.buttons.select_${type}`) + ": ";

            changedFilters[type] &&
                buildSplitedText(
                    filterName +
                        _map(
                            filter[type].filter((option) => chartParams[type].includes(option.id)),
                            "label",
                        ).join(", "),
                );
        };

        const buildSingleSelectFilterRow = (type) => {
            changedFilters[type] &&
                buildTextRow(
                    I18n.t(`diversity.buttons.select_${type}`) +
                        ": " +
                        (
                            filter[type].find((option) => chartParams[type] === option.id.toString()) || {
                                label: chartParams[type],
                            }
                        ).label,
                );
        };

        const buildAttributesFilter = () => {
            const filterName = I18n.t(`diversity.buttons.select_attributes`) + ": ";
            let groupCheckedCounter = 0;
            let textForSplit;

            const fetchGroupInfo = (group) => {
                const groupName = filter.attributes[group].header + ": ";

                switch (chartParams.attributes[group].length) {
                    case filter.attributes[group].values.length:
                        ++groupCheckedCounter;
                        return groupName + I18n.t("common.links.all");
                    case 0:
                        break;
                    default:
                        return (
                            groupName +
                            _map(
                                chartParams.attributes[group],
                                (option) => filter.attributes[group].values.find((item) => item.value === option).label,
                            ).join(", ")
                        );
                }
            };

            textForSplit = _compact(_map(Object.keys(chartParams.attributes), fetchGroupInfo)).join(", ");

            groupCheckedCounter === Object.keys(chartParams.attributes).length
                ? buildTextRow(filterName + I18n.t("common.links.all"))
                : buildSplitedText(filterName + textForSplit);
        };

        fetchSelectedFirms();

        _map(multiSelectFilterKeys, buildMultiselectFilterRow);
        _map(singleSelectFilterKeys, buildSingleSelectFilterRow);

        changedFilters.attributes && buildAttributesFilter();

        return filterPreviousYLocation;
    };

    return (
        <FilteredChartsExportButton
            canvasRefs={canvasRefs}
            headerLabel={headerLabel}
            buildFiltersData={filterSection}
            exportedFileName={fileName}
            chartParams={chartParams}
            buttonType="primary"
            buttonClass={["text-light m-1", buttonClass].join(" ")}
        />
    );
};

ExportButton.propTypes = {
    canvasRefs: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    chartParams: PropTypes.object.isRequired,
    changedFilters: PropTypes.object.isRequired,
    buttonClass: PropTypes.string,
};

export default ExportButton;
