import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import _toNumber from "lodash/toNumber";
import PropTypes from "prop-types";
import I18n from "../../../utils/i18n";
import { AddCompaniesToNegotiationModal, AddCompaniesToNewNegotiation } from "../../CompanyListsPage/components/export";

const AddToProjectAction = ({ organizationId }) => {
    const [showAddToNegotiation, setShowAddToNegotiation] = useState(false);
    const [showAddToNewNegotiation, setShowAddToNewNegotiation] = useState(false);

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" className="mr-2">
                    {I18n.t("companies.lists.sections.companies.actions.projects.add")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowAddToNegotiation(true)}>
                        {I18n.t("companies.lists.sections.companies.actions.projects.existing")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowAddToNewNegotiation(true)}>
                        {I18n.t("companies.lists.sections.companies.actions.projects.new")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <AddCompaniesToNegotiationModal
                companyIds={[_toNumber(organizationId)]}
                handleClose={() => setShowAddToNegotiation(false)}
                show={showAddToNegotiation}
            />
            <AddCompaniesToNewNegotiation
                companyIds={[_toNumber(organizationId)]}
                handleClose={() => setShowAddToNewNegotiation(false)}
                show={showAddToNewNegotiation}
            />
        </>
    );
};

AddToProjectAction.propTypes = {
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AddToProjectAction;
