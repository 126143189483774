import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import FirmwideMirrorChart from "./FirmwideMirrorChart";

const RelationshipAllocatedChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="relationship_allocated"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.relationship_allocated.x_axis"),
                        },
                    },
                ],
            },
        }}
    />
);

RelationshipAllocatedChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default RelationshipAllocatedChart;
