import React from "react";
import { Field } from "formik";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import FormRow from "../../../../common/components/FormRow";
import I18n from "../../../../utils/i18n";

const UserEmail = ({ value }) => {
    const inputName = "filter[users_email_cont]";

    return (
        <div className="col">
            <FormRow label={I18n.t("activerecord.attributes.organization.user_email")}>
                <Field as={FormControl} name={inputName} value={value} />
            </FormRow>
        </div>
    );
};

UserEmail.propTypes = {
    value: PropTypes.string.isRequired,
};

export default UserEmail;
