import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, getIn } from "formik";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import _map from "lodash/map";
import I18n from "../../../utils/i18n";

const BunchRequestModal = ({
    companyIds,
    show,
    onClose,
    setCompanyIds,
    translationsScope,
    submitAction,
    initialIds,
    validationSchema,
}) => {
    const [message, setMessage] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [successCount, setSuccessCount] = useState(0);
    const [requestErrors, setRequestErrors] = useState([]);
    const initialValues = { ...initialIds, comment: message };

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        submitAction(values)
            .then(({ data: { success_count, errors } }) => {
                setCurrentStep(2);
                setSuccessCount(success_count);
                setRequestErrors(errors);
            })
            .finally(() => setSubmitting(false));
    };
    const handleClose = (handleReset) => {
        handleReset();
        setCompanyIds([]);
        setMessage("");
        onClose();
    };

    useEffect(() => setCurrentStep(1), [show]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, handleSubmit, handleReset, handleChange, isSubmitting }) => (
                <Modal show={show} onHide={onClose}>
                    <Modal.Header closeButton>{I18n.t("title", { scope: translationsScope })}</Modal.Header>
                    {currentStep === 1 && (
                        <>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>{I18n.t("message_label", { scope: translationsScope })}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="comment"
                                        placeholder={I18n.t("placeholder", { scope: translationsScope })}
                                        onChange={handleChange}
                                        isInvalid={getIn(errors, "comment")}
                                        value={values.comment}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {getIn(errors, "comment")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <span className="text-muted">
                                    {I18n.t("selected_to_send", {
                                        scope: translationsScope,
                                        count: companyIds.length,
                                    })}
                                </span>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={onClose}>
                                    {I18n.t("common.links.cancel")}
                                </Button>

                                <Button
                                    disabled={isSubmitting}
                                    variant="primary"
                                    className="text-white"
                                    onClick={handleSubmit}
                                >
                                    <Spinner
                                        hidden={!isSubmitting}
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        className="mr-2"
                                    />
                                    {I18n.t("common.links.send")}
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                    {currentStep === 2 && (
                        <>
                            <Modal.Body>
                                {!isSubmitting && (
                                    <>
                                        <p>
                                            {I18n.t("success", {
                                                scope: translationsScope,
                                                count: successCount,
                                            })}
                                        </p>
                                        {requestErrors.length > 0 && (
                                            <>
                                                <p>{I18n.t("diversity.clients.steps.errors.common")}</p>
                                                <ul>
                                                    {_map(requestErrors, (error, index) => (
                                                        <li key={index} className="text-danger">
                                                            {error}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </>
                                )}
                                <Spinner
                                    hidden={!isSubmitting}
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    className="mr-2"
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => handleClose(handleReset)}
                                    disabled={isSubmitting}
                                >
                                    {I18n.t("common.links.close")}
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </Modal>
            )}
        </Formik>
    );
};

BunchRequestModal.propTypes = {
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    setCompanyIds: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    translationsScope: PropTypes.string.isRequired,
    initialIds: PropTypes.object,
    validationSchema: PropTypes.object,
};

export default BunchRequestModal;
