import { SET_ALERT, CLEAR_ALERT } from "./types";

const DEFAULT_STATE = { translationKey: null, text: null, variant: null };

const alertReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_ALERT:
            return { ...action.payload };
        case CLEAR_ALERT:
            return { ...DEFAULT_STATE };
        default:
            return state;
    }
};

export default alertReducer;
