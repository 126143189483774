import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FILE_INVALID_TYPE, FILE_TOO_LARGE, FILE_TOO_SMALL, TOO_MANY_FILES } from "react-dropzone/src/utils";
import classNames from "classnames";
import { FormControl, Card } from "react-bootstrap";
import { isFunction } from "formik";
import I18n from "../../utils/i18n";

const FileContainer = ({
    accept = [],
    maxFiles = 1,
    maxSize = 5242880,
    minSize = 0,
    onSuccess = null,
    disabled = false,
}) => {
    const [error, setError] = useState();

    const validationMessages = {
        [FILE_INVALID_TYPE]: I18n.t("errors.messages.allowed_file_content_types", {
            types: accept.map((type) => I18n.t(`dropzone.formats.${type}`)).join(", "),
        }),
        [FILE_TOO_LARGE]: I18n.t("errors.messages.file_size_is_less_than", { count: maxSize / (1024 * 1024) }),
        [FILE_TOO_SMALL]: I18n.t("errors.messages.file_size_is_greater_than", { count: minSize }),
        [TOO_MANY_FILES]: I18n.t("errors.messages.too_many_files"),
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        maxFiles: maxFiles,
        multiple: false,
        onDrop: (acceptedFiles, fileRejections) => {
            acceptedFiles[0] && isFunction(onSuccess) && onSuccess(acceptedFiles);

            if (fileRejections[0]) {
                setError(fileRejections[0].errors.map((error) => validationMessages[error.code]).join("/n"));
            }
        },
    });

    return (
        <>
            {error && <p className="text-danger">{error}</p>}
            <div
                className={classNames("d-flex justify-content-center", {
                    "is-invalid": error,
                })}
            >
                <Card className="drop-area avatar-placeholder avatar-placeholder-rect" {...getRootProps()}>
                    <FormControl {...getInputProps({ name: "file", disabled: disabled })} />
                    <Card.Body>
                        <Card.Text>{I18n.t("dropzone.message")}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

FileContainer.propTypes = {
    action: PropTypes.string,
    accept: PropTypes.array,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    maxFiles: PropTypes.number,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
};

export default FileContainer;
