import React from "react";
import PropTypes from "prop-types";
import { Table, Card } from "react-bootstrap";
import classNames from "classnames";
import _map from "lodash/map";
import _includes from "lodash/includes";
import I18n from "../../../utils/i18n";

const TableComponent = ({ title, data, canvasRefs, inProgress = false, useCss = false, position }) => {
    const addChartToRef = (chart) => {
        canvasRefs ? (canvasRefs.current[title] = { content: chart, position: position, tagName: "TABLE" }) : null;
    };
    const moveToRightLabels = ["spend_this_period", "attribute_value"];

    return (
        <Card className={classNames("mt-3", { "bg-light": inProgress })}>
            <Card.Body>
                <Card.Title as="h4">{title}</Card.Title>
                <Table ref={addChartToRef} data-use-css={useCss} responsive bordered>
                    <thead>
                        <tr>
                            {_map(data.labels, (label, index) => (
                                <th scope="column" key={index}>
                                    {I18n.t(`diversity.columns.${label}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {_map(data.datasets, (dataset, index) => (
                            <tr key={index}>
                                {_map(data.labels, (label) => (
                                    <td
                                        key={label}
                                        scope="row"
                                        className={classNames({
                                            "text-right": _includes(moveToRightLabels, label),
                                        })}
                                    >
                                        {label === "rating" && !dataset[label] ? index + 1 : dataset[label]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    {data.total && (
                        <tfoot>
                            <tr>
                                {_map(data.labels, (label, index) => (
                                    <td
                                        key={`total-${index}`}
                                        scope="row"
                                        className={classNames({ "text-right": index === 1 })}
                                    >
                                        {index === 0 ? I18n.t("diversity.columns.total") : data.total[label]}
                                    </td>
                                ))}
                            </tr>
                        </tfoot>
                    )}
                </Table>
            </Card.Body>
        </Card>
    );
};

TableComponent.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
        labels: PropTypes.array,
        datasets: PropTypes.array,
        total: PropTypes.object,
    }).isRequired,
    canvasRefs: PropTypes.object,
    inProgress: PropTypes.bool,
    useCss: PropTypes.bool,
    position: PropTypes.number.isRequired,
};

export default TableComponent;
