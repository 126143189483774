import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner, Card, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik, getIn } from "formik";
import _filter from "lodash/filter";
import { useDispatch } from "react-redux";
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import _flatMap from "lodash/flatMap";
import _cloneDeep from "lodash/cloneDeep";
import I18n from "../../../../utils/i18n";
import { getListForCompany, addCompanyToLists, createNewList } from "../../../../api/listsApi";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";
import { setAlert } from "../../../../state/alert/alertActions";
import { filterBy } from "../filterBy";

const AddCompanyToListsModal = ({ show, onClose, organizationUid }) => {
    const [reloadLists, setReloadLists] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [unscopedLists, setUnscopedLists] = useState([]);
    const [lists, setLists] = useState([]);
    const [availableIds, setAvailableIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        list_ids: Yup.array().required(),
        uid: Yup.string().required(),
    });
    const newListValidationSchema = Yup.object().shape({
        companies_list: Yup.object().shape({
            name: Yup.string().required().min(3).max(255),
        }),
    });

    const initialValues = {
        list_ids: [],
        uid: organizationUid,
    };

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        addCompanyToLists(values)
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_add", variant: "info" }));
                resetForm();
                onClose();
            })
            .catch(() => {
                dispatch(setAlert({ translationKey: "common.alerts.error", variant: "danger" }));
            })
            .finally(() => setSubmitting(false));
    };

    const handleCreateNewList = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        createNewList(values)
            .then(() => {
                setReloadLists((prevState) => !prevState);
                setSearchTerm("");
                resetForm();
            })
            .finally(() => setSubmitting(false));
    };

    const handleClose = (handleReset) => {
        handleReset();
        onClose();
    };

    const listIds = (array) =>
        _flatMap(array, (list) => {
            const listId = list.exists ? [] : [list.id];

            return _isEmpty(list.children) ? listId : [...listIds(list.children), ...listId];
        });

    useEffect(() => {
        if (searchTerm.length > 2) {
            setLists(filterBy(_cloneDeep(unscopedLists), searchTerm));
        } else {
            setLists(unscopedLists);
        }
    }, [searchTerm]);

    useEffect(() => {
        setLoading(true);
        if (show) {
            setSearchTerm("");
            getListForCompany(organizationUid)
                .then(({ data: { lists, available_list_ids } }) => {
                    setLists(lists);
                    setUnscopedLists(_cloneDeep(lists));
                    setAvailableIds(available_list_ids);
                })
                .finally(() => setLoading(false));
        }
    }, [show, organizationUid, reloadLists]);

    const renderBranch = (lists, setFieldValue, values, level) =>
        lists.map((list) => (
            <div key={list.id} className={classNames({ "ml-3": level > 0 })}>
                <Form.Check custom key={list.id}>
                    <Form.Check.Input
                        id={`add-to-lists-${list.id}`}
                        type="checkbox"
                        name="list_ids"
                        onChange={(event) => {
                            const { checked } = event.target;
                            if (checked) {
                                setFieldValue("list_ids", [...values.list_ids, list.id]);
                            } else {
                                setFieldValue(
                                    "list_ids",
                                    _filter(values.list_ids, (id) => id !== list.id),
                                );
                            }
                        }}
                        value={list.id}
                        disabled={list.exists}
                        checked={list.exists || _includes(values.list_ids, list.id)}
                    />
                    <Form.Check.Label htmlFor={`add-to-lists-${list.id}`}>{list.name}</Form.Check.Label>
                </Form.Check>
                {!_isEmpty(list.children) && renderBranch(list.children, setFieldValue, values, level + 1)}
            </div>
        ));

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(listsForm) => (
                <Modal show={show} onHide={() => handleClose(listsForm.handleReset)}>
                    <Modal.Header closeButton>
                        {I18n.t("companies.lists.modals.add_company_to_lists.title")}
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Header>
                                <Formik
                                    initialValues={{ companies_list: { name: searchTerm } }}
                                    validationSchema={newListValidationSchema}
                                    onSubmit={handleCreateNewList}
                                >
                                    {(newListForm) => (
                                        <>
                                            <Row>
                                                <Col sm={1} className="mt-2">
                                                    <IndeterminateCheck
                                                        id="select-all-lists"
                                                        type="checkbox"
                                                        checked={
                                                            listIds(lists).length > 0 &&
                                                            listsForm.values.list_ids.length === listIds(lists).length
                                                        }
                                                        indeterminate={
                                                            listsForm.values.list_ids.length > 0 &&
                                                            listsForm.values.list_ids.length < listIds(lists).length
                                                        }
                                                        onChange={(event) => {
                                                            const { checked } = event.target;

                                                            if (checked) {
                                                                listsForm.setFieldValue(
                                                                    "list_ids",
                                                                    _filter(availableIds, (id) =>
                                                                        _includes(listIds(lists), id),
                                                                    ),
                                                                );
                                                            } else {
                                                                listsForm.setFieldValue("list_ids", []);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={11}>
                                                    <Form.Group className="mb-0">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={I18n.t(
                                                                "companies.lists.placeholders.search_or_create",
                                                            )}
                                                            name="companies_list.name"
                                                            value={newListForm.values.companies_list.name}
                                                            onChange={(event) => {
                                                                newListForm.handleChange(event);
                                                                setSearchTerm(event.target.value);
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className="mt-1">
                                                            {getIn(newListForm.errors, "companies_list.name")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {_isEmpty(lists) && (
                                                <Row className="mt-3">
                                                    <Col sm={12} className="d-flex">
                                                        <Button
                                                            variant="primary"
                                                            className="ml-auto text-white"
                                                            onClick={newListForm.handleSubmit}
                                                            disabled={newListForm.isSubmitting}
                                                        >
                                                            {newListForm.isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className="mr-2"
                                                                />
                                                            )}
                                                            {I18n.t("companies.lists.buttons.create_list")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </Formik>
                            </Card.Header>
                            <Card.Body className="overflow-y-auto max-height-25">
                                <Spinner hidden={!loading} animation="border" />
                                {!loading && (
                                    <Form.Group>
                                        {renderBranch(lists, listsForm.setFieldValue, listsForm.values, 0)}
                                    </Form.Group>
                                )}
                            </Card.Body>
                            <Card.Footer>
                                {I18n.t("companies.lists.sections.companies.selected", {
                                    count: listsForm.values.list_ids.length,
                                    total: availableIds.length,
                                })}
                            </Card.Footer>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(listsForm.handleReset)}>
                            {I18n.t("common.links.cancel")}
                        </Button>

                        <Button
                            disabled={listsForm.isSubmitting || loading || listsForm.values.list_ids.length === 0}
                            variant="primary"
                            className="text-white"
                            onClick={listsForm.handleSubmit}
                        >
                            <Spinner hidden={!loading} as="span" animation="border" size="sm" className="mr-2" />
                            {I18n.t("common.links.add")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

AddCompanyToListsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    organizationUid: PropTypes.string.isRequired,
};

export default AddCompanyToListsModal;
