import qs from "qs";
import API from "../utils/api";

export function getLogs(page, pageSize, column, order, query) {
    return API.get(
        `/activity/logs?${qs.stringify(
            { page: page, page_size: pageSize, column: column, order: order, q: query },
            {
                arrayFormat: "brackets",
                encode: false,
            }
        )}`
    );
}

export function getSources() {
    return API.get("/activity/sources");
}

export function getDescriptions() {
    return API.get("/activity/descriptions");
}
