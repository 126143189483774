import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ text }) => (
    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>{text}</h2>
    </div>
);

PageTitle.propTypes = {
    text: PropTypes.string.isRequired,
};

export default PageTitle;
