import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _filter from "lodash/filter";
import I18n from "../../../../utils/i18n";

const OrganizationsActions = ({
    setOpenFilter,
    openFilter,
    setApplyFilter,
    form,
    values,
    currentUser,
    currentActor,
}) => {
    const countValues = () => _filter(_omit(values.filter, "whitelabel"), (field) => !_isEmpty(field)).length;

    return (
        <div className="pb-2">
            <Row>
                {(currentUser.superadmin || currentUser.admin) && (
                    <Col className="text-left">
                        <Link to="/organizations/new" className="text-white btn btn-primary m-1">
                            {I18n.t("common.links.create")}
                        </Link>
                    </Col>
                )}
                <Col className="text-right">
                    <Button
                        type="reset"
                        variant="light"
                        className="mx-2"
                        onClick={() => {
                            setOpenFilter(false);
                            setApplyFilter({});
                            window.localStorage.removeItem(`organizations-filter-${currentActor.uid}`);
                            form.handleReset();
                        }}
                    >
                        {I18n.t("common.clear")}
                        <Badge variant="primary" className="mx-1">
                            {countValues()}
                        </Badge>
                    </Button>
                    {openFilter && (
                        <Button
                            type="reset"
                            variant="light"
                            className="mx-2"
                            onClick={() => {
                                setOpenFilter(false);
                                form.handleReset();
                            }}
                        >
                            {I18n.t("common.links.cancel")}
                        </Button>
                    )}
                    {!openFilter && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setOpenFilter(true);
                            }}
                            aria-controls="collapse-filter"
                            aria-expanded={openFilter}
                        >
                            {I18n.t("common.links.filter")}
                        </Button>
                    )}
                    {openFilter && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setOpenFilter(false);
                                window.localStorage.setItem(
                                    `organizations-filter-${currentActor.uid}`,
                                    JSON.stringify(values),
                                );
                                form.handleSubmit(values);
                            }}
                        >
                            {I18n.t("common.links.apply")}
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

OrganizationsActions.propTypes = {
    setOpenFilter: PropTypes.func.isRequired,
    openFilter: PropTypes.bool.isRequired,
    setApplyFilter: PropTypes.func.isRequired,
    form: PropTypes.shape({
        handleSubmit: PropTypes.func.isRequired,
        handleReset: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    values: PropTypes.object.isRequired,
    currentActor: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default OrganizationsActions;
