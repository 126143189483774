import React from "react";
import { Container } from "react-bootstrap";
import DashboardForm from "../../common/components/DashboardPage/DashboardForm";
import FirmwideMirrorCharts from "../../utils/firmwideMirrorCharts";
import I18n from "../../utils/i18n";
import { getFirmwideMirrorFilters } from "../../api/diversityApi";

const DiversityFirmwideMirrorDashboardPage = () => (
    <div id="firmwide-dashboard">
        <div className="top-bar">
            <Container fluid className="py-3" />
        </div>

        <DashboardForm
            backlink="/diversity/dashboards/firmwide_mirror"
            chartsRow={FirmwideMirrorCharts}
            namespace="diversity/firmwide_mirror"
            description={I18n.t("diversity.charts.firmwide_mirror_dashboard.description")}
            getFilters={getFirmwideMirrorFilters}
            channelName="Diversity::FirmwideMirror::ChartsChannel"
        />
    </div>
);

export default DiversityFirmwideMirrorDashboardPage;
