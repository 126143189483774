import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import {
    editClientSpecificKeyIndicators,
    updateClientSpecificKeyIndicators,
    getClientSpecificReportsFilters,
} from "../../api/diversityApi";
import KeyIndicatorsTable from "./components/KeyIndicators/KeyIndicatorsTable";
import KeyIndicatorsFormSchema from "./components/KeyIndicators/KeyIndicatorsFormSchema";
import { setAlert } from "../../state/alert/alertActions";
import MatterBasedScoringTable from "./components/MatterBasedScoringTable";
import TotalScoringTable from "./components/TotalScoringTable";
import FirmwideScoringTable from "./components/FirmwideScoringTable";
import DiversityKeyIndicatorsFiltersBar from "./components/DiversityKeyIndicatorsFiltersBar";
import NavigationPrompt from "../../common/components/NavigationPrompt";

const DiversityKeyIndicatorsPage = () => {
    const dispatch = useDispatch();
    const [firm, setFirm] = useState({ partial_firmwide: false });
    const [params, setParams] = useState({});
    const [keyIndicators, setKeyIndicators] = useState([]);
    const [currentGoals, setCurrentGoals] = useState({});
    const [scores, setScores] = useState({});
    const [weights, setWeights] = useState({});
    const history = useHistory();
    const currentActor = useSelector((state) => state.currentActor.resource);

    const onSubmit = (values, { setSubmitting }) => {
        updateClientSpecificKeyIndicators(values)
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setKeyIndicators(values.key_indicators);
                setWeights(values.client);
                setSubmitting(false);
            })
            .catch(() => {
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    const formik = useFormik({
        initialValues: { client: weights, key_indicators: keyIndicators },
        enableReinitialize: true,
        validationSchema: KeyIndicatorsFormSchema,
        onSubmit: onSubmit,
    });

    return (
        <>
            <div className="top-bar">
                <Container fluid className="py-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <Button
                                variant="warning"
                                className="text-light"
                                href={`/diversity/client_specific/key_indicators.xlsx?quarter_id=${params.quarter}`}
                                target="_blank"
                            >
                                {I18n.t("diversity.key_indicators.excel.name")}
                            </Button>
                        </div>
                        <div>
                            <DiversityKeyIndicatorsFiltersBar
                                setParams={(newParams) => {
                                    setParams(newParams);
                                    setKeyIndicators([]);
                                    setCurrentGoals({});
                                    setScores({});
                                    setWeights({});

                                    editClientSpecificKeyIndicators(newParams)
                                        .then((response) => {
                                            setFirm(response.data.firm);
                                            setKeyIndicators(response.data.key_indicators);
                                            setCurrentGoals(response.data.current_goals);
                                            setScores(response.data.scores);
                                            setWeights(response.data.weights);
                                        })
                                        .catch(() => history.push("/"));
                                }}
                                getFilters={getClientSpecificReportsFilters}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <NavigationPrompt when={formik.dirty} />
                    <Col>
                        <Container fluid className="py-3">
                            <Row>
                                <Col>
                                    <KeyIndicatorsTable
                                        formik={formik}
                                        currentGoals={currentGoals || {}}
                                        partialFirmwide={firm.partial_firmwide}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FirmwideScoringTable
                                        formik={formik}
                                        scores={scores || {}}
                                        currentGoals={currentGoals || {}}
                                        partialFirmwide={firm.partial_firmwide}
                                    />
                                </Col>
                                <Col md={3}>
                                    <MatterBasedScoringTable keyIndicators={formik.values.key_indicators} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <TotalScoringTable
                                        keyIndicators={formik.values.key_indicators}
                                        currentGoals={currentGoals || {}}
                                        scores={scores || {}}
                                        weights={formik.values.client}
                                        partialFirmwide={firm.partial_firmwide}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </div>
                {(currentActor.super_manager || currentActor.diversity_manager) && (
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button
                                            type="button"
                                            className="ml-2"
                                            variant="outline-secondary"
                                            onClick={formik.handleReset}
                                            disabled={formik.isSubmitting}
                                        >
                                            {I18n.t("common.links.cancel")}
                                        </Button>
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            onClick={formik.handleSubmit}
                                            disabled={formik.isSubmitting}
                                        >
                                            {formik.isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                )}
            </div>
        </>
    );
};

export default DiversityKeyIndicatorsPage;
