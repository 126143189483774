import React from "react";
import PropTypes from "prop-types";
import { Collapse, Form } from "react-bootstrap";
import ActivityLogsFilterDescription from "./ActivityLogsFilterDescription";
import ActivityLogsFilterSource from "./ActivityLogsFilterSource";
import ActivityLogsFilterActorName from "./ActivityLogsFilterActorName";
import ActivityLogsFilterRecordName from "./ActivityLogsFilterRecordName";
import ActivityLogsFilterCreatedAt from "./ActivityLogsFilterCreatedAt";

const ActivityLogsFilter = ({ open = false, onChange }) => (
    <Collapse in={open}>
        <div className="jumbotron jumbotron-fluid p-3 bg-white position-absolute w-100" id="collapse-filter">
            <div className="container-fluid">
                <Form>
                    <Form.Row>
                        <ActivityLogsFilterDescription />
                        <ActivityLogsFilterSource />
                    </Form.Row>
                    <Form.Row>
                        <ActivityLogsFilterActorName onChange={onChange} />
                        <ActivityLogsFilterRecordName onChange={onChange} />
                    </Form.Row>
                    <Form.Row>
                        <ActivityLogsFilterCreatedAt onChange={onChange} />
                    </Form.Row>
                </Form>
            </div>
        </div>
    </Collapse>
);

ActivityLogsFilter.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default ActivityLogsFilter;
