import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _set from "lodash/set";
import { MonthPicker } from "react-tempusdominus-bootstrap";
import { DATE_FORMAT } from "../../../../utils/datetimeFormats";

const RangeFilter = ({ params, setParams, applyFilters }) => {
    const onChange = (event, direction, value) => {
        const newParams = _set(params, `range.${direction}`, value);
        setParams(newParams);
        applyFilters(event, newParams);
    };

    return (
        <div className="d-inline-flex">
            <MonthPicker
                className="m-1"
                onChange={(event) => onChange(event, "from", moment(event.date).format(DATE_FORMAT))}
                date={moment(params.range.from, DATE_FORMAT)}
            />
            <MonthPicker
                className="m-1"
                onChange={(event) => onChange(event, "to", moment(event.date).format(DATE_FORMAT))}
                date={moment(params.range.to, DATE_FORMAT)}
            />
        </div>
    );
};

RangeFilter.propTypes = {
    params: PropTypes.shape({
        range: PropTypes.shape({
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
        }),
    }),
    setParams: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default RangeFilter;
