import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Formik } from "formik";
import I18n from "../../utils/i18n";
import { setAlert } from "../../state/alert/alertActions";
import { createPublicDomain } from "../../api/publicDomainApi";
import PublicDomainsFields from "../../common/components/PublicDomainsForm/PublicDomainsFields";
import PublicDomainsValidationSchema from "../../common/components/PublicDomainsForm/PublicDomainsValidationSchema";
import PublicDomainsActions from "../../common/components/PublicDomainsForm/PublicDomainsActions";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import NewPageTitle from "../../common/components/NewPageTitle";

const NewPublicDomainsPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();
    const dispatch = useDispatch();

    const backPath = "/public_domains";

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createPublicDomain(values)
            .then(() => {
                resetForm(values);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                history.push(backPath);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <>
            <TopBarWithBackLink backPath={backPath} linkName={I18n.t("sidebar.links.manage.public_domains")} />
            <div className="scrollable d-flex flex-column">
                <Formik
                    initialValues={{ public_domain: {} }}
                    validationSchema={PublicDomainsValidationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, handleChange, handleReset, handleSubmit, isSubmitting, dirty }) => (
                        <>
                            <NavigationPrompt when={dirty} />
                            <div className="flex-grow-1 overflow-auto bg-white">
                                <Col>
                                    <Container fluid className="py-3">
                                        <NewPageTitle resource={I18n.t("activerecord.models.public_domain")} />

                                        <Row>
                                            <Col>
                                                <PublicDomainsFields
                                                    values={values}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </div>
                            <div className="bottom-navigation p-2">
                                <Col>
                                    <Container fluid>
                                        <div className="d-flex justify-content-between">
                                            <PublicDomainsActions
                                                isSubmitting={isSubmitting}
                                                handleSubmit={handleSubmit}
                                                handleReset={handleReset}
                                            />
                                        </div>
                                    </Container>
                                </Col>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default NewPublicDomainsPage;
