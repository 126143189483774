import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";
import { sanitizeText } from "../../utils/sanitizeText";

const ConfirmAction = ({ show, onHide, onSubmit, confirm, title, text, hideCancel, loading = false, children }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <Modal
            show={show}
            onHide={(event) => {
                onHide(event);
                setIsSubmitting(false);
            }}
            size="mb"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading && text ? <p dangerouslySetInnerHTML={sanitizeText(text)} /> : children}
                {loading && <Spinner animation="border" />}
            </Modal.Body>
            <Modal.Footer>
                {!hideCancel && (
                    <Button variant="outline-secondary" onClick={onHide}>
                        {I18n.t("common.links.cancel")}
                    </Button>
                )}
                <Button
                    variant="primary"
                    className="text-white"
                    disabled={isSubmitting || loading}
                    onClick={(event) => {
                        setIsSubmitting(true);
                        onSubmit(event, { setIsSubmitting: setIsSubmitting });
                    }}
                >
                    {(isSubmitting || loading) && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                    )}
                    {I18n.t(confirm)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmAction.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
    confirm: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    hideCancel: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
};

export default ConfirmAction;
