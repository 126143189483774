import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _join from "lodash/join";
import _map from "lodash/map";
import moment from "moment";
import { getOrganizations } from "../../../api/organizationApi";
import Table from "../../../common/components/Table";
import Avatar from "../../../common/components/Avatar";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import { deactivateOrganization } from "../../../api/organizationApi";
import DeactivateAction from "../../../common/components/DeactivateAction";

const ExpanderCell = ({ row }) =>
    row.original.expandable && (
        <>
            <span {...row.getToggleRowExpandedProps()} className={classNames("expand", { down: row.isExpanded })}>
                <FontAwesomeIcon icon={faChevronRight} />
            </span>
        </>
    );

const ActionsCell = ({ row: { original }, column: { enableDelete }, isSubOrganizations }) => {
    const [modalShow, setModalShow] = useState(false);

    const onSubmit = () => {
        deactivateOrganization(original.uid).then(() => {
            setModalShow(false);
            window.location.reload();
        });
    };

    const normalizedPrevPath = () => {
        if (isSubOrganizations) {
            return location.pathname + "/subOrganizations";
        } else return location.pathname;
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item
                        as={Link}
                        to={{
                            pathname: `/organizations/${original.uid}`,
                            state: { prevPath: normalizedPrevPath() },
                        }}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    {enableDelete && original.can_be_deleted && (
                        <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            {I18n.t("common.links.delete")}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            {enableDelete && (
                <DeactivateAction
                    show={modalShow}
                    onSubmit={onSubmit}
                    onHide={() => setModalShow(false)}
                    resource={I18n.t("activerecord.models.organization")}
                />
            )}
        </>
    );
};

const NameWithAvatarCell = ({ row: { original } }) => (
    <>
        <Avatar preview={original.logo} size="sm" name={original.name} />
        <div className="d-flex flex-column ml-3">
            {original.name}
            <small className="text-muted">{original.id}</small>
        </div>
    </>
);

const PermissionsCell = ({ row: { original } }) => (
    <>
        {_join(
            _map(
                original.permissions,
                (permission) =>
                    `${I18n.t(`activerecord.attributes.organization.permissions.${permission.name}`)} ${moment(
                        permission.date,
                    ).format("(D MMM YYYY)")}
                `,
            ),
            ",  ",
        )}
    </>
);

const UsersCountCell = ({ row: { original } }) => (
    <div className="d-flex flex-column ml-3 text-right">
        {original.users_count}
        <small className="text-muted">
            {I18n.t("tables.organizations.users_count.cell", { count: original.managers_count })}
        </small>
    </div>
);

const OrganizationTable = ({ organizationUid, applyFilter, isSubOrganizations }) => {
    const currentUser = useSelector((store) => store.currentUser.resource);
    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: "expander",
                isSorted: false,
                Cell: ExpanderCell,
            },
            {
                Header: I18n.t("activerecord.models.organization"),
                accessor: "name",
                Cell: NameWithAvatarCell,
            },
            {
                Header: I18n.t("activerecord.attributes.organization.whitelabel_id"),
                accessor: "whitelabel",
            },
            {
                Header: I18n.t("activerecord.attributes.organization.permission"),
                accessor: "permissions",
                Cell: PermissionsCell,
            },
            {
                Header: I18n.t("tables.organizations.users_count.header"),
                accessor: "users_count",
                type: "number",
                Cell: UsersCountCell,
            },
            {
                Header: I18n.t("activerecord.attributes.common.updated_at"),
                accessor: "updated_at",
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                enableDelete: currentUser.superadmin || currentUser.admin,
                Cell: (props) => <ActionsCell {...props} isSubOrganizations={isSubOrganizations} />,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || { id: "id", desc: false };
            setLoading(true);

            getOrganizations(organizationUid, pageIndex + 1, sort.id, sort.desc ? "desc" : "asc", applyFilter).then(
                (response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                },
            );
        },
        [applyFilter],
    );

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <tr>
                <td colSpan={columns.length} className="bg-light">
                    <Card>
                        <Card.Body>
                            <OrganizationTable organizationUid={row.original.uid} />
                        </Card.Body>
                    </Card>
                </td>
            </tr>
        ),
        [],
    );

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            renderRowSubComponent={renderRowSubComponent}
        />
    );
};

OrganizationTable.propTypes = {
    organizationUid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    applyFilter: PropTypes.object,
    isSubOrganizations: PropTypes.bool,
};

ExpanderCell.propTypes = {
    row: PropTypes.shape({
        getToggleRowExpandedProps: PropTypes.func.isRequired,
        isExpanded: PropTypes.bool,
        original: PropTypes.shape({ expandable: PropTypes.bool }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            uid: PropTypes.string.isRequired,
            can_be_deleted: PropTypes.bool.isRequired,
        }),
    }),
    column: PropTypes.shape({
        enableDelete: PropTypes.bool.isRequired,
    }),
    isSubOrganizations: PropTypes.bool,
};

NameWithAvatarCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            logo: PropTypes.string,
        }),
    }),
};

PermissionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            permissions: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    date: PropTypes.string,
                }),
            ),
        }),
    }),
};

UsersCountCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            users_count: PropTypes.number.isRequired,
            managers_count: PropTypes.number.isRequired,
        }),
    }),
};

export default OrganizationTable;
