import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import DiversityClientSpecificRequestsTable from "../../common/components/DiversityClientSpecificRequestsTable";
import PageSizeSelector from "../../common/components/PageSizeSelector";

const DiversityClientSpecificRequestsPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const [pageSize, setPageSize] = useState(10);
    const history = useHistory();

    useEffect(() => {
        if (!currentUser.superadmin && !currentUser.admin) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="top-bar">
                <Container fluid className="py-3" />
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <Row className="justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <Col>
                                    <h2>{I18n.t("sidebar.links.diversity.client_specific_requests")}</h2>
                                </Col>
                                <Col md={2}>
                                    <PageSizeSelector onChange={setPageSize} currentSize={pageSize} />
                                </Col>
                                <Col />
                            </Row>

                            <Row>
                                <Col>
                                    <DiversityClientSpecificRequestsTable pageSize={pageSize} globalRemind />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default DiversityClientSpecificRequestsPage;
