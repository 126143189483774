import _map from "lodash/map";
import { Table } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";

const TableAttributes = ({ title, scope, prefix = "" }) => (
        <Table bordered>
            <thead>
                <tr>
                    <th>{title}</th>
                    <th>{I18n.t("activerecord.attributes.common.period")}</th>
                </tr>
            </thead>
            <tbody>
                {_map(scope, (item, index) => (
                    <tr key={index}>
                        <td>
                            {prefix}
                            {item.value}
                        </td>
                        <td>{item.period}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

TableAttributes.propTypes = {
    title: PropTypes.string.isRequired,
    scope: PropTypes.array.isRequired,
    prefix: PropTypes.string,
};

export default TableAttributes;
