import React from "react";
import { useParams } from "react-router-dom";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { createOrganizationLocation } from "../../api/locationsApi";
import LocationForm from "../../common/components/LocationForm";

const NewLocationPage = () => {
    const { organization_uid: organizationUid } = useParams();

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/locations`}
                linkName={I18n.t("forms.organization.edit.tabs.locations")}
            />
            <div className="scrollable d-flex flex-column">
                <LocationForm
                    organizationUid={organizationUid}
                    initialValues={{
                        name: "",
                        location_type_id: null,
                        location_type_required: false,
                        address_line1: "",
                        address_line2: "",
                        country: "",
                        state: "",
                        city: "",
                        zip_code: "",
                        phone: "",
                        email: "",
                        industry_relations_attributes: [],
                    }}
                    createOrUpdateOrganizationLocation={createOrganizationLocation}
                />
            </div>
        </>
    );
};

export default NewLocationPage;
