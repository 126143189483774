import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCheck } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../../common/components/Table";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import { getWhitelabels } from "../../../api/whitelabelApi";

const ActionsCell = ({ row: { original } }) => (
    <Dropdown>
        <Dropdown.Toggle as={ThreeDotsToggle} />
        <Dropdown.Menu size="sm" title="">
            <Dropdown.Item as={Link} to={`/whitelabels/${original.domain.replace(/\./g, "-")}/edit`}>
                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                {I18n.t("common.links.edit")}
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

const NameCell = ({ row: { original } }) => (
    <>
        <div className="d-flex flex-column">
            {original.name}
            <small className="text-muted">{original.id}</small>
        </div>
    </>
);

const DomainCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.domain}</div>;

const MailToCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.mail_to}</div>;

const AllowSignInAsCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {original.allow_sign_in_as && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
    </div>
);

const WhitelabelTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.whitelabel.name"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.whitelabel.domain"),
                accessor: "domain",
                Cell: DomainCell,
            },
            {
                Header: I18n.t("activerecord.attributes.whitelabel.mail_to"),
                accessor: "mail_to",
                Cell: MailToCell,
            },
            {
                Header: I18n.t("activerecord.attributes.whitelabel.allow_sign_in_as"),
                accessor: "allow_sign_in_as",
                Cell: AllowSignInAsCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: ActionsCell,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "id", desc: false };
        setLoading(true);

        getWhitelabels(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);

    return <Table columns={columns} data={data} fetchData={fetchData} pageCount={pageCount} loading={loading} />;
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            domain: PropTypes.string.isRequired,
        }),
    }),
};

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }),
};

DomainCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            domain: PropTypes.string.isRequired,
        }),
    }),
};

MailToCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            mail_to: PropTypes.string,
        }),
    }),
};

AllowSignInAsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            allow_sign_in_as: PropTypes.bool.isRequired,
        }),
    }),
};

export default WhitelabelTable;
