import React, { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import _isNull from "lodash/isNull";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import ButtonCard from "./ButtonCard";
import { createNegotiation } from "../../../../api/negotiationApi";

const MethodStep = () => {
    const { setCurrentStep, onHide, setCreateMethod, createMethod, eventType, exportCompanies } =
        useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [creating, setCreating] = useState(false);
    const industryPickerStepIndex = 4;
    const currentStepIndex = 1;
    const exportCompaniesStepIndex = 5;

    const proceedHandler = () => {
        if (createMethod === "rfpBuilder") return setCurrentStep(industryPickerStepIndex);
        if (createMethod === "fromScratch") {
            if (exportCompanies) return setCurrentStep(exportCompaniesStepIndex);

            setCreating(true);
            createNegotiation({ legal: "legal" === eventType })
                .then(({ data: { link } }) => (window.location = `//${currentWhitelabel.hosts.bid}${link}`))
                .catch(() => setCreating(false));
        } else setCurrentStep(currentStepIndex + 1);
    };

    const translationScope = "home.create_event_wizard.steps.method_step";

    return (
        <>
            <Modal.Header className="border-0 pb-1">
                <Modal.Title className="mb-3">
                    <Link className="text-dark" to="#" onClick={() => setCurrentStep(currentStepIndex - 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                        {I18n.t("home.create_event_wizard.buttons.back")}
                    </Link>
                    <div className="mt-4 mb-2 text-2xl">
                        {I18n.t(`${eventType}.title`, { scope: translationScope })}
                    </div>
                    <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                        {I18n.t(`${eventType}.tip`, { scope: translationScope })}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex justify-content-around">
                    {eventType === "legal" && (
                        <ButtonCard
                            text={I18n.t("buttons.rfp_builder.title", { scope: translationScope })}
                            tip={I18n.t("buttons.rfp_builder.tip", { scope: translationScope })}
                            clickHandler={() => setCreateMethod("rfpBuilder")}
                            active={createMethod === "rfpBuilder"}
                        />
                    )}
                    <ButtonCard
                        text={I18n.t("buttons.from_scratch.title", { scope: translationScope })}
                        tip={I18n.t("buttons.from_scratch.tip", { scope: translationScope })}
                        clickHandler={() => setCreateMethod("fromScratch")}
                        active={createMethod === "fromScratch"}
                    />
                    <ButtonCard
                        text={I18n.t("buttons.from_template.title", { scope: translationScope })}
                        tip={I18n.t("buttons.from_template.tip", { scope: translationScope })}
                        clickHandler={() => setCreateMethod("from_template")}
                        active={createMethod === "from_template"}
                    />
                    <ButtonCard
                        text={I18n.t("buttons.from_previous.title", { scope: translationScope })}
                        tip={I18n.t("buttons.from_previous.tip", { scope: translationScope })}
                        clickHandler={() => setCreateMethod("from_previous")}
                        active={createMethod === "from_previous"}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer className="pt-0 border-0 d-flex justify-content-between">
                <Button variant="outline-secondary" onClick={onHide}>
                    {I18n.t("home.create_event_wizard.buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    className="text-white"
                    onClick={proceedHandler}
                    disabled={_isNull(createMethod)}
                >
                    <Spinner animation="border" size="sm" className="mr-2" hidden={!creating} />
                    {I18n.t("home.create_event_wizard.buttons.proceed")}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default MethodStep;
