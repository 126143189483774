import React from "react";
import PropTypes from "prop-types";
import { AccordionCollapse, Card } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import CustomersTable from "./CustomersTable";

const CustomersSection = ({ activeId, setActiveId }) => {
    const eventKey = "customers";

    return (
        <Card className="border-0 mb-3">
            <AccordionTitle
                eventKey={eventKey}
                activeId={activeId}
                title={I18n.t("tiered_diversity.requests.customers.title")}
                toggleActive={setActiveId}
            />
            <AccordionCollapse eventKey={eventKey}>
                <CustomersTable />
            </AccordionCollapse>
        </Card>
    );
};

CustomersSection.propTypes = {
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default CustomersSection;
