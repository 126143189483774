import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityFirmwideChart from "./DiversityFirmwideChart";

const ScoreByFirmSizeChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="score_by_firm_size"
        chartType="scatter"
        chartOptions={{
            ...chartOptions,
            plugins: {
                datalabels: {
                    formatter: (value, context) => context.chart.data.datasets[context.datasetIndex].label,
                },
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.score_by_firm_size.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.score_by_firm_size.y_axis"),
                        },
                    },
                ],
            },
            elements: {
                point: {
                    radius: 5,
                },
            },
            tooltips: {
                callbacks: {
                    title: (items, data) => data.datasets[items[0].datasetIndex].label,
                },
            },
        }}
        chartUseDataLabels={true}
    />
);

ScoreByFirmSizeChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default ScoreByFirmSizeChart;
