import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import map from "lodash/map";
import I18n from "../../../utils/i18n";

const AddFirmModalBody = ({ organizations, values, handleChange }) => {
    const [searchCriteria, setSearchCriteria] = useState("");

    const isCheckboxVisible = (organization) => {
        const criteria = searchCriteria.toLowerCase().trim();

        if (criteria.length) {
            return organization.name.toLowerCase().trim().indexOf(criteria) >= 0;
        } else {
            return true;
        }
    };

    const isCheckboxChecked = (organization) => values.organizations.indexOf(organization.id.toString()) !== -1;

    return (
        <Modal.Body>
            <p>{I18n.t("diversity.clients.add_firm_modal.body")}</p>
            <Form.Group>
                <div className="border rounded-top p-3">
                    <Form.Control
                        size="sm"
                        onChange={(event) => setSearchCriteria(event.target.value)}
                        value={searchCriteria}
                    />
                </div>
                <div className="border-left border-right border-bottom p-3 overflow-auto">
                    {map(organizations, (organization) => (
                        <Form.Check
                            custom
                            className={classNames({ "d-none": !isCheckboxVisible(organization) })}
                            key={organization.id}
                            type="checkbox"
                            checked={isCheckboxChecked(organization)}
                            label={organization.name}
                            name="organizations"
                            onChange={handleChange}
                            value={organization.id}
                            id={`organizations-${organization.id}`}
                        />
                    ))}
                </div>
                <div className="border-left border-right border-bottom p-3">
                    {`${I18n.t("diversity.clients.add_firm_modal.selected")}: ${values.organizations.length}`}
                </div>
            </Form.Group>
        </Modal.Body>
    );
};

AddFirmModalBody.propTypes = {
    organizations: PropTypes.array.isRequired,
    values: PropTypes.shape({
        organizations: PropTypes.array.isRequired,
    }),
    handleChange: PropTypes.func.isRequired,
};

export default AddFirmModalBody;
