import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import classNames from "classnames";
import Select from "react-select";
import _find from "lodash/find";
import I18n from "../../../utils/i18n";
import { setAlert } from "../../../state/alert/alertActions";
import NavigationPrompt from "../NavigationPrompt";
import NewPageTitle from "../NewPageTitle";
import EditPageTitle from "../EditPageTitle";
import { getRoles } from "../../../api/legalApi";

const LegalCaseForm = ({ organizationUid, attorneyId, caseId = null, initialValues, createOrUpdateLegalCase }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        venue: Yup.string().max(255).nullable(),
        judge: Yup.string().max(255).nullable(),
        matter_description: Yup.string().max(3000).nullable(),
        attorneys_contribution: Yup.string().max(3000).nullable(),
        opposing_counsel: Yup.string().max(255).nullable(),
        outcome_summary: Yup.string().max(3000).nullable(),
        trial_experience: Yup.boolean().required(),
        start_date: Yup.date()
            .min(moment({ year: 1900 }))
            .nullable()
            .when("end_date", (endDate, schema) => (endDate ? schema.max(endDate) : schema)),
        end_date: Yup.date().nullable(),
        case_role_attributes: Yup.object().shape({
            legal_role_id: Yup.number()
                .nullable()
                .when("description", (description, schema) =>
                    description && description.length > 0 ? schema.required() : schema,
                ),
            description: Yup.string().max(3000).nullable(),
        }),
    });

    useEffect(() => {
        getRoles().then((response) => setRoles(response.data));
    }, []);

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createOrUpdateLegalCase(organizationUid, attorneyId, caseId, { case: values })
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                resetForm(values);
                history.push(`/organizations/${organizationUid}/attorneys`);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, dirty }) => (
                <>
                    <NavigationPrompt when={dirty} />
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Col>
                            <Container fluid className="py-3">
                                {caseId ? (
                                    <EditPageTitle resource={I18n.t("activerecord.models.legal/case")} />
                                ) : (
                                    <NewPageTitle resource={I18n.t("activerecord.models.legal/case")} />
                                )}
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.name")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        onChange={handleChange}
                                                        value={values.name || ""}
                                                        isInvalid={errors.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t(
                                                            "activerecord.attributes.legal/case.matter_description",
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="matter_description"
                                                        onChange={handleChange}
                                                        value={values.matter_description || ""}
                                                        isInvalid={errors.matter_description}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.matter_description}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.venue")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="venue"
                                                        onChange={handleChange}
                                                        value={values.venue || ""}
                                                        isInvalid={errors.venue}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.venue}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t(
                                                            "activerecord.attributes.legal/case.attorneys_contribution",
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="attorneys_contribution"
                                                        onChange={handleChange}
                                                        value={values.attorneys_contribution || ""}
                                                        isInvalid={errors.attorneys_contribution}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.attorneys_contribution}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.judge")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="judge"
                                                        onChange={handleChange}
                                                        value={values.judge || ""}
                                                        isInvalid={errors.judge}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.judge}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.trial_experience")}
                                                    </Form.Label>
                                                    <Form.Check
                                                        id="trial_experience"
                                                        type="switch"
                                                        name="trial_experience"
                                                        onChange={handleChange}
                                                        checked={values.trial_experience}
                                                        isInvalid={errors.trial_experience}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.trial_experience}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.start_date")}
                                                    </Form.Label>
                                                    <DatePicker
                                                        useCurrent={false}
                                                        format="MM-DD-YYYY"
                                                        date={values.start_date}
                                                        onChange={({ date }) => setFieldValue("start_date", date)}
                                                        className={classNames({ "is-invalid": errors.start_date })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case_role.name")}
                                                    </Form.Label>
                                                    <Select
                                                        name="case_role_attributes[legal_role_id]"
                                                        className={classNames("react-select", {
                                                            "is-invalid": errors.case_role_attributes?.legal_role_id,
                                                        })}
                                                        classNamePrefix="react-select"
                                                        options={roles}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={(option) =>
                                                            setFieldValue(
                                                                "case_role_attributes[legal_role_id]",
                                                                option.id,
                                                            )
                                                        }
                                                        isLoading={roles.length === 0}
                                                        value={_find(roles, {
                                                            id: values.case_role_attributes.legal_role_id,
                                                        })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.case_role_attributes?.legal_role_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.end_date")}
                                                    </Form.Label>
                                                    <DatePicker
                                                        useCurrent={false}
                                                        format="MM-DD-YYYY"
                                                        date={values.end_date}
                                                        onChange={({ date }) => setFieldValue("end_date", date)}
                                                        className={classNames({ "is-invalid": errors.end_date })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case_role.description")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="case_role_attributes[description]"
                                                        onChange={handleChange}
                                                        value={values.case_role_attributes.description || ""}
                                                        isInvalid={errors.case_role_attributes?.description}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.case_role_attributes?.description}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.opposing_counsel")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="opposing_counsel"
                                                        onChange={handleChange}
                                                        value={values.opposing_counsel || ""}
                                                        isInvalid={errors.opposing_counsel}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.opposing_counsel}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} />
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/case.outcome_summary")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="outcome_summary"
                                                        onChange={handleChange}
                                                        value={values.outcome_summary || ""}
                                                        isInvalid={errors.outcome_summary}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.outcome_summary}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} />
                                            </Form.Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </>
            )}
        </Formik>
    );
};

LegalCaseForm.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    attorneyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValues: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        title: PropTypes.string,
        graduation_year: PropTypes.number,
        role_attributes: PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
        }),
    }),
    createOrUpdateLegalCase: PropTypes.func.isRequired,
};

export default LegalCaseForm;
