import React from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import I18n from "../../../utils/i18n";
import LoadingBody from "../../../common/components/Table/LoadingBody";
import { calculateOverallFirmwideScore } from "../../../utils/diversityCalculators";

const FirmwideScoringTable = ({ formik, scores, currentGoals, partialFirmwide }) => (
        <Table bordered size="sm" className="diversity-scoring-table">
            <thead>
                <tr>
                    <th scope="col" className="text-uppercase">
                        {I18n.t("diversity.columns.firmwide_scoring")}
                    </th>
                    <th scope="col">{I18n.t("diversity.columns.score")}</th>
                    <th scope="col">{I18n.t("diversity.columns.weight")}</th>
                </tr>
            </thead>
            <tbody>
                {_.isEmpty(formik.values.client) ? (
                    <LoadingBody columnCount={3} rowCount={4} />
                ) : (
                    <>
                        <tr>
                            <td scope="row">{I18n.t("diversity.scores.overall_score")}</td>
                            <td scope="row">
                                {_.isEmpty(currentGoals)
                                    ? I18n.t("common.not_available")
                                    : calculateOverallFirmwideScore(
                                          formik.values.key_indicators,
                                          currentGoals,
                                          partialFirmwide
                                      ).toFixed(2)}
                            </td>
                            <td scope="row">
                                <Row>
                                    <Col>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="client[overall_weight]"
                                                value={formik.values.client.overall_weight}
                                                isInvalid={(formik.errors.client || {}).overall_weight}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                {(formik.errors.client || {}).overall_weight}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">{I18n.t("diversity.scores.leadership_score")}</td>
                            <td scope="row">
                                {_.isNil(scores.leadership_score)
                                    ? I18n.t("common.not_available")
                                    : scores.leadership_score.toFixed(2)}
                            </td>
                            <td scope="row">
                                <Row>
                                    <Col>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="client[leadership_weight]"
                                                value={formik.values.client.leadership_weight}
                                                isInvalid={(formik.errors.client || {}).leadership_weight}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                {(formik.errors.client || {}).leadership_weight}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">{I18n.t("diversity.scores.hiring_and_promotions_score")}</td>
                            <td scope="row">
                                {_.isNil(scores.hiring_and_promotions_score)
                                    ? I18n.t("common.not_available")
                                    : scores.hiring_and_promotions_score.toFixed(2)}
                            </td>
                            <td scope="row">
                                <Row>
                                    <Col>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="client[hiring_and_promotions_weight]"
                                                value={formik.values.client.hiring_and_promotions_weight}
                                                isInvalid={(formik.errors.client || {}).hiring_and_promotions_weight}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                {(formik.errors.client || {}).hiring_and_promotions_weight}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">{I18n.t("diversity.scores.attrition_score")}</td>
                            <td scope="row">
                                {_.isNil(scores.attrition_score)
                                    ? I18n.t("common.not_available")
                                    : scores.attrition_score.toFixed(2)}
                            </td>
                            <td scope="row">
                                <Row>
                                    <Col>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="client[attrition_weight]"
                                                value={formik.values.client.attrition_weight}
                                                isInvalid={(formik.errors.client || {}).attrition_weight}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                {(formik.errors.client || {}).attrition_weight}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    </>
                )}
            </tbody>
        </Table>
    );

FirmwideScoringTable.propTypes = {
    formik: PropTypes.object.isRequired,
    scores: PropTypes.object,
    currentGoals: PropTypes.object,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default FirmwideScoringTable;
