import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const QuartersFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("diversity.buttons.select_quarters")}
        filterKey="quarters"
        filterValues={values.quarters}
        defaultParams={defaultParams.quarters}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

QuartersFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        quarters: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default QuartersFilter;
