import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const EligiblePromotedByDemographicsChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="eligible_promoted_by_demographics"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t(
                                "diversity.axises.client_specific.eligible_promoted_by_demographics.y_axis"
                            ),
                        },
                    },
                ],
            },
        }}
    />
);

EligiblePromotedByDemographicsChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default EligiblePromotedByDemographicsChart;
