import React from "react";
import PropTypes from "prop-types";
import { DiversityClientSpecificChart } from "./index";

const MiniStatsBar = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        chartOptions={chartOptions}
        inProgress={inProgress}
        chartType="text"
        type="mini_stats_bar"
        canvasRefs={canvasRefs}
    />
);

MiniStatsBar.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default MiniStatsBar;
