import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import * as _ from "lodash";

const LoadingBody = ({ rowCount = 5, columnCount }) =>
    _.range(rowCount).map((rowIndex) => (
        <tr key={rowIndex}>
            {_.range(columnCount).map((cellIndex) => (
                <td key={cellIndex}>
                    <Skeleton />
                </td>
            ))}
        </tr>
    ));

LoadingBody.propTypes = {
    columnCount: PropTypes.number.isRequired,
};

export default LoadingBody;
