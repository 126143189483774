import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { map, filter, every, reject, isEmpty, some, uniq, concat, difference } from "lodash";
import I18n from "../../../utils/i18n";
import AddFirmModalCheckbox from "./AddFirmModalCheckbox";

const AddFirmModalBody = ({ firms, values, setValues, handleChange }) => {
    const [searchCriteria, setSearchCriteria] = useState("");
    const submittedFirms = filter(firms, "submitted");
    const submittedNotRequestedFirms = filter(firms, (firm) => firm.submitted && !firm.requested && !firm.reviewed);
    const notSubmittedFirms = reject(firms, "submitted");

    const isCheckboxesVisible = (firms) => {
        const criteria = searchCriteria.toLowerCase().trim();

        if (criteria.length) {
            return some(firms, (firm) => firm.name.toLowerCase().trim().indexOf(criteria) >= 0);
        } else {
            return true;
        }
    };

    const isCheckboxesChecked = (firms) => {
        if (isEmpty(firms)) {
            return false;
        }

        return every(firms, (firm) => values.firms.indexOf(firm.id.toString()) !== -1);
    };

    const onSelect = (event, firms) => {
        const ids = map(firms, (firm) => firm.id.toString());

        if (event.target.checked) {
            setValues((prev) => ({ ...prev, firms: uniq(concat(values.firms, ids)) }));
        } else {
            setValues((prev) => ({ ...prev, firms: difference(values.firms, ids) }));
        }
    };

    const onSelectSubmitted = (event) => onSelect(event, submittedNotRequestedFirms);

    return (
        <Modal.Body>
            <p>{I18n.t("diversity.surveys.add_firm_modal.body")}</p>
            <Form.Group>
                <div className="border rounded-top p-3">
                    <Form.Control
                        size="sm"
                        onChange={(event) => setSearchCriteria(event.target.value)}
                        value={searchCriteria}
                    />
                </div>
                <div className="border-left border-right p-3 overflow-auto">
                    {submittedFirms.length > 0 && isCheckboxesVisible(submittedFirms) && (
                        <span>{I18n.t("diversity.surveys.add_firm_modal.submitted")}</span>
                    )}
                    {map(submittedFirms, (firm) => (
                        <AddFirmModalCheckbox
                            key={firm.id}
                            firm={firm}
                            visible={isCheckboxesVisible([firm])}
                            checked={isCheckboxesChecked([firm])}
                            handleChange={handleChange}
                        />
                    ))}
                    {notSubmittedFirms.length > 0 && isCheckboxesVisible(notSubmittedFirms) && (
                        <span>{I18n.t("diversity.surveys.add_firm_modal.not_submitted")}</span>
                    )}
                    {map(notSubmittedFirms, (firm) => (
                        <AddFirmModalCheckbox
                            key={firm.id}
                            firm={firm}
                            visible={isCheckboxesVisible([firm])}
                            checked={isCheckboxesChecked([firm])}
                            handleChange={handleChange}
                        />
                    ))}
                </div>
                <div className="border rounded-bottom p-3">
                    <Form.Check
                        custom
                        type="checkbox"
                        label={I18n.t("diversity.surveys.add_firm_modal.select_submitted")}
                        id="select-submitted-firms"
                        disabled={isEmpty(submittedNotRequestedFirms)}
                        checked={isCheckboxesChecked(submittedNotRequestedFirms)}
                        onChange={(event) => onSelectSubmitted(event)}
                    />
                    <div className="mt-3">
                        {`${I18n.t("diversity.surveys.add_firm_modal.selected")}: ${values.firms.length}`}
                    </div>
                </div>
            </Form.Group>
        </Modal.Body>
    );
};

AddFirmModalBody.propTypes = {
    values: PropTypes.shape({
        firms: PropTypes.array,
    }),
    firms: PropTypes.array.isRequired,
    setValues: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default AddFirmModalBody;
