import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { AccordionCollapse, Card, Col, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import { getDashboardChart, getDashboardChartTypes } from "../../../api/tieredDiversityApi";
import Tier1SuppliersByStatus from "./charts/Tier1SuppliersByStatus";
import Tier1SpendPercentageByStatus from "./charts/Tier1SpendPercentageByStatus";
import Tier1SpendPercentageByCategory from "./charts/Tier1SpendPercentageByCategory";
import Tier1SpendByCategory from "./charts/Tier1SpendByCategory";
import Tier1SpendByMonth from "./charts/Tier1SpendByMonth";
import Tier2SuppliersPercentageByStatus from "./charts/Tier2SuppliersPercentageByStatus";
import Tier2SpendPercentageByStatus from "./charts/Tier2SpendPercentageByStatus";
import Tier2SpendPercentageByCategory from "./charts/Tier2SpendPercentageByCategory";
import Tier2SpendByCategory from "./charts/Tier2SpendByCategory";
import Tier2SpendByMonth from "./charts/Tier2SpendByMonth";
import FiltersBar from "./FiltersBar";

const DataSection = ({ activeId, setActiveId }) => {
    const history = useHistory();
    const [chartTypes, setChartTypes] = useState([]);
    const [chartParams, setChartParams] = useState({});

    const chartComponents = {
        tier1_suppliers_by_status: Tier1SuppliersByStatus,
        tier1_spend_percentage_by_status: Tier1SpendPercentageByStatus,
        tier1_spend_percentage_by_category: Tier1SpendPercentageByCategory,
        tier1_spend_by_category: Tier1SpendByCategory,
        tier1_spend_by_month: Tier1SpendByMonth,
        tier2_suppliers_percentage_by_status: Tier2SuppliersPercentageByStatus,
        tier2_spend_percentage_by_status: Tier2SpendPercentageByStatus,
        tier2_spend_percentage_by_category: Tier2SpendPercentageByCategory,
        tier2_spend_by_category: Tier2SpendByCategory,
        tier2_spend_by_month: Tier2SpendByMonth,
    };

    useEffect(() => {
        getDashboardChartTypes()
            .then((response) => setChartTypes(response.data))
            .catch((error) => {
                error.response.status === 403 && history.push("/");
            });
    }, []);

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                eventKey="data"
                activeId={activeId}
                title={I18n.t("tiered_diversity.dashboard.data.title")}
                toggleActive={setActiveId}
            />
            <AccordionCollapse eventKey="data">
                <Card.Body id="tiered-diversity-data">
                    <Row>
                        <Col className="mr-auto" />
                        <Col className="col-auto">
                            <FiltersBar setChartParams={setChartParams} />
                        </Col>
                    </Row>
                    {!_isEmpty(chartParams) && (
                        <Row>
                            {_map(chartTypes, (chartType) => (
                                <Col lg={12} xl={6} key={chartType}>
                                    {chartComponents[chartType] &&
                                        React.createElement(chartComponents[chartType], {
                                            params: chartParams,
                                            getChart: getDashboardChart,
                                        })}
                                </Col>
                            ))}
                        </Row>
                    )}
                </Card.Body>
            </AccordionCollapse>
        </Card>
    );
};

DataSection.propTypes = {
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DataSection;
