import React from "react";
import PropTypes from "prop-types";
import _lowerCase from "lodash/lowerCase";
import ConfirmAction from "./ConfirmAction";
import I18n from "../../utils/i18n";

const DeleteAction = ({ show, onHide, resource, onSubmit }) => {
    const formattedResource = _lowerCase(resource);
    return (
        <ConfirmAction
            show={show}
            onHide={onHide}
            onSubmit={onSubmit}
            confirm="common.links.delete"
            title={I18n.t("common.actions.delete.title", { resource: formattedResource })}
            text={I18n.t("common.actions.delete.body", { resource: formattedResource })}
        />
    );
};

DeleteAction.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default DeleteAction;
