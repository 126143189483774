import API from "../utils/api";

export function getOrganizationLocations(organizationUid, page, column, order) {
    return API.get(`/organizations/${organizationUid}/locations`, { params: { page, column, order } });
}

export function createOrganizationLocation(organizationUid, values) {
    return API.post(`/organizations/${organizationUid}/locations`, values);
}

export function updateOrganizationLocation(organizationUid, { location: { id: locationId, ...values } }) {
    return API.patch(`/organizations/${organizationUid}/locations/${locationId}`, { location: values });
}

export function editOrganizationLocation(organizationUid, locationId) {
    return API.get(`/organizations/${organizationUid}/locations/${locationId}/edit`);
}

export function deleteOrganizationLocation(organizationUid, locationId) {
    return API.delete(`/organizations/${organizationUid}/locations/${locationId}`);
}
