import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik } from "formik";
import _toString from "lodash/toString";
import _isNull from "lodash/isNull";
import moment from "moment";
import CommentStep from "../../../../common/components/Steps/CommentStep";
import { createClientSpecificFirm, createFirmwideFirm, getDeadline } from "../../../../api/diversityApi";
import { initFirm } from "../../../../utils/commonSteps";
import I18n from "../../../../utils/i18n";

const RequestModal = ({ show, onClose, type, organization_id }) => {
    const [deadlineMessage, setDeadlineMessage] = useState(null);
    const content = {
        firmwide: {
            title: I18n.t("companies.profiles.actions.request_firmwide"),
            text: I18n.t("diversity.surveys.add_firm_modal.comment_text"),
            success_text: I18n.t("companies.profiles.modals.request_firmwide.success_text"),
            values: { firms: [_toString(organization_id)], new_firms: [initFirm] },
            submit: createFirmwideFirm,
        },
        client_specific: {
            title: I18n.t("companies.profiles.actions.request_client_specific"),
            text: I18n.t("companies.profiles.modals.request_client_specific.text"),
            success_text: I18n.t("companies.profiles.modals.request_client_specific.success_text"),
            values: { organizations: [_toString(organization_id)], new_organizations: [initFirm] },
            submit: createClientSpecificFirm,
        },
    };
    const [showResult, setShowResult] = useState(false);
    const onSubmit = (values) => {
        content[type].submit(values).then(() => {
            setShowResult(true);
        });
    };

    const handleClose = (handleReset) => {
        handleReset();
        onClose();
        showResult && window.location.reload();
        setShowResult(false);
    };

    const onShow = () => {
        getDeadline().then((response) => {
            let deadlineDate = response.data?.deadline_at;

            if (_isNull(deadlineDate)) {
                setDeadlineMessage(I18n.t("diversity.labels.no_deadline"));
            } else {
                setDeadlineMessage(
                    I18n.t("diversity.labels.deadline_exist", {
                        count: moment(deadlineDate).diff(
                            moment().quarter(moment().quarter()).startOf("quarter"),
                            "days",
                        ),
                    }),
                );
            }
        });
    };
    return (
        <Formik initialValues={{ ...content[type].values, comment: "" }} onSubmit={onSubmit}>
            {({ values, setFieldValue, handleSubmit, handleReset }) => (
                <Modal show={show} onHide={() => handleClose(handleReset)} onShow={onShow}>
                    <Modal.Header closeButton>{content[type].title}</Modal.Header>
                    <Modal.Body>
                        {showResult ? (
                            content[type].success_text
                        ) : (
                            <CommentStep
                                value={values.comment}
                                setFieldValue={setFieldValue}
                                title={content[type].text}
                                deadlineMessage={deadlineMessage}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {showResult ? (
                            <Button variant="primary" className="text-white" onClick={() => handleClose(handleReset)}>
                                {I18n.t("common.links.text_ok")}
                            </Button>
                        ) : (
                            <>
                                <Button variant="secondary" onClick={() => handleClose(handleReset)}>
                                    {I18n.t("common.links.cancel")}
                                </Button>
                                <Button variant="primary" className="text-white" onClick={handleSubmit}>
                                    {I18n.t("diversity.clients.steps.send")}
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

RequestModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    organization_id: PropTypes.number.isRequired,
};

export default RequestModal;
