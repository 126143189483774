import React from "react";
import { Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";

const IntroStep = ({ setCurrentStep }) => {
    const currentActor = useSelector((store) => store.currentActor.resource);
    const history = useHistory();

    return (
        <Card className="text-dark">
            <Card.Body>
                <p>{I18n.t("forms.two_factor_authentication.steps.intro.description")}</p>
                <h3>{I18n.t("forms.two_factor_authentication.steps.intro.methods.app.title")}</h3>
                <p>{I18n.t("forms.two_factor_authentication.steps.intro.methods.app.body")}</p>
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="outline-secondary"
                    className="m-1"
                    onClick={() => history.push(`/users/${currentActor.uid}/edit`)}
                >
                    {I18n.t("common.links.cancel")}
                </Button>
                <Button
                    variant="primary"
                    className="text-light m-1"
                    onClick={() => setCurrentStep((prevState) => prevState + 1)}
                >
                    {I18n.t("common.links.continue")}
                </Button>
            </Card.Footer>
        </Card>
    );
};

IntroStep.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
};

export default IntroStep;
