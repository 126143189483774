import React from "react";
import PropTypes from "prop-types";
import _forEach from "lodash/forEach";
import I18n from "../../../../utils/i18n";
import FirmwideMirrorChart from "./FirmwideMirrorChart";

const EmployeesHiredChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        type="employees_hired"
        chartType="bar"
        position={position}
        chartOptions={{
            ...chartOptions,
            legend: {
                onClick: function (event, legendItem) {
                    let chart = this.chart;

                    _forEach(this.chart.data.datasets, (dataset) => {
                        if (dataset.label === legendItem.text) {
                            let meta = dataset._meta[chart.id];
                            meta.hidden = meta.hidden === null ? !dataset.hidden : null;
                        }
                    });

                    chart.update();
                },
                labels: {
                    filter: (legendItem, chartData) => chartData.datasets[legendItem.datasetIndex].type === "bar",
                },
            },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.employees_hired.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: "combined",
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.employees_hired.y_axis"),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(2)
                            .toLocaleString(I18n.locale)} %`,
                },
            },
        }}
    />
);

EmployeesHiredChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default EmployeesHiredChart;
