import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import Table from "../../../../common/components/Table";
import StatusCell from "./components/StatusCell";

const ActiveTable = ({ isFetching, fetchData, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("home.negotiations.columns.organization_name"),
                accessor: "organization_name",
            },
            {
                Header: I18n.t("home.negotiations.columns.project_name"),
                accessor: "name",
            },
            {
                Header: I18n.t("home.negotiations.columns.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("home.negotiations.columns.due"),
                accessor: "response_due",
                Cell: DateCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: ButtonCell,
            },
        ],
        [],
    );

    return (
        <>
            {_isEmpty(data) && <div className="pt-4 px-4 pb-4">{I18n.t("home.negotiations.no_data")}</div>}
            <Table
                loading={isFetching}
                data={data || []}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "response_due", desc: true }]}
                manualSortBy
            />
        </>
    );
};

const DateCell = ({
    row: {
        original: { response_due },
    },
}) => <div className="d-flex flex-column">{I18n.l("time.formats.short", response_due)}</div>;

const ButtonCell = ({
    row: {
        original: { review_path, scoring, score_path },
    },
}) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const baseUrl = `//${currentWhitelabel.hosts.bid}`;

    return (
        <>
            {review_path && !scoring && (
                <Button variant="outline-action" href={`${baseUrl}${review_path}`} className="mr-2">
                    {I18n.t("home.negotiations.actions.review_button")}
                </Button>
            )}
            {score_path && scoring && (
                <Button variant="outline-action" href={`${baseUrl}${score_path}`}>
                    {I18n.t("home.negotiations.actions.score_button")}
                </Button>
            )}
        </>
    );
};

DateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            response_due: PropTypes.string,
        }),
    }),
};

ButtonCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            score_path: PropTypes.string,
            review_path: PropTypes.string,
            scoring: PropTypes.bool,
        }),
    }),
};

ActiveTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ActiveTable;
