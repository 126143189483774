import React from "react";
import PropTypes from "prop-types";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const ScoreByPracticeAreaChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="score_by_practice_area"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            tooltips: {
                callbacks: {
                    title: () => "",
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(1)
                            .toLocaleString(I18n.locale)}`,
                },
            },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        }}
    />
);

ScoreByPracticeAreaChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default ScoreByPracticeAreaChart;
