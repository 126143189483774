import React from "react";
import { Accordion, Button, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";

const AccordionTitle = ({ title, eventKey, activeId, toggleActive, actions, children }) => (
    <Card.Header
        className={classNames("p-0", "bg-white", "border-0", "rotate-arrow", {
            collapsed: activeId !== eventKey,
        })}
    >
        <Row>
            <Col className="d-flex">
                <Accordion.Toggle
                    as={Button}
                    variant="transparent"
                    eventKey={eventKey}
                    className="w-100 text-left text-body align-baseline shadow-none pb-0"
                    onClick={() => (activeId === eventKey ? toggleActive("none") : toggleActive(eventKey))}
                >
                    <h3 className="m-0 font-weight-normal">
                        <FontAwesomeIcon icon={faChevronDown} className="mr-3 align-baseline arrow" size="xs" />
                        {title}
                        {children}
                    </h3>
                </Accordion.Toggle>
            </Col>
            {actions && <Col className="col-auto justify-content-end mr-3">{actions}</Col>}
        </Row>
        <hr className="mr-3 ml-3" />
    </Card.Header>
);

AccordionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    eventKey: PropTypes.string.isRequired,
    activeId: PropTypes.string.isRequired,
    toggleActive: PropTypes.func.isRequired,
    actions: PropTypes.node,
    children: PropTypes.node,
};

export default AccordionTitle;
