import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Container, Collapse } from "react-bootstrap";
import { getIn } from "formik";
import I18n from "../../../../utils/i18n";
import TimezoneSelect from "../inputs/TimezoneSelect";
import LocaleSelect from "../inputs/LocaleSelect";
import FormRow from "../../../../common/components/FormRow";
import { ShowPassword } from "../../../../common/components";
import TwoFactorAuthenticationSettings from "./TwoFactorAuthenticationSettings";

const AccountSettings = ({ form, enablePasswordEditing, ssoEnabled }) => {
    const [passwordsVisible, setPasswordsVisibility] = useState(false);

    return (
        <Container fluid className="mt-3 pt-2">
            <FormRow
                label={I18n.t("activerecord.attributes.user.locale")}
                error={
                    getIn(form.touched, "actor.user_attributes.locale") &&
                    getIn(form.errors, "actor.user_attributes.locale")
                }
            >
                <LocaleSelect form={form} />
            </FormRow>
            <FormRow
                label={I18n.t("activerecord.attributes.user.time_zone")}
                error={
                    getIn(form.touched, "actor.user_attributes.time_zone") &&
                    getIn(form.errors, "actor.user_attributes.time_zone")
                }
            >
                <TimezoneSelect form={form} />
            </FormRow>
            {ssoEnabled ? (
                <FormRow>{I18n.t("forms.edit_profile.account_settings.sso_enabled")}</FormRow>
            ) : (
                <>
                    {enablePasswordEditing && (
                        <Form.Group>
                            <Form.Check
                                custom
                                type="checkbox"
                                label={I18n.t("activerecord.attributes.user.change_password")}
                                id="change-password-checkbox"
                                className="mb-3"
                                onChange={() => {
                                    setPasswordsVisibility(!passwordsVisible);
                                    form.setFieldValue("actor[user_attributes][current_password]", null);
                                    form.setFieldValue("actor[user_attributes][password]", null);
                                    form.setFieldValue("actor[user_attributes][password_confirmation]", null);
                                }}
                            />
                            <Collapse in={passwordsVisible}>
                                <div>
                                    <FormRow label={I18n.t("activerecord.attributes.user.current_password")}>
                                        <ShowPassword
                                            name="actor[user_attributes][current_password]"
                                            onChange={form.handleChange}
                                            value={getIn(form.values, "actor[user_attributes][current_password]") || ""}
                                            disabled={!passwordsVisible}
                                            isInvalid={
                                                getIn(form.errors, ["user.current_password"]) ||
                                                getIn(form.errors, "actor.user_attributes.current_password")
                                            }
                                        />
                                    </FormRow>
                                    <FormRow label={I18n.t("activerecord.attributes.user.password")}>
                                        <ShowPassword
                                            name="actor[user_attributes][password]"
                                            onChange={form.handleChange}
                                            value={getIn(form.values, "actor[user_attributes][password]") || ""}
                                            disabled={!passwordsVisible}
                                            isInvalid={
                                                getIn(form.errors, ["user.password"]) ||
                                                getIn(form.errors, "actor.user_attributes.password")
                                            }
                                        />
                                    </FormRow>
                                    <FormRow label={I18n.t("activerecord.attributes.user.password_confirmation")}>
                                        <ShowPassword
                                            name="actor[user_attributes][password_confirmation]"
                                            onChange={form.handleChange}
                                            value={
                                                getIn(form.values, "actor[user_attributes][password_confirmation]") ||
                                                ""
                                            }
                                            disabled={!passwordsVisible}
                                            isInvalid={
                                                getIn(form.errors, ["user.password_confirmation"]) ||
                                                getIn(form.errors, "actor.user_attributes.password_confirmation")
                                            }
                                        />
                                    </FormRow>
                                </div>
                            </Collapse>
                        </Form.Group>
                    )}
                    <TwoFactorAuthenticationSettings
                        otpRequiredForLogin={getIn(form.values, "actor[user_attributes][otp_required_for_login]")}
                        setFieldValue={form.setFieldValue}
                        enableEditing={enablePasswordEditing}
                    />
                </>
            )}
        </Container>
    );
};

AccountSettings.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        errors: PropTypes.object,
        touched: PropTypes.object.isRequired,
    }),
    enablePasswordEditing: PropTypes.bool.isRequired,
    ssoEnabled: PropTypes.bool.isRequired,
};

export default AccountSettings;
