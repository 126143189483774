import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, FormLabel, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import classNames from "classnames";
import { DatePicker } from "react-tempusdominus-bootstrap";
import moment from "moment/moment";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import _isNull from "lodash/isNull";
import I18n from "../../utils/i18n";
import { setAlert } from "../../state/alert/alertActions";
import FormRow from "../../common/components/FormRow";
import CertificateOriginContainer from "../../common/components/CertificateOriginContainer";
import { getCertificateTypesForSelect } from "../../api/diversityApi";
import { editPartnershipCertificate, updatePartnershipCertificate } from "../../api/tieredDiversityApi";
import CertificateTypeRequestModal from "../../common/components/CertificateTypeRequestModal";
import Loader from "../HomePage/components/common/Loader";
import NewPageTitle from "../../common/components/NewPageTitle";

const DATE_FORMAT = "DD-MMM-YYYY";
const VALUE_DATE_FORMAT = "YYYY-MM-DD";

const EditTieredDiversityCertificatePage = () => {
    const { partnership_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [initialValues, setInitialValues] = useState({});
    const [certificateTypes, setCertificateTypes] = useState([]);
    const [showCertificateTypeRequestModal, setShowCertificateTypeRequestModal] = useState(false);
    const [certificatePersisted, setCertificatePersisted] = useState(false);
    const [originDownloadFilename, setOriginDownloadFilename] = useState(null);

    useEffect(() => {
        editPartnershipCertificate(partnership_id)
            .then((response) => {
                setCertificatePersisted(!_isNull(response.data.id));
                setOriginDownloadFilename(response.data.origin_download_filename);
                setInitialValues({ tiered_diversity_certificate: response.data });
                getCertificateTypesForSelect().then((response) => setCertificateTypes(response.data));
            })
            .catch(() => history.push("/"));
    }, []);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        updatePartnershipCertificate(partnership_id, values)
            .then(() => history.push("/tiered_diversity/dashboard"))
            .catch((error) => {
                setSubmitting(false);
                setErrors({ tiered_diversity_certificate: error.response.data });
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
            });
    };

    const validationSchema = Yup.object().shape({
        tiered_diversity_certificate: Yup.object().shape({
            diversity_certificate_type_id: Yup.number().required(),
            origin: Yup.mixed().required(I18n.t("errors.messages.diversity/certificate.origin_missed")),
            expiration_date: Yup.date()
                .min(
                    moment(),
                    I18n.t("activerecord.errors.models.diversity/certificate.attributes.expiration_date.after_today"),
                )
                .required(),
        }),
    });

    const originPreview = () =>
        certificatePersisted ? `/tiered_diversity/partnerships/${partnership_id}/certificate.pdf` : null;

    if (isEmpty(initialValues) || isEmpty(certificateTypes)) {
        return <Loader />;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting }) => (
                <>
                    <div className="top-bar" />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <NewPageTitle resource={I18n.t("activerecord.models.diversity/certificate")} />

                                    <Container fluid className="mt-3 pt-2">
                                        <Row>
                                            <Col md={3}>
                                                <FormRow
                                                    label={I18n.t(
                                                        "activerecord.attributes.diversity/certificate.origin",
                                                    )}
                                                    error={errors.tiered_diversity_certificate?.origin}
                                                >
                                                    <CertificateOriginContainer
                                                        inputName="tiered_diversity_certificate[origin]"
                                                        accept={["application/pdf"]}
                                                        previewUrl={originPreview()}
                                                        fileName={originDownloadFilename}
                                                        classNamePostfix="rectangle"
                                                    />
                                                </FormRow>
                                                <FormLabel>
                                                    {I18n.t("forms.certify_text", {
                                                        whitelabel_name: currentWhitelabel.name,
                                                    })}
                                                </FormLabel>
                                            </Col>
                                            <Col md={9}>
                                                <FormRow
                                                    label={I18n.t("activerecord.attributes.diversity/certificate.name")}
                                                    error={
                                                        errors.tiered_diversity_certificate
                                                            ?.diversity_certificate_type_id
                                                    }
                                                >
                                                    <Select
                                                        name="diversity_certificate[diversity_certificate_type_id]"
                                                        className={classNames("react-select", {
                                                            "is-invalid":
                                                                errors.tiered_diversity_certificate
                                                                    ?.diversity_certificate_type_id,
                                                        })}
                                                        placeholder={I18n.t(
                                                            "common.placeholders.select_certificate_type",
                                                        )}
                                                        classNamePrefix="react-select"
                                                        options={certificateTypes}
                                                        onChange={(option) =>
                                                            setFieldValue(
                                                                "tiered_diversity_certificate[diversity_certificate_type_id]",
                                                                option.id,
                                                            )
                                                        }
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        value={
                                                            values.tiered_diversity_certificate
                                                                .diversity_certificate_type_id &&
                                                            certificateTypes.find(
                                                                (option) =>
                                                                    option.id ===
                                                                    values.tiered_diversity_certificate
                                                                        .diversity_certificate_type_id,
                                                            )
                                                        }
                                                    />
                                                </FormRow>
                                                <FormRow>
                                                    <CertificateTypeRequestModal
                                                        setShowModal={setShowCertificateTypeRequestModal}
                                                        show={showCertificateTypeRequestModal}
                                                        onHide={() => setShowCertificateTypeRequestModal(false)}
                                                    />

                                                    <Link
                                                        to="#"
                                                        onClick={() => setShowCertificateTypeRequestModal(true)}
                                                        className="pull-right"
                                                    >
                                                        {I18n.t("forms.add_certificate_type_request")}
                                                    </Link>
                                                </FormRow>
                                                <FormRow
                                                    label={I18n.t(
                                                        "activerecord.attributes.diversity/certificate.expiration_date",
                                                    )}
                                                    error={errors.tiered_diversity_certificate?.expiration_date}
                                                >
                                                    <DatePicker
                                                        format={DATE_FORMAT}
                                                        useCurrent={false}
                                                        minDate={moment()}
                                                        onChange={(event) =>
                                                            setFieldValue(
                                                                "tiered_diversity_certificate[expiration_date]",
                                                                event.date.format(VALUE_DATE_FORMAT),
                                                            )
                                                        }
                                                        date={moment(
                                                            values.tiered_diversity_certificate.expiration_date,
                                                            VALUE_DATE_FORMAT,
                                                        )}
                                                        className={classNames("flex-grow-1", {
                                                            "is-invalid":
                                                                errors.tiered_diversity_certificate?.expiration_date,
                                                        })}
                                                    />
                                                </FormRow>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                type="button"
                                                className="ml-2"
                                                variant="outline-secondary"
                                                disabled={isSubmitting}
                                                onClick={handleReset}
                                            >
                                                {I18n.t("common.links.cancel")}
                                            </Button>
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default EditTieredDiversityCertificatePage;
