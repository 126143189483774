import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import _each from "lodash/each";
import { useFormik } from "formik";
import Select from "react-select";
import _find from "lodash/find";
import _map from "lodash/map";
import _values from "lodash/values";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import { createNegotiation, updateNegotiation } from "../../../../api/listsApi";
import i18n from "../../../../utils/i18n";
import { getPreview } from "../../../../api/listsApi";
import LoadingBody from "../../../../common/components/Table/LoadingBody";

const ValueCell = (props) => (
    <td role="cell" className="align-middle">
        <div className="d-flex align-items-center justify-content-center">{props.children}</div>
    </td>
);

ValueCell.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

const CompanyRow = ({ company, setFieldValue }) => {
    const [actor, setActor] = useState(company.actors[0]);

    const getField = (field) => (actor ? actor[field] : I18n.t("companies.lists.sections.export.common.not_available"));

    return (
        <tr role="row">
            <ValueCell>{company.name}</ValueCell>
            <ValueCell>{getField("first_name")}</ValueCell>
            <ValueCell>{getField("last_name")}</ValueCell>
            <ValueCell>
                {company.actors.length > 1 ? (
                    <Select
                        className="react-select w-100"
                        classNamePrefix="react-select"
                        onChange={(option) => {
                            setFieldValue(`organizations.${company.uid}`, {
                                email: option.email,
                                company_name: company.name,
                            });
                            setActor(_find(company.actors, { email: option.email }));
                        }}
                        options={company.actors}
                        getOptionLabel={(option) => option.email}
                        getOptionValue={(option) => option.email}
                        defaultValue={company.actors[0]}
                        menuPosition="fixed"
                    />
                ) : (
                    getField("email")
                )}
            </ValueCell>
        </tr>
    );
};

CompanyRow.propTypes = {
    company: PropTypes.shape({
        actors: PropTypes.array,
        name: PropTypes.string,
        uid: PropTypes.string,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

const TableHead = ({ fieldName, className = "" }) => (
    <th className={className}>
        <div className="text-uppercase d-flex align-items-center justify-content-center">{fieldName}</div>
    </th>
);

TableHead.propTypes = {
    fieldName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const ParticipantsStep = () => {
    const { setCurrentStep, onHide, negotiationParams, exportCompanies, eventType } =
        useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const prevStepIndex = 1;
    const [creating, setCreating] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);

        getPreview({ organizations: exportCompanies }).then(({ data }) => {
            setData(data);
            _each(data, (organization) =>
                formik.setFieldValue(`organizations.${organization.uid}`, {
                    email: organization.actors[0]?.email,
                    company_name: organization.name,
                }),
            );
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const proceedHandler = useCallback((values) => {
        setCreating(true);
        const actors = _values(values.organizations);

        const wizard_url = negotiationParams.industry_uid ? "?wizard=true" : "";

        const companiesExport = negotiationParams.negotiation_id
            ? updateNegotiation(negotiationParams.negotiation_id, { actors: actors })
            : createNegotiation({ type: eventType, actors: actors, ...negotiationParams });

        companiesExport
            .then(
                ({ data: { link } }) =>
                    (window.location = `//${currentWhitelabel.hosts.bid}${link}/general_settings${wizard_url}`),
            )
            .catch(() => setCreating(false));
    }, []);

    const formik = useFormik({ initialValues: {}, onSubmit: proceedHandler });

    return (
        <>
            <Modal.Header className="border-0 pb-1">
                <Modal.Title className="mb-3">
                    <Link className="text-dark" to="#" onClick={() => setCurrentStep(prevStepIndex)}>
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                        {I18n.t("home.create_event_wizard.buttons.back")}
                    </Link>
                    <div className="mt-4 mb-2 text-2xl">
                        {I18n.t("companies.lists.sections.export.export_to_new.title")}
                    </div>
                    <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                        {i18n.t("companies.lists.sections.export.export_to_new.preview_description")}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb-4">
                <div className="w-100 p-3 mb-3 jumbotron">
                    {I18n.t("companies.lists.sections.export.export_to_new.warning")}
                </div>

                <div className="table-responsive max-height-500">
                    <div className="table-wrapper h-100 w-100">
                        <table role="table" className="participants-table table table-borderless table-hover">
                            <thead className="border-bottom position-sticky top-0 z-index-fixed">
                                <tr role="row">
                                    <TableHead fieldName={I18n.t("activerecord.attributes.organization.name")} />
                                    <TableHead fieldName={I18n.t("activerecord.attributes.user.first_name")} />
                                    <TableHead fieldName={I18n.t("activerecord.attributes.user.last_name")} />
                                    <TableHead
                                        className="w-50"
                                        fieldName={I18n.t("activerecord.attributes.user.email")}
                                    />
                                </tr>
                            </thead>
                            <tbody role="rowgroup" className="border-bottom">
                                {loading ? (
                                    <LoadingBody columnCount={4} />
                                ) : (
                                    _map(data, (company) => (
                                        <CompanyRow
                                            key={company.name}
                                            company={company}
                                            setFieldValue={formik.setFieldValue}
                                        />
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className="border-0 d-flex justify-content-between">
                <Button variant="outline-secondary" onClick={onHide}>
                    {I18n.t("home.create_event_wizard.buttons.cancel")}
                </Button>
                <Button variant="primary" className="text-white" onClick={() => proceedHandler(formik.values)}>
                    <Spinner animation="border" size="sm" className="mr-2" hidden={!creating} />
                    {I18n.t("home.create_event_wizard.buttons.proceed")}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ParticipantsStep;
