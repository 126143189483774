import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificProgressChart from "./DiversityClientSpecificProgressChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const TotalCountByFirmChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificProgressChart
        inProgress={inProgress}
        chartData={chartData}
        canvasRefs={canvasRefs}
        type="total_count_by_firm"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataLabel = data.datasets[tooltipItem.datasetIndex].label;
                        let value = _round(
                            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                        ).toLocaleString(I18n.locale);
                        return `${dataLabel}: ${value}`;
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific_progress.total_count_by_firm.y_axis"),
                        },
                    },
                ],
            },
        }}
    />
);

TotalCountByFirmChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default TotalCountByFirmChart;
