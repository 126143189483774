import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import TableComponent from "./TableComponent";
import ChartComponent from "./ChartComponent";
import TextComponent from "./TextComponent";
import I18n from "../../../utils/i18n";

const DiversityClientSpecificChart = ({
    canvasRefs,
    type,
    scope = "client_specific",
    chartType,
    chartData,
    chartOptions,
    chartUseDataLabels = false,
    chartUseTooltips = false,
    inProgress = true,
    useCss = false,
    position = 1,
}) => {
    if (chartType === "table") {
        return (
            <>
                {inProgress && <Spinner animation="border" role="status" className="overlay-loader" />}
                <TableComponent
                    title={I18n.t(`diversity.charts.${scope}.${type}`)}
                    data={chartData || { datasets: [], labels: [] }}
                    canvasRefs={canvasRefs}
                    useCss={useCss}
                    position={position}
                />
            </>
        );
    }

    if (chartType === "text") {
        return (
            <TextComponent
                title={I18n.t(`diversity.charts.${scope}.${type}`, chartData)}
                type={type}
                canvasRefs={canvasRefs}
                chartData={chartData}
                inProgress={inProgress}
                position={position}
            />
        );
    }

    return (
        <>
            {inProgress && <Spinner animation="border" role="status" className="overlay-loader" />}
            <ChartComponent
                title={I18n.t(`diversity.charts.${scope}.${type}.title`, {
                    defaultValue: I18n.t(`diversity.charts.${scope}.${type}`),
                })}
                subtitle={I18n.t(`diversity.charts.${scope}.${type}.subtitle`, { defaultValue: "" })}
                tooltip={I18n.t(`diversity.tooltips.${scope}.${type}`)}
                type={chartType}
                data={chartData || { datasets: [], labels: [] }}
                options={chartOptions || {}}
                useDataLabels={chartUseDataLabels}
                useTooltips={chartUseTooltips}
                canvasRefs={canvasRefs}
                chartData={chartData}
                inProgress={inProgress}
                position={position}
            />
        </>
    );
};

DiversityClientSpecificChart.propTypes = {
    type: PropTypes.string.isRequired,
    scope: PropTypes.string,
    chartType: PropTypes.string.isRequired,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    chartUseDataLabels: PropTypes.bool,
    chartUseTooltips: PropTypes.bool,
    canvasRefs: PropTypes.object,
    inProgress: PropTypes.bool,
    useCss: PropTypes.bool,
    position: PropTypes.number,
};

export default DiversityClientSpecificChart;
