import { combineReducers } from "redux";
import currentUserReducer from "./currentUser/currentUserReducer";
import currentActorReducer from "./currentActor/currentActorReducer";
import currentWhitelabelReducer from "./currentWhitelabel/currentWhitelabelReducer";
import alertReducer from "./alert/alertReducer";

const rootReducer = combineReducers({
    currentUser: currentUserReducer,
    currentActor: currentActorReducer,
    currentWhitelabel: currentWhitelabelReducer,
    alert: alertReducer,
});

export default rootReducer;
