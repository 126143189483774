import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import I18n from "../../utils/i18n";

const CopyrightContainer = ({ currentWhitelabel }) => (
    <div className="copyright-container d-flex flex-column align-items-center p-3 position-sticky">
        {currentWhitelabel.resource.banner && (
            <img
                src={currentWhitelabel.resource.banner}
                alt={currentWhitelabel.resource.name}
                className="logo text-light"
            />
        )}

        <p className="h6 text-light text-center pt-3">
            {I18n.t(`footer.${currentWhitelabel.resource.key}.company`, { year: moment().year() })}
        </p>
        <p className="h6 text-light">{I18n.t(`footer.all_rights_reserved`)}</p>
    </div>
);

const mapStateToProps = (state) => ({
    currentWhitelabel: state.currentWhitelabel,
});

CopyrightContainer.propTypes = {
    currentWhitelabel: PropTypes.shape({
        fetching: PropTypes.bool.isRequired,
        resource: PropTypes.object,
    }),
};

export default connect(mapStateToProps)(CopyrightContainer);
