import React from "react";
import { Button, Card, Row, Col, Figure } from "react-bootstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _chunk from "lodash/chunk";
import _kebabCase from "lodash/kebabCase";
import { saveAs } from "file-saver";
import I18n from "../../utils/i18n";

const RecoveryStep = ({ setCurrentStep, otpBackupCodes }) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);

    const downloadOtpBackupCodes = () => {
        let blob = new Blob(
            otpBackupCodes.map((otpBackupCode) => `${otpBackupCode}\r\n`),
            { type: "text/plain;charset=utf-8" },
        );
        saveAs(blob, `${_kebabCase(currentWhitelabel.name)}-recovery-codes.txt`);
    };

    return (
        <Card className="text-dark">
            <Card.Body>
                <Figure className="highlight">
                    <Row className="p-3">
                        {_chunk(otpBackupCodes, otpBackupCodes.length / 2).map((otpBackupCodeChunk, chunkIndex) => (
                            <Col key={chunkIndex}>
                                {otpBackupCodeChunk.map((otpBackupCode, index) => (
                                    <h2 key={index}>{otpBackupCode}</h2>
                                ))}
                            </Col>
                        ))}
                    </Row>
                </Figure>

                <Row>
                    <Col>
                        <Button variant="primary" className="text-light mb-3" onClick={downloadOtpBackupCodes}>
                            {I18n.t("common.links.download")}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{I18n.t("forms.two_factor_authentication.steps.recovery.description")}</p>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="primary"
                    className="text-light m-1"
                    onClick={() => setCurrentStep((prevState) => prevState + 1)}
                >
                    {I18n.t("common.links.continue")}
                </Button>
            </Card.Footer>
        </Card>
    );
};

RecoveryStep.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
    otpBackupCodes: PropTypes.array.isRequired,
};

export default RecoveryStep;
