import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityFirmwideChart from "./DiversityFirmwideChart";

const LeadershipDiversityChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityFirmwideChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="leadership_diversity"
        chartType="bubble"
        chartOptions={{
            ...chartOptions,
            tooltips: {
                callbacks: {
                    label: (item, data) => {
                        const datasetLabel = data.datasets[item.datasetIndex].label || "";
                        const dataPoint = data.datasets[item.datasetIndex].data[item.index];
                        return `${datasetLabel} - ${dataPoint.firm_name}: (${item.xLabel}, ${item.yLabel}, ${dataPoint.r_value})`;
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.leadership_diversity.x_axis"),
                        },
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide.leadership_diversity.y_axis"),
                        },
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                    },
                ],
            },
        }}
        chartUseDataLabels={true}
    />
);

LeadershipDiversityChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default LeadershipDiversityChart;
