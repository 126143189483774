import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import Table from "../../../../common/components/Table";

const DraftTable = ({ isFetching, fetchData, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("home.negotiations.columns.organization_name"),
                id: "organization_name",
                isSorted: false,
                Cell: ({ row: { original } }) => original.organization_name,
            },
            {
                Header: I18n.t("home.negotiations.columns.project_name"),
                id: "name",
                isSorted: false,
                Cell: ({ row: { original } }) => original.name,
            },
            {
                Header: I18n.t("home.negotiations.columns.created_at"),
                id: "created_at",
                isSorted: false,
                Cell: ({ row: { original } }) => I18n.l("time.formats.short", original.created_at),
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: ButtonCell,
            },
        ],
        [],
    );

    return (
        <>
            {_isEmpty(data) && <div className="pt-4 px-4 pb-4">{I18n.t("home.negotiations.no_data")}</div>}
            <Table
                loading={isFetching}
                data={data || []}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "created_at", desc: true }]}
                manualSortBy
            />
        </>
    );
};

const ButtonCell = ({
    row: {
        original: { id },
    },
}) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);

    return (
        <Button variant="outline-action" href={`//${currentWhitelabel.hosts.bid}/setup/${id}`} className="mr-2">
            {I18n.t("home.negotiations.actions.edit_button")}
        </Button>
    );
};

ButtonCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }),
};

DraftTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DraftTable;
