import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { Prompt, useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";

const NavigationPrompt = ({ when, title, message }) => {
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const history = useHistory();

    const handleClose = useCallback(() => setShowPrompt(false), []);

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (!confirmedNavigation) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        },
        [confirmedNavigation]
    );

    const handleConfirmNavigationClick = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal show={showPrompt} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-uppercase">
                        {title || I18n.t("common.alerts.unsaved_data.title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{message || I18n.t("common.alerts.unsaved_data.message")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className="text-uppercase" onClick={handleClose}>
                        {I18n.t("common.links.text_no")}
                    </Button>
                    <Button
                        variant="primary"
                        className="text-white"
                        onClick={handleConfirmNavigationClick}
                    >
                        {I18n.t("common.links.text_yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

NavigationPrompt.propTypes = {
    when: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default NavigationPrompt;
