import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import I18n from "../../../utils/i18n";

const PublicDomainsActions = ({ isSubmitting, handleSubmit }) => (
        <div className="form-actions">
            <Button
                className="ml-2 text-white"
                variant="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
            >
                {isSubmitting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
                )}
                {I18n.t("common.links.save")}
            </Button>
        </div>
    );

PublicDomainsActions.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default PublicDomainsActions;
