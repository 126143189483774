import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { Table as BootstrapTable } from "react-bootstrap";
import { useTable, usePagination, useSortBy, useExpanded, useRowSelect } from "react-table";
import _map from "lodash/map";
import _isString from "lodash/isString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import LoadingBody from "../../../../common/components/Table/LoadingBody";

const Table = ({ columns, data, fetchData, loading, pageCount, perPage }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        state: { pageIndex, sortBy },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: pageCount,
            disableMultiSort: true,
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    useEffect(() => {
        fetchData({ pageIndex, sortBy });
    }, [fetchData, sortBy, pageIndex]);

    useEffect(() => gotoPage(0), [perPage]);

    return (
        <>
            <div className="table-wrapper create-wizard-negotiations-grid overflow-auto">
                <BootstrapTable borderless hover {...getTableProps()} className="text-nowrap m-0">
                    <thead className="border-bottom">
                        {_map(headerGroups, (headerGroup, headerGroupIndex) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                                {_map(headerGroup.headers, (column, headerColumnIndex) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={headerColumnIndex}
                                        className="overflow-visible"
                                    >
                                        <div
                                            {...column.getSortByToggleProps()}
                                            className={classNames("text-uppercase d-flex align-items-center mb-2", {
                                                "justify-content-end": column.type === "number",
                                                "justify-content-between": column.type !== "number",
                                            })}
                                        >
                                            {column.render("Header")}
                                            <span className="ml-2">
                                                {column.isSorted && (
                                                    <FontAwesomeIcon
                                                        icon={column.isSortedDesc ? faSortDown : faSortUp}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="border-bottom">
                        {loading ? (
                            <LoadingBody rowCount={3} columnCount={columns.length} />
                        ) : (
                            _map(page, (row, pageIndex) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={pageIndex}>
                                        <tr {...row.getRowProps()} className="table-light" id={row.original.id}>
                                            {_map(row.cells, (cell, cellId) => (
                                                <Fragment key={cellId}>
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={cellId}
                                                        className="align-middle"
                                                        title={_isString(cell.value) ? cell.value : ""}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                </Fragment>
                                            ))}
                                        </tr>
                                    </Fragment>
                                );
                            })
                        )}
                    </tbody>
                </BootstrapTable>
            </div>
            {data.length >= 1 && (
                <div className="pagination justify-content-center mt-3">
                    <ReactPaginate
                        previousLabel="«"
                        nextLabel="»"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={({ selected }) => gotoPage(selected)}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="active"
                        forcePage={pageIndex}
                    />
                </div>
            )}
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    fetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
};

export default Table;
