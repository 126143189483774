import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const TieredDiversityRoute = ({ children, renderer, ...rest }) => {
    const currentActor = useSelector((state) => state.currentActor.resource);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (currentActor.tiered_diversity_customer.show_starter_page) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/tiered_diversity/starter",
                                state: { from: location },
                            }}
                        />
                    );
                } else {
                    return (
                        <PrivateRoute renderer={renderer} {...rest}>
                            {children}
                        </PrivateRoute>
                    );
                }
            }}
        />
    );
};

TieredDiversityRoute.propTypes = {
    children: PropTypes.node,
    renderer: PropTypes.func,
};

export default TieredDiversityRoute;
