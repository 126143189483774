import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Card, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import I18n from "../../../utils/i18n";
import { getLatestQuarterRange } from "../../../utils/diversityQuarters";
import AccordionTitle from "../../../common/components/AccordionTitle";
import Table from "../../../common/components/Table";
import { getClientSpecificPartnerships, updatePartnershipStats, createNoWork } from "../../../api/diversityApi";
import DiversityClientSpecificUploadForm from "./DiversityClientSpecificUploadForm";
import CommentPopover from "../../../common/components/DiversityPage/CommentPopover";

const ClientNameCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.client_name}</div>;

const SubmitCell = ({ row: { original }, firm, updateData }) => (
    <div className="d-flex flex-column">
        <Form.Check
            type="switch"
            id={`client-specific-submitted-switch-${original.stats.id}`}
            checked={original.stats?.submitted}
            disabled={
                (original.client_information_count === 0 && !original.stats?.submitted && !original.stats?.no_work) ||
                !firm.submitted
            }
            onChange={(event) => {
                updatePartnershipStats(original.stats.id, {
                    submitted_at: event.target.checked ? moment() : null,
                }).then((response) => updateData(original.id, { stats: response.data }));
            }}
        />
    </div>
);

const NoWorkCell = ({ row: { original }, firmSubmitted, updateData }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    return (
        <div className="d-flex flex-column">
            <Form.Check
                custom
                id={`no-work-cell-${original.id}`}
                type="checkbox"
                checked={original.stats?.no_work}
                disabled={isUpdating || original.stats?.submitted || !firmSubmitted}
                onChange={(event) => {
                    setIsUpdating(true);
                    createNoWork({ no_work: event.target.checked, partnership_id: original.id })
                        .then((response) => updateData(original.id, { stats: response.data }))
                        .finally(() => setIsUpdating(false));
                }}
            />
        </div>
    );
};

const FileCell = ({ row: { original }, firm, setFirm, updateData }) => {
    const [showFormModal, setShowFormModal] = useState(false);

    return (
        <div className="d-flex align-items-start">
            <FontAwesomeIcon icon={faUpload} className="file-upload" size="lg" onClick={() => setShowFormModal(true)} />
            {original?.comment?.text && (
                <CommentPopover comment={original.comment.text} requesterName={original.comment.author} />
            )}
            <Modal
                className="client-specific-upload-form"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showFormModal}
                onHide={() => setShowFormModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                        {I18n.t(`diversity.client_specific_upload.form.title`, { client_name: original.client_name })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DiversityClientSpecificUploadForm
                        firm={firm}
                        setFirm={setFirm}
                        partnership={original}
                        setPartnership={updateData}
                        setShowFormModal={setShowFormModal}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

const DeadlineCell = ({ row: { original } }) =>
    I18n.l("time.formats.short", original.deadline_at) || I18n.t("common.not_available");

const DiversityClientSpecificUploadRequests = ({ firm, setFirm, activeId, setActiveId }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("diversity.client_specific_upload.requests.columns.client_name"),
                accessor: "client_name",
                Cell: ClientNameCell,
            },
            {
                Header: I18n.t("diversity.client_specific_upload.requests.columns.upload"),
                isSorted: false,
                Cell: (props) => <FileCell {...props} firm={firm} setFirm={setFirm} updateData={updateData} />,
            },
            {
                Header: I18n.t("diversity.client_specific_upload.requests.columns.due_by"),
                isSorted: false,
                Cell: DeadlineCell,
            },
            {
                Header: I18n.t("diversity.client_specific_upload.requests.columns.submit"),
                accessor: "submitted",
                Cell: (props) => <SubmitCell {...props} firm={firm} updateData={updateData} />,
            },
            {
                Header: I18n.t("diversity.client_specific_upload.requests.columns.no_work"),
                accessor: "no_work",
                Cell: (props) => <NoWorkCell {...props} firmSubmitted={firm.submitted} updateData={updateData} />,
            },
        ],
        [],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "client_name", desc: true };
        setLoading(true);

        getClientSpecificPartnerships(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);

    const updateData = (rowId, newRow) => {
        setData((oldRows) =>
            oldRows.map((oldRow) => {
                if (oldRow.id === rowId) {
                    return { ...oldRow, ...newRow };
                }
                return oldRow;
            }),
        );
    };

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                title={I18n.t("diversity.client_specific_upload.requests.title")}
                eventKey="requests"
                activeId={activeId}
                toggleActive={setActiveId}
            />
            <Accordion.Collapse eventKey="requests">
                <Card.Body>
                    <h2>{getLatestQuarterRange()}</h2>
                    <Table
                        loading={loading}
                        data={data}
                        fetchData={fetchData}
                        columns={columns}
                        pageCount={pageCount}
                        initialSortBy={[{ id: "client_name", desc: true }]}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

DeadlineCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            deadline_at: PropTypes.string,
        }),
    }),
};

ClientNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            client_name: PropTypes.string.isRequired,
        }),
    }),
};

FileCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            client_name: PropTypes.string.isRequired,
            comment: PropTypes.string,
        }),
    }),
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    setFirm: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
};

SubmitCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            stats: PropTypes.shape({
                id: PropTypes.number,
                no_work: PropTypes.bool,
                submitted: PropTypes.bool.isRequired,
            }),
            client_information_count: PropTypes.number.isRequired,
        }),
    }),
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
    }),
    updateData: PropTypes.func.isRequired,
};

NoWorkCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            stats: PropTypes.shape({
                id: PropTypes.number,
                no_work: PropTypes.bool,
                submitted: PropTypes.bool.isRequired,
            }),
        }),
    }),
    firmSubmitted: PropTypes.bool.isRequired,
    updateData: PropTypes.func.isRequired,
};

DiversityClientSpecificUploadRequests.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
    }),
    setFirm: PropTypes.func.isRequired,
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DiversityClientSpecificUploadRequests;
