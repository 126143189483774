import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { DatePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import _get from "lodash/get";
import _isNull from "lodash/isNull";
import _isNaN from "lodash/isNaN";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DeadlineToolTip from "./DeadlineToolTip";
import { createDeadline, getDeadline } from "../../api/diversityApi";
import I18n from "../../utils/i18n";
import { setAlert } from "../../state/alert/alertActions";
import NavigationPrompt from "../../common/components/NavigationPrompt";

const DeadlineFields = () => {
    const [deadline, setDeadline] = useState({ deadline_at: null, deadline_movable: false });
    const DATE_FORMAT = "DD-MMM-YYYY";
    const VALUE_DATE_FORMAT = "YYYY-MM-DD";
    const dispatch = useDispatch();
    const parseDate = (dateAsString) => {
        if (_isNull(dateAsString)) {
            return null;
        } else {
            return moment(dateAsString, VALUE_DATE_FORMAT);
        }
    };
    const daysFromQuarterStart = (date) =>
        moment(date).diff(moment().quarter(moment().quarter()).startOf("quarter"), "days");

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        createDeadline(values)
            .then(() => {
                setDeadline(values.diversity_client);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
            })
            .catch(() => dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" })))
            .finally(() => setSubmitting(false));
    };

    const validationSchema = Yup.object().shape({
        diversity_client: Yup.object().shape({
            deadline_movable: Yup.bool(),
            deadline_at: Yup.date()
                .nullable()
                .min(moment())
                .max(moment().endOf("quarter"))
                .when("deadline_movable", {
                    is: true,
                    then: Yup.date().required(I18n.t("errors.messages.cant_be_blank")),
                }),
        }),
    });

    const renderCheckboxMessage = (daysCount = null) => {
        let baseMessage = I18n.t("diversity.labels.base_deadline_movable");

        if (_isNull(daysCount) || _isNaN(daysCount)) {
            return baseMessage;
        } else {
            let enhancedMessage = I18n.t("diversity.labels.enhanced_deadline_movable", { count: daysCount });

            return `${enhancedMessage}, ${baseMessage}`;
        }
    };

    useEffect(() => {
        getDeadline().then((response) => {
            setDeadline({
                deadline_at: _isNull(response.data.deadline_at) ? "" : response.data.deadline_at,
                deadline_movable: response.data.deadline_movable,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{ diversity_client: deadline }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ values, errors, isSubmitting, resetForm, handleSubmit, setFieldValue, dirty }) => (
                <div className="px-4">
                    <NavigationPrompt when={dirty} />
                    <div className="d-flex">
                        <div className="d-flex mt-2">
                            {I18n.t("activerecord.attributes.diversity/client.deadline_at")}
                            <DeadlineToolTip />
                        </div>
                        <div>
                            <div className="mb-2">
                                <DatePicker
                                    format={DATE_FORMAT}
                                    useCurrent={false}
                                    onChange={(event) => {
                                        setFieldValue(
                                            "diversity_client[deadline_at]",
                                            event.date.format(VALUE_DATE_FORMAT),
                                        );
                                    }}
                                    date={parseDate(_get(values, "diversity_client[deadline_at]"))}
                                    className={classNames("mx-2", "d-inline-block", "deadline-popover", {
                                        "is-invalid": _get(errors, "diversity_client[deadline_at]"),
                                    })}
                                />
                                <Button
                                    variant="primary"
                                    className="text-white"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                    {I18n.t("common.links.text_ok")}
                                    {isSubmitting && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-2"
                                        />
                                    )}
                                </Button>
                                <Button
                                    variant="outline-secondary"
                                    className="ml-2"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setDeadline({ deadline_at: null, deadline_movable: false });
                                        resetForm();
                                    }}
                                >
                                    {I18n.t("common.clear")}
                                </Button>
                                <Form.Control.Feedback type="invalid" className="ml-2">
                                    {_get(errors, "diversity_client[deadline_at]")}
                                </Form.Control.Feedback>
                            </div>
                            <div>
                                <Form.Check
                                    custom
                                    className="mx-2"
                                    type="checkbox"
                                    label={renderCheckboxMessage(
                                        daysFromQuarterStart(values.diversity_client.deadline_at),
                                    )}
                                    id="deadline-movable"
                                    checked={values.diversity_client.deadline_movable}
                                    onChange={(event) =>
                                        setFieldValue("diversity_client[deadline_movable]", event.currentTarget.checked)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default DeadlineFields;
