import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button, Spinner, Badge } from "react-bootstrap";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _first from "lodash/first";
import _isUndefined from "lodash/isUndefined";
import Loader from "../../../../pages/HomePage/components/common/Loader";
import I18n from "../../../../utils/i18n";
import { getSubscriptions, updateSubscription } from "../../../../api/paymentsApi";

const SubscriptionsTab = ({ organizationUid }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (_isUndefined(organizationUid)) {
            setSubscriptions([]);
            setIsLoading(false);
        } else {
            getSubscriptions(organizationUid)
                .then((response) => setSubscriptions(response.data))
                .finally(() => setIsLoading(false));
        }
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (subscriptions.length === 0) {
        return (
            <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
                <Row>
                    <Col>{I18n.t("forms.organization.edit.tabs.subscriptions.no_subscriptions")}</Col>
                </Row>
            </Container>
        );
    }

    const onUpdate = (subscription, params) => {
        setIsUpdating(true);

        updateSubscription(organizationUid, subscription.id, { subscription: params })
            .then((response) => {
                setSubscriptions((prevState) =>
                    _map(prevState, (item) => {
                        if (item.id === response.data.id) {
                            return { ...item, ...response.data };
                        }

                        return item;
                    })
                );
            })
            .finally(() => setIsUpdating(false));
    };

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <div className="card-deck">
                {_map(subscriptions, (subscription) => (
                    <Card key={subscription.id}>
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-between">
                                <div>
                                    <h3>{subscription.product_name}</h3>
                                    <h3>
                                        <Badge pill variant={subscription.active ? "success" : "danger"}>
                                            {I18n.t(
                                                `forms.organization.edit.tabs.subscriptions.status.${subscription.status}`
                                            )}
                                        </Badge>
                                    </h3>
                                </div>

                                <div>
                                    <Button
                                        variant="primary"
                                        className="text-white"
                                        onClick={() =>
                                            history.push(`/${_first(subscription.lookup_key.split("/"))}/pricing`)
                                        }
                                        disabled={isUpdating}
                                    >
                                        {I18n.t("forms.organization.edit.tabs.subscriptions.change_plan")}
                                    </Button>

                                    <Button
                                        variant="primary"
                                        className="text-white ml-2"
                                        onClick={() =>
                                            onUpdate(subscription, {
                                                cancel_at_period_end: !subscription.cancel_at_period_end,
                                            })
                                        }
                                        disabled={isUpdating}
                                    >
                                        {isUpdating && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="mr-2"
                                            />
                                        )}

                                        {I18n.t(
                                            `forms.organization.edit.tabs.subscriptions.${
                                                subscription.cancel_at_period_end ? "resume_plan" : "cancel_plan"
                                            }`
                                        )}
                                    </Button>
                                </div>
                            </Card.Title>
                            <div className="d-flex flex-column">
                                <div className="font-weight-bold">
                                    {`${subscription.price_currency.symbol}${(
                                        subscription.price_tier.flat_amount / 100.0
                                    )
                                        .toFixed(2)
                                        .toLocaleString(I18n.locale)}`}
                                </div>
                                <div>
                                    {I18n.t("forms.organization.edit.tabs.subscriptions.quantity", {
                                        count: subscription.price_tier.up_to || `${subscription.quantity}+`,
                                    })}
                                </div>
                                <div>
                                    {I18n.t(
                                        `forms.organization.edit.tabs.subscriptions.${
                                            subscription.cancel_at_period_end ? "ends_on" : "renews_on"
                                        }`,
                                        {
                                            date: I18n.l("date.formats.long", subscription.current_period_end),
                                        }
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </Container>
    );
};

SubscriptionsTab.propTypes = {
    organizationUid: PropTypes.string,
};

export default SubscriptionsTab;
