import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Modal, CardGroup, Card } from "react-bootstrap";
import classNames from "classnames";
import ExportPdfButton from "./ExportPdfButton";
import ExportXlsxButton from "./ExportXlsxButton";
import I18n from "../../../utils/i18n";
import TooltipComponent from "../../../common/components/DiversityPage/TooltipComponent";

const ExportModal = ({ show = false, onHide, canvasRefs, filter, chartParams, changedFilters }) => {
    const currentOrganization = useSelector((state) => state.currentActor.resource.organization);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{I18n.t("home.export")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardGroup>
                    <Card className="text-center">
                        <Card.Header>{I18n.t("common.export.types.pdf")}</Card.Header>
                        <Card.Body>
                            <Card.Title>{I18n.t("common.pricing.free")}</Card.Title>
                            <ExportPdfButton
                                canvasRefs={canvasRefs}
                                title="sidebar.links.diversity.firmwide_dashboard"
                                filter={filter}
                                chartParams={chartParams}
                                changedFilters={changedFilters}
                                buttonClass="btn-block"
                            />
                        </Card.Body>
                    </Card>
                    <Card
                        className={classNames("text-center", {
                            "text-muted": !currentOrganization.permissions.diversity_firmwide_viewer_plus_permission,
                        })}
                    >
                        <Card.Header>{I18n.t("common.export.types.xlsx")}</Card.Header>
                        <Card.Body>
                            <Card.Title>
                                {I18n.t("common.pricing.firmwide_plus.title")}
                                <TooltipComponent
                                    content={I18n.t("common.pricing.firmwide_plus.description")}
                                    trigger={["focus"]}
                                />
                            </Card.Title>
                            <ExportXlsxButton
                                allQuarters={filter.quarter || []}
                                disabled={!currentOrganization.permissions.diversity_firmwide_viewer_plus_permission}
                            />
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Modal.Body>
        </Modal>
    );
};

ExportModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    canvasRefs: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    chartParams: PropTypes.object.isRequired,
    changedFilters: PropTypes.object.isRequired,
};

export default ExportModal;
