import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import I18n from "../../utils/i18n";

const OecFaqPage = () => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentWhitelabel.has_oec_faq) {
            history.push("/");
        }
    }, []);

    return (
        <div className="d-flex flex-column">
            <div className="flex-grow-1 bg-white">
                <Col>
                    <Container fluid className="py-3">
                        <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                            <h2>{I18n.t(`oec_faq.title.${currentWhitelabel.key}`)}</h2>
                        </div>
                        <object
                            data={`/docs/${I18n.locale}/OEC_FAQ.pdf`}
                            type="application/pdf"
                            className="embed-oec-faq-pdf"
                            width="100%"
                        >
                            <div className="embed-oec-faq-pdf">
                                {I18n.t("oec_faq.not_support")}
                                <br />
                                <a href={`/docs/${I18n.locale}/OEC_FAQ.pdf`} target="_blank" rel="noreferrer">
                                    {I18n.t("oec_faq.download")}
                                </a>
                            </div>
                        </object>
                    </Container>
                </Col>
            </div>
        </div>
    );
};

export default OecFaqPage;
