import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPlus, faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { faStar, faFileSearch } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import _map from "lodash/map";
import Table from "../../../../common/components/Table";
import I18n from "../../../../utils/i18n";
import { addFavorite, cloneFromTemplate, removeFavorite } from "../../../../api/widgetsTemplatesApi";
import ConfirmAction from "../../../../common/components/ConfirmAction";

const TemplatesTable = ({ isFetching, fetchData, data, setNegotiations }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("home.templates.columns.organization_name"),
                accessor: "organization_name",
            },
            {
                Header: I18n.t("home.templates.columns.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("home.templates.columns.owner_display_name"),
                accessor: "owner_user_name",
            },
            {
                Header: I18n.t("home.templates.columns.type"),
                accessor: "type",
            },
            {
                Header: I18n.t("home.templates.columns.updated_at"),
                accessor: "updated_at",
                Cell: DateCell,
            },
            {
                Header: I18n.t("home.templates.columns.number_of_uses"),
                accessor: "template_info_number_of_uses",
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} setNegotiations={setNegotiations} />,
            },
        ],
        [],
    );

    return (
        <div className="mt-3">
            {_isEmpty(data) && <div className="pt-4 px-4 pb-4">{I18n.t("home.negotiations.no_data")}</div>}
            <Table
                loading={isFetching}
                data={data || []}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "template_info_number_of_uses", desc: true }]}
                manualSortBy
            />
        </div>
    );
};

const DateCell = ({
    row: {
        original: { updated_at },
    },
}) => <div className="d-flex flex-column">{I18n.l("time.formats.short", updated_at)}</div>;

const ActionsCell = ({
    row: {
        original: { favorite, editable, id },
    },
    setNegotiations,
}) => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const baseUrl = `//${currentWhitelabel.hosts.bid}`;
    const [clonning, setClonning] = useState(false);
    const [favoritting, setFavoritting] = useState(false);
    const [confirmClonning, setConfirmClonning] = useState(false);
    const TIPS_SCOPE = "home.templates.action_buttons";
    const handleClone = () => {
        if (clonning) return;

        setClonning(true);
        cloneFromTemplate(id).then(({ data: { negotiation_id } }) =>
            (window.location = `${baseUrl}/setup/${negotiation_id}`).finally(() => setClonning(false)),
        );
    };

    const updateData = (value) =>
        setNegotiations((oldData) =>
            _map(oldData, (event) => (event.id === id ? { ...event, favorite: value } : event)),
        );

    const handleRemoveFavorite = () => {
        if (favoritting) return;

        setFavoritting(true);
        removeFavorite(id)
            .then(() => updateData(false))
            .finally(() => setFavoritting(false));
    };

    const handleAddFavorite = () => {
        if (favoritting) return;

        setFavoritting(true);
        addFavorite(id)
            .then(() => updateData(true))
            .finally(() => setFavoritting(false));
    };

    return (
        <>
            <Link to={`${baseUrl}/setup/${id}`} target="_blank" className="mr-2">
                <span
                    title={I18n.t(editable ? "edit.tip" : "preview.tip", { scope: TIPS_SCOPE })}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={editable ? faCog : faFileSearch} />
                </span>
            </Link>
            {favoritting ? (
                <Spinner animation="border" size="sm" className="mr-1-5" />
            ) : (
                <Link to="#" onClick={favorite ? handleRemoveFavorite : handleAddFavorite} className="mr-2">
                    <span
                        title={I18n.t(favorite ? "remove_favorite.tip" : "add_favorite.tip", { scope: TIPS_SCOPE })}
                        className="action-button"
                    >
                        <FontAwesomeIcon icon={favorite ? faStarSolid : faStar} />
                    </span>
                </Link>
            )}
            {clonning ? (
                <Spinner animation="border" size="sm" />
            ) : (
                <>
                    <Link to="#" onClick={() => setConfirmClonning(true)} className="mr-2">
                        <span title={I18n.t("home.templates.action_buttons.clone.tip")} className="action-button">
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                    </Link>
                    <ConfirmAction
                        show={confirmClonning}
                        onHide={() => setConfirmClonning(false)}
                        onSubmit={handleClone}
                        confirm="home.templates.confirm_modal.confirm"
                        title={I18n.t("home.templates.confirm_modal.title")}
                        text={I18n.t("home.templates.confirm_modal.text")}
                    />
                </>
            )}
        </>
    );
};

DateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            favorite: PropTypes.bool,
            editable: PropTypes.bool,
            id: PropTypes.number,
        }),
    }),
    setNegotiations: PropTypes.func.isRequired,
};

TemplatesTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    setNegotiations: PropTypes.func.isRequired,
};

export default TemplatesTable;
