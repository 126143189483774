import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import _map from "lodash/map";
import { useFormikContext } from "formik";
import { getNegotiations } from "../../../../../../api/listsApi";
import I18n from "../../../../../../utils/i18n";

const MIN_LENGTH = 3;

const SearchStep = ({ children, cancelButton, goToPage }) => {
    const { values, setFieldValue } = useFormikContext();

    const loadOptions = (term) =>
        (term || "").trim().length >= MIN_LENGTH &&
        getNegotiations({ q: term }).then(({ data }) =>
            _map(data, (negotiation) => ({
                value: negotiation.negotiation_id,
                label: `${negotiation.name} (${negotiation.negotiation_id})`,
            }))
        );
    return (
        <>
            <Modal.Body>
                <Form.Group>
                    {children}
                    <Modal.Body>
                        <p>{I18n.t("companies.lists.sections.export.export_to_exist.search_description")}</p>
                        <AsyncSelect
                            className="react-select flex-grow-1"
                            classNamePrefix="react-select"
                            loadOptions={loadOptions}
                            onChange={(option) => setFieldValue("negotiation_id", option.value)}
                        />
                    </Modal.Body>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {cancelButton}

                <Button
                    disabled={!values?.negotiation_id}
                    variant="primary"
                    className="text-white"
                    onClick={goToPage}
                >
                    {I18n.t("common.links.next")}
                </Button>
            </Modal.Footer>
        </>
    );
};

SearchStep.propTypes = {
    children: PropTypes.node.isRequired,
    cancelButton: PropTypes.node.isRequired,
    goToPage: PropTypes.func.isRequired,
};

export default SearchStep;
