import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import FirmwideCharts from "../../utils/firmwideCharts";
import I18n from "../../utils/i18n";
import DiversityFirmwideFiltersBar from "./DiversityFirmwideFiltersBar";
import { getFirmwideFilters, updateFirmwideCharts } from "../../api/diversityApi";
import AddFirmModal from "./components/AddFirmModal";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";
import ExportModal from "./components/ExportModal";

const DiversityFirmwidePage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);

    const [chartParams, setChartParams] = useState({});
    const [filter, setFilter] = useState({});
    const canvasRefs = useRef({});
    const [changedFilters, setChangedFilters] = useState({});
    const [addFirmModalShow, setAddFirmModalShow] = useState(false);
    const [exportModalShow, setExportModalShow] = useState(false);

    return (
        <div id="firmwide-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <Row>
                        <Col className="mr-auto">
                            {currentActor.organization.permissions.diversity_firmwide_viewer_permission &&
                                (currentActor.super_manager || currentActor.diversity_manager) && (
                                    <>
                                        <AddFirmModal
                                            show={addFirmModalShow}
                                            onHide={() => setAddFirmModalShow(false)}
                                        />

                                        <Button
                                            variant="warning"
                                            className="text-light m-1"
                                            onClick={() => setAddFirmModalShow(true)}
                                        >
                                            {I18n.t("diversity.buttons.add_firm")}
                                        </Button>
                                    </>
                                )}

                            {!_isEmpty(filter) && !_isEmpty(chartParams) && (
                                <>
                                    <ExportModal
                                        show={exportModalShow}
                                        canvasRefs={canvasRefs}
                                        chartParams={chartParams}
                                        changedFilters={changedFilters}
                                        filter={filter}
                                        onHide={() => setExportModalShow(false)}
                                    />

                                    <Button
                                        variant="warning"
                                        className="text-light m-1"
                                        onClick={() => setExportModalShow(true)}
                                    >
                                        {I18n.t("home.export")}
                                    </Button>
                                </>
                            )}
                        </Col>
                        <Col className="col-auto">
                            <DiversityFirmwideFiltersBar
                                setChartParams={setChartParams}
                                getFilters={getFirmwideFilters}
                                setFilter={setFilter}
                                setChangedFilters={setChangedFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={FirmwideCharts}
                        updateCharts={updateFirmwideCharts}
                        channelName="Diversity::Firmwide::ChartsChannel"
                        namespace="firmwide"
                    />
                </div>
                <div className="bottom-navigation p-2">
                    <Col>
                        <Container fluid>
                            <div className="d-flex justify-content-between">
                                {I18n.t("diversity.dashboards.firmwide.notice")}
                            </div>
                        </Container>
                    </Col>
                </div>
            </div>
        </div>
    );
};

export default DiversityFirmwidePage;
