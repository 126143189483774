import React, { useState } from "react";
import { useField } from "formik";
import { Form, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import _map from "lodash/map";
import _each from "lodash/each";
import _includes from "lodash/includes";
import _lowerCase from "lodash/lowerCase";
import _every from "lodash/every";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _get from "lodash/get";
import _union from "lodash/union";
import PropTypes from "prop-types";
import ethnicities from "../../../../utils/ethnicities";
import I18n from "../../../../utils/i18n";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";

const DiverseOwnedMenu = ({ counter }) => {
    const [searchValue, setSearchValue] = useState("");

    const elements = [
        {
            id: "women_owned",
            name: I18n.t("activerecord.attributes.company_structure.women_owned"),
            field: useField("diverse_owned[women_owned]"),
        },
        {
            id: "minority_owned",
            name: I18n.t("activerecord.attributes.company_structure.minority_owned"),
            field: useField(`diverse_owned[ethnicity]`),
            sub_id: "ethnicity",
            sub_elements: _map(ethnicities, (ethnicity) => ({
                name: I18n.t(`activerecord.attributes.company_structure.ethnicities.${ethnicity}`),
                id: ethnicity,
            })),
        },
        {
            id: "lgbtq_owned",
            name: I18n.t("activerecord.attributes.company_structure.lgbtq_owned"),
            field: useField("diverse_owned[lgbtq_owned]"),
        },
        {
            id: "veteran_owned",
            name: I18n.t("activerecord.attributes.company_structure.veteran_owned"),
            field: useField("diverse_owned[veteran_owned]"),
        },
        {
            id: "differently_abled_owned",
            name: I18n.t("activerecord.attributes.company_structure.differently_abled_owned"),
            field: useField("diverse_owned[differently_abled_owned]"),
        },
        {
            id: "disadvantaged_business",
            name: I18n.t("activerecord.attributes.company_structure.disadvantaged_business"),
            field: useField("diverse_owned[disadvantaged_business]"),
        },
    ];

    const onClear = () => {
        setSearchValue("");
        _each(elements, (element) => element.field[2].setValue(element.sub_elements ? [] : false));
    };

    const isSearchMatched = (value) => _includes(_lowerCase(value), _lowerCase(searchValue));
    const onSearch = (event) => setSearchValue(event.target.value);

    const getFilteredElements = (elements) =>
        _filter(
            elements,
            (element) =>
                isSearchMatched(element.name) ||
                _some(element.sub_elements || [], (subElement) => isSearchMatched(subElement.name)),
        );

    const isSubElementChecked = (element, subElement) => _includes(element.field[1].value, subElement.id);
    const isElementChecked = (element) => element.field[1].value;

    const areChecked = (elements) =>
        _every(elements, (element) =>
            element.sub_elements
                ? _every(element.sub_elements, (subElement) => isSubElementChecked(element, subElement))
                : isElementChecked(element),
        );

    const someChecked = (elements) =>
        _some(elements, (element) =>
            element.sub_elements
                ? _some(element.sub_elements, (subElement) => isSubElementChecked(element, subElement))
                : isElementChecked(element),
        );

    const areIndeterminate = (elements) => someChecked(elements) && !areChecked(elements);

    const onChangeGroup = (event, elements) => {
        _each(elements, (element) => {
            element.sub_elements
                ? element.field[2].setValue(
                      event.target.checked ? _union(element.field[1].value, _map(element.sub_elements, "id")) : [],
                  )
                : element.field[2].setValue(event.target.checked);
        });
    };

    const onSelectAll = (event) => onChangeGroup(event, elements);

    const labelFor = (element, object = counter) =>
        I18n.t("companies.profiles.sections.filters.counter", {
            name: element.name,
            count: _get(object, element.id) || 0,
        });

    return (
        <div className="group-select-menu">
            <div className="d-flex align-items-center justify-content-between">
                <strong>{I18n.t("companies.profiles.sections.filters.diverse_owned")}</strong>
                <small className="text-muted cursor-pointer" onClick={onClear}>
                    {I18n.t("common.clear")}
                </small>
            </div>
            <Form className="mt-2">
                <Form.Control
                    placeholder={I18n.t("common.placeholders.search")}
                    onChange={onSearch}
                    value={searchValue}
                    className="mb-2"
                />
                <IndeterminateCheck
                    key="diverse-owned-all"
                    type="checkbox"
                    name="diverse-owned"
                    id="diverse-owned-all"
                    label={I18n.t("companies.profiles.sections.filters.counter", {
                        name: I18n.t("common.select_all"),
                        count: counter?.total,
                    })}
                    onChange={onSelectAll}
                    className="mb-2"
                    checked={areChecked(elements)}
                    indeterminate={areIndeterminate(elements)}
                />
                <div className="checkbox-list">
                    {_map(getFilteredElements(elements), (element) =>
                        element.sub_elements ? (
                            <Accordion key={`diverse_owned_${element.id}`}>
                                <Accordion.Toggle
                                    as="div"
                                    className="d-inline-block"
                                    eventKey={`diverse_owned_${element.id}`}
                                >
                                    <div className="form-check-inline">
                                        <IndeterminateCheck
                                            className="multiselect-filter d-inline-block text-truncate w-100"
                                            type="checkbox"
                                            id={`diverse_owned_${element.id}`}
                                            checked={areChecked([element])}
                                            indeterminate={areIndeterminate([element])}
                                            onChange={(event) => onChangeGroup(event, [element])}
                                            label={labelFor(element)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="align-baseline ml-2 arrow"
                                            size="xs"
                                        />
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={`diverse_owned_${element.id}`}>
                                    <div className="ml-4">
                                        {_map(element.sub_elements, (subElement) => (
                                            <Form.Check custom key={subElement.id}>
                                                <Form.Check.Input
                                                    className="multiselect-filter d-inline-block text-truncate"
                                                    onChange={element.field[0].onChange}
                                                    name={element.field[0].name}
                                                    type="checkbox"
                                                    id={`diverse_owned_${element.id}_${subElement.id}`}
                                                    value={subElement.id}
                                                    checked={isSubElementChecked(element, subElement)}
                                                />
                                                <Form.Check.Label
                                                    htmlFor={`diverse_owned_${element.id}_${subElement.id}`}
                                                    className="w-100"
                                                >
                                                    {labelFor(subElement, _get(counter, element.sub_id))}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        ))}
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        ) : (
                            <Form.Check custom key={`diverse_owned_${element.id}`}>
                                <Form.Check.Input
                                    className="multiselect-filter d-inline-block text-truncate w-100"
                                    type="checkbox"
                                    id={`diverse_owned_${element.id}`}
                                    onChange={element.field[0].onChange}
                                    name={element.field[0].name}
                                    value={true}
                                    checked={isElementChecked(element)}
                                    label={element.name}
                                />
                                <Form.Check.Label htmlFor={`diverse_owned_${element.id}`} className="w-100">
                                    {labelFor(element)}
                                </Form.Check.Label>
                            </Form.Check>
                        ),
                    )}
                </div>
            </Form>
        </div>
    );
};

DiverseOwnedMenu.propTypes = {
    counter: PropTypes.object,
};

export default DiverseOwnedMenu;
