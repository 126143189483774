import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form } from "react-bootstrap";
import _includes from "lodash/includes";
import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import _has from "lodash/has";
import I18n from "../../utils/i18n";
import { editOrganization, updateOrganization } from "../../api/organizationApi";
import { AppLoader } from "../../common/components";
import { setAlert } from "../../state/alert/alertActions";
import OrganizationForm from "../../common/components/OrganizationForm";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { modifiedIndustryAttributes } from "../../utils/organizationProfile";

const EditOrganizationPage = () => {
    const { uid, tab } = useParams();
    const history = useHistory();
    const [organization, setOrganization] = useState({});
    const [key, setKey] = useState(tab ? tab : "generalInfo");
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.resource);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const enableChildrenManaging = currentUser.superadmin || currentUser.admin || currentActor.super_manager;
    const enableCertificateAndViewManaging = enableChildrenManaging || currentActor.profile_manager;

    const setBackTab = () =>
        history.location.state?.prevPath && {
            path: history.location.state.prevPath,
            title: _includes(history.location.state.prevPath, "subOrganization")
                ? I18n.t("forms.sub_organization.title")
                : I18n.t("sidebar.links.manage.organizations"),
        };

    const backPath = setBackTab();

    const loadOrganization = (userValues) =>
        editOrganization(uid, userValues)
            .then((response) => {
                setOrganization(response.data);
            })
            .catch(() => {
                history.push("/");
            });

    useEffect(loadOrganization, []);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        values.organization.industry_relations_attributes = modifiedIndustryAttributes(values);

        updateOrganization(uid, values)
            .then((response) => {
                setSubmitting(false);
                setOrganization(response.data);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                !_isUndefined(backPath) && history.push(backPath.path);
            })
            .catch((error) => {
                setSubmitting(false);
                error.response.status === 422 && setErrors({ organization: error.response.data });
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
            });
    };

    if (_isEmpty(organization)) {
        return <AppLoader />;
    }

    const ROUTE_LINKS = {
        diversityCertificates: {
            link: `/organizations/${uid}/diversity/certificates/new`,
            accessor: enableCertificateAndViewManaging,
        },
        locations: {
            link: `/organizations/${uid}/locations/new`,
            accessor: enableCertificateAndViewManaging,
        },
        industrySpecificQuestions: {
            link: {
                pathname: "/external/bid/industry_specific_questionnaire_respondents",
                search: `?redirect_to=${currentWhitelabel.hosts.bid}/industry_specific/questionnaire/respondents/${uid}`,
            },
            accessor: currentActor.super_manager || currentActor.profile_manager,
            title: I18n.t("forms.organization.edit.tabs.industry_specific_questions.edit"),
        },
        subOrganizations: { link: `/organizations/${uid}/organizations/new`, accessor: enableChildrenManaging },
        users: { link: `/organizations/${uid}/users/new`, accessor: enableChildrenManaging },
        attorneys: { link: `/organizations/${uid}/legal/attorneys/new`, accessor: enableCertificateAndViewManaging },
    };

    return (
        <>
            <TopBarWithBackLink backPath={backPath?.path} linkName={backPath?.title}>
                <Col className="text-right">
                    {_has(ROUTE_LINKS, key) && ROUTE_LINKS[key].accessor && (
                        <Link
                            to={ROUTE_LINKS[key].link}
                            state={ROUTE_LINKS[key].state}
                            className="text-white btn btn-primary m-1 ml-2"
                        >
                            {ROUTE_LINKS[key].title || I18n.t("common.links.create")}
                        </Link>
                    )}
                    {enableChildrenManaging && (
                        <>
                            {key === "subscriptions" && organization.payments_customer_uid && (
                                <Form action="/payments/customer_portal" method="GET" className="d-inline">
                                    <input
                                        type="hidden"
                                        name="authenticity_token"
                                        value={document
                                            .querySelector("meta[name='csrf-token']")
                                            .getAttribute("content")}
                                    />

                                    <Button variant="primary" className="text-white m-1 ml-2" type="submit">
                                        {I18n.t("forms.organization.edit.tabs.subscriptions.customer_portal")}
                                    </Button>
                                </Form>
                            )}
                        </>
                    )}
                    {enableCertificateAndViewManaging && (
                        <Link to={`/companies/profiles/${uid}`} className="btn btn-light m-1 ml-2">
                            {I18n.t("common.links.view")}
                        </Link>
                    )}
                </Col>
            </TopBarWithBackLink>

            <OrganizationForm
                uid={uid}
                title={
                    currentActor.organization.uid === uid ? I18n.t("forms.organization.edit.title") : organization.name
                }
                initialValues={{
                    organization: {
                        ...organization,
                        industry_relations_attributes: organization.industry_relations_attributes.length
                            ? organization.industry_relations_attributes
                            : [{ primary: true, industry_id: "" }],
                    },
                }}
                onSubmit={onSubmit}
                tab={{ key, setKey }}
                showEditOrganization
            />
        </>
    );
};

export default EditOrganizationPage;
