import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import I18n from "../../../../utils/i18n";
import { addFavorite, removeFavorite } from "../../../../api/templatesApi";

const FavoriteButtons = ({ id, isFavorite }) => {
    const translationScope = "home.create_event_wizard.templates_table.buttons";
    const [favoriting, setFavoriting] = useState(false);
    const [favorite, setFavorite] = useState(isFavorite);
    const handleAddToFavorites = useCallback(() => {
        if (favoriting) return;

        setFavoriting(true);

        addFavorite(id)
            .then(() => setFavorite(true))
            .catch(() => setFavorite(false))
            .finally(() => setFavoriting(false));
    }, [id]);

    const handleRemoveFromFavorites = useCallback(() => {
        if (favoriting) return;

        setFavoriting(true);

        removeFavorite(id)
            .then(() => setFavorite(false))
            .catch(() => setFavorite(true))
            .finally(() => setFavoriting(false));
    }, [id]);
    const handler = favorite ? handleRemoveFromFavorites : handleAddToFavorites;
    const icon = favorite ? faStarSolid : faStar;
    const titleKey = favorite ? "remove_from_favorites.tip" : "add_to_favorites.tip";

    return favoriting ? (
        <Spinner animation="border" size="sm" variant="primary" role="status" />
    ) : (
        <Link to="#" onClick={handler}>
            <span title={I18n.t(titleKey, { scope: translationScope })}>
                <FontAwesomeIcon icon={icon} />
            </span>
        </Link>
    );
};

FavoriteButtons.propTypes = {
    id: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool.isRequired,
};

export default FavoriteButtons;
