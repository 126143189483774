import * as Yup from "yup";
import I18n from "../../../utils/i18n";

export default Yup.object().shape({
    diversity_certificate_type: Yup.object().shape({
        name: Yup.string().max(255).required(),
        source: Yup.string()
            .max(255)
            .matches(
                /^[w|W]{3}[.]{1}[^,.\s]+([-.]{1}[^,.\s]+)*\.[^,.\s]{2,5}$/,
                I18n.t("activerecord.errors.models.diversity/certificate_type.attributes.source.invalid")
            )
            .nullable(),
        description: Yup.string().nullable(),
        ownership_attributes: Yup.object().shape({
            minority_owned: Yup.bool(),
            ethnicity: Yup.string()
                .nullable()
                .when("minority_owned", {
                    is: true,
                    then: Yup.string()
                        .typeError(I18n.t("errors.messages.cant_be_blank"))
                        .required(I18n.t("errors.messages.cant_be_blank")),
                }),
        }),
    }),
});
