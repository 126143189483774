import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const StatusesFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("diversity.buttons.select_statuses")}
        filterKey="statuses"
        filterValues={values.statuses}
        defaultParams={defaultParams.statuses}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

StatusesFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        statuses: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default StatusesFilter;
