import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Row, Form, Container, FormLabel } from "react-bootstrap";
import _ from "lodash";
import { DatePicker } from "react-tempusdominus-bootstrap";
import { useField } from "formik";
import _isNull from "lodash/isNull";
import _get from "lodash/get";
import FormRow from "./FormRow";
import I18n from "../../utils/i18n";
import { getCertificateTypesForSelect } from "../../api/diversityApi";
import CertificateOriginContainer from "./CertificateOriginContainer";
import CertificateTypeRequestModal from "./CertificateTypeRequestModal";

const FormDiversityCertificate = ({ form, certificateId = null, organizationUid }) => {
    const whitelabelName = useSelector((state) => state.currentWhitelabel.resource.name);
    const [showModal, setShowModal] = useState(false);
    const [options, setOptions] = useState([]);
    const DATE_FORMAT = "DD-MMM-YYYY";
    const VALUE_DATE_FORMAT = "YYYY-MM-DD";
    const [fieldNumber, metaNumber] = useField("diversity_certificate[number]");
    const certificateExist = !_isNull(certificateId);
    const preview = () =>
        certificateExist ? `/organizations/${organizationUid}/diversity/certificates/${certificateId}.pdf` : null;

    useEffect(() => {
        getCertificateTypesForSelect().then((response) => setOptions(response.data));
    }, []);

    const validationError = (name) => {
        const errors = _get(form.errors, name);

        if (_.isArray(errors)) {
            return errors.join("; ");
        } else {
            return errors;
        }
    };

    const originErrorMessage = () => validationError("diversity_certificate.origin");

    const parseDate = (dateAsString) => moment(dateAsString, VALUE_DATE_FORMAT);
    return (
        <Container fluid className="mt-3 pt-2">
            <Row>
                <Col md={3}>
                    <FormRow
                        label={I18n.t("activerecord.attributes.diversity/certificate.origin")}
                        error={originErrorMessage()}
                    >
                        <CertificateOriginContainer
                            form={form}
                            inputName="diversity_certificate[origin]"
                            accept={["application/pdf"]}
                            previewUrl={preview()}
                            classNamePostfix="rectangle"
                        />
                    </FormRow>

                    <FormLabel className="mt-2">
                        {I18n.t("forms.certify_text", { whitelabel_name: whitelabelName })}
                    </FormLabel>
                </Col>
                <Col md={9}>
                    <FormRow
                        label={I18n.t("activerecord.attributes.diversity/certificate.name")}
                        error={
                            validationError("diversity_certificate.diversity_certificate_type_id") ||
                            validationError("diversity_certificate.certificate_type")
                        }
                    >
                        <Select
                            name="diversity_certificate[diversity_certificate_type_id]"
                            className={classNames("react-select", {
                                "is-invalid":
                                    validationError("diversity_certificate.diversity_certificate_type_id") ||
                                    validationError("diversity_certificate.certificate_type"),
                            })}
                            placeholder={I18n.t("common.placeholders.select_certificate_type")}
                            classNamePrefix="react-select"
                            options={options}
                            onChange={(option) => {
                                form.setFieldValue("diversity_certificate[diversity_certificate_type_id]", option.id);
                            }}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isDisabled={certificateExist}
                            value={
                                _get(form.values, "diversity_certificate.diversity_certificate_type_id") &&
                                options.find(
                                    (option) =>
                                        option.id === form.values.diversity_certificate.diversity_certificate_type_id,
                                )
                            }
                        />
                    </FormRow>
                    {!certificateExist && (
                        <FormRow>
                            <CertificateTypeRequestModal
                                setShowModal={setShowModal}
                                show={showModal}
                                onHide={() => setShowModal(false)}
                            />

                            <Link to="#" onClick={() => setShowModal(true)} className="pull-right">
                                {I18n.t("forms.add_certificate_type_request")}
                            </Link>
                        </FormRow>
                    )}
                    <FormRow label={I18n.t("activerecord.attributes.diversity/certificate.number")}>
                        <Form.Control
                            type="text"
                            {...fieldNumber}
                            value={fieldNumber.value || ""}
                            isInvalid={!!metaNumber.error}
                            disabled={certificateExist}
                        />
                        <Form.Control.Feedback type="invalid">{metaNumber.error}</Form.Control.Feedback>
                    </FormRow>
                    <FormRow
                        label={I18n.t("activerecord.attributes.diversity/certificate.origin_date")}
                        error={validationError("diversity_certificate.origin_date")}
                    >
                        <DatePicker
                            format={DATE_FORMAT}
                            useCurrent={false}
                            minDate={parseDate("2000 01 01")}
                            maxDate={moment()}
                            onChange={(event) =>
                                form.setFieldValue(
                                    "diversity_certificate[origin_date]",
                                    event.date.format(VALUE_DATE_FORMAT),
                                )
                            }
                            date={parseDate(_get(form.values, "diversity_certificate.origin_date"))}
                            className={classNames("flex-grow-1", {
                                "is-invalid": validationError("diversity_certificate.origin_date"),
                            })}
                            readOnly={certificateExist}
                        />
                    </FormRow>
                    <FormRow
                        label={I18n.t("activerecord.attributes.diversity/certificate.current_origin_date")}
                        error={validationError("diversity_certificate.current_origin_date")}
                    >
                        <DatePicker
                            format={DATE_FORMAT}
                            useCurrent={false}
                            minDate={parseDate("2000 01 01")}
                            onChange={(event) =>
                                form.setFieldValue(
                                    "diversity_certificate[current_origin_date]",
                                    event.date.format(VALUE_DATE_FORMAT),
                                )
                            }
                            date={parseDate(_get(form.values, "diversity_certificate.current_origin_date"))}
                            className={classNames("flex-grow-1", {
                                "is-invalid": validationError("diversity_certificate.current_origin_date"),
                            })}
                        />
                    </FormRow>
                    <FormRow
                        label={I18n.t("activerecord.attributes.diversity/certificate.expiration_date")}
                        error={validationError("diversity_certificate.expiration_date")}
                    >
                        <DatePicker
                            format={DATE_FORMAT}
                            useCurrent={false}
                            minDate={moment()}
                            onChange={(event) =>
                                form.setFieldValue(
                                    "diversity_certificate[expiration_date]",
                                    event.date.format(VALUE_DATE_FORMAT),
                                )
                            }
                            date={parseDate(_get(form.values, "diversity_certificate.expiration_date"))}
                            className={classNames("flex-grow-1", {
                                "is-invalid": validationError("diversity_certificate.expiration_date"),
                            })}
                        />
                    </FormRow>
                </Col>
            </Row>
        </Container>
    );
};

FormDiversityCertificate.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object,
        errors: PropTypes.object.isRequired,
    }),
    certificateId: PropTypes.string,
    disabled: PropTypes.bool,
    organizationUid: PropTypes.string.isRequired,
};

export default FormDiversityCertificate;
