import React, { forwardRef } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";

const ThreeDotsToggle = forwardRef(({ onClick }, ref) => (
    <Button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        variant="link"
        className="text-secondary"
    >
        <FontAwesomeIcon icon={faEllipsisH} />
    </Button>
));

ThreeDotsToggle.displayName = "ThreeDotsToggle";
ThreeDotsToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ThreeDotsToggle;
