import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import _filter from "lodash/filter";
import { getRoles } from "../../../../api/roleApi";
import ActorRoles from "./ActorRoles";
import UserRoles from "./UserRoles";

const RolesTab = ({ form }) => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles().then((response) => setRoles(response.data));
    }, []);

    return (
        <Container fluid className="mt-3 pt-2">
            <ActorRoles
                roles={_filter(roles, ["context", "actor"])}
                actorRoles={form.values.actor.actor_roles_attributes}
                setFieldValue={form.setFieldValue}
            />

            <UserRoles
                roles={_filter(roles, ["context", "user"])}
                userRoles={form.values.actor.user_attributes.user_roles_attributes}
                setFieldValue={form.setFieldValue}
            />
        </Container>
    );
};

RolesTab.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.shape({
            actor: PropTypes.shape({
                actor_roles_attributes: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        role_id: PropTypes.number,
                        _destroy: PropTypes.bool,
                    })
                ),
                user_attributes: PropTypes.shape({
                    user_roles_attributes: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number,
                            role_id: PropTypes.number,
                            _destroy: PropTypes.bool,
                        })
                    ),
                }),
            }),
        }),
    }),
};

export default RolesTab;
