import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faUserLock } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import _findIndex from "lodash/findIndex";

const ChartSettings = ({ type }) => {
    const { values } = useFormikContext();
    const index = _findIndex(values.charts_attributes, { name: type });

    const [showToManagerField] = useField({
        name: `charts_attributes[${index}][settings][show_to_super_and_diversity_manager]`,
        type: "checkbox",
    });
    const [showToUserField] = useField({
        name: `charts_attributes[${index}][settings][show_to_user]`,
        type: "checkbox",
    });

    return (
        <Form>
            <Row className="justify-content-end">
                <Col className="col-auto">
                    <Form.Check
                        custom
                        {...showToManagerField}
                        label={<FontAwesomeIcon icon={faUserEdit} />}
                        id={`${type}_for_manager`}
                    />
                </Col>
                <Col className="col-auto">
                    <Form.Check
                        custom
                        {...showToUserField}
                        label={<FontAwesomeIcon icon={faUserLock} />}
                        id={`${type}_for_user`}
                    />
                </Col>
            </Row>
        </Form>
    );
};

ChartSettings.propTypes = {
    type: PropTypes.string.isRequired,
};

export default ChartSettings;
