import React from "react";
import PropTypes from "prop-types";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import _ from "lodash";
import I18n from "../../../../utils/i18n";
import { DIVERSITY_KEY_INDICATORS_VALUES } from "../../../../utils/diversityKeyIndicators";
import DIVERSITY_DIRECTIONS from "../../../../utils/diversityDirections";
import { calculateDiversityCurrentPointsFor } from "../../../../utils/diversityCalculators";

const KeyIndicatorsTableRow = ({
    rowIndex,
    idValues,
    diversityAttributesCount,
    values,
    errors,
    handleChange,
    currentGoals,
    partialFirmwide,
}) => {
    const groupedValues = _.keyBy(values, "key_indicator");

    const getNameFor = (index, attribute) => `key_indicators[${index}][${attribute}]`;

    return (
        <tr>
            {_.map(DIVERSITY_KEY_INDICATORS_VALUES, (keyIndicator, columnIndex) => {
                const value = groupedValues[keyIndicator];
                const index = idValues.indexOf(value.id);

                return (
                    <React.Fragment key={keyIndicator}>
                        {rowIndex === 0 && columnIndex === 0 && (
                            <th scope="row" rowSpan={diversityAttributesCount}>
                                {I18n.t(`diversity.groups.${value.diversity_group}`)}
                            </th>
                        )}
                        {columnIndex === 0 && (
                            <th scope="row">
                                {I18n.t(`diversity.attributes.${value.diversity_group}.${value.diversity_attribute}`, {
                                    defaultValue: I18n.t(`diversity.groups.${value.diversity_group}`),
                                })}
                            </th>
                        )}
                        <td scope="row">
                            {_.isNil(currentGoals[value.id])
                                ? I18n.t("common.not_available")
                                : `${currentGoals[value.id].toFixed(2)}%`}
                        </td>
                        <td scope="row">
                            {_.isNil(currentGoals[value.id])
                                ? I18n.t("common.not_available")
                                : calculateDiversityCurrentPointsFor(
                                      keyIndicator,
                                      value.goal,
                                      value.possible_points,
                                      value.direction,
                                      currentGoals[value.id],
                                      partialFirmwide
                                  ).toFixed(2)}
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        onChange={handleChange}
                                        name={getNameFor(index, "possible_points")}
                                        value={value.possible_points || ""}
                                        isInvalid={(errors[index] || {}).possible_points}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {(errors[index] || {}).possible_points}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        name={getNameFor(index, "direction")}
                                        value={value.direction}
                                        onChange={handleChange}
                                        isInvalid={(errors[index] || {}).direction}
                                        custom
                                    >
                                        {_.map(DIVERSITY_DIRECTIONS, (diversityDirection) => (
                                            <option key={diversityDirection} value={diversityDirection}>
                                                {diversityDirection}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {(errors[index] || {}).direction}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </td>
                        <td scope="row">
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <Form.Control
                                            onChange={handleChange}
                                            name={getNameFor(index, "goal")}
                                            value={value.goal || ""}
                                            isInvalid={(errors[index] || {}).goal}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {(errors[index] || {}).goal}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </td>
                    </React.Fragment>
                );
            })}
        </tr>
    );
};

KeyIndicatorsTableRow.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    idValues: PropTypes.array.isRequired,
    diversityAttributesCount: PropTypes.number.isRequired,
    values: PropTypes.array.isRequired,
    errors: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    currentGoals: PropTypes.object.isRequired,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default KeyIndicatorsTableRow;
