import {
    AttributesByFirmChart,
    AttributesPercentsByFirmChart,
    BillRateByAttributeChart,
    CreditByAttributeChart,
    DifferenceFromGoalChart,
    DiversityOfFees,
    DiversityOfHours,
    EligiblePromotedByDemographicsChart,
    FeesVsClientScoreChart,
    GoalsVsActualChart,
    MatterLeadRolesByAttributeChart,
    MiniStatsBar,
    ScoreByFirmChart,
    ScoreByFirmTableChart,
    ScoreByPracticeAreaChart,
    TotalFeesByFirmChart,
    TotalFeesByRoleChart,
} from "../pages/DiversityClientSpecificPage/components/charts";

const ClientSpecificCharts = [
    [{ type: "mini_stats_bar", component: MiniStatsBar }],
    [
        { type: "score_by_firm", component: ScoreByFirmChart },
        { type: "score_by_firm_table", component: ScoreByFirmTableChart },
    ],
    [{ type: "goals_vs_actual", component: GoalsVsActualChart }],
    [
        { type: "fees_vs_client_score", component: FeesVsClientScoreChart },
        { type: "difference_from_goal", component: DifferenceFromGoalChart },
    ],
    [{ type: "score_by_practice_area", component: ScoreByPracticeAreaChart }],
    [
        { type: "diversity_of_fees", component: DiversityOfFees },
        { type: "total_fees_by_role", component: TotalFeesByRoleChart },
    ],
    [
        { type: "total_fees_by_firm", component: TotalFeesByFirmChart },
        { type: "bill_rate_by_attribute_role", component: BillRateByAttributeChart },
    ],
    [
        { type: "diversity_of_hours", component: DiversityOfHours },
        { type: "credit_by_attribute", component: CreditByAttributeChart },
    ],
    [
        { type: "attributes_by_firm", component: AttributesByFirmChart },
        { type: "attributes_percents_by_firm", component: AttributesPercentsByFirmChart },
    ],
    [
        { type: "matter_lead_roles_by_attribute", component: MatterLeadRolesByAttributeChart },
        { type: "eligible_promoted_by_demographics", component: EligiblePromotedByDemographicsChart },
    ],
];
export default ClientSpecificCharts;
