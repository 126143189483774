import React from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";
import _map from "lodash/map";
import BodyStep from "../common/BodyStep";
import ActorFields from "../common/ActorFields";
import I18n from "../../../utils/i18n";

const UsersStep = ({ organizationUid, actorRoles }) => {
    const { values } = useFormikContext();

    const initActor = {
        uid: "",
        super_manager: false,
        role_ids: [],
        user_attributes: { last_name: "", first_name: "", email: "" },
        roles: [],
    };

    return (
        <BodyStep
            title={I18n.t("forms.organization.edit.tabs.users")}
            text={I18n.t("wizard.steps.users.text")}
            pro_tip={I18n.t("wizard.steps.users.pro_tip")}
        >
            <FieldArray name="actors">
                {({ push, remove }) => (
                    <>
                        {_map(values.actors, (actor, index) => (
                            <ActorFields
                                key={index}
                                actor={actor}
                                index={index}
                                remove={remove}
                                totalActors={values.actors.length}
                                organizationUid={organizationUid}
                                actorRoles={actorRoles}
                            />
                        ))}

                        <Form.Group>
                            <Button variant="outline-secondary" onClick={() => push(initActor)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Form.Group>
                    </>
                )}
            </FieldArray>
        </BodyStep>
    );
};

UsersStep.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    actorRoles: PropTypes.array.isRequired,
};

export default UsersStep;
