import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "../../../../common/components/DiversityPage/filters/common/MultipleChoiceFilter";

const DiversityCertificateTypesFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("tiered_diversity.filters.diversity_certificate_types.title")}
        filterKey="diversity_certificate_types"
        filterValues={values.diversity_certificate_types}
        defaultParams={defaultParams.diversity_certificate_types}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

DiversityCertificateTypesFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        diversity_certificate_types: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default DiversityCertificateTypesFilter;
