import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _last from "lodash/last";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus } from "@fortawesome/pro-solid-svg-icons";
import TooltipComponent from "../../../common/components/DiversityPage/TooltipComponent";
import I18n from "../../../utils/i18n";

const CHECKED_MAP = {
    manual: {
        categorize_supplier_type: false,
        identify_supplier: false,
        upload_certificate: false,
        tier_data: false,
    },
    automated: {
        categorize_supplier_type: true,
        identify_supplier: true,
        upload_certificate: true,
        tier_data: false,
    },
    tiered_collection: {
        categorize_supplier_type: true,
        identify_supplier: true,
        upload_certificate: true,
        tier_data: true,
    },
};

const CategoryRow = ({ category, lookupKeys }) => {
    const isChecked = (lookupKey) => CHECKED_MAP[_last(lookupKey.split("/"))][category];

    return (
        <tr>
            <td className="font-weight-bolder border-bottom align-middle">
                <div className="d-flex align-items-center">
                    <h3 className="w-100 px-1 py-2">
                        {I18n.t(`tiered_diversity.pricing.categories.${category}.title`)}
                    </h3>
                    <TooltipComponent content={I18n.t(`tiered_diversity.pricing.categories.${category}.tooltip`)} />
                </div>
            </td>
            {_map(lookupKeys, (lookupKey) => (
                <td key={lookupKey} className="text-center border-bottom align-middle">
                    <FontAwesomeIcon
                        icon={isChecked(lookupKey) ? faCheck : faMinus}
                        size="lg"
                        className={isChecked(lookupKey) ? "text-primary" : "text-secondary"}
                    />
                </td>
            ))}
        </tr>
    );
};

CategoryRow.propTypes = { category: PropTypes.string.isRequired, lookupKeys: PropTypes.array.isRequired };

export default CategoryRow;
