import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import MultipleChoiceFilter from "./common/MultipleChoiceFilter";

const LocationsFilter = ({ params, setParams, values, defaultParams, applyFilters }) => (
    <MultipleChoiceFilter
        label={I18n.t("diversity.buttons.select_locations")}
        filterKey="locations"
        filterValues={values.locations}
        defaultParams={defaultParams.locations}
        params={params}
        setParams={setParams}
        applyFilters={applyFilters}
    />
);

LocationsFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        locations: PropTypes.array.isRequired,
    }),
    defaultParams: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default LocationsFilter;
