import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import {
    EditPasswordPage,
    NewPasswordPage,
    SignInPage,
    UnlockPage,
    NewInvitationPage,
    AcceptInvitationPage,
} from "../../pages";
import { DismissibleAlert, FooterContainer } from "../components";

function LayoutContainer() {
    return (
        <div className="page-wrapper">
            <DismissibleAlert />
            <div id="content" className="hide-sidebar">
                <Container className="h-100">
                    <Row className="with-footer">
                        <Col md={3} />
                        <Col md={6} className="d-flex flex-column justify-content-center">
                            <Router>
                                <Switch>
                                    <Route exact path="/" component={SignInPage} />
                                    <Route exact path="/users/password/new" component={NewPasswordPage} />
                                    <Route exact path="/users/password/edit" component={EditPasswordPage} />
                                    <Route exact path="/users/unlock/new" component={UnlockPage} />
                                    <Route exact path="/users/unlock" component={UnlockPage} />
                                    <Route exact path="/users/invitation/new" component={NewInvitationPage} />
                                    <Route exact path="/users/invitation/accept" component={AcceptInvitationPage} />
                                    <Route render={() => <Redirect to="/" />} />
                                </Switch>
                            </Router>
                        </Col>
                    </Row>
                </Container>
                <FooterContainer />
            </div>
        </div>
    );
}

export default LayoutContainer;
