import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { getQuestionnaireStats } from "../../../../api/industrySpecificApi";
import I18n from "../../../../utils/i18n";

const IndustrySpecificQuestionsTab = ({ organizationUid }) => {
    const [stats, setStats] = useState({ total_questions: 0, total_answered_questions: 0 });

    useEffect(() => {
        getQuestionnaireStats(organizationUid).then((response) => {
            setStats(response.data);
        });
    }, [organizationUid]);

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <h3>{I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.title")}</h3>

            <ul className="list-unstyled">
                <li>
                    <strong>
                        {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.total", {
                            count: stats.total_questions,
                        })}
                    </strong>
                </li>
                <li>
                    {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.answered", {
                        count: stats.total_answered_questions,
                    })}
                </li>
                <li>
                    {I18n.t("forms.organization.edit.tabs.industry_specific_questions.stats.not_answered", {
                        count: stats.total_questions - stats.total_answered_questions,
                    })}
                </li>
            </ul>
        </Container>
    );
};

IndustrySpecificQuestionsTab.propTypes = {
    organizationUid: PropTypes.string.isRequired,
};

export default IndustrySpecificQuestionsTab;
