import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificMirrorChart from "./DiversityClientSpecificMirrorChart";
import { getKLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const HoursAttributesByRole = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        type="hours_attributes_by_role"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => getKLabelForValue(value),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t(
                                "diversity.axises.client_specific_mirror.hours_attributes_by_role.x_axis"
                            ),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        tooltipItem.value === "NaN"
                            ? ""
                            : `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(
                                  tooltipItem.value
                              ).toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

HoursAttributesByRole.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default HoursAttributesByRole;
