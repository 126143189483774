import _first from "lodash/first";
import _map from "lodash/map";
import _last from "lodash/last";
import _fromPairs from "lodash/fromPairs";

const getDefaultParamsForFilter = (values) => ({
    quarter: (_first(_map(values.quarter || [], "id")) || "").toString(),
    quarters: _map(values.quarters || [], "id"),
    hire_date: (_last(_map(values.hire_date || [], "id")) || "").toString(),
    score: (values.range_score ? [values.range_score.min, values.range_score.max] : [0, 0]).join("-"),
    firm: (_first(_map(values.firm || [], "id")) || "").toString(),
    firms: _map(values.firms || [], "id"),
    firm_size: (_first(_map(values.firmfirm_sizes || [], "id")) || 501).toString(),
    firm_sizes: _map(values.firm_sizes || [], "id"),
    clients: _map(values.clients || [], "id"),
    roles: _map(values.roles || [], "id"),
    attributes: _fromPairs(
        Object.keys(values.attributes || {}).map((attribute) => [
            attribute,
            values.attributes[attribute].values.map((option) => option.value),
        ])
    ),
    leaderships: [],
    lists: "",
    statuses: _map(values.statuses || [], "id"),
    practice_areas: _map(values.practice_areas || [], "id"),
    locations: _map(values.locations || [], "id"),
});

export { getDefaultParamsForFilter };
