import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import I18n from "../../../utils/i18n";

const AddFirmModalCheckbox = ({ firm, visible = true, checked = false, handleChange }) => (
    <Form.Check
        custom
        className={classNames("ml-3", { "d-none": !visible })}
        key={firm.id}
        type="checkbox"
        disabled={firm.requested || firm.reviewed}
        checked={checked}
        label={compact([
            firm.name,
            (firm.requested || firm.reviewed) &&
                I18n.t("activerecord.attributes.diversity/participation.statuses.requested"),
        ]).join(" - ")}
        name="firms"
        onChange={handleChange}
        value={firm.id}
        id={`firms-${firm.id}`}
    />
);

AddFirmModalCheckbox.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        requested: PropTypes.bool.isRequired,
        reviewed: PropTypes.bool.isRequired,
    }).isRequired,
    visible: PropTypes.bool,
    checked: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
};

export default AddFirmModalCheckbox;
