import React from "react";
import { Col, Container } from "react-bootstrap";
import I18n from "../../utils/i18n";
import ActivityLogsTable from "./components/ActivityLogsTable";

const ActivityLogsPage = () => (
    <>
        <div className="top-bar">
            <Col>
                <Container fluid>
                    <div className="py-3" />
                </Container>
            </Col>
        </div>
        <div className="scrollable d-flex flex-column">
            <div className="flex-grow-1 overflow-auto bg-white">
                <Col>
                    <Container fluid className="py-3">
                        <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                            <h2>{I18n.t("sidebar.links.manage.activity_logs")}</h2>
                        </div>
                        <ActivityLogsTable />
                    </Container>
                </Col>
            </div>
        </div>
    </>
);

export default ActivityLogsPage;
