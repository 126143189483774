import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const CreditByAttributeChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="credit_by_attribute"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => `${value} %`,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.credit_by_attribute.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(2)
                            .toLocaleString(I18n.locale)} %`,
                },
            },
        }}
    />
);

CreditByAttributeChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default CreditByAttributeChart;
