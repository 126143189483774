import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import moment from "moment";
import I18n from "../../utils/i18n";

const TermsAndConditionsPage = ({ currentWhitelabel }) => (
    <div className="scrollable full-scrollable d-flex flex-column">
        <div className="flex-grow-1 overflow-auto bg-white">
            <Col>
                <Container fluid className="py-3">
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                        <h2>{I18n.t("terms_and_conditions.title")}</h2>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: I18n.t(`terms_and_conditions.${currentWhitelabel.key}`, {
                                privacy_policy_path: "/privacy_policy",
                                year: moment().year(),
                            }),
                        }}
                    />
                </Container>
            </Col>
        </div>
    </div>
);

const mapStateToProps = (state) => ({
    currentWhitelabel: state.currentWhitelabel.resource,
});

TermsAndConditionsPage.propTypes = {
    currentWhitelabel: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(TermsAndConditionsPage);
