import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { getIn } from "formik";

const LocaleSelect = ({ form }) => {
    const options = [
        { label: "US English", value: "en-US" },
        { label: "Español (México)", value: "es-MX" },
        { label: "简化中国 (Chinese - Simplified)", value: "zh-CN" },
    ];
    const name = "actor[user_attributes][locale]";

    return (
        <Select
            name={name}
            className="react-select"
            classNamePrefix="react-select"
            options={options}
            onChange={(option) => form.setFieldValue(name, option.value)}
            onBlur={() => {
                form.setFieldTouched(name);
            }}
            value={options.find(
                (option) => option.value === (getIn(form.values, "actor.user_attributes.locale") || "")
            )}
        />
    );
};

LocaleSelect.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    }),
};

export default LocaleSelect;
