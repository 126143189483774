import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import ClientSpecificProgressCharts from "../../utils/clientSpecificProgressCharts";
import FiltersBar from "./components/FiltersBar";
import ExportButton from "../../common/components/DiversityPage/ExportButton";
import { getClientSpecificProgressFilters, updateClientSpecificProgressCharts } from "../../api/diversityApi";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";

const DiversityClientSpecificProgressPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const [chartParams, setChartParams] = useState({});
    const [filter, setFilter] = useState({});
    const [changedFilters, setChangedFilters] = useState({});
    const canvasRefs = useRef({});

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <Row>
                        <Col className="mr-auto">
                            {!_isEmpty(filter) && !_isEmpty(chartParams) && (
                                <ExportButton
                                    canvasRefs={canvasRefs}
                                    title="sidebar.links.diversity.client_specific_progress"
                                    filter={filter}
                                    changedFilters={changedFilters}
                                    chartParams={chartParams}
                                />
                            )}
                        </Col>
                        <Col className="col-auto">
                            <FiltersBar
                                setChartParams={setChartParams}
                                getFilters={getClientSpecificProgressFilters}
                                setFilter={setFilter}
                                setChangedFilters={setChangedFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="scrollable bg-white">
                <div className="overflow-auto">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={ClientSpecificProgressCharts}
                        updateCharts={updateClientSpecificProgressCharts}
                        channelName="Diversity::ClientSpecificProgress::ChartsChannel"
                        namespace="client_specific_progress"
                    />
                </div>
            </div>
        </div>
    );
};

export default DiversityClientSpecificProgressPage;
