import React from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import I18n from "../../utils/i18n";
import FormDiversityCertificate from "../../common/components/FormDiversityCertificate";
import { createCertificate } from "../../api/diversityApi";
import { setAlert } from "../../state/alert/alertActions";
import { CertificateValidation } from "../../common/components/DiversityPage/CertificateValidationSchema";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import NewPageTitle from "../../common/components/NewPageTitle";

const NewDiversityCertificatePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { uid } = useParams();

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createCertificate({ values })
            .then(() => {
                resetForm(values);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                history.push(`/organizations/${uid}/diversityCertificates`);
            })
            .catch((error) => {
                setSubmitting(false);
                setErrors(error.response.data.errors);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
            });
    };

    return (
        <Formik
            initialValues={{ uid: uid }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
                diversity_certificate: CertificateValidation,
            })}
        >
            {(form) => (
                <>
                    <TopBarWithBackLink
                        backPath={`/organizations/${uid}/diversityCertificates`}
                        linkName={I18n.t("forms.organization.edit.tabs.diversity_certificates")}
                    />
                    <NavigationPrompt when={form.dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <NewPageTitle resource={I18n.t("activerecord.models.diversity/certificate")} />
                                    <FormikForm as={Form}>
                                        <FormDiversityCertificate form={form} organizationUid={uid} />
                                    </FormikForm>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                type="button"
                                                className="ml-2"
                                                variant="outline-secondary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleReset}
                                            >
                                                {I18n.t("common.links.cancel")}
                                            </Button>
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleSubmit}
                                            >
                                                {form.isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default NewDiversityCertificatePage;
