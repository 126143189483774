import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import consumer from "./consumer";

function useChannel({ channelParams, onReceived = null, onConnected = null, readyToSubscribe = true }) {
    const [, setChannel] = useState(null);

    useEffect(() => {
        if (readyToSubscribe) {
            const channel = consumer.subscriptions.create(channelParams, {
                received(data) {
                    onReceived(data);
                },
                connected() {
                    onConnected && onConnected();
                },
            });
            setChannel(channel);

            return () => {
                channel.unsubscribe();
            };
        }
    }, [readyToSubscribe]);
}

useChannel.propTypes = {
    channelParams: PropTypes.object.isRequired,
    onReceived: PropTypes.func,
    onConnected: PropTypes.func,
    readyToSubscribe: PropTypes.bool,
};

export default useChannel;
