import React from "react";
import { Spinner } from "react-bootstrap";

const AppLoader = () => (
    <div className="page-wrapper">
        <div id="main-loader">
            <Spinner animation="border" role="status" variant="primary" />
        </div>
    </div>
);

export default AppLoader;
