import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Dropdown, Form } from "react-bootstrap";
import * as _ from "lodash";
import I18n from "../../../../utils/i18n";

const AttributesFilter = ({ params, setParams, values, applyFilters }) => {
    const filterKey = "attributes";
    const filterValues = values[filterKey];
    const componentParams = params[filterKey];
    const [searchCriteria, setSearchCriteria] = useState("");

    const getOptionsPaths = ({ diverse }) =>
        _.flatten(
            _.keys(filterValues).map((attribute) =>
                _.map(
                    _.isBoolean(diverse)
                        ? _.filter(filterValues[attribute].values, (option) => diverse === option.diverse)
                        : filterValues[attribute].values,
                    (option) => `${attribute}.${option.value}`,
                ),
            ),
        );

    const getSelectedOptionsPaths = () =>
        _.flatten(
            _.keys(componentParams).map((attribute) =>
                _.map(componentParams[attribute], (option) => `${attribute}.${option}`),
            ),
        );

    const updateParams = (checked, value, path) => {
        componentParams[path] = componentParams[path] || [];
        checked ? componentParams[path].push(value) : _.remove(componentParams[path], (element) => value === element);
        setParams({ ...params, [filterKey]: componentParams });
    };

    const selectAll = (checked, attribute, values) => {
        const currentOptions = componentParams[attribute] || [];
        const newOptions = _.map(
            _.filter(values, (option) => checked || isShown([option])),
            "value",
        );

        componentParams[attribute] = checked
            ? _.uniq([...currentOptions, ...newOptions])
            : _.xor(currentOptions, newOptions);
        setParams({ ...params, [filterKey]: componentParams });
    };

    const selectAllDiverseOptions = (checked, diverse) => {
        _.each(_.keys(filterValues), (attribute) => {
            componentParams[attribute] = componentParams[attribute] || [];
            _.each(
                _.filter(filterValues[attribute].values, (option) => diverse === option.diverse),
                (option) =>
                    checked
                        ? (componentParams[attribute] = _.union(componentParams[attribute], [option.value]))
                        : _.remove(componentParams[attribute], (element) => option.value === element),
            );
        });
        setParams({ ...params, [filterKey]: componentParams });
    };

    const selectAllGlobal = (checked) => {
        _.each(_.keys(filterValues), (attribute) => selectAll(checked, attribute, filterValues[attribute].values));
    };

    const isChecked = (attribute, value) => !!_.find(componentParams[attribute], (element) => element === value);

    const isGlobalChecked = ({ diverse }) => {
        const selectedOptionsPaths = getSelectedOptionsPaths();

        return getOptionsPaths({ diverse }).every((path) => selectedOptionsPaths.includes(path));
    };

    const isCheckedSelectAll = (attribute, values) =>
        _.isEqual((componentParams[attribute] || []).sort(), _.map(values, (option) => option.value).sort());

    const isShown = (values) => {
        const criteria = searchCriteria.toLowerCase().trim();

        if (criteria.length) {
            return _.some(values, (value) => value.label.toLowerCase().trim().indexOf(criteria) >= 0);
        } else {
            return true;
        }
    };

    const badgeCount = () => {
        const defaultOptionsPaths = getOptionsPaths({});
        const selectedOptionsPaths = getSelectedOptionsPaths();

        return defaultOptionsPaths.length == selectedOptionsPaths.length ? -1 : selectedOptionsPaths.length;
    };

    return (
        <Dropdown as="div" className="d-inline-flex">
            <Dropdown.Toggle variant="primary" className="text-light m-1">
                {I18n.t("diversity.buttons.select_attributes")}
                {badgeCount() >= 0 && (
                    <Badge variant="light" className="mx-1">
                        {badgeCount()}
                    </Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="dropdown-item">
                    <Form.Control
                        size="sm"
                        onChange={(event) => setSearchCriteria(event.currentTarget.value)}
                        value={searchCriteria}
                    />
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    {_.map(_.keys(filterValues), (attribute, attributeIndex) => {
                        const attributesData = filterValues[attribute];

                        return (
                            isShown(attributesData.values) && (
                                <div key={attributeIndex}>
                                    <div className="dropdown-item">
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id={`selectAllCheckbox-${attribute}`}
                                            label={attributesData.header}
                                            checked={isCheckedSelectAll(attribute, attributesData.values)}
                                            onChange={(event) =>
                                                selectAll(event.currentTarget.checked, attribute, attributesData.values)
                                            }
                                        />
                                    </div>
                                    {_.map(
                                        attributesData.values,
                                        (options, optionIndex) =>
                                            isShown([options]) && (
                                                <div className="dropdown-item ml-3" key={optionIndex}>
                                                    <Form.Check
                                                        custom
                                                        name={options.value}
                                                        type="checkbox"
                                                        id={`custom-checkbox-${attributeIndex}-${optionIndex}`}
                                                        label={options.label}
                                                        checked={isChecked(attribute, options.value)}
                                                        onChange={(event) =>
                                                            updateParams(
                                                                event.currentTarget.checked,
                                                                options.value,
                                                                attribute,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ),
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <div className="dropdown-item">
                        <Form.Check
                            custom
                            type="checkbox"
                            id="selectAllCheckbox-diverse"
                            label={I18n.t("diversity.buttons.select_diverse")}
                            checked={isGlobalChecked({ diverse: true })}
                            onChange={(event) => selectAllDiverseOptions(event.currentTarget.checked, true)}
                        />
                    </div>
                    <div className="dropdown-item">
                        <Form.Check
                            custom
                            type="checkbox"
                            id="selectAllCheckbox-nonDiverse"
                            label={I18n.t("diversity.buttons.select_non_diverse")}
                            checked={isGlobalChecked({ diverse: false })}
                            onChange={(event) => selectAllDiverseOptions(event.currentTarget.checked, false)}
                        />
                    </div>
                    <div className="dropdown-item">
                        <Form.Check
                            custom
                            type="checkbox"
                            id="selectAllCheckbox-all"
                            label={I18n.t("common.select_all")}
                            checked={isGlobalChecked({})}
                            onChange={(event) => selectAllGlobal(event.currentTarget.checked, false)}
                        />
                    </div>
                </div>
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <Dropdown.Item onClick={applyFilters} className="text-center">
                        {I18n.t("diversity.buttons.submit")}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

AttributesFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        attributes: PropTypes.object.isRequired,
    }),
    applyFilters: PropTypes.func.isRequired,
};

export default AttributesFilter;
