import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import { getKLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";
import { currencyChartLabel } from "../../../../utils/calculateTotal";

const FeesVsClientScoreChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="fees_vs_client_score"
        chartType="scatter"
        chartOptions={{
            ...chartOptions,
            legend: { display: false },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => getKLabelForValue(value),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.fees_vs_client_score.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.fees_vs_client_score.y_axis"),
                        },
                    },
                ],
            },
            elements: {
                point: {
                    radius: 5,
                },
            },
            tooltips: {
                callbacks: {
                    label: (item, data) =>
                        `${data.datasets[item.datasetIndex].label || ""}: ` +
                        `(${currencyChartLabel(item.xLabel)}, ${item.yLabel.toFixed(1).toLocaleString(I18n.locale)})`,
                },
            },
        }}
    />
);

FeesVsClientScoreChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default FeesVsClientScoreChart;
