import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificReportsChart from "./DiversityClientSpecificReportsChart";

const DiversityOfHoursChart = ({ inProgress, canvasRefs, chartOptions, chartData, position }) => (
    <DiversityClientSpecificReportsChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="diversity_of_hours"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: (value) => `${value} %`,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific_reports.diversity_of_hours.y_axis"),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(2)
                            .toLocaleString(I18n.locale)} %`,
                },
            },
        }}
    />
);

DiversityOfHoursChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default DiversityOfHoursChart;
