import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Table from "../../../common/components/Table";
import I18n from "../../../utils/i18n";
import { HeaderWithToolTip } from "./PartnershipsTable";

const SubPartnershipsTable = ({ currency, subPartnerships }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.certificate.name"),
                accessor: "name",
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/monthly_partnership_stats.sub_total"),
                    I18n.t("activerecord.attributes.tiered_diversity/monthly_partnership_stats.tooltip")
                ),
                accessor: "total_amount",
                Cell: ({
                    row: {
                        original: { total_amount },
                    },
                }) => `${currency.symbol}${parseFloat(total_amount.toFixed(2)).toLocaleString(I18n.locale)}`,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/partnership.sub_partnerships_spend_percent.title"),
                    I18n.t(
                        "activerecord.attributes.tiered_diversity/partnership.sub_partnerships_spend_percent.tooltip"
                    )
                ),
                accessor: "percents",
                Cell: ({
                    row: {
                        original: { percents },
                    },
                }) => `${percents} %`,
            },
        ],
        [currency]
    );

    return (
        <Table
            data={subPartnerships.grouped_sub_partnerships}
            columns={columns}
            fetchData={() => {}}
            pageCount={-1}
            loading={false}
            initialSortBy={[{ id: "total_amount", desc: true }]}
            manualSortBy={false}
        />
    );
};

SubPartnershipsTable.propTypes = {
    currency: PropTypes.shape({
        iso_code: PropTypes.string,
        symbol: PropTypes.string,
    }),
    subPartnerships: PropTypes.shape({
        grouped_sub_partnerships: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                percents: PropTypes.number,
                total_amount: PropTypes.number,
            })
        ),
    }),
};

export default SubPartnershipsTable;
