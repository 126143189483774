import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";
import ethnicities from "../../../../utils/ethnicities";
import I18n from "../../../../utils/i18n";

const OwnershipInputs = ({ values, handleChange, setFieldValue, setFieldTouched, errors, ownerType }) => {
    const ethnicityOptions = ethnicities.map((ethnicity) => ({
            label: I18n.t(`activerecord.attributes.company_structure.ethnicities.${ethnicity}`),
            value: ethnicity,
        }));

    return (
        <>
            <Row>
                <Col>
                    <Form.Group controlId={`${ownerType}_ownership_attributes_women_owned`} className="mt-4">
                        <Form.Label>{I18n.t("activerecord.attributes.company_structure.women_owned")}</Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][women_owned]`}
                            onChange={handleChange}
                            checked={values.women_owned}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId={`${ownerType}_ownership_attributes_minority_owned`} className="mt-4">
                        <Form.Label>{I18n.t("activerecord.attributes.company_structure.minority_owned")}</Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][minority_owned]`}
                            onChange={(event) => {
                                let value = event.target.checked;
                                if (!value) {
                                    setFieldValue(`${ownerType}[ownership_attributes][ethnicity]`, null);
                                }
                                setFieldValue(`${ownerType}[ownership_attributes][minority_owned]`, value);
                            }}
                            checked={values.minority_owned}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    {values.minority_owned && (
                        <Form.Group controlId={`${ownerType}_ownership_attributes_ethnicity`} className="mb-0 mt-0">
                            <Form.Label>{I18n.t("activerecord.attributes.company_structure.ethnicity")}</Form.Label>
                            <Select
                                name={`${ownerType}[ownership_attributes][ethnicity]`}
                                className={classNames("react-select", { "is-invalid": errors?.ethnicity })}
                                classNamePrefix="react-select"
                                options={ethnicityOptions}
                                onChange={(option) => {
                                    setFieldValue(`${ownerType}[ownership_attributes][ethnicity]]`, option.value);
                                }}
                                onBlur={() => setFieldTouched(`${ownerType}[ownership_attributes][ethnicity]`)}
                                value={ethnicityOptions.find((option) => option.value === values.ethnicity)}
                            />
                            <Form.Control.Feedback type="invalid">{errors?.ethnicity}</Form.Control.Feedback>
                        </Form.Group>
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group controlId={`${ownerType}_ownership_attributes_lgbtq_owned`} className="mt-4">
                        <Form.Label>{I18n.t("activerecord.attributes.company_structure.lgbtq_owned")}</Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][lgbtq_owned]`}
                            onChange={handleChange}
                            checked={values.lgbtq_owned}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId={`${ownerType}_ownership_attributes_veteran_owned`} className="mt-4">
                        <Form.Label>{I18n.t("activerecord.attributes.company_structure.veteran_owned")}</Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][veteran_owned]`}
                            onChange={handleChange}
                            checked={values.veteran_owned}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group
                        controlId={`${ownerType}_ownership_attributes_differently_abled_owned`}
                        className="mt-4"
                    >
                        <Form.Label>
                            {I18n.t("activerecord.attributes.company_structure.differently_abled_owned")}
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][differently_abled_owned]`}
                            onChange={handleChange}
                            checked={values.differently_abled_owned}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId={`${ownerType}_ownership_attributes_disadvantaged_business`} className="mt-4">
                        <Form.Label>
                            {I18n.t("activerecord.attributes.company_structure.disadvantaged_business")}
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            name={`${ownerType}[ownership_attributes][disadvantaged_business]`}
                            onChange={handleChange}
                            checked={values.disadvantaged_business}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

OwnershipInputs.propTypes = {
    values: PropTypes.shape({
        women_owned: PropTypes.bool,
        minority_owned: PropTypes.bool,
        ethnicity: PropTypes.string,
        lgbtq_owned: PropTypes.bool,
        veteran_owned: PropTypes.bool,
        differently_abled_owned: PropTypes.bool,
        disadvantaged_business: PropTypes.bool,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    ownerType: PropTypes.string.isRequired,
};

export default OwnershipInputs;
