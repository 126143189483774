import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { getSources } from "../../../api/activityApi";
import I18n from "../../../utils/i18n";

const ActivityLogsFilterSource = () => {
    const [field] = useField("q[source]");
    const { setFieldValue } = useFormikContext();
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        getSources()
            .then((response) => {
                setOptions(
                    response.data.map((source) => ({
                        value: source,
                        label: I18n.t(`activerecord.attributes.activity/log.sources.${source}`),
                    }))
                );
            })
            .catch(() => setOptions([]))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Form.Group as={Col}>
            <Form.Label htmlFor="q_source">{I18n.t("activerecord.attributes.activity/log.source")}</Form.Label>
            <Select
                id="q_source"
                name="q[source]"
                className="react-select"
                classNamePrefix="react-select"
                options={options}
                onChange={(options) =>
                    setFieldValue(
                        "q[source]",
                        options.map((option) => option.value)
                    )
                }
                isLoading={isLoading}
                isMulti
                isClearable
                value={options.filter((option) => (field.value || "").includes(option.value))}
            />
        </Form.Group>
    );
};

export default ActivityLogsFilterSource;
