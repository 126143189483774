import React, { useCallback, useMemo, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import _isEmpty from "lodash/isEmpty";
import { Portal } from "react-overlays";
import PropTypes from "prop-types";
import { getClientSpecificRequests } from "../../../../api/informationApi";
import WidgetCard from "../common/WidgetCard";
import ThreeDotsToggle from "../../../../common/components/Table/ThreeDotsToggle";
import Table from "../../../../common/components/Table";
import I18n from "../../../../utils/i18n";

const ActionsCell = ({ containerRef }) => (
    <Dropdown>
        <Dropdown.Toggle as={ThreeDotsToggle} />
        <Portal container={containerRef}>
            <Dropdown.Menu size="sm">
                <Dropdown.Item href="diversity/client_specific">
                    <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
                    {I18n.t("tiered_diversity.requests.customers.buttons.upload")}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Portal>
    </Dropdown>
);

ActionsCell.propTypes = {
    containerRef: PropTypes.object,
};

const DeadlineAtCell = ({ row: { original } }) =>
    I18n.l("time.formats.short", original.deadline_at) || I18n.t("common.not_available");

const ClientSpecificRequests = () => {
    const containerRef = useRef(null);

    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.diversity/participation.diversity_firm_id"),
                accessor: "client_name",
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.deadline_at"),
                accessor: "deadline_at",
                Cell: DeadlineAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} containerRef={containerRef} />,
            },
        ],
        [],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);

        getClientSpecificRequests().then((response) => {
            setData(response.data.records);
            setLoading(false);
        });
    }, []);

    return (
        <WidgetCard
            title={I18n.t("home.diversity_analytics.requests.client_specific.title")}
            containerRef={containerRef}
        >
            {!loading && _isEmpty(data) && (
                <p>{I18n.t("home.diversity_analytics.requests.client_specific.empty_data")}</p>
            )}
            <Table
                loading={loading}
                data={data}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "deadline_at", desc: true }]}
                manualSortBy={false}
            />
        </WidgetCard>
    );
};

export default ClientSpecificRequests;
