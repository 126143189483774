import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import _includes from "lodash/includes";

const PrivateRoute = ({ children, renderer, ...rest }) => {
    const currentUser = useSelector((store) => store.currentUser.resource);
    const currentActor = useSelector((store) => store.currentActor.resource);
    const allowedRoutes = [
        "/organizations/wizard",
        "/select_organizations",
        "/terms_and_conditions",
        "/privacy_policy",
        "/oec_faq",
        "/oec_privacy_faq",
    ];

    return (
        <Route
            {...rest}
            render={({ location, ...props }) => {
                if (currentUser.need_change_password && location.pathname !== "/users/password_expired") {
                    return (
                        <Redirect
                            to={{
                                pathname: "/users/password_expired",
                                state: { from: location },
                            }}
                        />
                    );
                } else if (currentActor?.organization?.show_wizard && !_includes(allowedRoutes, location.pathname)) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/organizations/wizard",
                                state: { from: location },
                            }}
                        />
                    );
                } else {
                    return renderer ? renderer({ location, ...props }) : children;
                }
            }}
        />
    );
};

PrivateRoute.propTypes = {
    children: PropTypes.node,
    renderer: PropTypes.func,
};

export default PrivateRoute;
