import React, { useState } from "react";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import _values from "lodash/values";
import { updateNegotiation } from "../../../../../api/listsApi";
import I18n from "../../../../../utils/i18n";
import { StepperHeader } from "../../../../../common/components/Stepper";
import { PreviewStep, SearchStep, ResultStep } from "./steps/index";
import CancelButton from "../common/CancelButton";

const STEPS = [
    { title: I18n.t("companies.lists.sections.export.export_to_exist.search_step"), key: "search" },
    { title: I18n.t("companies.lists.sections.export.common.preview_step"), key: "preview" },
    { title: I18n.t("diversity.clients.steps.result"), key: "result" },
];

const STEP_COMPONENT = {
    search: SearchStep,
    preview: PreviewStep,
    result: ResultStep,
};

const AddCompaniesToNegotiationModal = ({ show, handleClose, companyIds }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [result, setResult] = useState({});

    const stepProps = {
        search: { goToPage: () => setCurrentStep(1) },
        preview: { companyIds: companyIds, scope: "export_to_exist" },
        result: { result: result, scope: "export_to_exist" },
    };

    const exportToNegotiation = (values, { setSubmitting }) => {
        const actors = _values(values.organizations);
        updateNegotiation(values.negotiation_id, { actors: actors })
            .then(({ data }) => {
                setResult(data);
                setSubmitting(false);
            })
            .catch(() => setSubmitting(false))
            .finally(() => setCurrentStep(2));
    };

    return (
        <Formik onSubmit={exportToNegotiation} initialValues={{}}>
            {({ handleReset }) => (
                <Modal
                    show={show}
                    size="lg"
                    onHide={() => {
                        setCurrentStep(0);
                        handleClose(handleReset);
                    }}
                >
                    <Modal.Header closeButton>
                        {I18n.t("companies.lists.sections.export.export_to_exist.title")}
                    </Modal.Header>

                    {React.createElement(
                        STEP_COMPONENT[STEPS[currentStep].key],
                        {
                            ...stepProps[STEPS[currentStep].key],
                            cancelButton: <CancelButton setCurrentStep={setCurrentStep} handleClose={handleClose} />,
                        },
                        <StepperHeader steps={STEPS} currentStep={currentStep} />
                    )}
                </Modal>
            )}
        </Formik>
    );
};

AddCompaniesToNegotiationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    companyIds: PropTypes.array.isRequired,
};

export default AddCompaniesToNegotiationModal;
