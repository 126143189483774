import React from "react";
import PropTypes from "prop-types";
import PageTitle from "./PageTitle";
import I18n from "../../utils/i18n";

const EditPageTitle = ({ resource }) => <PageTitle text={I18n.t("common.titles.edit", { resource: resource })} />;

EditPageTitle.propTypes = {
    resource: PropTypes.string,
};

export default EditPageTitle;
