import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const AttributesByFirmChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="attributes_by_firm"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific.attributes_by_firm.y_axis"),
                        },
                    },
                ],
            },
        }}
    />
);

AttributesByFirmChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default AttributesByFirmChart;
