import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificProgressChart from "./DiversityClientSpecificProgressChart";
import { currencyChartLabel } from "../../../../utils/calculateTotal";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const TotalFeesByFirmChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificProgressChart
        inProgress={inProgress}
        chartData={chartData}
        canvasRefs={canvasRefs}
        type="total_fees_by_firm"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: (value) => currencyChartLabel(value),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific_progress.total_fees_by_firm.y_axis"),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${currencyChartLabel(tooltipItem.value)}`,
                },
            },
        }}
    />
);

TotalFeesByFirmChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default TotalFeesByFirmChart;
