import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _find from "lodash/find";
import _isNull from "lodash/isNull";
import _indexOf from "lodash/indexOf";
import I18n from "../../../../utils/i18n";

const ActorRoles = ({ roles, actorRoles, setFieldValue }) => {
    const onChange = (event, actorRole, index) => {
        setFieldValue(`actor[actor_roles_attributes][${index}][role_id]`, actorRole.role_id);
        setFieldValue(`actor[actor_roles_attributes][${index}][_destroy]`, !event.target.checked);
    };

    return _map(roles, (role) => {
        const actorRole = _find(actorRoles, ["role_id", role.id]) || { id: null, role_id: role.id, _destroy: true };
        const actorRoleIndex = _isNull(actorRole.id) ? actorRoles.length : _indexOf(actorRoles, actorRole);

        return (
            <Form.Group
                className="d-flex mb-0"
                controlId={`actor_actor_roles_attributes_${role.id}_role_id`}
                key={role.id}
            >
                <Form.Check
                    type="switch"
                    label={I18n.t(`activerecord.attributes.actor.${role.name}`)}
                    name={`actor[actor_roles_attributes][${actorRoleIndex}][role_id]`}
                    onChange={(event) => onChange(event, actorRole, actorRoleIndex)}
                    checked={!actorRole._destroy}
                />
                <p className="ml-1">{I18n.t(`activerecord.attributes.actor.descriptions.${role.name}`)}</p>
            </Form.Group>
        );
    });
};

ActorRoles.propTypes = {
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            context: PropTypes.string.isRequired,
        })
    ),
    actorRoles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            role_id: PropTypes.number,
            _destroy: PropTypes.bool,
        })
    ),
    setFieldValue: PropTypes.func.isRequired,
};

export default ActorRoles;
