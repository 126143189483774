import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useFormikContext } from "formik";
import I18n from "../../../../utils/i18n";
import legalStatuses from "../../../../utils/legalStatuses";
import companyTypes from "../../../../utils/companyTypes";

const CompanyStructureSection = ({ values, errors }) => {
    const { handleChange, setFieldValue, setFieldTouched } = useFormikContext();

    const legalStatusOptions = legalStatuses.map((legalStatus) => ({
            label: I18n.t(`activerecord.attributes.company_structure.legal_statuses.${legalStatus}`),
            value: legalStatus,
        }));

    const companyTypeOptions = companyTypes.map((companyType) => ({
            label: I18n.t(`activerecord.attributes.company_structure.company_types.${companyType}`),
            value: companyType,
        }));

    return (
        <Row>
            <Col>
                <Form.Group>
                    <h3>{I18n.t("activerecord.models.company_structure")}</h3>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_duns">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.duns")}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            name="organization[company_structure_attributes][duns]"
                            onChange={handleChange}
                            value={values.duns || ""}
                            isInvalid={errors.duns || errors["company_structure.duns"]}
                        />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        {errors.duns || errors["company_structure.duns"]}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_legal_status">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.legal_status")}</Form.Label>
                    <Select
                        name="organization[company_structure_attributes][legal_status]"
                        className="react-select"
                        classNamePrefix="react-select"
                        options={legalStatusOptions}
                        onChange={(option) => {
                            setFieldValue("organization[company_structure_attributes][legal_status]", option.value);
                        }}
                        onBlur={() => setFieldTouched("organization[company_structure_attributes][legal_status]")}
                        value={legalStatusOptions.find((option) => option.value === values.legal_status)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.legal_status}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_company_type">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.company_type")}</Form.Label>
                    <Select
                        name="organization[company_structure_attributes][company_type]"
                        className="react-select"
                        classNamePrefix="react-select"
                        options={companyTypeOptions}
                        onChange={(option) => {
                            setFieldValue("organization[company_structure_attributes][company_type]", option.value);
                        }}
                        onBlur={() => setFieldTouched("organization[company_structure_attributes][company_type]")}
                        value={companyTypeOptions.find((option) => option.value === values.company_type)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.company_type}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_doing_business_as">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.doing_business_as")}</Form.Label>
                    <Form.Control
                        type="text"
                        name="organization[company_structure_attributes][doing_business_as]"
                        onChange={handleChange}
                        value={values.doing_business_as || ""}
                        isInvalid={errors.doing_business_as}
                    />
                    <Form.Control.Feedback type="invalid">{errors.doing_business_as}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_parent_name">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.parent_name")}</Form.Label>
                    <Form.Control
                        type="text"
                        name="organization[company_structure_attributes][parent_name]"
                        onChange={handleChange}
                        value={values.parent_name || ""}
                        isInvalid={errors.parent_name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.parent_name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_parent_duns">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.parent_duns")}</Form.Label>
                    <Form.Control
                        type="text"
                        name="organization[company_structure_attributes][parent_duns]"
                        onChange={handleChange}
                        value={values.parent_duns || ""}
                        isInvalid={errors.parent_duns}
                    />
                    <Form.Control.Feedback type="invalid">{errors.parent_duns}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organization_company_structure_attributes_year_founded">
                    <Form.Label>{I18n.t("activerecord.attributes.company_structure.year_founded")}</Form.Label>
                    <Form.Control
                        type="text"
                        name="organization[company_structure_attributes][year_founded]"
                        onChange={handleChange}
                        value={values.year_founded || ""}
                        isInvalid={errors.year_founded}
                    />
                    <Form.Control.Feedback type="invalid">{errors.year_founded}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

CompanyStructureSection.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default CompanyStructureSection;
