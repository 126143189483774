import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import I18n from "../../utils/i18n";
import { clearAlert } from "../../state/alert/alertActions";

const DismissibleAlert = ({ alert, clearAlert }) => {
    if (isEmpty(alert.translationKey) && isEmpty(alert.text)) {
        return <></>;
    }

    useEffect(() => {
        setTimeout(clearAlert, 3000);
    }, []);

    return (
        <Alert
            variant={alert.variant || "danger"}
            onClose={clearAlert}
            className="position-absolute w-100 rounded-0 text-center"
            dismissible
        >
            {!isEmpty(alert.translationKey) ? I18n.t(alert.translationKey) : alert.text}
        </Alert>
    );
};

DismissibleAlert.propTypes = {
    alert: PropTypes.object.isRequired,
    clearAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
    clearAlert: () => dispatch(clearAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DismissibleAlert);
