import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row, ButtonGroup, Form } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import moment from "moment";
import _ from "lodash";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/src/sass/tempusdominus-bootstrap-build.scss";
import I18n from "../../../utils/i18n";
import Loader from "./common/Loader";
import EmptyData from "./common/EmptyData";
import EventsCharts from "./content/EventsCharts";
import { getReports } from "../../../api/informationApi";

const Reports = () => {
    const startDateInputRef = useRef();
    const endDateInputRef = useRef();
    const DATE_FORMAT = "MM-DD-YYYY";
    const [reports, setReports] = useState({});
    const [fetching, setFetching] = useState(false);
    const initialValues = {
        start_date: moment().subtract(1, "years"),
        end_date: moment(),
    };

    useEffect(() => {
        onSubmit(initialValues);
    }, []);

    const onSubmit = (values) => {
        setFetching(true);

        getReports(values)
            .then((response) => {
                setReports(response.data);
                setFetching(false);
            })
            .catch(() => {
                setReports({});
                setFetching(false);
            });
    };

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values, setFieldValue, handleSubmit }) => (
                <FormikForm onSubmit={handleSubmit}>
                    <h3>{I18n.t("home.reports.header")}</h3>
                    <Card className="mb-3">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{I18n.t("home.reports.labels.start_date")}</Form.Label>
                                        <DatePicker
                                            pickerRef={startDateInputRef}
                                            format={DATE_FORMAT}
                                            useCurrent={false}
                                            onChange={(event) => {
                                                setFieldValue("start_date", event.date);
                                            }}
                                            date={values.start_date}
                                            className="flex-grow-1"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{I18n.t("home.reports.labels.end_date")}</Form.Label>
                                        <DatePicker
                                            pickerRef={endDateInputRef}
                                            format={DATE_FORMAT}
                                            useCurrent={false}
                                            onChange={(event) => {
                                                setFieldValue("end_date", event.date);
                                            }}
                                            date={values.end_date}
                                            className="flex-grow-1"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex flex-row-reverse">
                                    <ButtonGroup>
                                        <Button type="submit" variant="secondary">
                                            {I18n.t("home.reports.actions.show")}
                                        </Button>
                                        <Button
                                            href={`/widgets/reports/new.csv?start_date=${JSON.stringify(
                                                values.start_date,
                                            )}&end_date=${JSON.stringify(values.end_date)}`}
                                            target="_blank"
                                            variant="primary"
                                            className="text-white"
                                        >
                                            CSV
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <hr />

                            {fetching ? (
                                <Loader />
                            ) : !_.isEmpty(reports) ? (
                                <EventsCharts reports={reports} />
                            ) : (
                                <EmptyData label={I18n.t("home.reports.messages.empty")} type_icon="charts" />
                            )}
                        </Card.Body>
                    </Card>
                </FormikForm>
            )}
        </Formik>
    );
};

export default Reports;
