import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import PreviewTable from "../common/PreviewTable";
import I18n from "../../../../../utils/i18n";

const PreviewStep = ({ children, cancelButton, companyIds, scope }) => {
    const { isSubmitting, setFieldValue, handleSubmit } = useFormikContext();

    return (
        <>
            <Modal.Body>
                {children}

                <Modal.Body>
                    <p>{I18n.t(`companies.lists.sections.export.${scope}.preview_description`)}</p>
                    <PreviewTable organizationIds={companyIds} setFieldValue={setFieldValue} />
                </Modal.Body>
            </Modal.Body>
            <Modal.Footer>
                {cancelButton}

                <Button
                    disabled={isSubmitting}
                    variant="primary"
                    className="text-white ml-2"
                    onClick={handleSubmit}
                >
                    <Spinner hidden={!isSubmitting} as="span" animation="border" size="sm" className="mr-2" />
                    {I18n.t("common.links.export")}
                </Button>
            </Modal.Footer>
        </>
    );
};

PreviewStep.propTypes = {
    children: PropTypes.node.isRequired,
    cancelButton: PropTypes.node.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    scope: PropTypes.string.isRequired,
};

export default PreviewStep;
