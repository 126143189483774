import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../../common/components/Table";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import { getPublicDomains, deletePublicDomain } from "../../../api/publicDomainApi";
import DeleteAction from "../../../common/components/DeleteAction";

const ActionsCell = ({ row: { original } }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDelete = () => {
        deletePublicDomain(original.id).then(() => {
            setShowDeleteModal(false);
            window.location.reload();
        });
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as={ThreeDotsToggle} />
            <Dropdown.Menu size="sm" title="">
                <Dropdown.Item as={Link} to={`/public_domains/${original.id}/edit`}>
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                    {I18n.t("common.links.edit")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#" onClick={() => setShowDeleteModal(true)}>
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    {I18n.t("common.links.delete")}
                </Dropdown.Item>
            </Dropdown.Menu>
            <DeleteAction
                show={showDeleteModal}
                onSubmit={onDelete}
                onHide={() => setShowDeleteModal(false)}
                resource={I18n.t("activerecord.models.public_domain")}
            />
        </Dropdown>
    );
};

const DomainCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.domain}</div>;
const WebsiteUrlCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        <a href={`//${original.website_url}`} target="_blank" rel="noreferrer">
            {original.website_url}
        </a>
    </div>
);
const UpdatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.updated_at)}</div>
);

const PublicDomainsTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.public_domain.domain"),
                accessor: "domain",
                Cell: DomainCell,
            },
            {
                Header: I18n.t("activerecord.attributes.public_domain.website_url"),
                accessor: "website_url",
                Cell: WebsiteUrlCell,
            },
            {
                Header: I18n.t("activerecord.attributes.common.updated_at"),
                accessor: "updated_at",
                Cell: UpdatedAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: ActionsCell,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "updated_at", desc: true };
        setLoading(true);

        getPublicDomains({ page: pageIndex + 1, column: sort.id, order: sort.desc ? "desc" : "asc" }).then(
            (response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(response.data.records);
                    setPageCount(response.data.page_count);
                    setLoading(false);
                }
            },
        );
    }, []);

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pageCount}
            loading={loading}
            initialSortBy={[{ id: "updated_at", desc: true }]}
        />
    );
};

DomainCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            domain: PropTypes.string.isRequired,
        }),
    }),
};

WebsiteUrlCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            website_url: PropTypes.string,
        }),
    }),
};

UpdatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string.isRequired,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }),
};

export default PublicDomainsTable;
