import React, { useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _map from "lodash/map";
import _toString from "lodash/toString";
import * as Yup from "yup";
import I18n from "../../../utils/i18n";
import BunchRequestModal from "./BunchRequestsModal";
import { createClientSpecificFirm, createFirmwideFirm } from "../../../api/diversityApi";
import { AddCompaniesToNegotiationModal, AddCompaniesToNewNegotiation } from "./export/index";

const ActionsButton = ({ companyIds, setCompanyIds }) => {
    const [showFirmwideRequestModal, setShowFirmwideRequestModal] = useState(false);
    const [showCsRequestModal, setShowCsRequestModal] = useState(false);
    const [showNegotiationModal, setShowNegotiationModal] = useState(false);
    const [showNewNegotiationModal, setShowNewNegotiationModal] = useState(false);

    const currentOrganization = useSelector((state) => state.currentActor.resource.organization);

    if (
        !(
            currentOrganization.permissions.diversity_firmwide_viewer_permission ||
            currentOrganization.permissions.diversity_client_viewer_permission ||
            currentOrganization.permissions.customer_permission
        )
    ) {
        return <></>;
    }

    return (
        <Dropdown className="to-the-front">
            <Dropdown.Toggle
                as={Button}
                disabled={_isEmpty(companyIds)}
                variant="primary"
                className="mr-2 text-white"
            >
                {I18n.t("companies.lists.buttons.actions")}
            </Dropdown.Toggle>
            <Dropdown.Menu size="sm">
                {currentOrganization.permissions.customer_permission && (
                    <>
                        <Dropdown.Item onClick={() => setShowNegotiationModal(true)}>
                            {I18n.t("companies.lists.sections.companies.actions.projects.existing")}
                        </Dropdown.Item>
                        <AddCompaniesToNegotiationModal
                            companyIds={companyIds}
                            show={showNegotiationModal}
                            handleClose={() => setShowNegotiationModal(false)}
                        />
                        <Dropdown.Item onClick={() => setShowNewNegotiationModal(true)}>
                            {I18n.t("companies.lists.sections.companies.actions.projects.new")}
                        </Dropdown.Item>
                        <AddCompaniesToNewNegotiation
                            companyIds={companyIds}
                            show={showNewNegotiationModal}
                            handleClose={() => setShowNewNegotiationModal(false)}
                        />
                    </>
                )}
                {currentOrganization.permissions.diversity_firmwide_viewer_permission && (
                    <>
                        <Dropdown.Item onClick={() => setShowFirmwideRequestModal(true)}>
                            {I18n.t("companies.lists.sections.companies.actions.request_firmwide")}
                        </Dropdown.Item>
                        <BunchRequestModal
                            companyIds={companyIds}
                            onClose={() => setShowFirmwideRequestModal(false)}
                            setCompanyIds={setCompanyIds}
                            show={showFirmwideRequestModal}
                            initialIds={{ firms: _map(companyIds, (id) => _toString(id)) }}
                            validationSchema={Yup.object().shape({
                                firms: Yup.array().of(Yup.number()).required(),
                                comment: Yup.string().max(5000),
                            })}
                            submitAction={createFirmwideFirm}
                            translationsScope="companies.lists.modals.send_firmwide_requests"
                        />
                    </>
                )}
                {currentOrganization.permissions.diversity_client_viewer_permission && (
                    <>
                        <Dropdown.Item onClick={() => setShowCsRequestModal(true)}>
                            {I18n.t("companies.lists.sections.companies.actions.request_client_specific")}
                        </Dropdown.Item>
                        <BunchRequestModal
                            companyIds={companyIds}
                            onClose={() => setShowCsRequestModal(false)}
                            setCompanyIds={setCompanyIds}
                            show={showCsRequestModal}
                            initialIds={{ organizations: _map(companyIds, (id) => _toString(id)) }}
                            validationSchema={Yup.object().shape({
                                organizations: Yup.array().of(Yup.number()).required(),
                                comment: Yup.string().max(5000),
                            })}
                            submitAction={createClientSpecificFirm}
                            translationsScope="companies.lists.modals.send_cs_requests"
                        />
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

ActionsButton.propTypes = {
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setCompanyIds: PropTypes.func.isRequired,
};
export default ActionsButton;
