import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../../utils/i18n";
import Loader from "../../../pages/HomePage/components/common/Loader";
import TableComponent from "./TableComponent";
import ChartComponent from "./ChartComponent";
import TextComponent from "./TextComponent";

const DiversityChart = ({
    canvasRefs,
    type,
    scope,
    chartType,
    chartData,
    chartOptions,
    chartUseDataLabels = false,
    chartUseTooltips = false,
    inProgress = true,
    position = 1,
}) => {
    const data = chartData || { datasets: [], labels: [] };

    if (inProgress || _isEmpty(chartData)) return <Loader />;

    if (chartType === "text") {
        return (
            <TextComponent
                title={I18n.t(`diversity.charts.mini_stats_bar.total`, chartData)}
                type={type}
                canvasRefs={canvasRefs}
                chartData={chartData}
                inProgress={inProgress}
                position={position}
            />
        );
    }

    return chartType === "table" ? (
        <TableComponent
            title={I18n.t(`diversity.charts.${scope}.${type}`)}
            data={data}
            canvasRefs={canvasRefs}
            inProgress={inProgress}
            position={position}
        />
    ) : (
        <ChartComponent
            title={I18n.t(`diversity.charts.${scope}.${type}.title`, {
                defaultValue: I18n.t(`diversity.charts.${scope}.${type}`),
            })}
            subtitle={I18n.t(`diversity.charts.${scope}.${type}.subtitle`, { defaultValue: "" })}
            tooltip={I18n.t(`diversity.tooltips.${scope}.${type}`)}
            type={chartType}
            data={data}
            options={chartOptions || {}}
            useDataLabels={chartUseDataLabels}
            useTooltips={chartUseTooltips}
            inProgress={inProgress}
            canvasRefs={canvasRefs}
            position={position}
        />
    );
};

DiversityChart.propTypes = {
    type: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    chartType: PropTypes.string.isRequired,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    chartUseDataLabels: PropTypes.bool,
    chartUseTooltips: PropTypes.bool,
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    position: PropTypes.number,
};

export default DiversityChart;
