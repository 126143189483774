import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import LoadingBody from "../../../../common/components/Table/LoadingBody";
import DIVERSITY_ATTRIBUTES from "../../../../utils/diversityAttributes";
import KeyIndicatorsTableRow from "./KeyIndicatorsTableRow";
import KeyIndicatorsTableOverallRow from "./KeyIndicatorsTableOverallRow";

const KeyIndicatorsTableBody = ({ formik, currentGoals, partialFirmwide }) => {
    if (_.isEmpty(formik.values.key_indicators)) {
        return <LoadingBody columnCount={37} rowCount={17} />;
    }

    const idValues = _.map(formik.values.key_indicators, "id");
    const groupedValues = _.groupBy(formik.values.key_indicators, "diversity_attribute");

    return (
        <>
            {_.map(DIVERSITY_ATTRIBUTES, (diversityAttributes) =>
                _.map(diversityAttributes, (diversityAttribute, rowIndex) => (
                    <KeyIndicatorsTableRow
                        key={diversityAttribute}
                        diversityAttributesCount={diversityAttributes.length}
                        idValues={idValues}
                        rowIndex={rowIndex}
                        handleChange={formik.handleChange}
                        values={groupedValues[diversityAttribute]}
                        errors={formik.errors.key_indicators || []}
                        currentGoals={currentGoals}
                        partialFirmwide={partialFirmwide}
                    />
                ))
            )}
            <KeyIndicatorsTableOverallRow
                values={formik.values.key_indicators}
                currentGoals={currentGoals}
                partialFirmwide={partialFirmwide}
            />
        </>
    );
};

KeyIndicatorsTableBody.propTypes = {
    formik: PropTypes.object.isRequired,
    currentGoals: PropTypes.object,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default KeyIndicatorsTableBody;
