import React from "react";
import { Collapse, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { OrganizationName, PermissionDate, PermissionName, UserEmail, WhitelabelName } from "./inputs/index";

const OrganizationFilter = ({ openFilter, form, values, currentUser, permissions }) => (
        <>
            <Collapse in={openFilter}>
                <div className="jumbotron jumbotron-fluid p-3 bg-white position-absolute w-100" id="collapse-filter">
                    <div className="container-fluid">
                        <Form.Row>
                            <OrganizationName values={values} />
                            {currentUser.superadmin && (
                                <WhitelabelName form={form} value={values.filter.whitelabel_id_in} />
                            )}
                            <UserEmail value={values.filter.users_email_cont} />
                        </Form.Row>
                        {currentUser.superadmin && (
                            <Form.Row>
                                <PermissionName
                                    permissions={permissions}
                                    setFieldValue={form.setFieldValue}
                                    value={values.filter.permissions_name_in}
                                />
                                <PermissionDate setFieldValue={form.setFieldValue} value={values.filter} />
                            </Form.Row>
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    );

OrganizationFilter.propTypes = {
    openFilter: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OrganizationFilter;
