import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Field, Formik } from "formik";
import _map from "lodash/map";
import _some from "lodash/some";
import _every from "lodash/every";
import { useSelector } from "react-redux";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import I18n from "../../../../utils/i18n";
import { getCloneOptionsForTemplate, cloneTemplate } from "../../../../api/templatesApi";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";
import { getCloneOptionsForNegotiation, cloneNegotiation } from "../../../../api/negotiationApi";

const OptionsStep = () => {
    const { setCurrentStep, onHide, selectedEvent, createMethod, exportCompanies, setNegotiationParams } =
        useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const baseUrl = `//${currentWhitelabel.hosts.bid}`;
    const [tabs, setTabs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const fetchData = {
        from_template: getCloneOptionsForTemplate,
        from_previous: getCloneOptionsForNegotiation,
    };
    const cloneData = {
        from_template: cloneTemplate,
        from_previous: cloneNegotiation,
    };
    const currentStepIndex = 3;
    const exportCompaniesStepIndex = 5;
    const handleClone = (values, { setSubmitting }) => {
        setSubmitting(true);
        cloneData[createMethod](selectedEvent, { data: values })
            .then(({ data: { negotiation_id } }) => {
                if (exportCompanies) {
                    setNegotiationParams({ negotiation_id: negotiation_id });
                    setCurrentStep(exportCompaniesStepIndex);
                } else {
                    window.location = `${baseUrl}/setup/${negotiation_id}`;
                }
            })
            .finally(() => setSubmitting(false));
    };

    useEffect(() => {
        setLoading(true);

        fetchData[createMethod](selectedEvent)
            .then(({ data: { tabs } }) => setTabs(tabs))
            .finally(() => setLoading(false));
    }, [selectedEvent]);

    const translateScope = "home.create_event_wizard.steps.options_step";

    return (
        <Formik initialValues={{}} onSubmit={handleClone}>
            {({ values, setValues, isSubmitting, handleSubmit }) => (
                <>
                    <Modal.Header className="border-0 pb-1">
                        <Modal.Title>
                            <Link className="text-dark" to="#" onClick={() => setCurrentStep(currentStepIndex - 1)}>
                                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                                {I18n.t("home.create_event_wizard.buttons.back")}
                            </Link>
                            <div className="mt-4 mb-2 text-2xl">{I18n.t("title", { scope: translateScope })}</div>
                            <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                                {I18n.t("tip", { scope: translateScope })}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <div className="p-4">
                            {isLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <Spinner variant="secondary" as="span" animation="border" className="mr-2" />
                                </div>
                            ) : (
                                <div className="d-flex flex-column">
                                    {_map(tabs, (tab, index) => {
                                        if (tab.root) {
                                            return (
                                                <Field
                                                    key={`tab-${index}`}
                                                    className="mb-1"
                                                    as={Form.Check}
                                                    custom
                                                    type="checkbox"
                                                    id={tab.key}
                                                    name={tab.key}
                                                    label={tab.label}
                                                    disabled={isLoading || isSubmitting}
                                                />
                                            );
                                        } else {
                                            const keys = _map(tab.subTabs, (subTab) => values[subTab.key]);
                                            const isAll = _every(keys);

                                            return (
                                                <div key={`div-${index}`}>
                                                    <IndeterminateCheck
                                                        key={`tab-${index}`}
                                                        id={`${tab.key}-${index}`}
                                                        className="mb-1"
                                                        checked={isAll}
                                                        indeterminate={_some(keys) && !isAll}
                                                        onChange={(event) =>
                                                            setValues((oldValues) => ({
                                                                ...oldValues,
                                                                ...Object.fromEntries(
                                                                    _map(tab.subTabs, (subTab) => [
                                                                        subTab.key,
                                                                        event.target.checked,
                                                                    ]),
                                                                ),
                                                            }))
                                                        }
                                                        name={tab.key}
                                                        label={tab.label}
                                                        disabled={isLoading || isSubmitting}
                                                    />
                                                    {_map(tab.subTabs, (subTab, subIndex) => (
                                                        <Field
                                                            key={`sub-tab-${index}-${subIndex}`}
                                                            className="ml-3 my-2"
                                                            as={Form.Check}
                                                            custom
                                                            type="checkbox"
                                                            id={subTab.key}
                                                            name={subTab.key}
                                                            label={subTab.label}
                                                            disabled={isLoading || isSubmitting}
                                                        />
                                                    ))}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-0 d-flex justify-content-between">
                        <Button variant="outline-secondary" onClick={onHide}>
                            {I18n.t("home.create_event_wizard.buttons.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            className="text-white"
                            onClick={handleSubmit}
                            disabled={isLoading || isSubmitting || !_some(values)}
                        >
                            <Spinner className="mr-2" animation="border" size="sm" hidden={!isSubmitting} />
                            {I18n.t("home.create_event_wizard.buttons.proceed")}
                        </Button>
                    </Modal.Footer>
                </>
            )}
        </Formik>
    );
};
export default OptionsStep;
