import React from "react";
import PropTypes from "prop-types";
import { AccordionCollapse, Card } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import PartnershipsTable from "./PartnershipsTable";

const DetailsSection = ({ activeId, setActiveId }) => (
    <Card className="border-0 overflow-visible mb-3">
        <AccordionTitle
            eventKey="details"
            activeId={activeId}
            title={I18n.t("tiered_diversity.dashboard.suppliers.title")}
            toggleActive={setActiveId}
        />
        <AccordionCollapse eventKey="details">
            <PartnershipsTable />
        </AccordionCollapse>
    </Card>
);

DetailsSection.propTypes = {
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DetailsSection;
