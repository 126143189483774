import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import _ceil from "lodash/ceil";
import TwoRangeSelector from "./TwoRangeSelector";
import I18n from "../../../../utils/i18n";

const RangeInput = ({ title, values, setValues, maxValue, fetching }) => {
    const onClear = () => setValues([0, maxValue]);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <strong>{title}</strong>
                <small className="text-muted cursor-pointer" onClick={onClear}>
                    {I18n.t("common.clear")}
                </small>
            </div>
            {fetching ? (
                <Skeleton />
            ) : (
                <TwoRangeSelector
                    values={values}
                    setValues={setValues}
                    step={maxValue === 0 ? 1 : _ceil(maxValue / 100)}
                    max={maxValue}
                />
            )}
        </div>
    );
};

RangeInput.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    setValues: PropTypes.func.isRequired,
    maxValue: PropTypes.number.isRequired,
    fetching: PropTypes.bool,
};

export default RangeInput;
