import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import DiversityFirmwideRequestsTable from "../../common/components/DiversityFirmwideRequestsTable";
import PageSizeSelector from "../../common/components/PageSizeSelector";

const DiversityFirmwideRequestsPage = () => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        if (
            !(currentActor.super_manager || currentActor.diversity_manager) ||
            !currentActor.organization.permissions.diversity_firmwide_viewer_permission
        ) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="top-bar">
                <Col>
                    <Container fluid>
                        <div className="py-3" />
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <Row className="justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <Col>
                                    <h2>{I18n.t("sidebar.links.diversity.firmwide_requests")}</h2>
                                </Col>
                                <Col md={2}>
                                    <PageSizeSelector onChange={setPageSize} currentSize={pageSize} />
                                </Col>
                                <Col />
                            </Row>

                            <Row>
                                <Col>
                                    <DiversityFirmwideRequestsTable
                                        organizationUid={currentActor.organization.uid}
                                        pageSize={pageSize}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default DiversityFirmwideRequestsPage;
