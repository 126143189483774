import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Spinner, Form, Button, FormControl } from "react-bootstrap";
import { startUnlockingUser, unlockUser } from "../../api/deviseApi";
import { clearAlert, setAlert } from "../../state/alert/alertActions";
import useQuery from "../../utils/hooks/useQuery";
import I18n from "../../utils/i18n";

const UnlockPage = ({ setAlert, clearAlert }) => {
    const history = useHistory();
    const token = useQuery().get("unlock_token");
    const [loading, setLoading] = useState(true);

    const onSubmit = (values) => {
        clearAlert();
        unlockUser(values).then(() => {
            setAlert({ translationKey: "devise.unlocks.send_paranoid_instructions", variant: "info" });
            history.push("/");
        });
    };

    const validationSchema = Yup.object().shape({
        user: Yup.object().shape({
            email: Yup.string().email().max(255).required(),
        }),
    });

    useEffect(() => {
        setLoading(true);
        if (token) {
            startUnlockingUser({ unlock_token: token })
                .then(() => {
                    window.location = "/";
                })
                .catch((error) => {
                    const errorMessages = error?.response?.data?.unlock_token;
                    if (!_isEmpty(errorMessages)) {
                        const text = _map(
                            errorMessages,
                            (message) => `${I18n.t("activerecord.attributes.user.unlock_token")} ${message}`,
                        ).join(" ");
                        setAlert({ text: text, variat: "danger" });
                    }
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [token]);

    return (
        <div className="form-wrapper bg-white p-4 rounded">
            {loading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <Spinner animation="border" variant="secondary" />
                </div>
            ) : (
                <Formik initialValues={{ user: { email: "" } }} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ values, handleSubmit, handleChange, isSubmitting, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                <h2>{I18n.t("forms.unlock.resend_instructions.title")}</h2>
                            </Form.Group>
                            <Form.Group>
                                <FormControl
                                    type="email"
                                    name="user[email]"
                                    placeholder={I18n.t("activerecord.attributes.user.email")}
                                    onChange={handleChange}
                                    value={values.user.email}
                                    isInvalid={errors.user && errors.user.email}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.user && errors.user.email}
                                </FormControl.Feedback>
                            </Form.Group>
                            <Form.Group className="text-center">
                                <Button variant="primary" type="submit" className="text-white" disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="mr-2"
                                        />
                                    )}
                                    {I18n.t("forms.unlock.resend_instructions.submit")}
                                </Button>
                            </Form.Group>
                            <div className="text-right">
                                <Link to="/">{I18n.t("forms.password.edit.already_have_account")}</Link>
                                <br />
                                <Link to="/users/password/new">{I18n.t("forms.password.new.title")}</Link>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

UnlockPage.propTypes = {
    setAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setAlert: (values) => dispatch(setAlert(values)),
    clearAlert: () => dispatch(clearAlert()),
});

export default connect(null, mapDispatchToProps)(UnlockPage);
