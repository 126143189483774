import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import _isNull from "lodash/isNull";
import _join from "lodash/join";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import _filter from "lodash/filter";
import I18n from "../../../../utils/i18n";
import RenameListModal from "../RenameListModal";
import AddListModal from "../AddListModal";
import CompaniesTable from "../CompaniesTable";
import { deleteList, getListCompanies, cloneList, removeCompanyFromList } from "../../../../api/listsApi";
import AddCompaniesToListModal from "../AddCompaniesToListModal";
import ConfirmAction from "../../../../common/components/ConfirmAction";
import { setAlert } from "../../../../state/alert/alertActions";
import ProfilesSection from "../CompaniesDashboard";
import ShareListModal from "../ShareListModal";
import ActionsButton from "../ActionsButton";

const CompaniesSection = ({ viewMode, currentList, setReloadLists, setCurrentList }) => {
    const [sectionTitle, setSectionTitle] = useState(currentList.name);
    const [isLoading, setIsLoading] = useState(false);
    const [isCloning, setIsCloning] = useState(false);
    const [showRenameListModal, setShowRenameListModal] = useState(false);
    const [showAddCompaniesToListModal, setShowAddCompaniesToListModal] = useState(false);
    const [showShareListModal, setShowShareListModal] = useState(false);
    const [showAddListModal, setShowAddListModal] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [recordsCount, setRecordsCount] = useState(0);
    const [companyIds, setCompanyIds] = useState([]);
    const [allCompaniesIds, setAllCompaniesIds] = useState([]);
    const defaultSortBy = { id: "name", desc: false };
    const dispatch = useDispatch();

    const viewersTooltip =
        !_isNull(currentList.id) &&
        I18n.t("companies.lists.sections.companies.tooltip", { count: currentList.viewers.length || 0 });

    const handleRemoveList = (event, { setIsSubmitting }) => {
        setIsLoading(true);
        deleteList(currentList.id)
            .then(() => {
                setCurrentList({ id: null, name: null, ancestry: null });
                dispatch(
                    setAlert({
                        translationKey: "companies.lists.sections.companies.actions.list_deleted",
                        variant: "info",
                    }),
                );
                setReloadLists((prevState) => !prevState);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitting(false);
                setShowRemoveConfirmation(false);
            });
    };

    const handleRemoveFromList = ({ uid, id, setModalShow }) => {
        removeCompanyFromList(currentList.id, uid).then(({ data: { records, page_count } }) => {
            setData(records);
            setPagesCount(page_count);
            setCompanyIds((prevState) => prevState.filter((companyId) => companyId !== id));
            setReloadLists((prevState) => !prevState);
        });
        setModalShow(false);
    };

    const handleCheckboxChange = useCallback(
        (event, organizationId) => {
            const { checked } = event.target;

            if (checked) {
                setCompanyIds((prevState) => [...prevState, organizationId]);
            } else {
                setCompanyIds((prevState) => _filter(prevState, (companyId) => companyId !== organizationId));
            }
        },
        [currentList.id],
    );

    const handleCloneList = useCallback(() => {
        setIsCloning(true);
        const params = currentList.shared ? { share_id: currentList.id } : { list_id: currentList.id };
        cloneList(params)
            .then(() => setReloadLists((prevState) => !prevState))
            .finally(() => setIsCloning(false));
    }, [currentList.id]);

    const goToPage = ({ selected }) => setPage(selected + 1);

    const fetchData = useCallback(
        ({ sortBy }) => {
            const sort = sortBy[0] || defaultSortBy;
            setIsLoading(true);

            if (!_isNull(currentList.id)) {
                getListCompanies(currentList.id, {
                    page: page,
                    column: sort.id,
                    direction: sort.desc ? "desc" : "asc",
                })
                    .then(({ data: { records_count, records, page_count, all_companies_ids } }) => {
                        setData(records);
                        setRecordsCount(records_count);
                        setPagesCount(page_count);
                        setAllCompaniesIds(all_companies_ids);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                setData([]);
                setIsLoading(false);
            }
        },
        [currentList.id, currentList.companies_count, page],
    );

    useEffect(() => {
        if (viewMode === "dashboard") fetchData({ sortBy: [defaultSortBy] });
    }, [currentList.id, page]);

    useEffect(() => {
        setCompanyIds([]);
        setSectionTitle(currentList.name);
        setPage(1);
    }, [currentList.id]);

    return (
        <Card className="border-0">
            <Card.Header className="bg-white ">
                <div className="top-bar list-header bg-white">
                    <Row>
                        <Col md={4} className="short-title mt-2">
                            {_isNull(currentList.id) ? (
                                <span className="mr-auto text-muted">
                                    {I18n.t("companies.lists.sections.companies.select_list")}
                                </span>
                            ) : (
                                <>
                                    <span className="mr-2">
                                        <OverlayTrigger
                                            placement="left"
                                            className="d-inline-block"
                                            trigger={["hover", "focus"]}
                                            overlay={
                                                <Popover id={currentList.id}>
                                                    <Popover.Content>
                                                        <h3>{sectionTitle}</h3>
                                                        <div>{viewersTooltip}</div>
                                                        <div>{_join(currentList.viewers, ", ")}</div>
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                        </OverlayTrigger>
                                    </span>
                                    {sectionTitle}
                                </>
                            )}
                        </Col>
                        <Col md={8}>
                            <span className="d-flex justify-content-end">
                                <Button
                                    disabled={_isNull(currentList.id) || currentList.shared}
                                    variant="primary"
                                    className="mb-2 mr-2 text-white"
                                    onClick={() => setShowAddCompaniesToListModal(true)}
                                >
                                    {I18n.t("companies.lists.buttons.add_to_list")}
                                </Button>
                                {currentList.id && !currentList.shared && (
                                    <AddCompaniesToListModal
                                        onClose={() => setShowAddCompaniesToListModal(false)}
                                        show={showAddCompaniesToListModal}
                                        listId={currentList.id}
                                        setData={setData}
                                        setPagesCount={setPagesCount}
                                        setReloadLists={setReloadLists}
                                    />
                                )}
                                <Button
                                    disabled={_isNull(currentList.id) || currentList.shared}
                                    variant="primary"
                                    className="mb-2 mr-2 text-white"
                                    onClick={() => setShowRenameListModal(true)}
                                >
                                    {I18n.t("companies.lists.buttons.rename_list")}
                                </Button>
                                {currentList.id && !currentList.shared && (
                                    <RenameListModal
                                        onClose={() => setShowRenameListModal(false)}
                                        show={showRenameListModal}
                                        list={currentList}
                                        setReloadLists={setReloadLists}
                                        setSectionTitle={setSectionTitle}
                                    />
                                )}
                                <Button
                                    disabled={_isNull(currentList.id) || currentList.shared || currentList.depth > 1}
                                    variant="primary"
                                    className="mb-2 mr-2 text-white"
                                    onClick={() => setShowAddListModal(true)}
                                >
                                    {I18n.t("companies.lists.buttons.create_sublist")}
                                </Button>
                                {currentList.id && !currentList.shared && (
                                    <AddListModal
                                        onClose={() => setShowAddListModal(false)}
                                        show={showAddListModal}
                                        parentId={currentList.id}
                                        setReloadLists={setReloadLists}
                                    />
                                )}
                                <Button
                                    disabled={_isNull(currentList.id) || currentList.shared}
                                    variant="primary"
                                    className="mb-2 mr-2 text-white"
                                    onClick={() => setShowShareListModal(true)}
                                >
                                    {I18n.t("companies.lists.buttons.share_list")}
                                </Button>
                                {currentList.id && (
                                    <ShareListModal
                                        onClose={() => setShowShareListModal(false)}
                                        show={showShareListModal}
                                        listId={currentList.id}
                                        setCurrentList={setCurrentList}
                                    />
                                )}
                                <Button
                                    disabled={_isNull(currentList.id) || isCloning}
                                    variant="primary"
                                    className="mb-2 mr-2 text-white"
                                    onClick={handleCloneList}
                                >
                                    {isCloning && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="mr-2"
                                        />
                                    )}
                                    {I18n.t("companies.lists.buttons.clone_list")}
                                </Button>
                                <Button
                                    disabled={_isNull(currentList.id) || currentList.shared}
                                    variant="danger"
                                    className="mb-2 mr-2 text-white"
                                    onClick={() => setShowRemoveConfirmation(true)}
                                >
                                    {I18n.t("companies.lists.buttons.delete_list")}
                                </Button>

                                <ConfirmAction
                                    show={showRemoveConfirmation}
                                    onHide={() => setShowRemoveConfirmation(false)}
                                    onSubmit={handleRemoveList}
                                    confirm="common.links.text_ok"
                                    title={I18n.t("tables.lists.delete_list.title")}
                                    text={I18n.t("tables.lists.delete_list.text")}
                                />
                            </span>
                        </Col>
                    </Row>
                </div>
            </Card.Header>
            <Card.Header className="bg-white">
                <ActionsButton setCompanyIds={setCompanyIds} companyIds={companyIds} />
            </Card.Header>
            <Card.Body className="p-0">
                {viewMode === "table" && (
                    <>
                        <CompaniesTable
                            companyIds={companyIds}
                            data={data}
                            setData={setData}
                            isLoading={isLoading}
                            fetchData={fetchData}
                            handleCheckboxChange={handleCheckboxChange}
                            setCompanyIds={setCompanyIds}
                            setPagesCount={setPagesCount}
                            setReloadLists={setReloadLists}
                            currentListId={currentList.id}
                            handleRemoveFromList={handleRemoveFromList}
                            hideActions={currentList.shared}
                            allCompaniesIds={allCompaniesIds}
                        />
                        {pagesCount > 1 && (
                            <div className="pagination justify-content-center mb-3">
                                <ReactPaginate
                                    previousLabel="«"
                                    nextLabel="»"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pagesCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={3}
                                    onPageChange={goToPage}
                                    containerClassName="pagination pagination-sm mb-0"
                                    pageClassName="page-item"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    nextClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    forcePage={page - 1}
                                />
                            </div>
                        )}
                    </>
                )}
                {viewMode === "dashboard" && (
                    <ProfilesSection
                        profilesFetching={isLoading}
                        profiles={data}
                        pageCount={pagesCount}
                        recordsCount={recordsCount}
                        goToPage={goToPage}
                        page={page}
                        companyIds={companyIds}
                        fetchData={fetchData}
                        handleCheckboxChange={handleCheckboxChange}
                        handleRemoveFromList={handleRemoveFromList}
                        hideActions={currentList.shared}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

CompaniesSection.propTypes = {
    currentList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shared: PropTypes.bool,
        depth: PropTypes.number,
        viewers: PropTypes.arrayOf(PropTypes.string),
        companies_count: PropTypes.number,
    }),
    setReloadLists: PropTypes.func.isRequired,
    viewMode: PropTypes.string.isRequired,
    setCurrentList: PropTypes.func.isRequired,
};

export default CompaniesSection;
