import React from "react";
import PropTypes from "prop-types";
import BaseFiltersBar from "../../../common/components/DiversityPage/FiltersBar";
import {
    QuartersFilter,
    FirmsFilter,
    AttributesFilter,
    RolesFilter,
    PracticeAreasFilter,
} from "../../../common/components/DiversityPage/filters";

const FiltersBar = ({ setChartParams, getFilters, setFilter, setChangedFilters }) => (
    <BaseFiltersBar
        filterTypes={[QuartersFilter, FirmsFilter, AttributesFilter, RolesFilter, PracticeAreasFilter]}
        getFilters={getFilters}
        setChartParams={setChartParams}
        setFilter={setFilter}
        setChangedFilters={setChangedFilters}
    />
);

FiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setChangedFilters: PropTypes.func,
};

export default FiltersBar;
