import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { editOrganizationLocation, updateOrganizationLocation } from "../../api/locationsApi";
import LocationForm from "../../common/components/LocationForm";
import Loader from "../HomePage/components/common/Loader";

const EditLocationPage = () => {
    const { organization_uid: organizationUid, id: locationId } = useParams();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        editOrganizationLocation(organizationUid, locationId).then((response) => setInitialValues(response.data));
    }, [organizationUid, locationId]);

    if (_isEmpty(initialValues)) {
        return <Loader />;
    }

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/locations`}
                linkName={I18n.t("forms.organization.edit.tabs.locations")}
            />
            <div className="scrollable d-flex flex-column">
                <LocationForm
                    organizationUid={organizationUid}
                    initialValues={initialValues}
                    createOrUpdateOrganizationLocation={updateOrganizationLocation}
                />
            </div>
        </>
    );
};

export default EditLocationPage;
