import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import I18n from "../../utils/i18n";
import { editWhitelabel, updateWhitelabel } from "../../api/whitelabelApi";
import { AppLoader } from "../../common/components";
import { setAlert } from "../../state/alert/alertActions";
import AvatarContainer from "../../common/components/AvatarContainer";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import EditPageTitle from "../../common/components/EditPageTitle";

const validationSchema = Yup.object().shape({
    whitelabel: Yup.object().shape({
        name: Yup.string().max(100).required(),
        domain: Yup.string()
            .max(255)
            .required()
            .matches(/([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/, I18n.t("errors.messages.invalid")),
        mail_to: Yup.string().email().max(255).nullable(),
        allow_sign_in_as: Yup.bool(),
        terms_and_conditions_url: Yup.string().max(255).nullable(),
        privacy_policy_url: Yup.string().max(255).nullable(),
    }),
});

const EditWhitelabelPage = () => {
    const { domain } = useParams();
    const history = useHistory();
    const [whitelabel, setWhitelabel] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        editWhitelabel(domain)
            .then((response) => {
                setWhitelabel(response.data);
            })
            .catch(() => {
                history.push("/");
            });
    }, []);

    const backPath = "/whitelabels";

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        updateWhitelabel(domain, values)
            .then((response) => {
                setWhitelabel(response.data);
                resetForm(values);
                history.push(backPath);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    if (_.isEmpty(whitelabel)) {
        return <AppLoader />;
    }

    return (
        <Formik
            initialValues={{ whitelabel: whitelabel }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(form) => (
                <FormikForm as={Form}>
                    <TopBarWithBackLink backPath={backPath} linkName={I18n.t("sidebar.links.manage.whitelabels")} />
                    <NavigationPrompt when={form.dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <EditPageTitle resource={I18n.t("activerecord.models.whitelabel")} />
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="whitelabel_banner">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.banner")}
                                                </Form.Label>
                                                <AvatarContainer
                                                    form={form}
                                                    inputName="whitelabel[banner]"
                                                    classNamePostfix="rect"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.banner")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group controlId="whitelabel_logo">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.logo")}
                                                </Form.Label>
                                                <AvatarContainer form={form} inputName="whitelabel[logo]" />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.logo")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Group controlId="whitelabel_name">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.name")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[name]"
                                                    onChange={form.handleChange}
                                                    value={form.values.whitelabel.name}
                                                    isInvalid={
                                                        _.get(form.errors, "whitelabel.name") ||
                                                        _.get(form.errors, "name")
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.name") ||
                                                        _.get(form.errors, "name")}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_domain">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.domain")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[domain]"
                                                    onChange={form.handleChange}
                                                    value={form.values.whitelabel.domain}
                                                    isInvalid={
                                                        _.get(form.errors, "whitelabel.domain") ||
                                                        _.get(form.errors, "domain")
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.domain") ||
                                                        _.get(form.errors, "domain")}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_email_from">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.email_from")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[email_from]"
                                                    disabled={true}
                                                    value={`${form.values.whitelabel.name} <no-reply@${form.values.whitelabel.domain}>`}
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_mail_to">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.mail_to")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[mail_to]"
                                                    onChange={form.handleChange}
                                                    value={form.values.whitelabel.mail_to}
                                                    isInvalid={_.get(form.errors, "whitelabel.mail_to")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.mail_to")}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_allow_sign_in_as">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.allow_sign_in_as")}
                                                </Form.Label>
                                                <Form.Check
                                                    custom
                                                    name="whitelabel[allow_sign_in_as]"
                                                    onChange={form.handleChange}
                                                    checked={form.values.whitelabel.allow_sign_in_as}
                                                    isInvalid={_.get(form.errors, "whitelabel.allow_sign_in_as")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.allow_sign_in_as")}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_terms_and_conditions_url">
                                                <Form.Label>
                                                    {I18n.t(
                                                        "activerecord.attributes.whitelabel.terms_and_conditions_url",
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[terms_and_conditions_url]"
                                                    onChange={form.handleChange}
                                                    value={form.values.whitelabel.terms_and_conditions_url}
                                                    isInvalid={_.get(
                                                        form.errors,
                                                        "whitelabel.terms_and_conditions_url",
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.terms_and_conditions_url")}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="whitelabel_privacy_policy_url">
                                                <Form.Label>
                                                    {I18n.t("activerecord.attributes.whitelabel.privacy_policy_url")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="whitelabel[privacy_policy_url]"
                                                    onChange={form.handleChange}
                                                    value={form.values.whitelabel.privacy_policy_url}
                                                    isInvalid={_.get(form.errors, "whitelabel.privacy_policy_url")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {_.get(form.errors, "whitelabel.privacy_policy_url")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleSubmit}
                                            >
                                                {form.isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </FormikForm>
            )}
        </Formik>
    );
};

export default EditWhitelabelPage;
