import React from "react";
import map from "lodash/map";
import I18n from "../../../../utils/i18n";
import { DIVERSITY_KEY_INDICATORS_MAPPING } from "../../../../utils/diversityKeyIndicators";
import ChartComponent from "../../../../common/components/DiversityPage/ChartComponent";

const GoalsVsActualChart = () => (
        <ChartComponent
            title={I18n.t("diversity.charts.client_specific_marketing.goals_vs_actual")}
            type="radar"
            data={{
                labels: map(DIVERSITY_KEY_INDICATORS_MAPPING, (_, keyIndicator) =>
                    I18n.t(`diversity.key_indicators.${keyIndicator}`)
                ),
                datasets: [
                    {
                        data: [35, 0, 35, 30, 68],
                        label: I18n.t("diversity.labels.client_goal"),
                        backgroundColor: "rgba(105,147,166,0.6)",
                        borderColor: "rgba(105,147,166,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [30, 28, 50, 39, 75],
                        label: I18n.t("diversity.labels.overall"),
                        backgroundColor: "rgba(254,112,0,0.6)",
                        borderColor: "rgba(254,112,0,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [37, 28, 57, 27, 63],
                        label: I18n.t("diversity.labels.selected_firm"),
                        backgroundColor: "rgba(80,164,82,0.6)",
                        borderColor: "rgba(80,164,82,1)",
                        borderWidth: 1,
                    },
                ],
            }}
            options={{
                tooltips: {
                    callbacks: {
                        title: (items) => parseFloat(items[0].value).toFixed(2).toLocaleString(I18n.locale),
                        label: (tooltipItem, data) =>
                            `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                                .toFixed(2)
                                .toLocaleString(I18n.locale)}`,
                    },
                },
            }}
        />
    );

export default GoalsVsActualChart;
