import axios from "axios";
import moment from "moment";

export function buildFormData(formData, data, parentKey) {
    if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !moment.isMoment(data)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
    }
}

export function createApi() {
    return axios.create({
        headers: {
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
}

const API = createApi();

API.interceptors.response.use(
    (response) => response,
    (error) => {
        switch (error.response.status) {
            case 401:
                return (window.location = "/");
            case 403:
                window.localStorage.setItem(
                    "alert",
                    JSON.stringify({
                        translationKey: "common.alerts.permissions_violation",
                        variant: "danger",
                    })
                );
                return (window.location = "/");
            default:
                return Promise.reject(error);
        }
    }
);

export default API;
