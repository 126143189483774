import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import { ListGroup } from "react-bootstrap";
import CertificateCard from "./CertificateCard";

const CertificatesList = ({ certificates }) => (
        <ListGroup className="certificates-paragraph">
            {_map(certificates, (certificate) => (
                <CertificateCard key={`certificate-card-key-${certificate.id}`} data={certificate} />
            ))}
        </ListGroup>
    );

CertificatesList.propTypes = {
    certificates: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            expiration_date: PropTypes.string,
            origin_date: PropTypes.string,
            primary: PropTypes.bool,
            description: PropTypes.string,
            source: PropTypes.string,
            name: PropTypes.string,
        }).isRequired
    ),
};

export default CertificatesList;
