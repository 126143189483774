import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificProgressChart from "./DiversityClientSpecificProgressChart";
import { currencyChartLabel } from "../../../../utils/calculateTotal";

const FeesOverTimeChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificProgressChart
        inProgress={inProgress}
        chartData={chartData}
        canvasRefs={canvasRefs}
        type="fees_over_time"
        chartType="line"
        chartOptions={{
            ...chartOptions,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: (value) => currencyChartLabel(value),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.client_specific_progress.fees_over_time.y_axis"),
                        },
                    },
                ],
                xAxes: [
                    {
                        beginAtZero: true,
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${currencyChartLabel(tooltipItem.value)}`,
                },
            },
        }}
    />
);

FeesOverTimeChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default FeesOverTimeChart;
