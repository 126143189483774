import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificChart from "../../../../common/components/DiversityPage/DiversityClientSpecificChart";

const GoalsVsActualChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificChart
        chartData={chartData}
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        type="goals_vs_actual"
        chartType="radar"
        chartOptions={{
            ...chartOptions,
            tooltips: {
                callbacks: {
                    title: (items) => parseFloat(items[0].value).toFixed(1).toLocaleString(I18n.locale),
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(1)
                            .toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

GoalsVsActualChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default GoalsVsActualChart;
