import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import _map from "lodash/map";
import I18n from "../../../utils/i18n";
import { convertMomentToQuarterFormat } from "../../../utils/diversityQuarters";
import SuppliersTable from "./tables/SuppliersTable";
import useChannel from "../../../utils/channels/useChannel";
import { updateCurrentActor } from "../../../state/currentActor/currentActorActions";

const ReviewStep = ({ period, uploadResults, requestParams, setRequestParams, showSubscriptionColumns }) => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setRequestParams(
            _map(uploadResults, (uploadResult) => ({
                organization_attributes:
                    uploadResult.organizations.length > 0
                        ? { uid: uploadResult.organizations[0].uid, name: uploadResult.organizations[0].name }
                        : { uid: "", name: uploadResult.supplier_attributes.name },
                user_attributes: uploadResult.user_attributes
                    ? { email: uploadResult.user_attributes.email }
                    : { email: uploadResult.supplier_attributes.email },
                monthly_partnership_stats_attributes: {
                    total_amount: uploadResult.monthly_partnership_stats_attributes.total_amount,
                    total_currency:
                        uploadResult.monthly_partnership_stats_attributes.total_currency &&
                        uploadResult.monthly_partnership_stats_attributes.total_currency.iso_code,
                },
            }))
        );
    }, []);

    useChannel({
        channelParams: { channel: "TieredDiversity::PartnershipsChannel" },
        onReceived: ({ type }) => {
            if (type === "created") {
                dispatch(
                    updateCurrentActor({
                        resource: {
                            tiered_diversity_customer: { show_starter_page: false },
                        },
                    })
                );

                showSubscriptionColumns
                    ? history.push("/tiered_diversity/dashboard")
                    : history.push("/tiered_diversity/pricing");
            }
        },
    });

    return (
        <Container fluid className="d-flex flex-column scrollable">
            <Row className="pt-3 pb-2 border-bottom">
                <h2>{I18n.t("tiered_diversity.suppliers.new.steps.review.title")}</h2>
            </Row>
            <Row className="flex-grow-1 w-100">
                <Col className="p-3">
                    <Row className="mb-3">
                        <Col>
                            <h2>
                                {I18n.t("diversity.quarters.date_range", {
                                    start_date: convertMomentToQuarterFormat(
                                        moment.unix(period.from).tz(currentUser.time_zone)
                                    ),
                                    end_date: convertMomentToQuarterFormat(
                                        moment.unix(period.to).tz(currentUser.time_zone)
                                    ),
                                })}
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <SuppliersTable
                                uploadResults={uploadResults}
                                requestParams={requestParams}
                                setRequestParams={setRequestParams}
                                showSubscriptionColumns={showSubscriptionColumns}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

ReviewStep.propTypes = {
    period: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
    }),
    uploadResults: PropTypes.arrayOf(
        PropTypes.shape({
            organizations: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    uid: PropTypes.string,
                    industry: PropTypes.shape({
                        name: PropTypes.string,
                    }),
                    diversity_certificate: PropTypes.shape({
                        name: PropTypes.string,
                        expiration_date: PropTypes.string,
                    }),
                })
            ),
            user_attributes: PropTypes.shape({
                email: PropTypes.string.isRequired,
                uid: PropTypes.string,
            }),
            monthly_partnership_stats_attributes: PropTypes.shape({
                total_amount: PropTypes.number.isRequired,
                total_currency: PropTypes.shape({
                    iso_code: PropTypes.string,
                    symbol: PropTypes.string,
                }),
            }),
            supplier_attributes: PropTypes.shape({
                email: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                name: PropTypes.string.isRequired,
            }),
        })
    ),
    requestParams: PropTypes.arrayOf(
        PropTypes.shape({
            organization_attributes: PropTypes.shape({
                uid: PropTypes.string,
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            }),
            user_attributes: PropTypes.shape({
                uid: PropTypes.string,
                email: PropTypes.string.isRequired,
            }),
            monthly_partnership_stats_attributes: PropTypes.shape({
                total_amount: PropTypes.number.isRequired,
                total_currency: PropTypes.string.isRequired,
            }),
        })
    ),
    setRequestParams: PropTypes.func.isRequired,
    showSubscriptionColumns: PropTypes.bool.isRequired,
};

export default ReviewStep;
