import React, { useEffect, useMemo } from "react";
import { Card, Accordion, Container, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import _map from "lodash/map";
import { Link } from "react-router-dom";
import _cloneDeep from "lodash/cloneDeep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import _isNull from "lodash/isNull";
import _isUndefined from "lodash/isUndefined";
import ListSearchSection from ".././ListSearchSection";
import I18n from "../../../../utils/i18n";
import Loader from "../../../HomePage/components/common/Loader";

const ListsSection = ({
    ownLists,
    sharedLists,
    setCurrentList,
    searchValue,
    setSearchValue,
    setOwnLists,
    setSharedLists,
    isLoading,
    setIsloading,
    sortValue,
    setSortValue,
    sortBy,
    currentList,
    reloadLists,
}) => {
    const defaultActiveKeys = useMemo(() => {
        if (!currentList.shared && !_isNull(currentList.ancestry)) {
            let ancestryArray = currentList.ancestry.split("/");
            return {
                rootKey: _isUndefined(ancestryArray[0]) ? undefined : parseInt(ancestryArray[0]),
                childKey: _isUndefined(ancestryArray[1]) ? undefined : parseInt(ancestryArray[1]),
            };
        }
        return { rootKey: undefined, childKey: undefined };
    }, [currentList.id]);

    useEffect(() => {
        let clonedOwnLists = _cloneDeep(ownLists);
        let clonedSharedLists = _cloneDeep(sharedLists);

        setOwnLists(sortBy(clonedOwnLists, sortValue.column, sortValue.direction));
        setSharedLists(sortBy(clonedSharedLists, sortValue.column, sortValue.direction));
    }, [sortValue.column, sortValue.direction]);

    const newSortDirection = () => (sortValue.direction === "asc" ? "desc" : "asc");

    return (
        <Card className="border-0 sticky-top" id="filters-section">
            <Card.Body className="overflow-y-auto overflow-x-hidden">
                <Card.Title>
                    <h3>{I18n.t("companies.lists.sections.my_lists.title")}</h3>
                </Card.Title>
                <ListSearchSection
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    isLoading={isLoading}
                    setOwnLists={setOwnLists}
                    setSharedLists={setSharedLists}
                    setIsloading={setIsloading}
                    reloadLists={reloadLists}
                />
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <Container className="mt-2 d-flex justify-content-start">
                            <span className="text-muted mr-2">
                                {I18n.t("companies.lists.sections.my_lists.sort_by")}
                            </span>
                            <Dropdown className="mr-2">
                                <Dropdown.Toggle as="span" id="list-sort-by" className="dropdown-toggle-no-arrow">
                                    <FontAwesomeIcon icon={sortValue.direction === "asc" ? faSortUp : faSortDown} />
                                </Dropdown.Toggle>
                                <span className="text-muted ml-2">{sortValue.title}</span>
                                <Dropdown.Menu size="sm">
                                    <Dropdown.Item
                                        as={Link}
                                        to="#"
                                        onSelect={() =>
                                            setSortValue({
                                                column: "updated_at",
                                                title: I18n.t(
                                                    "companies.lists.sections.my_lists.sort_types.by_updated_at",
                                                ),
                                                direction: newSortDirection(),
                                            })
                                        }
                                    >
                                        {I18n.t("companies.lists.sections.my_lists.sort_types.by_updated_at")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as={Link}
                                        to="#"
                                        onSelect={() =>
                                            setSortValue({
                                                column: "companies_count",
                                                title: I18n.t(
                                                    "companies.lists.sections.my_lists.sort_types.by_companies_count",
                                                ),
                                                direction: newSortDirection(),
                                            })
                                        }
                                    >
                                        {I18n.t("companies.lists.sections.my_lists.sort_types.by_companies_count")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as={Link}
                                        to="#"
                                        onSelect={() =>
                                            setSortValue({
                                                column: "name",
                                                title: I18n.t("companies.lists.sections.my_lists.sort_types.by_name"),
                                                direction: newSortDirection(),
                                            })
                                        }
                                    >
                                        {I18n.t("companies.lists.sections.my_lists.sort_types.by_name")}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Container>
                        <Container className="mt-2">
                            <Accordion defaultActiveKey={defaultActiveKeys.rootKey}>
                                {_map(ownLists, (list) => (
                                    <div key={`div-${list.id}`}>
                                        <Accordion.Toggle as="div" eventKey={list.id} className="show">
                                            <Link
                                                to="#"
                                                onClick={() => setCurrentList({ ...list })}
                                                className={classNames(
                                                    list.id === currentList.id
                                                        ? "font-weight-bold text-primary"
                                                        : "text-dark",
                                                )}
                                            >
                                                <span>{`${list.name} (${list.companies_count})`}</span>
                                            </Link>
                                            {list.children.length > 0 && (
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    className="align-baseline ml-2 arrow"
                                                    size="xs"
                                                />
                                            )}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={list.id}>
                                            <Accordion defaultActiveKey={defaultActiveKeys.childKey}>
                                                {_map(list.children, (child) => (
                                                    <div key={`div-${child.id}`}>
                                                        <Accordion.Toggle as="div" eventKey={child.id}>
                                                            <Link
                                                                to="#"
                                                                className={classNames(
                                                                    "ml-2",
                                                                    child.id === currentList.id
                                                                        ? "font-weight-bold text-primary"
                                                                        : "text-dark",
                                                                )}
                                                                onClick={() => setCurrentList({ ...child })}
                                                            >
                                                                <span>{`${child.name} (${child.companies_count})`}</span>
                                                            </Link>
                                                            {child.children.length > 0 && (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronDown}
                                                                    className="align-baseline ml-2 arrow"
                                                                    size="xs"
                                                                />
                                                            )}
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={child.id}>
                                                            <Accordion>
                                                                {_map(child.children, (grandchild) => (
                                                                    <div key={`div-${grandchild.id}`}>
                                                                        <Accordion.Toggle
                                                                            as="div"
                                                                            eventKey={grandchild.id}
                                                                        >
                                                                            <Link
                                                                                className={classNames(
                                                                                    "ml-4",
                                                                                    grandchild.id === currentList.id
                                                                                        ? "font-weight-bold text-primary"
                                                                                        : "text-dark",
                                                                                )}
                                                                                to="#"
                                                                                onClick={() =>
                                                                                    setCurrentList({ ...grandchild })
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {`${grandchild.name} (${grandchild.companies_count})`}
                                                                                </span>
                                                                            </Link>
                                                                        </Accordion.Toggle>
                                                                    </div>
                                                                ))}
                                                            </Accordion>
                                                        </Accordion.Collapse>
                                                    </div>
                                                ))}
                                            </Accordion>
                                        </Accordion.Collapse>
                                    </div>
                                ))}
                            </Accordion>
                        </Container>
                        <Container className="mt-3">
                            <>
                                {_map(sharedLists, (list) => (
                                    <Link
                                        to="#"
                                        key={list.id}
                                        className={classNames(
                                            "d-block",
                                            list.id === currentList.id ? "text-primary font-weight-bold" : "text-muted",
                                        )}
                                        onClick={() => setCurrentList({ ...list })}
                                    >
                                        {`${list.name} (${list.companies_count})`}
                                    </Link>
                                ))}
                            </>
                        </Container>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

ListsSection.propTypes = {
    ownLists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            companies_count: PropTypes.number,
            children: PropTypes.arrayOf(PropTypes.object),
        }),
    ).isRequired,
    sharedLists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            companies_count: PropTypes.number,
            children: PropTypes.arrayOf(PropTypes.object),
        }),
    ).isRequired,
    setCurrentList: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    setOwnLists: PropTypes.func.isRequired,
    setSharedLists: PropTypes.func.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsloading: PropTypes.func.isRequired,
    sortValue: PropTypes.shape({
        column: PropTypes.string,
        title: PropTypes.string,
        direction: PropTypes.string,
    }).isRequired,
    setSortValue: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    currentList: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        ancestry: PropTypes.string,
        shared: PropTypes.bool,
    }).isRequired,
    reloadLists: PropTypes.bool.isRequired,
};

export default ListsSection;
