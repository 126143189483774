import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getFirmwideMirrorFilters, updateFirmwideMirrorCharts } from "../../api/diversityApi";
import FiltersBar from "./components/FiltersBar";
import ExportButton from "../../common/components/DiversityPage/ExportButton";
import FirmwideMirrorCharts from "../../utils/firmwideMirrorCharts";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";

const DiversityFirmwidePage = () => {
    const [chartParams, setChartParams] = useState({});
    const [filter, setFilter] = useState({});
    const [changedFilters, setChangedFilters] = useState({});
    const canvasRefs = useRef({});
    const currentActor = useSelector((state) => state.currentActor.resource);

    return (
        <div id="firmwide-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <Row>
                        <Col className="mr-auto">
                            {!_isEmpty(filter) && !_isEmpty(chartParams) && (
                                <ExportButton
                                    canvasRefs={canvasRefs}
                                    title="sidebar.links.diversity.firmwide_dashboard"
                                    filter={filter}
                                    chartParams={chartParams}
                                    changedFilters={changedFilters}
                                />
                            )}
                        </Col>
                        <Col className="col-auto">
                            <FiltersBar
                                setChartParams={setChartParams}
                                getFilters={getFirmwideMirrorFilters}
                                setFilter={setFilter}
                                setChangedFilters={setChangedFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={FirmwideMirrorCharts}
                        updateCharts={updateFirmwideMirrorCharts}
                        channelName="Diversity::FirmwideMirror::ChartsChannel"
                        namespace="firmwide_mirror"
                    />
                </div>
            </div>
        </div>
    );
};

export default DiversityFirmwidePage;
