import * as Yup from "yup";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";
import I18n from "./i18n";

export function validationSchema(field) {
    return Yup.object().shape({
        [field]: Yup.array()
            .of(
                Yup.object().shape({
                    uid: Yup.string().nullable(),
                    email: Yup.string().when("uid", {
                        is: (uid) => !_isNull(uid) && uid.length > 0,
                        then: Yup.string().email().max(255).required(),
                    }),
                    name: Yup.string()
                        .min(3)
                        .max(255)
                        .nullable()
                        .when("uid", { is: false, then: Yup.string().required() })
                        .when("email", {
                            is: (email) => !_isUndefined(email) && email.length > 0,
                            then: Yup.string().required(),
                        }),
                    first_name: Yup.string().max(255).nullable(),
                    last_name: Yup.string().max(255).nullable(),
                })
            )
            .uniqueProperty("name", I18n.t("diversity.clients.steps.errors.duplicated")),
        comment: Yup.string().max(5000).nullable(),
    });
}

const initFirm = { uid: null, email: "", name: "", first_name: "", last_name: "" };

export { initFirm };
