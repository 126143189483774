import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faThumbtack } from "@fortawesome/pro-solid-svg-icons";

const PinIndicator = ({ pageName, element, isPinned, pinFunction }) => {
    const [pinning, setPinning] = useState(false);
    const handlePin = (filterKey, element, pin) => {
        setPinning(true);
        pinFunction(filterKey, element, pin).finally(() => setPinning(false));
    };

    return pinning ? (
        <FontAwesomeIcon icon={faCircleNotch} className="align-baseline ml-2 fa-spin" />
    ) : (
        <FontAwesomeIcon
            icon={faThumbtack}
            className={classNames("align-baseline ml-2 cursor-pointer", {
                "hover-hide": !isPinned,
                "text-secondary": !isPinned,
            })}
            size="xs"
            onClick={() => handlePin(pageName, element, !isPinned)}
        />
    );
};

PinIndicator.propTypes = {
    pageName: PropTypes.string.isRequired,
    isPinned: PropTypes.bool.isRequired,
    pinFunction: PropTypes.func.isRequired,
    element: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default PinIndicator;
