import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _has from "lodash/has";
import I18n from "../../../utils/i18n";
import FilteredChartsExportButton from "../../../common/components/FilteredChartsExportButton";

const ExportButton = ({ canvasRefs, quarterlyPartnershipStat }) => {
    const fileName = quarterlyPartnershipStat
        ? `${quarterlyPartnershipStat.firm_name}_Report_Card`.replace(" ", "_")
        : "";

    const labelFor = (field) => _get(quarterlyPartnershipStat, field, I18n.t("common.not_available"));
    const labelForTotalScore = () =>
        _has(quarterlyPartnershipStat, "total_score")
            ? quarterlyPartnershipStat?.total_score.toFixed(2).toLocaleString(I18n.locale)
            : labelFor("total_score");

    const buildHeader = (pdfFile, { previousYLocation, leftIndent, lineInterval, defaultFont }) => {
        pdfFile.setFont(defaultFont, "bold");
        pdfFile.setFontSize(14);
        pdfFile.text(
            leftIndent,
            previousYLocation,
            I18n.t("diversity.pdf.client_specific_reports.header", {
                firm_name: labelFor("firm_name"),
                firm_rating: labelFor("rating"),
                firm_total_score: labelForTotalScore(),
                client_name: labelFor("client_name"),
                max_rating: labelFor("max_rating"),
                quarter_name: labelFor("quarter_name"),
            }),
        );
        return previousYLocation + lineInterval * 3;
    };

    return (
        <FilteredChartsExportButton
            canvasRefs={canvasRefs}
            buildHeader={buildHeader}
            exportedFileName={fileName}
            buttonType="warning"
            buttonClass="text-light m-1 float-right"
        />
    );
};

ExportButton.propTypes = {
    canvasRefs: PropTypes.object.isRequired,
    quarterlyPartnershipStat: PropTypes.shape({
        firm_name: PropTypes.string,
        client_name: PropTypes.string,
        rating: PropTypes.number,
        max_rating: PropTypes.number,
        total_score: PropTypes.number,
        quarter_name: PropTypes.string,
    }),
};

export default ExportButton;
