import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faThList } from "@fortawesome/pro-solid-svg-icons";
import _map from "lodash/map";
import _orderBy from "lodash/orderBy";
import _isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import ListsSection from "./components/ListsSection";
import CompaniesSection from "./components/CompaniesSection";
import { getMyLists } from "../../api/listsApi";
import I18n from "../../utils/i18n";
import AddListModal from "./components/AddListModal";

const CompanyListPage = () => {
    const [sortValue, setSortValue] = useState({
        column: "updated_at",
        title: I18n.t("companies.lists.sections.my_lists.sort_types.by_updated_at"),
        direction: "desc",
    });
    const [searchValue, setSearchValue] = useState("");
    const [currentList, setCurrentList] = useState({ id: null, name: null, ancestry: null });
    const [ownLists, setOwnLists] = useState([]);
    const [sharedLists, setSharedLists] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [reloadLists, setReloadLists] = useState(false);
    const [showAddListModal, setShowAddListModal] = useState(false);
    const [viewMode, setViewMode] = useState("table");
    const currentActor = useSelector((state) => state.currentActor.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentActor.organization.permissions.company_profile_permission) {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        setIsloading(true);
        getMyLists()
            .then(({ data: { own, shared, newest } }) => {
                let sortedOwnLists = _orderBy(own, [sortValue.column], [sortValue.direction]);

                setOwnLists(sortedOwnLists);
                setSharedLists(sortBy(shared, sortValue.column, sortValue.direction));
                if (sortedOwnLists.length > 0) setCurrentList(newest);
            })
            .finally(() => setIsloading(false));
    }, [reloadLists]);

    const sortBy = (array, columnName, direction) => {
        _map(array, (element) => {
            if (!_isEmpty(element.children)) {
                element.children = sortBy(element.children, columnName, direction);
            }
        });

        return _orderBy(array, [columnName], [direction]);
    };

    return (
        <div id="company-profiles">
            <div className="top-bar">
                <Col lg={12}>
                    <div className="py-3 d-flex">
                        <Button variant="primary" onClick={() => setShowAddListModal(true)} className="text-white">
                            {I18n.t("companies.lists.buttons.create_list")}
                        </Button>
                        <AddListModal
                            show={showAddListModal}
                            onClose={() => setShowAddListModal(false)}
                            setReloadLists={setReloadLists}
                        />

                        <span className="ml-auto">
                            {I18n.t("companies.lists.buttons.toggle_view")}
                            <Link
                                to="#"
                                className={classNames("ml-3", { "text-muted": viewMode === "dashboard" })}
                                onClick={() => setViewMode("table")}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </Link>
                            <Link
                                to="#"
                                className={classNames("ml-3", { "text-muted": viewMode !== "dashboard" })}
                                onClick={() => setViewMode("dashboard")}
                            >
                                <FontAwesomeIcon icon={faThList} />
                            </Link>
                        </span>
                    </div>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column ">
                <div className="flex-grow-1 overflow-auto">
                    <Container fluid className="my-3">
                        <Row>
                            <Col sm={3}>
                                <ListsSection
                                    ownLists={ownLists}
                                    sharedLists={sharedLists}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    setCurrentList={setCurrentList}
                                    setOwnLists={setOwnLists}
                                    setSharedLists={setSharedLists}
                                    isLoading={isLoading}
                                    setIsloading={setIsloading}
                                    sortValue={sortValue}
                                    setSortValue={setSortValue}
                                    sortBy={sortBy}
                                    currentList={currentList}
                                    reloadLists={reloadLists}
                                />
                            </Col>
                            <Col sm={9}>
                                <CompaniesSection
                                    setCurrentList={setCurrentList}
                                    viewMode={viewMode}
                                    currentList={currentList}
                                    setReloadLists={setReloadLists}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default CompanyListPage;
