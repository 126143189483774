import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Select from "react-select";
import _map from "lodash/map";
import _find from "lodash/find";
import I18n from "../../utils/i18n";

const PageSizeSelector = ({ currentSize, pageSizes = [10, 25, 50, 100], onChange }) => {
    const pageSizeOptions = _map(pageSizes, (pageSize) => ({
        value: pageSize,
        label: I18n.t("tables.items_per_page", { count: pageSize }),
    }));

    return (
        <Form.Group>
            <Select
                className="react-select per-page-select"
                classNamePrefix="react-select"
                name="pageSize"
                onChange={({ value }) => onChange(value)}
                options={pageSizeOptions}
                value={_find(pageSizeOptions, { value: currentSize })}
            />
        </Form.Group>
    );
};

PageSizeSelector.propTypes = {
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
    currentSize: PropTypes.number.isRequired,
};

export default PageSizeSelector;
