import React from "react";
import PropTypes from "prop-types";
import { Form, Table } from "react-bootstrap";
import _map from "lodash/map";
import _find from "lodash/find";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";

const SuppliersTable = ({ uploadResults, requestParams, setRequestParams, showSubscriptionColumns }) => {
    const onChangeSupplierNameControl = (uploadResultId, organization) => {
        setRequestParams((prevState) => {
            const newState = [...prevState];
            newState[uploadResultId].organization_attributes = { uid: organization.uid, name: organization.name };
            return newState;
        });
    };

    const getSupplierNameControl = (uploadResult, uploadResultId, requestParam) => {
        if (uploadResult.organizations.length > 0) {
            if (uploadResult.organizations.length === 1) {
                return uploadResult.organizations[0].name;
            } else {
                return (
                    <Form.Control
                        as="select"
                        value={requestParam.organization_attributes.uid}
                        onChange={(event) => {
                            const organization = getOrganization(uploadResult, event.target.value);
                            onChangeSupplierNameControl(uploadResultId, organization);
                        }}
                        custom
                    >
                        {_map(uploadResult.organizations, (organization) => (
                            <option key={organization.uid} value={organization.uid}>
                                {organization.name}
                            </option>
                        ))}
                    </Form.Control>
                );
            }
        } else {
            return uploadResult.supplier_attributes.name;
        }
    };

    const getTotalCentsControl = (uploadResult) => {
        const totalCents = _round(uploadResult.monthly_partnership_stats_attributes.total_amount).toLocaleString(
            I18n.locale
        );

        if (uploadResult.monthly_partnership_stats_attributes.total_percentage) {
            const totalPercentage = _round(
                uploadResult.monthly_partnership_stats_attributes.total_percentage,
                2
            ).toLocaleString(I18n.locale);

            return `${totalPercentage}% (${uploadResult.monthly_partnership_stats_attributes.total_currency.symbol}${totalCents})`;
        } else {
            return `${uploadResult.monthly_partnership_stats_attributes.total_currency.symbol}${totalCents}`;
        }
    };

    const getOrganization = (uploadResult, uid) => _find(uploadResult.organizations, ["uid", uid]);

    return (
        <Table borderless className="sticky position-absolute">
            <thead className="text-uppercase">
                <tr>
                    <th>
                        {I18n.t("tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.supplier_name")}
                    </th>
                    {showSubscriptionColumns && (
                        <th>
                            {I18n.t(
                                "tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.supplier_category"
                            )}
                        </th>
                    )}
                    <th>
                        {I18n.t("tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.supplier_email")}
                    </th>
                    <th>{I18n.t("tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.spend")}</th>
                    {showSubscriptionColumns && (
                        <>
                            <th>
                                {I18n.t(
                                    "tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.certificate_type"
                                )}
                            </th>
                            <th>
                                {I18n.t(
                                    "tiered_diversity.suppliers.new.steps.review.tables.suppliers.header.certificate_expired"
                                )}
                            </th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody>
                {requestParams.length > 0 &&
                    _map(uploadResults, (uploadResult, uploadResultId) => {
                        const requestParam = requestParams[uploadResultId];
                        const organization = getOrganization(uploadResult, requestParam.organization_attributes.uid);
                        const supplierName = getSupplierNameControl(uploadResult, uploadResultId, requestParam);

                        return (
                            <tr
                                key={[
                                    requestParam.organization_attributes.name,
                                    requestParam.organization_attributes.uid,
                                    uploadResultId,
                                ].join("-")}
                            >
                                <td>{supplierName}</td>

                                {showSubscriptionColumns && (
                                    <td>{organization && organization.industry && organization.industry.name}</td>
                                )}
                                <td>{requestParam.user_attributes.email}</td>
                                <td>{getTotalCentsControl(uploadResult)}</td>
                                {showSubscriptionColumns && (
                                    <>
                                        <td>
                                            {organization &&
                                                organization.diversity_certificate &&
                                                organization.diversity_certificate.name}
                                        </td>
                                        <td>
                                            {organization &&
                                                organization.diversity_certificate &&
                                                I18n.l(
                                                    "date.formats.long",
                                                    organization.diversity_certificate.expiration_date
                                                )}
                                        </td>
                                    </>
                                )}
                            </tr>
                        );
                    })}
            </tbody>
        </Table>
    );
};

SuppliersTable.propTypes = {
    uploadResults: PropTypes.arrayOf(
        PropTypes.shape({
            organizations: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    uid: PropTypes.string,
                    industry: PropTypes.shape({
                        name: PropTypes.string,
                    }),
                    diversity_certificate: PropTypes.shape({
                        name: PropTypes.string,
                        expiration_date: PropTypes.string,
                    }),
                })
            ),
            user_attributes: PropTypes.shape({
                email: PropTypes.string.isRequired,
                uid: PropTypes.string,
            }),
            monthly_partnership_stats_attributes: PropTypes.shape({
                total_amount: PropTypes.number.isRequired,
                total_currency: PropTypes.shape({
                    iso_code: PropTypes.string,
                    symbol: PropTypes.string,
                }),
                total_percentage: PropTypes.number,
            }),
            supplier_attributes: PropTypes.shape({
                email: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }),
        })
    ),
    requestParams: PropTypes.arrayOf(
        PropTypes.shape({
            organization_attributes: PropTypes.shape({
                uid: PropTypes.string,
                name: PropTypes.string.isRequired,
            }),
            user_attributes: PropTypes.shape({
                uid: PropTypes.string,
                email: PropTypes.string.isRequired,
            }),
            monthly_partnership_stats_attributes: PropTypes.shape({
                total_amount: PropTypes.number.isRequired,
                total_currency: PropTypes.string.isRequired,
            }),
        })
    ),
    setRequestParams: PropTypes.func.isRequired,
    showSubscriptionColumns: PropTypes.bool.isRequired,
};

export default SuppliersTable;
