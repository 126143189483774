import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import I18n from "../../utils/i18n";

const ActivationStep = () => {
    const currentActor = useSelector((store) => store.currentActor.resource);
    const history = useHistory();

    return (
        <Card className="text-dark">
            <Card.Body>
                <h3>{I18n.t("forms.two_factor_authentication.steps.activation.title")}</h3>
                <p>{I18n.t("forms.two_factor_authentication.steps.activation.description")}</p>
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="primary"
                    className="text-light m-1"
                    onClick={() => history.push(`/users/${currentActor.uid}/edit`)}
                >
                    {I18n.t("common.links.done")}
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default ActivationStep;
