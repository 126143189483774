import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import _last from "lodash/last";
import _find from "lodash/find";
import _map from "lodash/map";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Col, Container, Form, Jumbotron, Row, Spinner } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import ConfirmAction from "../../../common/components/ConfirmAction";
import { sanitizeLink } from "../../../utils/sanitizeText";

const PricingCard = ({ customer, price, maxQuantity }) => {
    const localeKey = _last(price.lookup_key.split("/"));

    const history = useHistory();
    const pricingForm = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedTier, setSelectedTier] = useState(
        price.subscription.status === "active" || price.tiers.length === 0
            ? price.subscription.price_tier
            : _find(price.tiers, (tier) => !tier.up_to || maxQuantity / tier.up_to <= 1)
    );

    const canProceedWithoutPay = () =>
        price.subscription.status === "active" && selectedTier.up_to === price.subscription.price_tier.up_to;

    const onProceed = () => {
        setIsSubmitting(true);

        if (canProceedWithoutPay()) {
            history.push("/tiered_diversity/dashboard");
        } else if (customer.uid) {
            setShowModal(true);
        } else {
            onSubmit();
        }
    };

    const onSubmit = () => {
        pricingForm.current.submit();
    };

    const onHide = () => {
        setIsSubmitting(false);
        setShowModal(false);
    };

    return (
        <Jumbotron className="p-4 mb-0">
            <Container>
                <Row>
                    <Col>
                        <h3 className="font-weight-bolder">{I18n.t(`tiered_diversity.pricing.header.${localeKey}`)}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 className="text-nowrap">{`${price.currency.symbol}${(selectedTier.flat_amount / 100.0)
                            .toFixed(2)
                            .toLocaleString(I18n.locale)} / year`}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {price.tiers.length > 0 ? (
                            <div>
                                <small className="text-muted">
                                    {I18n.t("tiered_diversity.pricing.subscription_quantity")}
                                </small>
                                <ButtonGroup>
                                    {_map(price.tiers, (tier, index) => (
                                        <Button
                                            key={tier.flat_amount}
                                            variant={
                                                price.subscription.status !== "unavailable" &&
                                                selectedTier.up_to === tier.up_to
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            size="sm"
                                            className="text-white"
                                            role="button"
                                            disabled={
                                                price.subscription.status === "unavailable" ||
                                                maxQuantity > (tier.up_to || +Infinity)
                                            }
                                            onClick={() => setSelectedTier(tier)}
                                        >
                                            {tier.up_to || `${price.tiers[index - 1].up_to + 1}+`}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        ) : (
                            <div className="invisible">
                                <small className="text-muted">
                                    {I18n.t("tiered_diversity.pricing.subscription_quantity")}
                                </small>
                                <ButtonGroup>
                                    <Button size="sm" className="text-white" role="button">
                                        Hidden
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant={price.subscription.status === "unavailable" ? "secondary" : "primary"}
                            className="text-white"
                            size="lg"
                            block={true}
                            disabled={price.subscription.status === "unavailable" || isSubmitting}
                            onClick={onProceed}
                        >
                            <Form ref={pricingForm} action="/tiered_diversity/payments/checkout" method="POST">
                                <input type="hidden" name="lookup_key" value={price.lookup_key} />
                                {selectedTier.up_to && (
                                    <input type="hidden" name="quantity" value={selectedTier.up_to} />
                                )}
                                <input
                                    type="hidden"
                                    name="authenticity_token"
                                    value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                                />
                            </Form>

                            {isSubmitting && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2"
                                />
                            )}

                            {price.subscription.status === "unavailable"
                                ? I18n.t("common.not_available")
                                : I18n.t(`common.links.${canProceedWithoutPay() ? "next" : "upgrade"}`)}
                        </Button>

                        <ConfirmAction
                            show={showModal}
                            onHide={onHide}
                            onSubmit={onSubmit}
                            confirm="common.links.confirm"
                            title={I18n.t("tiered_diversity.pricing.warning.header")}
                        >
                            <p>{I18n.t("tiered_diversity.pricing.warning.body")}</p>
                            <p
                                dangerouslySetInnerHTML={sanitizeLink(
                                    I18n.t("tiered_diversity.pricing.warning.footer", {
                                        url: "/payments/customer_portal",
                                    })
                                )}
                            />
                        </ConfirmAction>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    );
};

PricingCard.propTypes = {
    customer: PropTypes.shape({
        uid: PropTypes.string,
    }),
    price: PropTypes.shape({
        lookup_key: PropTypes.string.isRequired,
        tiers: PropTypes.arrayOf(
            PropTypes.shape({
                up_to: PropTypes.number,
                flat_amount: PropTypes.number.isRequired,
            })
        ),
        currency: PropTypes.shape({
            symbol: PropTypes.string.isRequired,
        }),
        subscription: PropTypes.shape({
            status: PropTypes.string,
            price_tier: PropTypes.shape({
                up_to: PropTypes.number,
                flat_amount: PropTypes.number.isRequired,
            }),
        }),
    }),
    maxQuantity: PropTypes.number.isRequired,
};

export default PricingCard;
