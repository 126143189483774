import React, { useState } from "react";
import { OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import I18n from "../../../../../utils/i18n";
import CertificateName from "./CertificateName";
import CertificateReportIssueModal from "./CertificateReportIssueModal";

const CertificateCard = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const VALUE_DATE_FORMAT = "YYYY-MM-DD";
    const DATE_FORMAT = "DD/MM/YYYY";
    const { uid } = useParams();
    const datesLine = () =>
        I18n.t("companies.profiles.paragraphs.certificates.dates", {
            origin_year: moment(data.origin_date, VALUE_DATE_FORMAT).year(),
            expire_date: moment(data.expiration_date, VALUE_DATE_FORMAT).format(DATE_FORMAT),
        });
    return (
        <ListGroup.Item {...(data.primary && { variant: "primary" })}>
            <div className="d-flex">
                <div className="d-flex flex-grow-1 overflow-hidden">
                    <div>
                        <Link target="_blank" to={`/organizations/${uid}/diversity/certificates/${data.id}.pdf`}>
                            <FontAwesomeIcon icon={faFilePdf} size="2xl" />
                        </Link>
                    </div>
                    <div className="overflow-hidden">
                        <div className="ml-3">
                            <CertificateName {...data} />
                            <small className="text-muted">{datesLine()}</small>
                        </div>
                    </div>
                </div>
                <div>
                    <CertificateReportIssueModal
                        key={`${uid}-${data.id}`}
                        setShowModal={setShowModal}
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        certificateId={data.id}
                    />
                    <OverlayTrigger
                        placement="right"
                        trigger={["hover", "focus"]}
                        overlay={
                            <Tooltip id={`certificate-report-tooltip-${data.id}`}>
                                {I18n.t("companies.profiles.paragraphs.certificates.report_tooltip")}
                            </Tooltip>
                        }
                    >
                        <Link to="#" onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon className="mt-1" icon={faInfoCircle} />
                        </Link>
                    </OverlayTrigger>
                </div>
            </div>
        </ListGroup.Item>
    );
};

CertificateCard.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        expiration_date: PropTypes.string,
        origin_date: PropTypes.string,
        primary: PropTypes.bool,
        description: PropTypes.string,
        source: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
};

export default CertificateCard;
