import API from "../utils/api";

export function createNegotiation(params) {
    return API.post("/negotiations", params);
}

export function getNegotiations(params) {
    return API.get("/negotiations", { params: params });
}

export function getCloneOptionsForNegotiation(negotiation_id) {
    return API.get(`/negotiations/${negotiation_id}/clone/new`);
}

export function cloneNegotiation(negotiation_id, params) {
    return API.post(`/negotiations/${negotiation_id}/clone`, params);
}
