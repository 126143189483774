import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { getDescriptions } from "../../../api/activityApi";
import I18n from "../../../utils/i18n";

const parseDescription = (description) => {
    const index = description.lastIndexOf("_");
    return [description.slice(0, index), description.slice(index + 1)];
};

const ActivityLogsFilterDescription = () => {
    const [field] = useField("q[description]");
    const { setFieldValue } = useFormikContext();
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        getDescriptions()
            .then((response) => {
                setOptions(
                    response.data.map((description) => {
                        const [record_type, action] = parseDescription(description);

                        return {
                            value: description,
                            label: `${I18n.t(`activerecord.models.${record_type}.one`)} ${I18n.t(
                                `activerecord.attributes.activity/log.actions.${action}`,
                            )}`,
                        };
                    }),
                );
            })
            .catch(() => setOptions([]))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Form.Group as={Col}>
            <Form.Label htmlFor="q_description">{I18n.t("activerecord.attributes.activity/log.action")}</Form.Label>
            <Select
                id="q_description"
                name="q[description]"
                className="react-select"
                classNamePrefix="react-select"
                options={options}
                onChange={(options) =>
                    setFieldValue(
                        "q[description]",
                        options.map((option) => option.value),
                    )
                }
                isLoading={isLoading}
                isMulti
                isClearable
                value={options.filter((option) => (field.value || "").includes(option.value))}
            />
        </Form.Group>
    );
};

export default ActivityLogsFilterDescription;
