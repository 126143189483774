import React from "react";
import { Col, Row } from "react-bootstrap";
import _toString from "lodash/toString";
import PropTypes from "prop-types";
import _join from "lodash/join";
import _find from "lodash/find";
import DisplayField from "../content/DisplayField";
import I18n from "../../../../utils/i18n";

const Summary = ({ values }) => {
    const salesValue = values.company_structure_attributes.sales_indicators_attributes[0]
        ? "$" + values.company_structure_attributes.sales_indicators_attributes[0].value
        : "";

    const employeesValue = values.company_structure_attributes.employees_indicators_attributes[0]
        ? values.company_structure_attributes.employees_indicators_attributes[0].value
        : "";

    const firmwideTotalScore = values.firm_stats && values.firm_stats[0] ? values.firm_stats[0].total_score : "";
    const partnershipTotalScore =
        values.partnership_stats && values.partnership_stats[0] ? values.partnership_stats[0].total_score : "";
    const totalScores = _join(
        [firmwideTotalScore, partnershipTotalScore],
        firmwideTotalScore !== "" && partnershipTotalScore !== "" ? "/" : "",
    );
    const primaryIndustry = _find(values.industries, ["primary", true]);
    const primaryCertificate = _find(values.certificates, ["primary", true]);

    return (
        <Row>
            <Col md={6} lg={4}>
                <DisplayField
                    title={I18n.t("activerecord.models.industry.one")}
                    value={primaryIndustry && `${primaryIndustry.area}: ${primaryIndustry.focus}`}
                />
            </Col>
            <Col md={6} lg={3}>
                <DisplayField
                    title={I18n.t("activerecord.models.diversity/certificate")}
                    value={primaryCertificate && primaryCertificate.name}
                />
            </Col>
            <Col md={4} lg={2}>
                <DisplayField title={I18n.t("activerecord.models.sales_indicator")} value={salesValue} />
            </Col>
            <Col md={4} lg={2}>
                <DisplayField
                    title={I18n.t("activerecord.models.employees_indicator")}
                    value={_toString(employeesValue)}
                />
            </Col>
            <Col md={4} lg={1}>
                <DisplayField title={I18n.t("companies.profiles.text.jb_total_score")} value={totalScores} />
            </Col>
        </Row>
    );
};

Summary.propTypes = {
    values: PropTypes.shape({
        company_structure_attributes: PropTypes.shape({
            sales_indicators_attributes: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.number,
                }),
            ),
            employees_indicators_attributes: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.number,
                }),
            ),
        }),
        industries: PropTypes.arrayOf(
            PropTypes.shape({
                primary: PropTypes.bool,
                area: PropTypes.string,
            }),
        ),
        firm_stats: PropTypes.arrayOf(
            PropTypes.shape({
                total_score: PropTypes.number,
            }),
        ),
        partnership_stats: PropTypes.arrayOf(
            PropTypes.shape({
                total_score: PropTypes.string,
            }),
        ),
        certificates: PropTypes.arrayOf(
            PropTypes.shape({
                primary: PropTypes.bool,
                name: PropTypes.string,
            }),
        ),
    }),
};

export default Summary;
