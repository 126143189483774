import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import FormRow from "../../../../common/components/FormRow";
import { getWhitelabelsByTerm } from "../../../../api/searchApi";
import I18n from "../../../../utils/i18n";

const WhitelabelName = ({ form, value }) => {
    const selectName = "filter[whitelabel]";
    const inputName = "filter[whitelabel_id_in]";
    const [whitelabels, setWhitelabels] = useState([]);

    useEffect(() => {
        getWhitelabelsByTerm().then((response) => setWhitelabels(response.data));
    }, []);

    return (
        <div className="col">
            <FormRow label={I18n.t("activerecord.attributes.organization.whitelabel_id")}>
                <Select
                    isMulti
                    name={inputName}
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder={I18n.t("common.placeholders.search")}
                    options={whitelabels}
                    onChange={(option) => {
                        form.setFieldValue(inputName, _map(option, "value"));
                        form.setFieldValue(selectName, option);
                    }}
                    value={_filter(whitelabels, (option) => _includes(value, option.value))}
                />
            </FormRow>
        </div>
    );
};

WhitelabelName.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    value: PropTypes.arrayOf(PropTypes.number),
};

export default WhitelabelName;
