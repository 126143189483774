import React from "react";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import Title from "../common/Title";
import Header from "../common/Header";
import Chart from "../common/Chart";

const EventsCharts = ({ reports }) => (
        <>
            <Title label={I18n.t("home.reports.labels.savings")} />
            <div className="section mb-3">
                <Header />
                <Chart
                    label={I18n.t("home.reports.labels.value")}
                    totalValue={reports.value}
                    chartData={reports.value_chart}
                />
                <Chart
                    label={I18n.t("home.reports.labels.benefit")}
                    totalValue={reports.benefit}
                    chartData={reports.benefit_chart}
                />
            </div>
            <hr />
            <div className="section mb-3">
                <Header />
                <Chart
                    label={I18n.t("home.reports.labels.average_improvement")}
                    totalValue={reports.average_improvement}
                    chartData={reports.average_improvement_chart}
                />
                <Chart
                    label={I18n.t("home.reports.labels.overall_improvement")}
                    totalValue={reports.overall_improvement}
                    withoutChart={true}
                />
            </div>
            <hr />
            <Title label={`${reports.events_count} ${I18n.t("home.reports.labels.events")}`} />
            <div className="section mb-3">
                <Row>
                    {reports.types.map((events, index) => (
                            <div key={index} className="col-6 text-center">
                                {events.count} {events.name}
                            </div>
                        ))}
                </Row>
            </div>
        </>
    );

EventsCharts.propTypes = {
    reports: PropTypes.shape({
        types: PropTypes.array,
        events_count: PropTypes.number,
        overall_improvement: PropTypes.string,
        average_improvement: PropTypes.string,
        value: PropTypes.string,
        benefit: PropTypes.string,
        benefit_chart: PropTypes.object,
        average_improvement_chart: PropTypes.object,
        value_chart: PropTypes.object,
    }),
};

export default EventsCharts;
