import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import Table, { RemoteSelectFilter } from "./Table";
import CommentPopover from "./DiversityPage/CommentPopover";
import ThreeDotsToggle from "./Table/ThreeDotsToggle";
import ConfirmAction from "./ConfirmAction";
import { getFirmwideRequests, updateRequest } from "../../api/diversityApi";
import I18n from "../../utils/i18n";

const StatusCell = ({ row: { original } }) => {
    const getStatusText = () => {
        const statusText = I18n.t(`activerecord.attributes.diversity/participation.statuses.${original.status}`);

        switch (original.status) {
            case "requested":
                return `${statusText} ${original.requests_count}`;
            case "rejected":
                return `${statusText} ${original.rejects_count}`;
            default:
                return statusText;
        }
    };

    return (
        <div className="d-flex align-items-start">
            <div
                className={classNames("badge", "p-1", {
                    "badge-warning": original.status === "reviewed",
                    "badge-success": original.status === "approved",
                    "badge-light": original.status === "requested",
                    "badge-danger": original.status === "rejected",
                    "badge-secondary": original.status === "canceled",
                })}
            >
                {getStatusText()}
            </div>
            {original?.comment?.text && (
                <CommentPopover comment={original.comment.text} requesterName={original.comment.author} />
            )}
        </div>
    );
};

const SubmittedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.submitted_at)}</div>
);

const SubmittedCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {I18n.t(`tables.diversity/participation.submitted_statuses.${original.submitted}`)}
    </div>
);

const LastSignInAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.manager_current_sign_in_at)}</div>
);

const UpdatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.short", original.updated_at)}</div>
);

const ActionsCell = ({ row: { original, index }, updateData }) => {
    const [modalShow, setModalShow] = useState(false);

    const onSubmit = () =>
        updateRequest(original.id, { participation: { status: "canceled" } }).then((response) => {
            updateData(index, response.data);
            setModalShow(false);
        });

    const showActions = original.status === "requested";

    return (
        <>
            {showActions && (
                <>
                    <Dropdown>
                        <Dropdown.Toggle as={ThreeDotsToggle} />
                        <Dropdown.Menu size="sm" title="">
                            {original.status === "requested" && (
                                <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                    {I18n.t("tables.diversity/participation.actions.cancel")}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <ConfirmAction
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onSubmit={onSubmit}
                        confirm="common.links.text_ok"
                        title={I18n.t("tables.diversity/participation.titles.cancel")}
                        text={I18n.t("tables.diversity/participation.confirm_text")}
                    />
                </>
            )}
        </>
    );
};

const DiversityFirmwideRequestsTable = ({ organizationUid = null, pageSize }) => {
    const [globalFilters, setGlobalFilters] = useState({});
    const [globalFilterOptions, setGlobalFilterOptions] = useState({});

    const columns = useMemo(() => {
        let result = [
            {
                Header: I18n.t("tables.diversity/participation.requested_firm"),
                accessor: "firmwide_name",
                Filter: (props) => (
                    <RemoteSelectFilter
                        {...props}
                        setGlobalFilters={setGlobalFilters}
                        globalFilters={globalFilters}
                        globalFilterOptions={globalFilterOptions}
                    />
                ),
                filter: "includes",
            },
            {
                Header: I18n.t("tables.diversity/participation.submitted_at"),
                accessor: "submitted_at",
                Cell: SubmittedAtCell,
            },
            {
                Header: I18n.t("tables.diversity/participation.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("tables.diversity/participation.submitted"),
                accessor: "submitted",
                sortType: "basic",
                Cell: SubmittedCell,
            },
            {
                Header: I18n.t("tables.diversity/participation.manager_current_sign_in_at"),
                id: "manager_current_sign_in_at",
                isSorted: false,
                Cell: LastSignInAtCell,
            },
            {
                Header: I18n.t("tables.diversity/participation.updated_at"),
                accessor: "updated_at",
                Cell: UpdatedAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                Cell: (props) => <ActionsCell {...props} updateData={updateData} />,
            },
        ];

        if (!organizationUid) {
            result.unshift({
                Header: I18n.t("tables.diversity/participation.requester"),
                accessor: "name",
                Filter: (props) => (
                    <RemoteSelectFilter
                        {...props}
                        setGlobalFilters={setGlobalFilters}
                        globalFilters={globalFilters}
                        globalFilterOptions={globalFilterOptions}
                    />
                ),
                filter: "includes",
            });
        }

        return result;
    }, [globalFilters, globalFilterOptions]);
    const [data, setData] = useState([]);
    const [pagesCount, setPagesCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const fetchIdRef = useRef(0);

    const updateData = (rowIndex, newRow) => {
        setData((oldRows) => {
            const newRows = oldRows.map((oldRow, index) => {
                if (index === rowIndex) {
                    return newRow;
                }
                return oldRow;
            });

            return newRows;
        });
    };

    const fetchData = useCallback(({ sortBy, pageIndex, pageSize, globalFilters }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] ? sortBy[0] : { column: "created_at", desc: true };
        setLoading(true);

        getFirmwideRequests(organizationUid, {
            page: pageIndex + 1,
            column: sort.id,
            order: sort.desc ? "desc" : "asc",
            page_size: pageSize,
            filters: globalFilters,
        }).then(({ data: { total_pages, records, filter_options } }) => {
            if (fetchId === fetchIdRef.current) {
                setPagesCount(total_pages);
                setData(records);
                setGlobalFilterOptions(filter_options);
                setLoading(false);
            }
        });
    }, []);

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pagesCount}
            loading={loading}
            initialSortBy={[{ id: "updated_at", desc: true }]}
            pageSize={pageSize}
            globalFilters={globalFilters}
            manualSortBy
            globalFilterOptions={globalFilterOptions}
        />
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            firmwide_name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            comment: PropTypes.shape({
                text: PropTypes.string.isRequired,
                author: PropTypes.string,
            }),
            requests_count: PropTypes.number.isRequired,
            rejects_count: PropTypes.number.isRequired,
        }),
    }),
};

SubmittedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            submitted_at: PropTypes.string,
        }),
    }),
};

SubmittedCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            submitted: PropTypes.bool.isRequired,
        }),
    }),
};

LastSignInAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            manager_current_sign_in_at: PropTypes.string,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

UpdatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string.isRequired,
        }),
    }),
};

DiversityFirmwideRequestsTable.propTypes = {
    organizationUid: PropTypes.string,
    pageSize: PropTypes.number.isRequired,
};

export default DiversityFirmwideRequestsTable;
