import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";
import TieredDiversityChart from "../../../../common/components/TieredDiversityChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const Tier1SpendByCategory = ({ params, canvasRefs, getChart }) => (
        <TieredDiversityChart
            params={params}
            type="tier1_spend_by_category"
            chartType="bar"
            canvasRefs={canvasRefs}
            getChart={getChart}
            chartOptions={{
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                callback: getShortLabelForValue,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                callback: (value) => `${_round(value).toLocaleString(I18n.locale)}`,
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let dataLabel = data.labels[tooltipItem.index];
                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            let symbol = dataset.custom.symbol;
                            let value = _round(dataset.data[tooltipItem.index]).toLocaleString(I18n.locale);
                            return `${dataLabel}: ${symbol}${value}`;
                        },
                    },
                },
            }}
        />
    );

Tier1SpendByCategory.propTypes = {
    params: PropTypes.object,
    canvasRefs: PropTypes.object,
    getChart: PropTypes.func.isRequired,
};

export default Tier1SpendByCategory;
