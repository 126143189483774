import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificReportsChart from "./DiversityClientSpecificReportsChart";

const KpiGoalsChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <DiversityClientSpecificReportsChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="kpi_goals"
        chartType="radar"
        chartOptions={{
            ...chartOptions,
            tooltips: {
                callbacks: {
                    title: (items) => parseFloat(items[0].value).toFixed(2).toLocaleString(I18n.locale),
                    label: (tooltipItem, data) =>
                        `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                            .toFixed(2)
                            .toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

KpiGoalsChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default KpiGoalsChart;
