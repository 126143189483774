import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import _map from "lodash/map";
import _find from "lodash/find";
import _reverse from "lodash/reverse";
import _maxBy from "lodash/maxBy";
import _reduce from "lodash/reduce";
import { newPaymentsCheckout } from "../../api/tieredDiversityApi";
import Loader from "../HomePage/components/common/Loader";
import PricingCard from "./components/PricingCard";
import CategoryRow from "./components/CategoryRow";
import I18n from "../../utils/i18n";

const TieredDiversityPricingPage = () => {
    const CATEGORIES = ["categorize_supplier_type", "identify_supplier", "upload_certificate", "tier_data"];
    const LOOKUP_KEYS = ["tiered_diversity/manual", "tiered_diversity/automated", "tiered_diversity/tiered_collection"];
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState({ uid: null });
    const [prices, setPrices] = useState([
        {
            lookup_key: "tiered_diversity/manual",
            tiers: [],
            currency: {
                symbol: "$",
            },
            subscription: {
                status: "active",
                price_tier: {
                    up_to: null,
                    flat_amount: 0,
                },
            },
        },
    ]);
    const [maxQuantity, setMaxQuantity] = useState(0);

    useEffect(() => {
        newPaymentsCheckout()
            .then((response) => {
                setCustomer(response.data.customer);
                setMaxQuantity(_maxBy(response.data.prices, "subscription.quantity").subscription.quantity);
                setPrices((prevState) => {
                    let somethingPurchased = false;

                    return _reduce(
                        _reverse(LOOKUP_KEYS),
                        (result, lookupKey) => {
                            const price = _find([...prevState, ...response.data.prices], ["lookup_key", lookupKey]);

                            if (somethingPurchased) {
                                price.subscription.status = "unavailable";
                            }

                            if (price.subscription.status === "active") somethingPurchased = true;

                            return result.concat(price);
                        },
                        [],
                    );
                });
                setIsLoading(false);
            })
            .catch((error) => {
                error.response.status === 403 && history.push("/");
            });
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 bg-white">
                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                    <h2>{I18n.t("forms.organization.edit.tabs.subscriptions.title")}</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Container>
                                    <Row>
                                        <Col>
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="border-0"></th>
                                                        {_map(LOOKUP_KEYS, (lookupKey) => (
                                                            <th key={lookupKey} className="border-0">
                                                                <PricingCard
                                                                    customer={customer}
                                                                    price={_find(prices, ["lookup_key", lookupKey])}
                                                                    maxQuantity={maxQuantity}
                                                                />
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="font-weight-bolder align-middle border-0"
                                                            colSpan={4}
                                                        >
                                                            <h3 className="w-100 px-1 text-secondary">
                                                                {I18n.t("tiered_diversity.pricing.table_title")}
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                    {_map(CATEGORIES, (category) => (
                                                        <CategoryRow
                                                            key={category}
                                                            category={category}
                                                            lookupKeys={LOOKUP_KEYS}
                                                        />
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default TieredDiversityPricingPage;
