import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import { editAttorneyCase, updateAttorneyCase } from "../../api/legalApi";
import LegalCaseForm from "../../common/components/LegalCaseForm";
import Loader from "../HomePage/components/common/Loader";

const EditLegalCasePage = () => {
    const { organization_uid: organizationUid, attorney_id: attorneyId, id: caseId } = useParams();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        editAttorneyCase(organizationUid, attorneyId, caseId).then((response) => setInitialValues(response.data));
    }, [organizationUid, attorneyId, caseId]);

    if (_isEmpty(initialValues)) {
        return <Loader />;
    }

    return (
        <>
            <TopBarWithBackLink
                backPath={`/organizations/${organizationUid}/attorneys`}
                linkName={I18n.t("forms.organization.edit.tabs.attorneys")}
            />
            <div className="scrollable d-flex flex-column">
                <LegalCaseForm
                    organizationUid={organizationUid}
                    attorneyId={attorneyId}
                    caseId={caseId}
                    initialValues={initialValues}
                    createOrUpdateLegalCase={updateAttorneyCase}
                />
            </div>
        </>
    );
};

export default EditLegalCasePage;
