import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { getIn } from "formik";
import timeZones from "../../../../utils/timeZones";

const TimezoneSelect = ({ form }) => {
    const name = "actor[user_attributes][time_zone]";

    return (
        <Select
            name={name}
            className="react-select"
            classNamePrefix="react-select"
            options={timeZones}
            onChange={(option) => form.setFieldValue(name, option.value)}
            onBlur={() => {
                form.setFieldTouched(name);
            }}
            value={timeZones.find(
                (option) => option.value === (getIn(form.values, "actor.user_attributes.time_zone") || "")
            )}
        />
    );
};

TimezoneSelect.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    }),
};

export default TimezoneSelect;
