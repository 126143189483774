import React from "react";
import PropTypes from "prop-types";
import FiltersBar from "../../../common/components/DiversityPage/FiltersBar";
import { QuarterFilter } from "../../../common/components/DiversityPage/filters";

const DiversityFirmwideUploadFiltersBar = ({ setChartParams, getFilters, loadedAt }) => (
    <FiltersBar
        setChartParams={setChartParams}
        filterTypes={[QuarterFilter]}
        getFilters={getFilters}
        loadedAt={loadedAt}
    />
);

DiversityFirmwideUploadFiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
    loadedAt: PropTypes.string,
};

export default DiversityFirmwideUploadFiltersBar;
