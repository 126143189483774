import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import I18n from "../../../../utils/i18n";
import { deleteTwoFactorAuthentication } from "../../../../api/deviseApi";
import { ShowPassword } from "../../../../common/components";

const TwoFactorAuthenticationSettings = ({ otpRequiredForLogin, setFieldValue, enableEditing }) => {
    const history = useHistory();
    const [twoFactorModalShow, setTwoFactorModalShow] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordInvalid, setCurrentPasswordInvalid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <Form.Group>
            <Modal
                show={twoFactorModalShow}
                onHide={() => setTwoFactorModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                        {I18n.t("forms.edit_profile.two_factor_authentication_settings.disable_modal.title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{I18n.t("forms.edit_profile.two_factor_authentication_settings.disable_modal.body")}</p>
                    <ShowPassword
                        name="current_password"
                        placeholder={I18n.t("activerecord.attributes.user.current_password")}
                        onChange={(event) => {
                            setCurrentPassword(event.target.value);
                            setCurrentPasswordInvalid(false);
                        }}
                        value={currentPassword}
                        isInvalid={currentPasswordInvalid && I18n.t("errors.messages.invalid")}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setTwoFactorModalShow(false)}>
                        {I18n.t("common.links.cancel")}
                    </Button>
                    <Button
                        variant="danger"
                        disabled={currentPassword.length === 0 || isSubmitting}
                        onClick={() => {
                            setIsSubmitting(true);

                            deleteTwoFactorAuthentication(currentPassword)
                                .then(() => {
                                    setFieldValue("actor[user_attributes][otp_required_for_login]", false);
                                    setTwoFactorModalShow(false);
                                })
                                .catch(() => {
                                    setCurrentPasswordInvalid(true);
                                })
                                .finally(() => setIsSubmitting(false));
                        }}
                    >
                        {isSubmitting && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                            />
                        )}
                        {I18n.t("common.links.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form.Check
                custom
                type="checkbox"
                label={I18n.t("activerecord.attributes.user.otp_required_for_login")}
                id="change-two-factor-checkbox"
                className="mb-3"
                disabled={!enableEditing}
                checked={otpRequiredForLogin}
                onChange={(event) => {
                    if (event.target.checked) {
                        history.push("/users/two_factor_authentication");
                    } else {
                        setTwoFactorModalShow(true);
                    }
                }}
            />
        </Form.Group>
    );
};

TwoFactorAuthenticationSettings.propTypes = {
    otpRequiredForLogin: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    enableEditing: PropTypes.bool.isRequired,
};

export default TwoFactorAuthenticationSettings;
