import React from "react";
import { Modal, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../../utils/i18n";

const CommentStep = ({ value, title, setFieldValue, deadlineMessage }) => (
        <Modal.Body>
            <p>{title}</p>
            <Form.Control
                as="textarea"
                value={value}
                placeholder={I18n.t("tables.diversity/participation.comment")}
                onChange={(event) => setFieldValue("comment", event.target.value)}
            />
            {deadlineMessage && (
                <>
                    <br />
                    <p>{deadlineMessage}</p>
                </>
            )}
        </Modal.Body>
    );

CommentStep.propTypes = {
    value: PropTypes.string,
    title: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    deadlineMessage: PropTypes.string,
};

export default CommentStep;
