import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import _ from "lodash";
import LoadingBody from "../../../common/components/Table/LoadingBody";
import I18n from "../../../utils/i18n";
import { DIVERSITY_KEY_INDICATORS_MAPPING } from "../../../utils/diversityKeyIndicators";
import { calculateMatterBasedWeight, calculateSumBy } from "../../../utils/diversityCalculators";

const MatterBasedScoringTable = ({ keyIndicators }) => {
    const groupedValues = _.groupBy(keyIndicators, "key_indicator");
    const sumValue = calculateSumBy(keyIndicators, "possible_points");

    return (
        <Table bordered size="sm" className="diversity-scoring-table">
            <thead>
                <tr>
                    <th scope="col" className="text-uppercase">
                        {I18n.t("diversity.columns.matter_based_scoring")}
                    </th>
                    <th scope="col">{I18n.t("diversity.columns.weight")}</th>
                </tr>
            </thead>
            <tbody>
                {_.isEmpty(keyIndicators) ? (
                    <LoadingBody columnCount={2} rowCount={5} />
                ) : (
                    _.map(DIVERSITY_KEY_INDICATORS_MAPPING, (childKeyIndicators, parentKeyIndicator) => {
                        if (parentKeyIndicator === "firmwide_goal") {
                            return null;
                        }

                        return (
                            <tr key={parentKeyIndicator}>
                                <td scope="row">{I18n.t(`diversity.components.${parentKeyIndicator}`)}</td>
                                <td scope="row">
                                    {calculateMatterBasedWeight(
                                        groupedValues,
                                        parentKeyIndicator,
                                        childKeyIndicators,
                                        sumValue
                                    ).toFixed(2)}
                                    %
                                </td>
                            </tr>
                        );
                    })
                )}
            </tbody>
        </Table>
    );
};

MatterBasedScoringTable.propTypes = {
    keyIndicators: PropTypes.array,
};

export default MatterBasedScoringTable;
