import React, { useState, useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import _isUndefined from "lodash/isUndefined";
import { Dropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _reject from "lodash/reject";
import I18n from "../../../../utils/i18n";
import Table from "../../Table";
import { deleteAttorneyCase, getAttorneyCases } from "../../../../api/legalApi";
import ThreeDotsToggle from "../../Table/ThreeDotsToggle";
import DeleteAction from "../../DeleteAction";

const DateCell = ({ value }) => <div className="d-flex flex-column">{I18n.l("time.formats.short", value)}</div>;

const ActionsCell = ({
    row: {
        original: { id },
    },
    organizationUid,
    attorneyId,
    updateData,
}) => {
    const [deleteCaseModalShow, setDeleteCaseModalShow] = useState(false);

    const onDeleteCaseSubmit = () => {
        deleteAttorneyCase(organizationUid, attorneyId, id).then(() => {
            updateData(id);
            setDeleteCaseModalShow(false);
        });
    };

    return (
        <>
            <Dropdown drop="left">
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item
                        as={Link}
                        to={`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases/${id}/edit`}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} onClick={() => setDeleteCaseModalShow(true)} to="#">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        {I18n.t("common.links.delete")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <DeleteAction
                resource={I18n.t("activerecord.models.legal/case")}
                onSubmit={onDeleteCaseSubmit}
                show={deleteCaseModalShow}
                onHide={() => setDeleteCaseModalShow(false)}
            />
        </>
    );
};

const CasesTable = ({ organizationUid, attorneyId }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.legal/case.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case.venue"),
                accessor: "venue",
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case.judge"),
                accessor: "judge",
            },
            /* eslint-disable react/prop-types */
            {
                Header: I18n.t("activerecord.attributes.legal/case.start_date"),
                accessor: "start_date",
                type: "date",
                Cell: ({
                    row: {
                        original: { start_date },
                    },
                }) => <DateCell value={start_date} />,
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case.end_date"),
                accessor: "end_date",
                type: "date",
                Cell: ({
                    row: {
                        original: { end_date },
                    },
                }) => <DateCell value={end_date} />,
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case_role.name"),
                accessor: "role_name",
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case.matter_description"),
                id: "matter_description",
                Cell: ({
                    row: {
                        original: { matter_description },
                    },
                }) => matter_description,
                isSorted: false,
            },
            {
                Header: I18n.t("activerecord.attributes.legal/case.attorneys_contribution"),
                id: "attorneys_contribution",
                Cell: ({
                    row: {
                        original: { attorneys_contribution },
                    },
                }) => attorneys_contribution,
                isSorted: false,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => (
                    <ActionsCell
                        {...props}
                        organizationUid={organizationUid}
                        attorneyId={attorneyId}
                        updateData={updateData}
                    />
                ),
            },
            /* eslint-enable react/prop-types */
        ],
        [organizationUid, attorneyId],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        ({ pageIndex, pageSize, sortBy, initialSortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || initialSortBy[0];

            if (_isUndefined(organizationUid) || _isUndefined(attorneyId)) {
                setData([]);
                setPageCount(0);
            } else {
                setLoading(true);

                getAttorneyCases(organizationUid, attorneyId, {
                    page: pageIndex + 1,
                    page_size: pageSize,
                    column: sort.id,
                    order: sort.desc ? "desc" : "asc",
                }).then((response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                });
            }
        },
        [organizationUid, attorneyId],
    );

    const updateData = (id) => {
        setData((cases) => _reject(cases, ["id", id]));
    };

    return (
        <>
            <Row className="m-2">
                <Col className="mr-auto"></Col>
                <Col className="col-auto">
                    <Link
                        to={`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases/new`}
                        className="text-white btn btn-primary m-1 ml-2"
                    >
                        {I18n.t("common.links.create")}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        loading={loading}
                        pageCount={pageCount}
                        pageSize={5}
                        initialSortBy={[{ id: "name", desc: false }]}
                    />
                </Col>
            </Row>
        </>
    );
};

DateCell.propTypes = {
    value: PropTypes.string,
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    organizationUid: PropTypes.string.isRequired,
    attorneyId: PropTypes.number.isRequired,
    updateData: PropTypes.func.isRequired,
};

CasesTable.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    attorneyId: PropTypes.number.isRequired,
};

export default CasesTable;
