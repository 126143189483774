import React from "react";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import debounce from "lodash/debounce";
import I18n from "../../../utils/i18n";
import { getPublicDomains } from "../../../api/publicDomainApi";

const DropdownIndicator = (props) =>
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={faSearch} className="mx-2" />
        </components.DropdownIndicator>
    );

const PublicDomainsSearch = () => {
    const history = useHistory();

    const loadOptions = debounce((term, callback) => {
        getPublicDomains({ page: 1, column: "domain", order: "asc", q: { domain_cont: term } }).then((response) =>
            callback(response.data.records),
        );
    }, 500);

    return (
        <AsyncSelect
            cacheOptions
            className="react-select"
            classNamePrefix="react-select"
            placeholder={I18n.t("common.placeholders.search")}
            openMenuOnClick={false}
            loadOptions={loadOptions}
            getOptionLabel={(option) => option.domain}
            getOptionValue={(option) => option.id}
            onChange={({ id }) => history.push(`/public_domains/${id}/edit`)}
            components={{ DropdownIndicator }}
        />
    );
};

export default PublicDomainsSearch;
