import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FieldArray } from "formik";
import _map from "lodash/map";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import FirmFields from "../FirmFields";

const AddFirmStep = ({ values, title, handleBlur, setFieldValue, errors, field, initFirm }) => (
    <Modal.Body>
        <p>{title}</p>
        <FieldArray name={field}>
            {({ push, remove }) => (
                <>
                    {_map(values, (firm, index) => (
                        <FirmFields
                            firm={firm}
                            key={index}
                            index={index}
                            values={values}
                            setFieldValue={setFieldValue}
                            field={field}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleRemove={remove}
                        />
                    ))}
                    <Button variant="light" className="mt-2" onClick={() => push(initFirm)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </>
            )}
        </FieldArray>
    </Modal.Body>
);

AddFirmStep.propTypes = {
    values: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    field: PropTypes.string.isRequired,
    initFirm: PropTypes.object.isRequired,
};

export default AddFirmStep;
