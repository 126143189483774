import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const Title = ({ label }) => (
        <Row>
            <Col>
                <h3>{label}</h3>
            </Col>
        </Row>
    );

Title.propTypes = {
    label: PropTypes.string,
};

export default Title;
