import React from "react";
import PropTypes from "prop-types";
import FiltersBar from "../../../common/components/DiversityPage/FiltersBar";
import { QuarterFilter, FirmFilter } from "../../../common/components/DiversityPage/filters";

const DiversityClientSpecificReportsFiltersBar = ({ setChartParams, getFilters }) => (
    <FiltersBar setChartParams={setChartParams} filterTypes={[QuarterFilter, FirmFilter]} getFilters={getFilters} />
);

DiversityClientSpecificReportsFiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
};

export default DiversityClientSpecificReportsFiltersBar;
