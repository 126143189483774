import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Spinner, Col, Container } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import I18n from "../../utils/i18n";
import { renewExpiredPassword } from "../../api/deviseApi";
import { ShowPassword } from "../../common/components";
import { setAlert } from "../../state/alert/alertActions";
import { setCurrentUser } from "../../state/currentUser/currentUserActions";

const PasswordExpiredPage = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector((store) => store.currentUser.resource);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        renewExpiredPassword(values)
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                dispatch(setCurrentUser({ ...currentUser, need_change_password: false }));
                const { from } = location.state || { from: { pathname: "/" } };
                history.replace(from);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors({ user: error.response.data.errors });
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        user: Yup.object().shape({
            current_password: Yup.string().required(),
            password: Yup.string()
                .min(8)
                .max(128)
                .matches(/.*[0-9]/, I18n.t("errors.messages.password_complexity.digit.one"))
                .matches(/.*[a-z]/, I18n.t("errors.messages.password_complexity.lower.one"))
                .matches(/.*[!@#$%^&*()_+=\-<>,.\\{}[\]]/, I18n.t("errors.messages.password_complexity.symbol.one"))
                .matches(/.*[A-Z]/, I18n.t("errors.messages.password_complexity.upper.one"))
                .required(),
            password_confirmation: Yup.string().oneOf(
                [Yup.ref("password"), null],
                I18n.t("errors.messages.same_as_new_password"),
            ),
        }),
    });

    return (
        <Formik
            initialValues={{ user: { current_password: "", password: "", password_confirmation: "" } }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, handleChange, handleReset, isSubmitting, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="top-bar">
                        <Col>
                            <Container fluid className="py-3" />
                        </Col>
                    </div>
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                        <h2>{I18n.t("forms.password_expired.show.title")}</h2>
                                    </div>
                                    <Form.Group>
                                        <ShowPassword
                                            placeholder={I18n.t("activerecord.attributes.user.current_password")}
                                            name="user[current_password]"
                                            onChange={handleChange}
                                            value={values.user.current_password}
                                            isInvalid={errors.user && errors.user.current_password}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ShowPassword
                                            placeholder={I18n.t("activerecord.attributes.user.password")}
                                            name="user[password]"
                                            onChange={handleChange}
                                            value={values.user.password}
                                            isInvalid={errors.user && errors.user.password}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ShowPassword
                                            placeholder={I18n.t("activerecord.attributes.user.password_confirmation")}
                                            name="user[password_confirmation]"
                                            onChange={handleChange}
                                            value={values.user.password_confirmation}
                                            isInvalid={errors.user && errors.user.password_confirmation}
                                        />
                                    </Form.Group>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                type="button"
                                                className="ml-2"
                                                variant="outline-secondary"
                                                disabled={isSubmitting}
                                                onClick={handleReset}
                                            >
                                                {I18n.t("common.links.cancel")}
                                            </Button>
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PasswordExpiredPage;
