import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTrash, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
import _filter from "lodash/filter";
import ConfirmAction from "./ConfirmAction";
import I18n from "../../utils/i18n";
import Table, { RemoteSelectFilter } from "../../common/components/Table";
import {
    getClientSpecificRequests,
    remindRequest,
    cancelPartnership,
    globalRemindRequest,
} from "../../api/diversityApi";
import ThreeDotsToggle from "../../common/components/Table/ThreeDotsToggle";
import CommentPopover from "./DiversityPage/CommentPopover";

const CreatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.created_at)}</div>
);

const LastSignInAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.manager_current_sign_in_at)}</div>
);

const FirmSubmittedCell = ({ row: { original } }) => (
    <div className="d-flex align-items-start">
        <div
            className={classNames("badge", "p-1", {
                "badge-success": original.firm_submitted,
                "badge-warning": !original.firm_submitted,
            })}
        >
            {I18n.t(`tables.diversity/participation.submitted_statuses.${original.firm_submitted}`)}
        </div>
    </div>
);
const DeadlineCell = ({ row: { original } }) =>
    I18n.l("time.formats.short", original.deadline_at) || I18n.t("common.not_available");

const ClientSpecificSubmittedCell = ({ row: { original } }) => (
    <div className="d-flex align-items-start">
        <div
            className={classNames("badge", "p-1", {
                "badge-success": original.submitted && _isEmpty(original.canceled_at),
                "badge-warning": !original.submitted && _isEmpty(original.canceled_at),
                "badge-secondary": !_isEmpty(original.canceled_at),
            })}
        >
            {_isEmpty(original.canceled_at)
                ? I18n.t(`tables.diversity/participation.submitted_statuses.${original.submitted}`)
                : I18n.t("activerecord.attributes.diversity/participation.statuses.canceled")}
        </div>
        {original.no_work && original.submitted && (
            <OverlayTrigger
                placement="right"
                trigger={["hover", "focus"]}
                overlay={
                    <Tooltip id={`no-work-tooltip-${original.id}`}>
                        {I18n.t("diversity.dashboards.client_specific_requests.no_work_tooltip")}
                    </Tooltip>
                }
            >
                <FontAwesomeIcon className="ml-1" size="lg" icon={faInfoCircle} />
            </OverlayTrigger>
        )}
        {original?.comment?.text && (
            <CommentPopover comment={original.comment.text} requesterName={original.comment.author} />
        )}
    </div>
);

const ActionsCell = ({ row: { original, index }, updateData, globalRemind = false }) => {
    const currentActor = useSelector((state) => state.currentActor.resource);

    const [modalShow, setModalShow] = useState(false);

    const onCancel = () =>
        cancelPartnership(original.id, { quarter_id: original.quarter_id }).then((response) => {
            updateData(index, response.data);
            setModalShow(false);
        });

    const onRemind = () => {
        globalRemind
            ? globalRemindRequest(original.id, { client_id: original.diversity_client_id })
            : remindRequest(original.id);
    };

    return (
        <>
            {currentActor.super_manager && (
                <>
                    <Dropdown>
                        <Dropdown.Toggle as={ThreeDotsToggle} />
                        <Dropdown.Menu size="sm" title="">
                            <Dropdown.Item as={Link} onClick={onRemind} to="#">
                                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                {I18n.t("common.links.remind")}
                            </Dropdown.Item>
                            {_isEmpty(original.canceled_at) && (
                                <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                    {I18n.t("tables.diversity/participation.actions.cancel")}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <ConfirmAction
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onSubmit={onCancel}
                        confirm="common.links.text_ok"
                        title={I18n.t("tables.diversity/participation.titles.cancel")}
                        text={I18n.t("tables.diversity/participation.confirm_text")}
                    />
                </>
            )}
        </>
    );
};

const DiversityClientSpecificRequestsTable = ({ organizationUid = null, pageSize, globalRemind = false }) => {
    const [globalFilters, setGlobalFilters] = useState({});
    const [globalFilterOptions, setGlobalFilterOptions] = useState({});
    const columns = useMemo(() => {
        let deadlineColumnTile = I18n.t("activerecord.attributes.diversity/partnership.deadline");

        let result = [
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.firm_name"),
                accessor: "firm_name",
                Filter: (props) => (
                    <RemoteSelectFilter
                        {...props}
                        setGlobalFilters={setGlobalFilters}
                        globalFilters={globalFilters}
                        globalFilterOptions={globalFilterOptions}
                    />
                ),
                filter: "includes",
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.firm_submitted"),
                accessor: "firm_submitted",
                sortType: "basic",
                Cell: FirmSubmittedCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.quarter_name"),
                accessor: "quarter_name",
                Filter: (props) => (
                    <RemoteSelectFilter
                        {...props}
                        setGlobalFilters={setGlobalFilters}
                        globalFilters={globalFilters}
                        globalFilterOptions={globalFilterOptions}
                    />
                ),
                filter: "includes",
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.submitted"),
                accessor: "submitted",
                sortType: "basic",
                Cell: ClientSpecificSubmittedCell,
            },
            {
                Header: deadlineColumnTile,
                isSorted: false,
                Cell: DeadlineCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.created_at"),
                accessor: "created_at",
                Cell: CreatedAtCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/partnership.manager_current_sign_in_at"),
                id: "manager_current_sign_in_at",
                Cell: LastSignInAtCell,
                isSorted: false,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} updateData={updateData} globalRemind={globalRemind} />,
            },
        ];

        if (_isNull(organizationUid)) {
            result.unshift({
                Header: I18n.t("activerecord.attributes.diversity/partnership.client_name"),
                accessor: "client_name",
                Filter: (props) => (
                    <RemoteSelectFilter
                        {...props}
                        setGlobalFilters={setGlobalFilters}
                        globalFilters={globalFilters}
                        globalFilterOptions={globalFilterOptions}
                    />
                ),
                filter: "includes",
            });

            return result;
        } else {
            return _filter(result, (column) => column.Header !== deadlineColumnTile);
        }
    }, [globalFilters, globalFilterOptions]);
    const [pagesCount, setPagesCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchIdRef = useRef(0);
    const history = useHistory();

    const updateData = (rowIndex, newRow) => {
        setData((oldRows) => oldRows.map((oldRow, index) => (index === rowIndex ? newRow : oldRow)));
    };

    const fetchData = useCallback(({ sortBy, pageIndex, pageSize, globalFilters }) => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
        const sort = sortBy[0] ? sortBy[0] : { column: "created_at", desc: true };
        getClientSpecificRequests(organizationUid, {
            page: pageIndex + 1,
            column: sort.id,
            order: sort.desc ? "desc" : "asc",
            page_size: pageSize,
            filters: globalFilters,
        })
            .then(({ data: { total_pages, records, filter_options } }) => {
                if (fetchId === fetchIdRef.current) {
                    setData(records);
                    setPagesCount(total_pages);
                    setGlobalFilterOptions(filter_options);
                    setLoading(false);
                }
            })
            .catch(() => history.push("/"));
    }, []);

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pagesCount}
            loading={loading}
            initialSortBy={[{ id: "created_at", desc: true }]}
            manualSortBy
            pageSize={pageSize}
            globalFilters={globalFilters}
            globalFilterOptions={globalFilterOptions}
        />
    );
};

CreatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
        }),
    }),
};

LastSignInAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            manager_current_sign_in_at: PropTypes.string,
        }),
    }),
};

FirmSubmittedCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            firm_submitted: PropTypes.bool.isRequired,
        }),
    }),
};

ClientSpecificSubmittedCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            submitted: PropTypes.bool.isRequired,
            canceled_at: PropTypes.string,
            no_work: PropTypes.bool,
            client_name: PropTypes.string.isRequired,
            comment: PropTypes.shape({
                text: PropTypes.string.isRequired,
                author: PropTypes.string,
            }),
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            canceled_at: PropTypes.string,
            quarter_id: PropTypes.number,
            diversity_client_id: PropTypes.number,
        }),
    }),
    updateData: PropTypes.func.isRequired,
    globalRemind: PropTypes.bool,
};

DiversityClientSpecificRequestsTable.propTypes = {
    organizationUid: PropTypes.string,
    pageSize: PropTypes.number,
    globalRemind: PropTypes.bool,
};

DeadlineCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            deadline_at: PropTypes.string,
        }),
    }),
};

export default DiversityClientSpecificRequestsTable;
