import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../../utils/i18n";

const Footer = ({
    currentStep,
    setStep,
    steps,
    onSubmit,
    onClose,
    textSubmit,
    errors,
    requiredValue,
    disabled = false,
}) => (
    <Row className="w-100">
        <Col>
            {I18n.t("diversity.clients.steps.navigation", {
                current_step: currentStep + 1,
                total_steps: steps.length,
            })}
        </Col>
        <Col className="d-flex justify-content-end">
            {currentStep !== 0 && steps[currentStep].key !== "result" && (
                <Button variant="light" className="mr-2" onClick={() => setStep((prev) => --prev)} disabled={disabled}>
                    {I18n.t("common.links.previous")}
                </Button>
            )}
            {currentStep !== steps.length - 2 && steps[currentStep].key !== "result" && (
                <Button
                    variant="primary"
                    className="text-white"
                    onClick={() => setStep((prev) => ++prev)}
                    disabled={disabled || !_isEmpty(errors)}
                >
                    {I18n.t("common.links.next")}
                </Button>
            )}
            {currentStep === steps.length - 2 && steps[currentStep].key !== "result" && (
                <Button
                    variant="primary"
                    className="text-white"
                    onClick={() => {
                        onSubmit();
                        setStep(steps.length - 1);
                    }}
                    disabled={disabled || _isEmpty(requiredValue)}
                >
                    {textSubmit}
                </Button>
            )}
            {steps[currentStep].key === "result" && (
                <Button variant="primary" className="text-white" onClick={onClose}>
                    {I18n.t("common.links.text_ok")}
                </Button>
            )}
        </Col>
    </Row>
);

Footer.propTypes = {
    steps: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
    textSubmit: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    errors: PropTypes.object,
    requiredValue: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Footer;
