import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import _map from "lodash/map";
import I18n from "../../../../utils/i18n";

const MainRole = ({ role }) => (
    <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`role-${role}`}>{I18n.t(role, { scope: "roles.descriptions" })}</Tooltip>}
    >
        <span>{I18n.t(`activerecord.attributes.actor.${role}`)}</span>
    </OverlayTrigger>
);

const RoleName = ({ row: { original } }) => {
    let mainRole = original.roles[0] || "user";

    return (
        <div className="d-flex flex-column text-left">
            {original.roles.length < 2 ? (
                <MainRole role={mainRole} />
            ) : (
                <>
                    <MainRole role={mainRole} />
                    {_map(original.roles.slice(-(original.roles.length - 1)), (role, index) => (
                        <OverlayTrigger
                            key={index}
                            placement="right"
                            overlay={
                                <Tooltip id={`role-${role}`}>{I18n.t(role, { scope: "roles.descriptions" })}</Tooltip>
                            }
                        >
                            <small key={`role-key-${role}`} className="text-muted">
                                {I18n.t(`activerecord.attributes.actor.${role}`)}
                            </small>
                        </OverlayTrigger>
                    ))}
                </>
            )}
        </div>
    );
};

MainRole.propTypes = { role: PropTypes.string.isRequired };

RoleName.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            roles: PropTypes.array,
        }),
    }),
};

export default RoleName;
