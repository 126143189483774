import React from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import I18n from "../../utils/i18n";
import FormDiversityCertificateType from "../../common/components/FormDiversityCertificateType";
import { createCertificateType } from "../../api/diversityApi";
import { setAlert } from "../../state/alert/alertActions";
import validationSchema from "../../common/components/DiversityPage/CertificateTypeSchema";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import NewPageTitle from "../../common/components/NewPageTitle";

const NewDiversityCertificateTypePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const backPath = "/diversity/certificate_types";

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createCertificateType(values)
            .then(() => {
                resetForm(values);
                history.push(backPath);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
            })
            .catch((error) => {
                setSubmitting(false);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setErrors(error.response.data.errors);
            });
    };

    return (
        <Formik
            initialValues={{
                diversity_certificate_type: {
                    ownership_attributes: {
                        owner_type: "Diversity::CertificateType",
                        women_owned: false,
                        minority_owned: false,
                        lgbtq_owned: false,
                        veteran_owned: false,
                        differently_abled_owned: false,
                        disadvantaged_business: false,
                    },
                },
            }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {(form) => (
                <>
                    <TopBarWithBackLink
                        backPath={backPath}
                        linkName={I18n.t("forms.organization.edit.tabs.diversity_certificates")}
                    />
                    <NavigationPrompt when={form.dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <NewPageTitle resource={I18n.t("activerecord.models.diversity/certificate_type")} />

                                    <FormikForm as={Form}>
                                        <FormDiversityCertificateType />
                                    </FormikForm>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleSubmit}
                                            >
                                                {form.isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default NewDiversityCertificateTypePage;
