import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _join from "lodash/join";
import _map from "lodash/map";
import _compact from "lodash/compact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import _includes from "lodash/includes";
import _difference from "lodash/difference";
import _toString from "lodash/toString";
import I18n from "../../../../utils/i18n";
import Table from "../../../../common/components/Table";
import Avatar from "../../../../common/components/Avatar";
import ThreeDotsToggle from "../../../../common/components/Table/ThreeDotsToggle";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";
import ConfirmAction from "../../../../common/components/ConfirmAction";
import FormattedScore from "../../../../common/components/FormattedScore";

const CompaniesTable = ({
    data,
    isLoading,
    companyIds,
    setCompanyIds,
    fetchData,
    handleCheckboxChange,
    handleRemoveFromList,
    hideActions = false,
    allCompaniesIds,
}) => {
    const currentListIds = _map(data, "id");

    const LocationCell = ({ row: { original } }) => {
        const { locations } = original;
        const { state, city, country } = _find(locations, (location) => location.location_type_required) || {};
        const locationText = _join(_compact([city, state?.name, country?.name]), ", ");

        return <span>{locationText}</span>;
    };

    const IndustryCell = ({ row: { original } }) => {
        const { industries } = original;

        const industryText = industries[0]?.name;
        return <span>{industryText}</span>;
    };

    const CheckboxCell = ({ row: { original } }) => (
        <Form.Check
            custom
            type="checkbox"
            id={`list-${original.id}`}
            checked={_includes(companyIds, original.id)}
            onChange={(event) => handleCheckboxChange(event, original.id)}
        />
    );

    const ScoreCell = ({ row: { original } }) => {
        const { jb_score, total_score } = original;

        return (
            (jb_score || total_score) && (
                <FormattedScore jbScore={_toString(jb_score)} totalScore={_toString(total_score)} />
            )
        );
    };

    const NameCell = ({ row: { original } }) => (
        <>
            <Avatar preview={original.logo} size="sm" name={original.name} />
            <div className="d-flex flex-column ml-3">
                {original.name}
                <small className="text-muted">{original.id}</small>
            </div>
        </>
    );

    const ActionsCell = ({ row: { original } }) => {
        const [removeModalShow, setRemoveModalShow] = useState(false);

        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={ThreeDotsToggle} />
                    <Dropdown.Menu size="sm">
                        <Dropdown.Item onClick={() => setRemoveModalShow(true)}>
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            {I18n.t("companies.lists.actions.remove_from_list")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <ConfirmAction
                    show={removeModalShow}
                    onHide={() => setRemoveModalShow(false)}
                    onSubmit={() =>
                        handleRemoveFromList({
                            uid: original.uid,
                            id: original.id,
                            setModalShow: setRemoveModalShow,
                        })
                    }
                    confirm="common.links.text_ok"
                    title={I18n.t("tables.lists.remove_company_from_list.title")}
                    text={I18n.t("tables.lists.remove_company_from_list.text")}
                />
            </>
        );
    };

    LocationCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                locations: PropTypes.arrayOf(
                    PropTypes.shape({
                        city: PropTypes.string,
                        state: PropTypes.object,
                        country: PropTypes.object,
                    }),
                ),
            }),
        }),
    };

    IndustryCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                industries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                    }),
                ),
            }),
        }),
    };

    CheckboxCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.number,
            }),
        }),
    };

    ActionsCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                uid: PropTypes.string,
                id: PropTypes.number,
            }),
        }),
    };

    ScoreCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                jb_score: PropTypes.number,
                total_score: PropTypes.string,
            }),
        }),
    };

    NameCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                logo: PropTypes.string,
            }),
        }),
    };

    const handleSelectAll = ({ target: { checked } }) => {
        setCompanyIds((prevValue) => {
            const filteredIds = _difference(prevValue, currentListIds);

            return checked && filteredIds.length === prevValue.length ? [...prevValue, ...currentListIds] : filteredIds;
        });
    };

    const columns = useMemo(() => {
        let columns = [
            {
                Header: () => (
                    <IndeterminateCheck
                        id="lists-all"
                        type="checkbox"
                        checked={companyIds.length === allCompaniesIds.length && companyIds.length > 0}
                        indeterminate={companyIds.length > 0 && companyIds.length < allCompaniesIds.length}
                        onChange={handleSelectAll}
                    />
                ),
                id: "checkboxes",
                Cell: CheckboxCell,
                isSorted: false,
            },
            {
                Header: I18n.t("companies.lists.sections.companies.columns.name"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("companies.lists.sections.companies.columns.location"),
                accessor: "locations",
                Cell: LocationCell,
            },
            {
                Header: I18n.t("companies.lists.sections.companies.columns.industry"),
                accessor: "industries",
                Cell: IndustryCell,
            },
            {
                Header: I18n.t("companies.lists.sections.companies.columns.score"),
                isSorted: false,
                id: "score",
                Cell: ScoreCell,
            },
        ];

        if (!hideActions) {
            columns.push({
                Header: () => null,
                isSorted: false,
                id: "actions",
                Cell: (props) => <ActionsCell {...props} />,
            });
        }

        return columns;
    }, [currentListIds, companyIds.length, hideActions]);

    return (
        <>
            <div className="pt-4 px-4 pb-4">
                {_isEmpty(data) && I18n.t("companies.lists.sections.companies.no_companies")}
            </div>
            <Table
                loading={isLoading}
                data={data}
                fetchData={fetchData}
                columns={columns}
                pageCount={-1}
                hideTable={_isEmpty(data)}
                initialSortBy={[{ id: "name", desc: false }]}
                manualSortBy
            />
        </>
    );
};

CompaniesTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fetchData: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleRemoveFromList: PropTypes.func.isRequired,
    setCompanyIds: PropTypes.func.isRequired,
    hideActions: PropTypes.bool,
    allCompaniesIds: PropTypes.arrayOf(PropTypes.number),
};

export default CompaniesTable;
