import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Spinner, Form, Button, FormControl } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import I18n from "../../utils/i18n";
import { sendInvitation } from "../../api/deviseApi";
import { clearAlert, setAlert } from "../../state/alert/alertActions";

const NewInvitationPage = ({ setAlert, clearAlert }) => {
    const history = useHistory();

    const onSubmit = (values) => {
        clearAlert();

        sendInvitation(values).finally(() => {
            setAlert({
                text: I18n.t("devise.invitations.send_instructions", { email: values.user.email }),
                variant: "info",
            });
            history.push("/");
        });
    };

    const validationSchema = Yup.object().shape({
        user: Yup.object().shape({
            email: Yup.string().email().max(255).required(),
        }),
    });

    return (
        <div className="form-wrapper bg-white p-4 rounded">
            <Formik initialValues={{ user: { email: "" } }} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, handleSubmit, handleChange, isSubmitting, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group>
                            <h2>{I18n.t("devise.invitations.new.header")}</h2>
                        </Form.Group>
                        <Form.Group>
                            <FormControl
                                type="email"
                                name="user[email]"
                                placeholder={I18n.t("activerecord.attributes.user.email")}
                                onChange={handleChange}
                                value={values.user.email}
                                isInvalid={errors.user && errors.user.email}
                            />
                            <FormControl.Feedback type="invalid">
                                {errors.user && errors.user.email}
                            </FormControl.Feedback>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" className="text-white" disabled={isSubmitting}>
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2"
                                    />
                                )}
                                {I18n.t("devise.invitations.new.submit_button")}
                            </Button>
                        </Form.Group>
                        <div className="text-right">
                            <Link to="/">{I18n.t("forms.sign_in.title")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

NewInvitationPage.propTypes = {
    setAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setAlert: (values) => dispatch(setAlert(values)),
    clearAlert: () => dispatch(clearAlert()),
});

export default connect(null, mapDispatchToProps)(NewInvitationPage);
