import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Form } from "react-bootstrap";
import _find from "lodash/find";
import _map from "lodash/map";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _isUndefined from "lodash/isUndefined";
import _size from "lodash/size";
import I18n from "../../../../utils/i18n";
import { getPermissions } from "../../../../api/organizationApi";

const disabledCheck = (permissionName, values) => {
    const existPermission = (name) => {
        let organizationPermission = _find(values, { name: name });
        return !_isUndefined(organizationPermission) && !organizationPermission._destroy;
    };

    switch (permissionName) {
        case "customer_permission":
            return !existPermission("supplier_permission");
        case "diversity_provider_permission":
            return (
                existPermission("diversity_client_viewer_permission") ||
                existPermission("diversity_firmwide_viewer_permission") ||
                existPermission("diversity_firmwide_admin_permission")
            );
        case "diversity_firmwide_viewer_permission":
            return (
                existPermission("diversity_firmwide_admin_permission") ||
                existPermission("diversity_provider_permission")
            );

        case "diversity_firmwide_viewer_plus_permission":
            return !existPermission("diversity_firmwide_viewer_permission");
        case "diversity_mirror_permission":
            return !existPermission("diversity_provider_permission");
        case "diversity_firmwide_admin_permission":
            return (
                existPermission("diversity_firmwide_viewer_permission") ||
                existPermission("diversity_provider_permission")
            );
        case "diversity_client_viewer_permission":
            return existPermission("diversity_provider_permission");
        default:
            return false;
    }
};

const dependedPermissions = (targetPermissionName) => {
    switch (targetPermissionName) {
        case "supplier_permission":
            return ["customer_permission"];
        case "diversity_provider_permission":
            return [
                "diversity_client_viewer_permission",
                "diversity_firmwide_viewer_permission",
                "diversity_mirror_permission",
            ];
        case "diversity_firmwide_viewer_permission":
            return [
                "diversity_provider_permission",
                "diversity_firmwide_admin_permission",
                "diversity_firmwide_viewer_plus_permission",
            ];

        case "diversity_firmwide_admin_permission":
            return [
                "diversity_provider_permission",
                "diversity_firmwide_viewer_permission",
                "diversity_firmwide_viewer_plus_permission",
            ];
        case "diversity_client_viewer_permission":
            return ["diversity_provider_permission"];
        default:
            return [];
    }
};

const PermissionsTab = ({ values, setFieldValue }) => {
    const [permissions, setPermissions] = useState([]);
    const inputName = `organization[organization_permissions_attributes]`;

    useEffect(() => {
        getPermissions().then((response) => setPermissions(response.data));
    }, []);

    const handleChange = (targetNamePermission, checked, permissionNames) => {
        let selectedPermission = _find(permissions, ["name", targetNamePermission]);
        let organizationPermission = _find(values, ["name", targetNamePermission]);

        if (!_isUndefined(organizationPermission)) {
            setFieldValue(`${inputName}[${_findIndex(values, ["name", targetNamePermission])}][_destroy]`, !checked);
            _map(permissionNames, (permission) => {
                !_isUndefined(_find(values, ["name", permission])) &&
                    setFieldValue(`${inputName}[${_findIndex(values, ["name", permission])}][_destroy]`, true);
            });
        } else {
            if (checked) {
                setFieldValue(inputName, [
                    ...values,
                    { name: selectedPermission.name, permission_id: selectedPermission.id, _destroy: !checked },
                ]);
            } else {
                let newValues = _remove(values, (element) => selectedPermission.id !== element.permission_id);
                _size(newValues) === _size(values) - 1 &&
                    setFieldValue(
                        inputName,
                        _remove(values, (element) => selectedPermission.id !== element.permission_id)
                    );
            }
        }
    };

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            {_map(permissions, (permission) => (
                <Form.Group controlId={permission.name} key={permission.id}>
                    <Form.Check
                        type="switch"
                        label={I18n.t(`activerecord.attributes.organization.${permission.name}`)}
                        value={permission.id}
                        name={permission.name}
                        onChange={(event) => {
                            handleChange(permission.name, event.target.checked, dependedPermissions(permission.name));
                        }}
                        checked={
                            !!_find(values, {
                                permission_id: permission.id,
                                _destroy: false,
                            })
                        }
                        disabled={disabledCheck(permission.name, values)}
                    />
                </Form.Group>
            ))}
        </Container>
    );
};

PermissionsTab.propTypes = {
    values: PropTypes.array.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default PermissionsTab;
