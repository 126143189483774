import React, { useState } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";

const ShowPassword = (inputProps) => {
    const [show, setVisibility] = useState(false);

    return (
        <InputGroup>
            <FormControl {...inputProps} type={show ? "input" : "password"} />
            <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => setVisibility(!show)}>
                    <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />
                </Button>
            </InputGroup.Append>
            {inputProps.isInvalid && <FormControl.Feedback type="invalid">{inputProps.isInvalid}</FormControl.Feedback>}
        </InputGroup>
    );
};

export default ShowPassword;
