import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { getIn, useFormikContext } from "formik";
import _keys from "lodash/keys";
import _forEach from "lodash/forEach";
import _omit from "lodash/omit";
import _get from "lodash/get";
import PropTypes from "prop-types";
import _toString from "lodash/toString";
import _isNumber from "lodash/isNumber";
import BodyStep from "../common/BodyStep";
import OwnershipInputs from "../../../common/components/OrganizationForm/inputs/OwnershipInputs";
import FormDiversityCertificate from "../../../common/components/FormDiversityCertificate";
import I18n from "../../../utils/i18n";

const CompanyStep = ({ certificateId, uid }) => {
    const { validateForm, errors, setErrors, values, setFieldTouched, handleChange, setFieldValue } =
        useFormikContext();
    const certificateExist = _isNumber(certificateId);
    useEffect(() => validateForm(), []);

    useEffect(
        () =>
            !values.organization.showOwnership &&
            setErrors({
                ...errors,
                diversity_certificate: undefined,
                organization: undefined,
                showCertificate: undefined,
            }),
        [errors]
    );

    return (
        <BodyStep
            text={I18n.t("wizard.steps.company.text")}
            title={I18n.t("wizard.steps.company.title")}
            pro_tip={I18n.t("wizard.steps.company.pro_tip")}
        >
            <Form.Group controlId="show_ownership">
                <Form.Check
                    type="switch"
                    name="show_ownership"
                    onChange={(event) => {
                        if (!event.target.checked) {
                            _forEach(_keys(_omit(values.organization.ownership_attributes, "ethnicity")), (field) =>
                                setFieldValue(`organization.ownership_attributes.[${field}]`, false)
                            );
                            setFieldValue("organization.ownership_attributes.ethnicity", null);
                            setFieldValue("diversity_certificate", {});
                            setFieldValue("showCertificate", false);
                        }
                        setFieldValue("organization.showOwnership", event.target.checked);
                    }}
                    checked={values.organization.showOwnership}
                    disabled={certificateExist}
                />
                <Form.Control.Feedback type="invalid">
                    {getIn(errors, "organization.showOwnership")}
                </Form.Control.Feedback>
            </Form.Group>
            {values.organization.showOwnership && (
                <>
                    <OwnershipInputs
                        errors={_get(errors, "organization.ownership_attributes") || {}}
                        values={values.organization.ownership_attributes}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        handleChange={handleChange}
                        ownerType="organization"
                    />
                    <Form.Group controlId="show_certificate" className="mt-5">
                        <Form.Label>{I18n.t("wizard.steps.company.create_certificate")}</Form.Label>
                        <Form.Check
                            type="switch"
                            name="show_certificate"
                            onChange={(event) => setFieldValue("showCertificate", event.target.checked)}
                            checked={values.showCertificate}
                            disabled={certificateExist}
                        />
                        <Form.Control.Feedback type="invalid">{getIn(errors, "showCertificate")}</Form.Control.Feedback>
                    </Form.Group>

                    {values.showCertificate && (
                        <FormDiversityCertificate
                            form={{
                                errors: errors,
                                values: values,
                                setFieldValue: setFieldValue,
                            }}
                            certificateId={certificateId && _toString(certificateId)}
                            organizationUid={uid}
                        />
                    )}
                </>
            )}
        </BodyStep>
    );
};

CompanyStep.propTypes = {
    certificateId: PropTypes.number,
    uid: PropTypes.string.isRequired,
};

export default CompanyStep;
