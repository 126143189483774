import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import _isUndefined from "lodash/isUndefined";
import { Col, Container, Form, Tabs, Tab, Button, Spinner } from "react-bootstrap";
import AccountSettings from "./components/tabs/AccountSettings";
import { approveActor, getActor, updateActor } from "../../api/actorApi";
import I18n, { updateLocale } from "../../utils/i18n";
import { setAlert } from "../../state/alert/alertActions";
import RolesTab from "./components/tabs/RolesTab";
import FormUserPersonalDetails from "../../common/components/FormUserPersonalDetails";
import ConfirmAction from "../../common/components/ConfirmAction";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";

const validationSchema = Yup.object().shape({
    actor: Yup.object().shape({
        user_attributes: Yup.object().shape({
            email: Yup.string().email().max(255).required(),
            first_name: Yup.string().max(255).required(),
            last_name: Yup.string().max(255).required(),
            second_email: Yup.string().email().max(255).nullable(),
            phone: Yup.string().max(255).nullable(),
            job_title: Yup.string().max(255).nullable(),
            password: Yup.string()
                .min(8)
                .max(128)
                .matches(/.*[0-9]/, I18n.t("errors.messages.password_complexity.digit.one"))
                .matches(/.*[a-z]/, I18n.t("errors.messages.password_complexity.lower.one"))
                .matches(/.*[!@#$%^&*()_+=\-<>,.\\{}[\]]/, I18n.t("errors.messages.password_complexity.symbol.one"))
                .matches(/.*[A-Z]/, I18n.t("errors.messages.password_complexity.upper.one"))
                .nullable(),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], I18n.t("errors.messages.same_as_new_password"))
                .nullable(),
        }),
    }),
});

const EditUserProfilePage = () => {
    const { actorUid } = useParams();
    const [actor, setActor] = useState(null);
    const dispatch = useDispatch();
    const currentActor = useSelector((store) => store.currentActor.resource);
    const currentUser = useSelector((store) => store.currentUser.resource);
    const [approveModalShow, setApproveModalShow] = useState(false);
    const history = useHistory();

    const backPath = history.location.state?.prevPath;

    const fetchActor = () => {
        getActor(actorUid).then((response) => setActor(response.data));
    };

    useEffect(fetchActor, []);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        updateActor(actorUid, values)
            .then((response) => {
                setActor(response.data);
                currentActor.uid === actorUid && updateLocale(values.actor.user_attributes.locale);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                !_isUndefined(backPath) && history.push(`/organizations/${values.actor.organization.uid}/users`);
            })
            .catch((error) => {
                setErrors(error.response.data.errors);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    const onApprove = () => {
        approveActor(actorUid).then(() => {
            fetchActor();
            setApproveModalShow(false);
        });
    };

    if (!actor) {
        return null;
    }

    const initialValues = () => {
        const values = { ...actor };
        values.actor.user_attributes.current_password = null;
        values.actor.user_attributes.password = null;
        values.actor.user_attributes.password_confirmation = null;

        return values;
    };

    return (
        <Formik
            initialValues={initialValues()}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {(form) => (
                <>
                    <TopBarWithBackLink backPath={backPath} linkName={I18n.t("forms.organization.edit.tabs.users")}>
                        <Col className="text-right">
                            {form.values.actor.approve_requested_at && (
                                <>
                                    <Button className="text-light" onClick={() => setApproveModalShow(true)}>
                                        {I18n.t("forms.edit_profile.approve.confirm")}
                                    </Button>
                                    <ConfirmAction
                                        show={approveModalShow}
                                        onHide={() => setApproveModalShow(false)}
                                        onSubmit={onApprove}
                                        confirm="forms.edit_profile.approve.confirm"
                                        title={I18n.t("forms.edit_profile.approve.title")}
                                        text={I18n.t("forms.edit_profile.approve.body")}
                                    />
                                </>
                            )}
                        </Col>
                    </TopBarWithBackLink>
                    <NavigationPrompt when={form.dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                        <h2>
                                            {currentActor.uid === actorUid
                                                ? I18n.t("forms.edit_profile.your_profile")
                                                : I18n.t("forms.edit_profile.profile", {
                                                      name: `${form.values.actor.user_attributes.first_name} ${form.values.actor.user_attributes.last_name}`,
                                                  })}
                                        </h2>
                                    </div>
                                    <FormikForm as={Form}>
                                        <Tabs justify defaultActiveKey="personalDetails" id="uncontrolled-tab-example">
                                            <Tab
                                                eventKey="personalDetails"
                                                title={I18n.t("forms.edit_profile.personal_details")}
                                            >
                                                <FormUserPersonalDetails form={form} />
                                            </Tab>
                                            <Tab
                                                eventKey="accountSettings"
                                                title={I18n.t("forms.edit_profile.account_settings.title")}
                                            >
                                                <AccountSettings
                                                    form={form}
                                                    enablePasswordEditing={
                                                        currentActor.uid === actorUid &&
                                                        !currentUser.supporter &&
                                                        !form.values.actor.user_attributes.sso_enabled
                                                    }
                                                    ssoEnabled={form.values.actor.user_attributes.sso_enabled}
                                                />
                                            </Tab>
                                            {(currentActor.super_manager ||
                                                currentUser.admin ||
                                                currentUser.superadmin) && (
                                                <Tab eventKey="roles" title={I18n.t("forms.edit_profile.permissions")}>
                                                    <RolesTab form={form} />
                                                </Tab>
                                            )}
                                        </Tabs>
                                    </FormikForm>
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={form.isSubmitting}
                                                onClick={form.handleSubmit}
                                            >
                                                {form.isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default EditUserProfilePage;
