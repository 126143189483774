import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import DiversityRequests from "./DiversityRequests";
import DiversityCharts from "./DiversityCharts";
import DiversityGlobalAnalytics from "./DiversityGlobalAnalytics";
import I18n from "../../../utils/i18n";

const DiversityAnalytics = ({ currentUser, currentActor }) => {
    const currency = useSelector((state) => state.currentWhitelabel.resource.currency);
    const showStatistic =
        currentActor.organization.permissions.diversity_firmwide_viewer_permission ||
        currentActor.organization.permissions.diversity_client_viewer_permission;

    return (
        <>
            <h3>{I18n.t("home.diversity_analytics.header")}</h3>
            <Card className="mb-3">
                <Card.Body>
                    {(currentUser.superadmin || currentUser.admin) && currency && (
                        <DiversityGlobalAnalytics currency={currency} />
                    )}
                    {currentActor.organization.permissions.diversity_provider_permission &&
                        (currentActor.super_manager || currentActor.diversity_manager) && <DiversityRequests />}
                    {showStatistic && currency && (
                        <DiversityCharts
                            currency={currency}
                            showFirmwideStat={
                                currentActor.organization.permissions.diversity_firmwide_viewer_permission
                            }
                            showClientSpecificStat={
                                currentActor.organization.permissions.diversity_client_viewer_permission
                            }
                        />
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

DiversityAnalytics.propTypes = {
    currentUser: PropTypes.shape({
        superadmin: PropTypes.bool.isRequired,
        admin: PropTypes.bool.isRequired,
    }).isRequired,
    currentActor: PropTypes.shape({
        organization: PropTypes.shape({
            permissions: PropTypes.shape({
                diversity_provider_permission: PropTypes.bool.isRequired,
                diversity_firmwide_viewer_permission: PropTypes.bool.isRequired,
                diversity_client_viewer_permission: PropTypes.bool.isRequired,
            }),
        }).isRequired,
        super_manager: PropTypes.bool.isRequired,
        diversity_manager: PropTypes.bool.isRequired,
    }).isRequired,
};

export default DiversityAnalytics;
