import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import { Field, getIn, useFormikContext } from "formik";
import classNames from "classnames";
import Select from "react-select";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _each from "lodash/each";
import _includes from "lodash/includes";
import { getActorForOrganization } from "../../../api/actorApi";
import I18n from "../../../utils/i18n";

const ActorFields = ({ index, actor, remove, totalActors, organizationUid, actorRoles }) => {
    const [roleErrorMessage, setRoleErrorMessage] = useState(undefined);
    const { errors, setFieldValue, handleBlur, status, setStatus } = useFormikContext();
    const [fetchedActor, setFetchedActor] = useState(actor);
    const emailInput = `actors[${index}].user_attributes.email`;
    const firstNameInput = `actors[${index}].user_attributes.first_name`;
    const lastNameInput = `actors[${index}].user_attributes.last_name`;
    const roleInput = `actors[${index}][role_ids]`;
    const uidField = `actors[${index}].uid`;
    const [options, setOptions] = useState([]);
    const [allowedRoles, setAllowedRoles] = useState(actorRoles);
    const currentRole = (currentRoleId) => _find(actorRoles, (role) => role.id === currentRoleId);

    const buildOptions = () =>
        _map(allowedRoles, (role) => ({
            label: I18n.t(`activerecord.attributes.actor.${role.name}`),
            value: role.id,
        }));

    const restrictedRoles = (actor) => {
        if (_isEmpty(actor) || actor.uid === "" || actor.no_role) return [];
        if (actor.super_manager) return actorRoles;

        return _filter(actorRoles, (role) => _find(actor.roles, (actorRole) => actorRole.id === role.id));
    };

    const fetchAutocompleteActor = (email) => {
        getActorForOrganization(organizationUid, { email: email }).then((response) => {
            let restricted = restrictedRoles(response.data);
            let currentRoleId = !_isEmpty(response.data.roles) && response.data.roles[0].id;

            if (_isEmpty(restricted)) {
                setAllowedRoles(actorRoles);
            } else {
                setAllowedRoles([..._filter(actorRoles, (role) => restricted.indexOf(role) < 0)]);
            }

            if (response.data?.uid) {
                setFieldValue(uidField, response.data.uid);
                setFieldValue(lastNameInput, response.data.user_attributes.last_name);
                setFieldValue(firstNameInput, response.data.user_attributes.first_name);
                setFieldValue(roleInput, currentRoleId ? [currentRoleId] : []);
                setFetchedActor(response.data);
            } else {
                setFieldValue(roleInput, []);
                setFieldValue(lastNameInput, "");
                setFieldValue(firstNameInput, "");
                setFieldValue(uidField, "");
            }
            validateRole(response.data, currentRoleId);
        });
    };

    const resetRoleMessages = () => {
        let newArray = status.invalidActors.slice();
        let actorIndex = newArray.indexOf(index);

        setRoleErrorMessage(undefined);
        if (actorIndex > -1) {
            newArray.splice(actorIndex, 1);
            setStatus({ invalidActors: newArray });
        }
    };

    const validateRole = (actor, currentActorRoleId) => {
        resetRoleMessages();
        if (!_isEmpty(actor) && !_isEmpty(actor.uid)) {
            if (actor.super_manager) {
                setRoleErrorMessage(I18n.t("activerecord.errors.models.user.attributes.email.taken"));
                setStatus({ invalidActors: [...status.invalidActors, index] });
            } else {
                if (restrictedRoles(actor).indexOf(currentRole(currentActorRoleId)) >= 0) {
                    setRoleErrorMessage(I18n.t("diversity.clients.steps.warnings.users_step.upgrading_role"));
                    setStatus({ invalidActors: [...status.invalidActors, index] });
                }
            }
        }
    };

    useEffect(() => setOptions(buildOptions(allowedRoles)), [allowedRoles]);

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Field
                                    as={FormControl}
                                    name={emailInput}
                                    value={actor.user_attributes.email}
                                    type="email"
                                    placeholder={I18n.t("diversity.clients.steps.firm_email")}
                                    className="mb-2"
                                    isInvalid={getIn(errors, emailInput)}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        _isEmpty(getIn(errors, emailInput)) &&
                                            !_isEmpty(event.target.value) &&
                                            fetchAutocompleteActor(event.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, emailInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Select
                                    isMulti
                                    classNamePrefix="react-select"
                                    options={options}
                                    onChange={(option) => {
                                        setFieldValue(roleInput, _map(option, "value"));

                                        _each(option, (role) => validateRole(fetchedActor, role.value));
                                    }}
                                    value={_filter(options, (option) => _includes(actor.role_ids, option.value))}
                                    placeholder={I18n.t("common.placeholders.select_role")}
                                    className={classNames("react-select", {
                                        "is-invalid": getIn(status, roleInput) || getIn(errors, roleInput),
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {roleErrorMessage || getIn(errors, roleInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field
                                as={FormControl}
                                name={firstNameInput}
                                value={actor.user_attributes.first_name}
                                type="text"
                                placeholder={I18n.t("activerecord.attributes.user.first_name")}
                                isInvalid={getIn(errors, firstNameInput)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, firstNameInput)}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Field
                                as={FormControl}
                                name={lastNameInput}
                                value={actor.user_attributes.last_name}
                                type="text"
                                placeholder={I18n.t("activerecord.attributes.user.last_name")}
                                isInvalid={getIn(errors, lastNameInput)}
                            />
                            <Form.Control.Feedback type="invalid">{getIn(errors, lastNameInput)}</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Col>
                <Form.Group className="col-auto">
                    <Button variant="outline-secondary" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Form.Group>
            </Row>
            {totalActors - 1 !== index && <hr />}
        </>
    );
};

ActorFields.propTypes = {
    index: PropTypes.number.isRequired,
    actor: PropTypes.shape({
        role_ids: PropTypes.arrayOf(PropTypes.number),
        user_attributes: PropTypes.shape({
            email: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
        }),
        uid: PropTypes.string,
        super_manager: PropTypes.bool,
        diversity_manager: PropTypes.bool,
        profile_manager: PropTypes.bool,
        sd_manager: PropTypes.bool,
        no_role: PropTypes.bool,
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        ),
    }).isRequired,
    remove: PropTypes.func.isRequired,
    totalActors: PropTypes.number.isRequired,
    organizationUid: PropTypes.string.isRequired,
    actorRoles: PropTypes.array.isRequired,
};

export default ActorFields;
