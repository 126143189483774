import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _range from "lodash/range";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ProfileCard from "./ProfileCard";
import ProfileCardLoader from "../../../CompanyProfilesPage/components/ProfilesSection/ProfileCardLoader";

const ProfilesSection = ({
    profilesFetching,
    profiles,
    page,
    pageCount,
    goToPage,
    companyIds,
    handleCheckboxChange,
    handleRemoveFromList,
    hideActions = false,
}) => (
    <Card className="border-0">
        <Card.Body>
            <Card.Body className="p-0">
                {profilesFetching
                    ? _map(_range(1), (item) => <ProfileCardLoader key={`loader-${item}`} />)
                    : _map(profiles, (profile) => (
                          <ProfileCard
                              key={`profile-${profile.uid}`}
                              {...profile}
                              companyIds={companyIds}
                              handleCheckboxChange={handleCheckboxChange}
                              handleRemoveFromList={handleRemoveFromList}
                              hideActions={hideActions}
                          />
                      ))}
                {pageCount >= 0 && (
                    <div className="pagination justify-content-center mb-0">
                        <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={goToPage}
                            containerClassName="pagination pagination-sm mb-0"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            nextClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            forcePage={page - 1}
                        />
                    </div>
                )}
            </Card.Body>
        </Card.Body>
    </Card>
);

ProfilesSection.propTypes = {
    profilesFetching: PropTypes.bool,
    profiles: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleRemoveFromList: PropTypes.func.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    hideActions: PropTypes.bool,
};

export default ProfilesSection;
