import React from "react";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";
import FirmwideMirrorChart from "./FirmwideMirrorChart";

const CountByPracticeAreaChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="count_by_practice_area"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.count_by_practice_area.y_axis"),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) =>
                        tooltipItem.value === "NaN"
                            ? ""
                            : `${data.datasets[tooltipItem.datasetIndex].label}: ${parseInt(
                                  tooltipItem.value
                              ).toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

CountByPracticeAreaChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default CountByPracticeAreaChart;
