import React from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik, getIn } from "formik";
import _isNull from "lodash/isNull";
import { useDispatch } from "react-redux";
import I18n from "../../../../utils/i18n";
import { createNewList } from "../../../../api/listsApi";
import { setAlert } from "../../../../state/alert/alertActions";

const AddListModal = ({ show, onClose, parentId = null, setReloadLists }) => {
    const dispatch = useDispatch();
    const initialValues = {
        companies_list: { name: "", parent_id: parentId },
    };
    const validationSchema = Yup.object().shape({
        companies_list: Yup.object().shape({
            name: Yup.string().max(255).min(3).required(),
            parent_id: Yup.number().nullable(),
        }),
    });

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        createNewList(values)
            .then(() => {
                dispatch(setAlert({ variant: "info", translationKey: "common.alerts.successful_save" }));
                setReloadLists((prevState) => !prevState);
            })
            .catch(() => dispatch(setAlert({ variant: "info", translationKey: "common.alerts.something_went_wrong" })))
            .finally(() => {
                setSubmitting(false);
                resetForm();
                onClose();
            });
    };

    const handleClose = (handleReset) => {
        handleReset();
        onClose();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, handleSubmit, handleReset, handleChange, isSubmitting }) => (
                <Modal show={show} onHide={() => handleClose(handleReset)}>
                    <Modal.Header closeButton>
                        {_isNull(parentId)
                            ? I18n.t("companies.lists.modals.create.title")
                            : I18n.t("companies.lists.modals.create_sublist.title")}
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control
                                as="input"
                                name="companies_list.name"
                                placeholder={I18n.t("companies.lists.placeholders.name")}
                                onChange={handleChange}
                                isInvalid={getIn(errors, "companies_list.name")}
                                value={values.companies_list.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, "companies_list.name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(handleReset)}>
                            {I18n.t("common.links.cancel")}
                        </Button>

                        <Button disabled={isSubmitting} variant="primary" className="text-white" onClick={handleSubmit}>
                            <Spinner hidden={!isSubmitting} as="span" animation="border" size="sm" className="mr-2" />
                            {I18n.t("common.links.create")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

AddListModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setReloadLists: PropTypes.func.isRequired,
    parentId: PropTypes.number,
};

export default AddListModal;
