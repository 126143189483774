import React, { useState } from "react";
import { Accordion, Col, Container } from "react-bootstrap";
import I18n from "../../utils/i18n";
import CustomersSection from "./components/CustomersSection";
import SuppliersSection from "./components/SuppliersSection";

const TieredDiversityRequestsPage = () => {
    const [activeId, setActiveId] = useState("customers");

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                                <h2>{I18n.t("tiered_diversity.requests.title")}</h2>
                            </div>
                        </Container>
                    </Col>
                    <Container fluid className="py-3">
                        <Accordion activeKey={activeId}>
                            <CustomersSection activeId={activeId} setActiveId={setActiveId} />
                            <SuppliersSection activeId={activeId} setActiveId={setActiveId} />
                        </Accordion>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default TieredDiversityRequestsPage;
