import { SET_USER, START_FETCHING, STOP_FETCHING } from "./types";

const DEFAULT_STATE = { resource: {}, fetching: true };

const currentUserReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, resource: action.payload };
        case START_FETCHING:
            return { ...state, fetching: true };
        case STOP_FETCHING:
            return { ...state, fetching: false };
        default:
            return state;
    }
};

export default currentUserReducer;
