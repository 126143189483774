import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";
import DiversityClientSpecificMirrorChart from "./DiversityClientSpecificMirrorChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const HoursAttributesByClientChart = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        type="hours_attributes_by_client"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t(
                                "diversity.axises.client_specific_mirror.hours_attributes_by_client.y_axis",
                            ),
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataLabel = data.labels[tooltipItem.index];
                        let value = _round(
                            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                        ).toLocaleString(I18n.locale);
                        return `${dataLabel}: ${value}`;
                    },
                },
            },
        }}
    />
);

HoursAttributesByClientChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default HoursAttributesByClientChart;
