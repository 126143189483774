import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { useSelector } from "react-redux";
import {
    getClientSpecificReportsFilters,
    getClientSpecificReportsPartnershipStats,
    updateClientSpecificReportsCharts,
} from "../../api/diversityApi";
import DiversityClientSpecificReportsFiltersBar from "./components/DiversityClientSpecificReportsFiltersBar";
import ClientSpecificReportsCharts from "../../utils/clientSpecificReportsCharts";
import ExportButton from "./components/ExportButton";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";

const DiversityClientSpecificReportsPage = () => {
    const [quarterlyPartnershipStats, setQuarterlyPartnershipStats] = useState([]);
    const history = useHistory();
    const currentActor = useSelector((state) => state.currentActor.resource);
    const [chartParams, setChartParams] = useState({});
    const canvasRefs = useRef({});

    useEffect(() => {
        getClientSpecificReportsPartnershipStats()
            .then((response) => setQuarterlyPartnershipStats(response.data))
            .catch(() => history.push("/"));
    }, []);

    const findQuarterlyPartnershipStat = () =>
        _find(
            quarterlyPartnershipStats,
            (quarterlyPartnershipStat) =>
                quarterlyPartnershipStat.diversity_firm_id === parseInt(chartParams.firm) &&
                quarterlyPartnershipStat.diversity_quarter_id === parseInt(chartParams.quarter)
        ) || { client_name: currentActor.organization.name };

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            {!_isEmpty(chartParams) && (
                                <ExportButton
                                    canvasRefs={canvasRefs}
                                    quarterlyPartnershipStat={findQuarterlyPartnershipStat()}
                                />
                            )}
                        </div>
                        <div>
                            <DiversityClientSpecificReportsFiltersBar
                                getFilters={getClientSpecificReportsFilters}
                                setChartParams={setChartParams}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="scrollable">
                <div className="overflow-auto bg-white">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={ClientSpecificReportsCharts}
                        updateCharts={updateClientSpecificReportsCharts}
                        channelName="Diversity::ClientSpecificReports::ChartsChannel"
                        namespace="client_specific_reports"
                    />
                </div>
            </div>
        </div>
    );
};

export default DiversityClientSpecificReportsPage;
