import React from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import PropTypes from "prop-types";
import FormRow from "../../../../common/components/FormRow";
import I18n from "../../../../utils/i18n";

const PermissionName = ({ permissions, setFieldValue, value }) => {
    const permissionOptions = _map(permissions, (permission) => ({
        label: I18n.t(`activerecord.attributes.organization.permissions.${permission}`),
        value: permission,
    }));

    const inputName = "filter[permissions_name_in]";
    return (
        <Col>
            <FormRow label={I18n.t("activerecord.attributes.organization.permission_name")}>
                <Select
                    isMulti
                    name={inputName}
                    options={permissionOptions}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(options) => {
                        setFieldValue(
                            "filter[permissions_name_in]",
                            _map(options, (option) => option.value)
                        );
                    }}
                    value={_filter(permissionOptions, (option) => _includes(value, option.value))}
                />
            </FormRow>
        </Col>
    );
};

PermissionName.propTypes = {
    permissions: PropTypes.array.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
};

export default PermissionName;
