import React from "react";
import PropTypes from "prop-types";
import FirmwideMirrorChart from "./FirmwideMirrorChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";
import I18n from "../../../../utils/i18n";

const ScoreByPracticeAreaChart = ({ inProgress, canvasRefs, chartData, chartOptions, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="score_by_practice_area"
        chartType="bar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            callback: getShortLabelForValue,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.score_by_practice_area.y_axis"),
                        },
                    },
                ],
            },
        }}
    />
);

ScoreByPracticeAreaChart.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default ScoreByPracticeAreaChart;
