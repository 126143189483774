import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Badge, Card, Button } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/pro-solid-svg-icons";
import filter from "lodash/filter";
import { useSelector } from "react-redux";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import { getFirmwideParticipation } from "../../../api/diversityApi";
import Table from "../../../common/components/Table";
import DiversityFirmwideUploadAutoApproveForm from "./DiversityFirmwideUploadAutoApproveForm";
import DiversityFirmwideUploadShareModal from "./DiversityFirmwideUploadShareModal";
import ActionsCell from "../../../common/components/FirmwideRequest/ActionsCell";
import NameCell from "../../../common/components/FirmwideRequest/NameCell";
import CommentPopover from "../../../common/components/DiversityPage/CommentPopover";

const StatusCell = ({ row: { original } }) => (
    <div className="d-flex align-items-start">
        <div
            className={classNames("badge", "p-1", {
                "badge-warning": original.status === "reviewed",
                "badge-success": original.status === "approved",
                "badge-light": original.status === "requested",
                "badge-danger": original.status === "rejected",
            })}
        >
            {I18n.t(`activerecord.attributes.diversity/participation.statuses.${original.status}`)}
        </div>

        {original.comment?.text && (
            <CommentPopover comment={original.comment.text} requesterName={original.comment.author} />
        )}
    </div>
);

const UpdatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.updated_at)}</div>
);

const DiversityFirmwideUploadRequests = ({ firm, setFirm, activeId, setActiveId }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.diversity/participation.diversity_firm_id"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/participation.status"),
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: I18n.t("activerecord.attributes.diversity/participation.updated_at"),
                accessor: "updated_at",
                Cell: UpdatedAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => {
                    if (currentActor.super_manager || currentActor.diversity_manager) {
                        return <ActionsCell {...props} firm={firm} />;
                    } else return <></>;
                },
            },
        ],
        [firm.auto_approve_comment],
    );

    const [undecidedRequestsCount, setUndecidedRequestsCount] = useState(0);
    const [data, setData] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const [showShare, setShowShare] = useState(false);
    const currentActor = useSelector((state) => state.currentActor.resource);

    const recalculateUndecidedRequestsCount = (records) =>
        setUndecidedRequestsCount(filter(records, ["status", "requested"]).length);

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || { id: "status", desc: false };
            setLoading(true);

            getFirmwideParticipation(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then((response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(response.data.records);
                    setPageCount(response.data.page_count);
                    recalculateUndecidedRequestsCount(response.data.records);
                    setLoading(false);
                }
            });
        },
        [reloadTable],
    );

    const updateData = (rowIndex, newRow) => {
        setData((oldRows) => {
            const newRows = oldRows.map((oldRow, index) => {
                if (index === rowIndex) {
                    return newRow;
                }
                return oldRow;
            });

            recalculateUndecidedRequestsCount(newRows);

            return newRows;
        });
    };

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                toggleActive={setActiveId}
                eventKey="requests"
                title={I18n.t("diversity.firmwide_upload.requests.title")}
                activeId={activeId}
                actions={
                    <Button variant="link" onClick={() => setShowShare(true)}>
                        <FontAwesomeIcon icon={faShare} />
                    </Button>
                }
            >
                <Badge pill variant="primary" className="ml-1 text-light">
                    {undecidedRequestsCount}
                </Badge>
            </AccordionTitle>
            <Accordion.Collapse eventKey="requests">
                <Card.Body>
                    <DiversityFirmwideUploadAutoApproveForm firm={firm} setFirm={setFirm} />
                    <Table
                        loading={loading}
                        data={data}
                        fetchData={fetchData}
                        updateData={updateData}
                        columns={columns}
                        pageCount={pageCount}
                        initialSortBy={[{ id: "status", desc: false }]}
                    />
                    <DiversityFirmwideUploadShareModal
                        show={showShare}
                        handleClose={() => setShowShare(false)}
                        setReloadTable={setReloadTable}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    }),
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            comment: PropTypes.shape({
                text: PropTypes.string,
                author: PropTypes.string,
            }),
        }),
    }),
};

UpdatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string.isRequired,
        }),
    }),
};

DiversityFirmwideUploadRequests.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        auto_approve_enabled: PropTypes.bool.isRequired,
        auto_approve_comment: PropTypes.string,
    }),
    setFirm: PropTypes.func.isRequired,
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadRequests;
