import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";
import TieredDiversityChart from "../../../../common/components/TieredDiversityChart";

const Tier1SpendByMonth = ({ params, canvasRefs, getChart }) => (
        <TieredDiversityChart
            params={params}
            type="tier1_spend_by_month"
            chartType="line"
            canvasRefs={canvasRefs}
            getChart={getChart}
            chartOptions={{
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                callback: (value) => `${_round(value).toLocaleString(I18n.locale)}`,
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let dataset = data.datasets[tooltipItem.datasetIndex];

                            return `${dataset.label}: ${dataset.custom.symbol}${_round(
                                tooltipItem.value
                            ).toLocaleString(I18n.locale)}`;
                        },
                    },
                },
            }}
        />
    );

Tier1SpendByMonth.propTypes = {
    params: PropTypes.object,
    canvasRefs: PropTypes.object,
    getChart: PropTypes.func.isRequired,
};

export default Tier1SpendByMonth;
