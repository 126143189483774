import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button } from "react-bootstrap";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import _range from "lodash/range";
import moment from "moment";
import I18n from "../../../../utils/i18n";

const IndicatorSection = ({ label, valueLabel, inputName, values, errors }) => {
    const { setFieldValue, handleChange, handleBlur } = useFormikContext();

    return (
        <Row>
            <Col>
                <Form.Group>
                    <h3>{label}</h3>
                </Form.Group>
                {values.map(({ _destroy, value, period }, index) => (
                    <Form.Row key={index} className={classNames("align-items-baseline", { "d-none": _destroy })}>
                        <Form.Group as={Col}>
                            <Form.Label>{valueLabel}</Form.Label>
                            <Form.Control
                                name={`${inputName}[${index}][value]`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={value}
                                type="number"
                                isInvalid={errors[index] && errors[index].value}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors[index] && errors[index].value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{I18n.t("activerecord.attributes.common.period")}</Form.Label>
                            <Form.Control
                                as="select"
                                custom
                                name={`${inputName}[${index}][period]`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={period}
                                isInvalid={errors[index] && errors[index].period}
                            >
                                {_range(1992, moment().year() + 1).map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors[index] && errors[index].period}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="col-auto">
                            <Form.Label>&nbsp;</Form.Label>
                            <Button
                                variant="outline-secondary"
                                className="form-control"
                                onClick={() => setFieldValue(`${inputName}[${index}][_destroy]`, true)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Form.Group>
                    </Form.Row>
                ))}

                <Form.Group>
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            setFieldValue(`${inputName}[${values.length}]`, {
                                id: null,
                                value: "",
                                period: moment().year(),
                                _destroy: false,
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Form.Group>
            </Col>
        </Row>
    );
};

IndicatorSection.propTypes = {
    label: PropTypes.string.isRequired,
    valueLabel: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
};

export default IndicatorSection;
