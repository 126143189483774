import API, { buildFormData } from "../utils/api";

export function getActor(actorUid) {
    return API.get(`/actors/${actorUid}`);
}

export function updateActor(actorUid, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.patch(`/actors/${actorUid}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export function wizardCreateActor(values) {
    return API.post("/actors", values);
}

export function wizardUpdateActor(actorUid, values) {
    return API.patch(`/actors/${actorUid}`, values);
}

export function createActor(values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.post("/actors", formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export function deactivateActor(actorUid, params) {
    return API.delete(`/actors/${actorUid}`, { params });
}

export function approveActor(actorUid) {
    return API.post(`/actors/${actorUid}/approve`);
}

export function getAutocompleteActor(values) {
    return API.get("/autocomplete/actors", { params: values });
}

export function getActorForOrganization(organizationUid, values) {
    return API.get(`/autocomplete/actors/${organizationUid}`, { params: values });
}

export function resentInvitation(actorUid) {
    return API.post(`/actors/${actorUid}/invite`);
}
