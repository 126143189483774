import React from "react";
import I18n from "../../../../utils/i18n";
import ChartComponent from "../../../../common/components/DiversityPage/ChartComponent";

const DifferenceFromGoalChart = () => (
        <ChartComponent
            title={I18n.t("diversity.charts.client_specific_marketing.difference_from_goal")}
            type="horizontalBar"
            data={{
                labels: ["Your Company (Client)", "Firm 1", "Firm 2"],
                datasets: [
                    {
                        data: [5, 2, 10],
                        label: I18n.t("diversity.key_indicators.firmwide_goal"),
                        backgroundColor: "rgba(105,147,166,0.9)",
                        borderColor: "rgba(105,147,166,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [67, 38, 9],
                        label: I18n.t("diversity.key_indicators.matter_leadership_distribution_goal"),
                        backgroundColor: "rgba(28,93,121,0.9)",
                        borderColor: "rgba(28,93,121,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [-64, -37, -50],
                        label: I18n.t("diversity.key_indicators.credit_distribution_goal"),
                        backgroundColor: "rgba(80,164,82,0.9)",
                        borderColor: "rgba(80,164,82,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [-50, -25, -29],
                        label: I18n.t("diversity.key_indicators.hours_distribution_goal"),
                        backgroundColor: "rgba(255,66,1,0.9)",
                        borderColor: "rgba(255,66,1,1)",
                        borderWidth: 1,
                    },
                    {
                        data: [-16, -8, -17],
                        label: I18n.t("diversity.key_indicators.fees_distribution_goal"),
                        backgroundColor: "rgba(254,112,0,0.9)",
                        borderColor: "rgba(254,112,0,1)",
                        borderWidth: 1,
                    },
                ],
            }}
            options={{
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                callback: (value) => `${value} %`,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: I18n.t("diversity.axises.client_specific.difference_from_goal.x_axis"),
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) =>
                            `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value).toFixed(
                                2
                            )} %`,
                    },
                },
            }}
        />
    );

export default DifferenceFromGoalChart;
