import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Card, Form } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import Table from "../../../common/components/Table";
import { getFirmwideArchive, updateFirmStats } from "../../../api/diversityApi";
import FileCell from "./FileCell";

const PeriodCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        Q{original.number}, {original.year}
    </div>
);

const UploadedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.uploaded_at)}</div>
);

const SubmitCell = ({ updateData, row: { original } }) => {
    const [isSubmitted, setIsSubmitted] = useState(original.submitted);
    const [submitting, setSubmitting] = useState(false);

    return (
        <Form.Check
            id={`fw-submit-toggler-${original.diversity_quarter_id}`}
            type="switch"
            onChange={() => {
                setSubmitting(true);
                updateFirmStats(original.diversity_quarter_id, { submitted: true })
                    .then((response) => {
                        updateData(original.diversity_quarter_id, response.data);
                        setIsSubmitted(true);
                    })
                    .finally(() => setSubmitting(false));
            }}
            checked={isSubmitted}
            disabled={submitting || isSubmitted}
        />
    );
};

const DiversityFirmwideUploadArchive = ({ activeId, setActiveId }) => {
    const columns = useMemo(() => [
        {
            Header: I18n.t("diversity.firmwide_upload.archive.columns.period"),
            accessor: "period",
            Cell: PeriodCell,
        },
        {
            Header: I18n.t("diversity.firmwide_upload.archive.columns.uploaded_at"),
            accessor: "uploaded_at",
            Cell: UploadedAtCell,
        },
        {
            Header: I18n.t("diversity.firmwide_upload.archive.columns.filename"),
            isSorted: false,
            Cell: FileCell,
        },
        {
            Header: I18n.t("diversity.firmwide_upload.archive.columns.submit"),
            isSorted: false,
            Cell: (props) => <SubmitCell {...props} updateData={updateData} />,
        },
    ]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { diversity_quarter_id: "period", desc: true };
        setLoading(true);

        getFirmwideArchive(pageIndex + 1, sort.diversity_quarter_id, sort.desc ? "desc" : "asc").then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);
    const updateData = (rowId, newRow) => {
        setData((oldRows) =>
            oldRows.map((oldRow) => {
                if (oldRow.diversity_quarter_id === rowId) {
                    return { ...oldRow, ...newRow };
                }
                return oldRow;
            })
        );
    };

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                title={I18n.t("diversity.firmwide_upload.archive.title")}
                eventKey="archive"
                activeId={activeId}
                toggleActive={setActiveId}
            />
            <Accordion.Collapse eventKey="archive">
                <Card.Body>
                    <Table
                        loading={loading}
                        data={data}
                        fetchData={fetchData}
                        columns={columns}
                        pageCount={pageCount}
                        initialSortBy={[{ id: "period", desc: true }]}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

PeriodCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            number: PropTypes.number.isRequired,
            year: PropTypes.number.isRequired,
        }),
    }),
};

UploadedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            uploaded_at: PropTypes.string.isRequired,
        }),
    }),
};

DiversityFirmwideUploadArchive.propTypes = {
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
    firm: PropTypes.shape({
        diversity_quarter_id: PropTypes.number.isRequired,
        submitted: PropTypes.bool.isRequired,
        number: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
    }),
};

SubmitCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            submitted: PropTypes.bool.isRequired,
            diversity_quarter_id: PropTypes.number.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadArchive;
