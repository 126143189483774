import { SET_WHITELABEL, START_FETCHING, STOP_FETCHING } from "./types";
import { getWhitelabel } from "../../api/whitelabelApi";

export function fetchCurrentWhitelabel() {
    return (dispatch) => {
        dispatch(startFetching());

        getWhitelabel(window.location.hostname.replace(/\./g, "-"))
            .then((response) => {
                dispatch(setCurrentWhitelabel(response.data));
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function startFetching() {
    return { type: START_FETCHING };
}

export function stopFetching() {
    return { type: STOP_FETCHING };
}

export function setCurrentWhitelabel(whitelabel) {
    return { type: SET_WHITELABEL, payload: whitelabel };
}
