import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSlidersH,
    faCloudUploadAlt,
    faEye,
    faEnvelopeOpenText,
    faAddressCard,
    faChalkboardTeacher,
} from "@fortawesome/pro-solid-svg-icons";
import I18n from "../../utils/i18n";

const TieredDiversityStarterPage = () => {
    const SCREENCAST_ID = "c3jjoiVZd6a";

    useEffect(() => {
        const script = document.createElement("script");

        script.src = `https://screencast-o-matic.com/player/appearance/${SCREENCAST_ID}`;
        script.async = true;

        document.getElementById("som-player").prepend(script);
    }, []);

    return (
        <div>
            <div className="top-bar" />
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 bg-white">
                    <Container fluid className="p-4">
                        <Row>
                            <Col className="mr-auto">
                                <h3>{I18n.t("tiered_diversity.starter.header")}</h3>
                                <p>{I18n.t("tiered_diversity.starter.body")}</p>
                            </Col>
                            <Col className="col-auto">
                                <Link to="/tiered_diversity/partnerships/new">
                                    <Button variant="warning" className="text-white">
                                        {I18n.t("tiered_diversity.starter.buttons.upload")}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="text-center">
                                        <p>{I18n.t("tiered_diversity.starter.columns.define.header")}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon icon={faSlidersH} size="10x" color="#4CB9EC" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.define.setup")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon icon={faCloudUploadAlt} size="10x" color="#4CB9EC" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.define.upload")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon icon={faEye} size="10x" color="#4CB9EC" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.define.review")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            {I18n.t("tiered_diversity.starter.columns.define.footer.body")}
                                            <strong>
                                                <u>
                                                    {I18n.t("tiered_diversity.starter.columns.define.footer.warning")}
                                                </u>
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="border-left">
                                <Row>
                                    <Col className="text-center">
                                        <p>{I18n.t("tiered_diversity.starter.columns.capture.header")}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon icon={faEnvelopeOpenText} size="10x" color="#4CB9EC" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.capture.automate")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-center d-flex flex-column justify-content-end">
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.capture.or")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon
                                                    icon={faChalkboardTeacher}
                                                    size="10x"
                                                    color="#4CB9EC"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.capture.enter")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-center">
                                        <Row>
                                            <Col>
                                                <FontAwesomeIcon icon={faAddressCard} size="10x" color="#4CB9EC" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{I18n.t("tiered_diversity.starter.columns.capture.view")}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>{I18n.t("tiered_diversity.starter.columns.capture.footer")}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="border-bottom">
                            <Col>
                                <p>
                                    <strong>{I18n.t("tiered_diversity.starter.footer.warning")}</strong>
                                    {I18n.t("tiered_diversity.starter.footer.body")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div id="som-player" className="som-embed-player mt-3" data-id={SCREENCAST_ID}>
                                    <iframe
                                        className="w-100 h-100 border-0 overflow-hidden"
                                        src={`https://screencast-o-matic.com/player/${SCREENCAST_ID}?width=100%&height=100%&ff=1&title=0`}
                                        allowFullScreen={true}
                                    ></iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default TieredDiversityStarterPage;
