import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import { Button } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import { getDashboardFilters } from "../../../api/tieredDiversityApi";
import IndustriesFilter from "./filters/IndustriesFilter";
import DiversityCertificateTypesFilter from "./filters/DiversityCertificateTypesFilter";
import RangeFilter from "./filters/RangeFilter";
import { DATE_FORMAT } from "../../../utils/datetimeFormats";

const FiltersBar = ({ setChartParams }) => {
    const history = useHistory();
    const [values, setValues] = useState({});
    const [defaultParams, setDefaultParams] = useState({});
    const [params, setParams] = useState({});

    useEffect(() => {
        getDashboardFilters()
            .then((response) => {
                const fetchedParams = getDefaultParamsFor(response.data);
                setValues(response.data);
                setDefaultParams(_cloneDeep(fetchedParams));
                setParams(_cloneDeep(fetchedParams));
                setChartParams(_cloneDeep(fetchedParams));
            })
            .catch((error) => {
                error.response.status === 403 && history.push("/");
            });
    }, []);

    const getDefaultParamsFor = (values) => ({
        industries: _map(values.industries || [], "id"),
        diversity_certificate_types: _map(values.diversity_certificate_types || [], "id"),
        range: {
            from: moment().subtract(11, "months").startOf("month").format(DATE_FORMAT),
            to: moment().endOf("month").format(DATE_FORMAT),
        },
    });

    const applyFilters = (_event, newParams = params) => {
        setChartParams(_cloneDeep(newParams));
    };

    const clearFilters = () => {
        setParams(_cloneDeep(defaultParams));
        setChartParams(_cloneDeep(defaultParams));
    };

    if (_isEmpty(params) || _isEmpty(values)) {
        return <></>;
    }

    return (
        <>
            {_map([IndustriesFilter, DiversityCertificateTypesFilter, RangeFilter], (filterType, index) =>
                React.createElement(filterType, {
                    key: index,
                    params: params,
                    setParams: setParams,
                    values: values,
                    defaultParams: defaultParams,
                    applyFilters: applyFilters,
                }),
            )}
            <div className="d-inline-flex m-1">
                <Button variant="secondary" className="text-light" onClick={clearFilters}>
                    {I18n.t("common.clear")}
                </Button>
            </div>
        </>
    );
};

FiltersBar.propTypes = {
    setChartParams: PropTypes.func.isRequired,
};

export default FiltersBar;
