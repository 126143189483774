import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Container } from "react-bootstrap";
import _ from "lodash";
import I18n from "../../utils/i18n";
import FormRow from "./FormRow";
import AvatarContainer from "./AvatarContainer";

const FormUserPersonalDetails = ({ form }) => {
    const validationError = (name) => {
        const errors = _.get(form.errors, name);

        if (_.isArray(errors)) {
            return errors.join("; ");
        } else {
            return errors;
        }
    };

    return (
        <Container fluid className="mt-3 pt-2">
            <Row>
                <Col md={3}>
                    <FormRow
                        label={I18n.t("activerecord.attributes.user.avatar")}
                        error={validationError("actor.user_attributes.avatar")}
                    >
                        <AvatarContainer form={form} inputName="actor[user_attributes][avatar]" />
                    </FormRow>
                </Col>
                <Col md={9}>
                    <FormRow
                        label={I18n.t("activerecord.attributes.user.first_name")}
                        error={validationError("actor.user_attributes.first_name")}
                    >
                        <Form.Control
                            name="actor[user_attributes][first_name]"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={_.get(form.values, "actor.user_attributes.first_name") || ""}
                            isInvalid={validationError("actor.user_attributes.first_name")}
                        />
                    </FormRow>
                    <FormRow
                        label={I18n.t("activerecord.attributes.user.last_name")}
                        error={validationError("actor.user_attributes.last_name")}
                    >
                        <Form.Control
                            name="actor[user_attributes][last_name]"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={_.get(form.values, "actor.user_attributes.last_name") || ""}
                            isInvalid={validationError("actor.user_attributes.last_name")}
                        />
                    </FormRow>
                    <FormRow
                        label={I18n.t("activerecord.attributes.user.email")}
                        error={
                            validationError("actor.user_attributes.email") ||
                            validationError("user.email") ||
                            validationError("user_id")
                        }
                    >
                        <Form.Control
                            name="actor[user_attributes][email]"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={_.get(form.values, "actor.user_attributes.email") || ""}
                            isInvalid={
                                validationError("actor.user_attributes.email") ||
                                validationError("user.email") ||
                                validationError("user_id")
                            }
                        />
                    </FormRow>
                </Col>
            </Row>

            <FormRow
                label={I18n.t("activerecord.attributes.user.second_email")}
                error={validationError("actor.user_attributes.second_email")}
            >
                <Form.Control
                    name="actor[user_attributes][second_email]"
                    type="text"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={_.get(form.values, "actor.user_attributes.second_email") || ""}
                    isInvalid={validationError("actor.user_attributes.second_email")}
                />
            </FormRow>
            <FormRow
                label={I18n.t("activerecord.attributes.user.phone")}
                error={validationError("actor.user_attributes.phone")}
            >
                <Form.Control
                    name="actor[user_attributes][phone]"
                    type="text"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={_.get(form.values, "actor.user_attributes.phone") || ""}
                    isInvalid={validationError("actor.user_attributes.phone")}
                />
            </FormRow>
            <FormRow
                label={I18n.t("activerecord.attributes.user.job_title")}
                error={validationError("actor.user_attributes.job_title")}
            >
                <Form.Control
                    name="actor[user_attributes][job_title]"
                    type="text"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={_.get(form.values, "actor.user_attributes.job_title") || ""}
                    isInvalid={validationError("actor.user_attributes.job_title")}
                />
            </FormRow>
        </Container>
    );
};

FormUserPersonalDetails.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
    }),
};

export default FormUserPersonalDetails;
