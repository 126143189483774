import React, { useEffect, useState } from "react";
import _map from "lodash/map";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import PropTypes from "prop-types";

const Header = ({ steps, currentStep, styles = "mb-2", direction = undefined }) => {
    const [stepItems, setStepItems] = useState(steps);
    const stepStatus = (stepIndex) => (stepIndex === currentStep ? "process" : "wait");

    useEffect(
        () => setStepItems(_map(stepItems, (step, index) => ({ ...step, status: stepStatus(index) }))),
        [currentStep]
    );

    return (
        <Steps
            current={currentStep}
            labelPlacement="vertical"
            className={styles}
            items={stepItems}
            direction={direction}
        />
    );
};

Header.propTypes = {
    steps: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired,
    styles: PropTypes.string,
    direction: PropTypes.string,
};

export default Header;
