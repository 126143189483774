import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";
import { Field } from "formik";
import FormRow from "../../../../common/components/FormRow";
import I18n from "../../../../utils/i18n";

const OrganizationName = ({ values }) => {
    const inputName = "filter[name_or_id_or_descendants_name_or_id_cont]";

    return (
        <div className="col">
            <FormRow label={I18n.t("activerecord.models.organization")}>
                <Field
                    as={FormControl}
                    name={inputName}
                    value={values.filter.name_or_id_or_descendants_name_or_id_cont || ""}
                />
            </FormRow>
        </div>
    );
};

OrganizationName.propTypes = {
    values: PropTypes.object.isRequired,
};

export default OrganizationName;
