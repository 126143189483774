import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import _map from "lodash/map";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";

const ListFilter = ({ params, setParams, values, applyFilters }) => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const label = I18n.t("diversity.buttons.select_list");
    const filterKey = "lists";
    const selectedList = params[filterKey];
    const updateParams = (value) => setParams({ ...params, [filterKey]: value });
    const badgeCount = () => (selectedList.length === 0 ? 0 : 1);
    const truncate = (str, length) => (str.length > length ? `${str.substring(0, length)}...` : str);

    const labelElement = (labelText) => {
        if (labelText.length > 30) {
            let id = `list-filter-tooltip-${labelText.replace(/\s+/g, "-").toLowerCase()}`;

            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`list-filter-tooltip-${id}`}>{labelText}</Tooltip>}
                >
                    <span>{truncate(labelText, 30)}</span>
                </OverlayTrigger>
            );
        } else {
            return labelText;
        }
    };

    return (
        <Dropdown as="div" className="d-inline-flex">
            <Dropdown.Toggle variant="primary" className="text-light m-1">
                {label}
                {badgeCount() > 0 && (
                    <Badge variant="light" className="mx-1">
                        {badgeCount()}
                    </Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="dropdown-submenu">
                    {currentActor.organization.permissions.company_profile_permission &&
                        _map(values.lists, (filterValue) => (
                            <div className="dropdown-item" key={filterValue.id}>
                                <Form.Check
                                    custom
                                    name={filterKey}
                                    type="radio"
                                    id={`${filterKey}-${filterValue.id}`}
                                    label={labelElement(filterValue.label)}
                                    checked={selectedList === filterValue.id.toString()}
                                    onChange={() => updateParams(filterValue.id.toString())}
                                    className="short-label"
                                />
                            </div>
                        ))}
                </div>
                <Dropdown.Divider />
                {values.lists.length === 0 || !currentActor.organization.permissions.company_profile_permission ? (
                    <div className="dropdown-submenu">
                        <span
                            className="text-center dropdown-item-text"
                            dangerouslySetInnerHTML={{
                                __html: I18n.t("diversity.buttons.no_lists_available"),
                            }}
                        />
                    </div>
                ) : (
                    <div className="dropdown-submenu">
                        <Button className="dropdown-item text-center" onClick={() => updateParams("")}>
                            {I18n.t("diversity.buttons.select_none")}
                        </Button>
                    </div>
                )}
                <Dropdown.Divider />
                <div className="dropdown-submenu">
                    <Dropdown.Item onClick={applyFilters} className="text-center">
                        {I18n.t("diversity.buttons.submit")}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

ListFilter.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    values: PropTypes.shape({
        lists: PropTypes.array.isRequired,
    }),
    applyFilters: PropTypes.func.isRequired,
};

export default ListFilter;
