import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import CreateProjectWizard from "././CreateProjectWizard";

const NewProjectButton = () => {
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel);
    const [createModalShow, setCreateModalShow] = useState(false);

    if (currentWhitelabel.fetching) {
        return null;
    }

    return (
        <>
            <Button variant="primary" onClick={() => setCreateModalShow(true)}>
                {I18n.t("home.negotiations.actions.new_project_button.title")}
            </Button>
            <CreateProjectWizard onHide={() => setCreateModalShow(false)} show={createModalShow} />
        </>
    );
};

export default NewProjectButton;
