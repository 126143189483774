import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const TextComponent = ({ type, title, inProgress, canvasRefs, position }) => {
    canvasRefs.current[type] = {
        data: title,
        position: position,
        tagName: "TEXT",
    };

    return inProgress ? (
        <div className="d-flex justify-content-center">
            <Spinner as="span" animation="border" role="status" aria-hidden="true" />
        </div>
    ) : (
        title
    );
};

TextComponent.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired,
};

export default TextComponent;
