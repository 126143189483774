import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import OrganizationSection from "./components/OrganizationSection";

const OrganizationsPage = ({ currentActor }) => {
    if (isEmpty(currentActor.resource)) {
        return <Redirect to="/select_organizations" />;
    }

    return <OrganizationSection />;
};

const mapStateToProps = (state) => ({
    currentActor: state.currentActor,
});

OrganizationsPage.propTypes = {
    currentActor: PropTypes.shape({
        fetching: PropTypes.bool.isRequired,
        resource: PropTypes.object,
    }),
};

export default connect(mapStateToProps)(OrganizationsPage);
