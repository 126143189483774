import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

const WidgetCard = (props) => (
    <div className="mb-2">
        <div className="pre-scrollable overflow-x-hidden" ref={props.containerRef}>
            {props.loading ? (
                <Loader />
            ) : (
                <>
                    <h4 className="text-warning">{props.title}</h4>
                    {props.children}
                </>
            )}
        </div>
    </div>
);

WidgetCard.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    containerRef: PropTypes.object,
};

export default WidgetCard;
