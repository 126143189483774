import React from "react";
import { Modal } from "react-bootstrap";
import I18n from "../../../utils/i18n";

const AddFirmModalHeader = () => (
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {I18n.t("diversity.clients.add_firm_modal.header")}
        </Modal.Title>
    </Modal.Header>
);

export default AddFirmModalHeader;
