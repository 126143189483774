import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import I18n from "../../../utils/i18n";
import AccordionTitle from "../../../common/components/AccordionTitle";
import DiversityFirmwideUploadFiltersBar from "./DiversityFirmwideUploadFiltersBar";
import { getFirmwideUploadFilters, updateFirmwideUploadChart } from "../../../api/diversityApi";
import FirmwideCharts from "../../../utils/firmwideCharts";
import ViewDashboardPage from "../../../common/components/DashboardPage/ViewDashboardPage";

const DiversityFirmwideUploadData = ({ firm, activeId, setActiveId }) => {
    const [chartParams, setChartParams] = useState({});

    return (
        <Card className="border-0 overflow-visible mb-3">
            <AccordionTitle
                title={I18n.t("diversity.firmwide_upload.data.title")}
                eventKey="data"
                activeId={activeId}
                toggleActive={setActiveId}
            />
            <Accordion.Collapse eventKey="data">
                <Card.Body id="firmwide-data">
                    <Row>
                        <Col className="mr-auto" />
                        <Col className="col-auto">
                            <DiversityFirmwideUploadFiltersBar
                                getFilters={getFirmwideUploadFilters}
                                setChartParams={setChartParams}
                                loadedAt={firm.uploaded_at}
                            />
                        </Col>
                    </Row>
                    <ViewDashboardPage
                        chartParams={chartParams}
                        chartsRoute={FirmwideCharts}
                        updateCharts={updateFirmwideUploadChart}
                        channelName="Diversity::FirmwideUpload::ChartsChannel"
                        showDashboard={false}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

DiversityFirmwideUploadData.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uploaded_at: PropTypes.string,
    }),
    activeId: PropTypes.string.isRequired,
    setActiveId: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadData;
