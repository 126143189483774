import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _isUndefined from "lodash/isUndefined";
import pdfImage from "../../../images/pdfStub.png";

const CertificateOriginButtons = ({ file, previewUrl, classNamePostfix, open, setFieldValue, inputName, fileName }) => {
    const handleButtonClick = (event) => event.stopPropagation();

    return (
        <div className="certificate-pdf-stub-container">
            <div className="slide-down-container">
                <div className={`avatar avatar-${classNamePostfix} h-100 w-100`}>
                    <img className="avatar-image-rectangle" src={pdfImage} alt="pdfStubImage" />
                </div>
                <div className="button-wrapper">
                    <a
                        href={previewUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="manage-link"
                        onClick={handleButtonClick}
                    >
                        <FontAwesomeIcon size="lg" icon={faDownload} />
                    </a>
                    <a
                        href="#"
                        className="manage-link ml-2"
                        onClick={(event) => {
                            handleButtonClick(event);
                            open();
                        }}
                    >
                        <FontAwesomeIcon size="lg" icon={faUpload} />
                    </a>
                    <a
                        href="#"
                        className="manage-link ml-2 mr-2"
                        onClick={(event) => {
                            handleButtonClick(event);
                            setFieldValue(inputName, "");
                        }}
                    >
                        <FontAwesomeIcon size="lg" icon={faTrash} />
                    </a>
                </div>
            </div>
            <div className="avatar-filename">{(!_isUndefined(file) && file.name) || fileName}</div>
        </div>
    );
};

CertificateOriginButtons.propTypes = {
    file: PropTypes.any.isRequired,
    previewUrl: PropTypes.string,
    classNamePostfix: PropTypes.string,
    open: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    fileName: PropTypes.string,
};

export default CertificateOriginButtons;
