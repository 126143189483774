import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Card, Row, Col, Button, Dropdown, Form, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faChevronRight,
    faExclamationTriangle,
    faPlus,
    faPencilAlt,
    faQuestionCircle,
    faTrash,
    faEnvelope,
} from "@fortawesome/pro-solid-svg-icons";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import _round from "lodash/round";
import { MonthPicker } from "react-tempusdominus-bootstrap";
import { useSelector } from "react-redux";
import _lowerCase from "lodash/lowerCase";
import ExportButtons from "./export/ExportButtons";
import I18n from "../../../utils/i18n";
import useChannel from "../../../utils/channels/useChannel";
import Table from "../../../common/components/Table";
import {
    getDashboardPartnerships,
    updatePartnership,
    deletePartnershipCertificate,
} from "../../../api/tieredDiversityApi";
import { getIndustries } from "../../../api/industryApi";
import SubPartnershipsTable from "./SubPartnershipsTable";
import { DATE_FORMAT } from "../../../utils/datetimeFormats";
import TooltipComponent from "../../../common/components/DiversityPage/TooltipComponent";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import AddAlternativeContactModal from "./AddAlternativeContactModal";
import DeleteAction from "../../../common/components/DeleteAction";

const StatusCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        <FontAwesomeIcon icon={original.status === "completed" ? faCheck : faExclamationTriangle} />
    </div>
);

const SubPartnershipsStatus = ({ row: { original } }) => (
    <div className="d-flex flex-column">{`${(original.sub_partnerships.status || 0.0).toFixed(2)}%`}</div>
);

const SupplierNameCell = ({ row: { getToggleRowExpandedProps, isExpanded, original } }) => (
    <div className="d-flex align-items-center">
        {(original.sub_partnerships.count || 0) > 0 && (
            <span {...getToggleRowExpandedProps()} className={classNames("expand m-1", { down: isExpanded })}>
                <FontAwesomeIcon icon={faChevronRight} />
            </span>
        )}
        <span>{original.supplier_name}</span>
    </div>
);

const IndustryCell = ({ row: { index, original }, loadOptions, updateData }) => {
    const [inProgress, setInProgress] = useState(false);

    return (
        <div className="d-flex flex-column w-100">
            <AsyncSelect
                name="industry_id"
                className="react-select"
                classNamePrefix="react-select"
                noOptionsMessage={({ inputValue }) =>
                    inputValue.length > 0
                        ? I18n.t("common.placeholders.no_options")
                        : I18n.t("common.placeholders.start_typing")
                }
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.specialty || option.focus}
                getOptionValue={(option) => option.id}
                formatGroupLabel={(option) => <div>{option.label}</div>}
                formatOptionLabel={function (option, meta) {
                    if (meta.context === "value") {
                        return <div title={option.name}>{option.name}</div>;
                    } else {
                        return <div className={option.specialty ? "ml-4" : "ml-2"}>{this.getOptionLabel(option)}</div>;
                    }
                }}
                isDisabled={inProgress}
                isLoading={inProgress}
                onChange={(option) => {
                    setInProgress(true);

                    updatePartnership(original.id, { partnership: { industry_id: option.id } })
                        .then((response) => {
                            updateData(index, { ...response.data, industry: option.industry });
                        })
                        .finally(() => setInProgress(false));
                }}
                value={original.industry}
            />
        </div>
    );
};

const TotalAmountCell = ({ row: { original } }) => {
    const totalCents = parseFloat(_round(original.total_amount)).toLocaleString(I18n.locale);
    const totalPercentage = parseFloat(_round(original.total_percentage, 2)).toLocaleString(I18n.locale);

    return (
        <div className="d-flex flex-column">
            {original.total_amount && `${original.currency.symbol}${totalCents} (${totalPercentage}%)`}
        </div>
    );
};

const CertificateNameCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {original.certificate.origin_download_path && (
            <Link target="_blank" to={original.certificate.origin_download_path}>
                {original.certificate.name}
            </Link>
        )}
    </div>
);

const CertificateExpirationDateCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">
        {original.certificate.expiration_date && I18n.l("date.formats.long", original.certificate.expiration_date)}
    </div>
);

const ActionsCell = ({ row: { index, original }, updateData }) => {
    const [deleteCertificateModalShow, setDeleteCertificateModalShow] = useState(false);
    const [addAlternativeContactModalShow, setAddAlternativeContactModalShow] = useState(false);

    const onDeleteCertificateSubmit = () => {
        deletePartnershipCertificate(original.id).then(() => {
            updateData(index, { certificate: { editable: true } });
            setDeleteCertificateModalShow(false);
        });
    };

    if (original.certificate.editable) {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={ThreeDotsToggle} />
                    <Dropdown.Menu size="sm" title="">
                        {original.certificate.id ? (
                            <>
                                <Dropdown.Item
                                    as={Link}
                                    to={`/tiered_diversity/partnerships/${original.id}/certificate/edit`}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                    {I18n.t("common.links.edit")}
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={() => setDeleteCertificateModalShow(true)} to="#">
                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                    {I18n.t("common.links.delete")}
                                </Dropdown.Item>
                            </>
                        ) : (
                            <Dropdown.Item
                                as={Link}
                                to={`/tiered_diversity/partnerships/${original.id}/certificate/new`}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                {I18n.t("common.links.add")}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <DeleteAction
                    onSubmit={onDeleteCertificateSubmit}
                    show={deleteCertificateModalShow}
                    onHide={() => setDeleteCertificateModalShow(false)}
                    resource={I18n.t("activerecord.models.diversity/certificate")}
                />
            </>
        );
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} onClick={() => setAddAlternativeContactModalShow(true)} to="#">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        {`${I18n.t("common.links.add")} ${_lowerCase(
                            I18n.t("activerecord.attributes.tiered_diversity/partnership.supplier_email"),
                        )}`}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <AddAlternativeContactModal
                partnershipId={original.id}
                show={addAlternativeContactModalShow}
                setShow={setAddAlternativeContactModalShow}
            />
        </>
    );
};

export const HeaderWithToolTip = (content, toolTip) => (
    <>
        {content}
        <TooltipComponent content={toolTip} placement="top-start">
            <FontAwesomeIcon icon={faQuestionCircle} />
        </TooltipComponent>
    </>
);

const PartnershipsTable = () => {
    const history = useHistory();
    const [from, setFrom] = useState(moment().subtract(11, "months").startOf("month").format(DATE_FORMAT));
    const [to, setTo] = useState(moment().endOf("month").format(DATE_FORMAT));
    const [statuses, setStatuses] = useState(["completed", "not_completed"]);
    const [params, setParams] = useState({});
    const currentActor = useSelector((state) => state.currentActor.resource);

    const loadOptions = debounce((term, callback) => {
        if (term && term.length >= 3) {
            getIndustries({ q: { area_or_focus_or_specialty_cont: term } })
                .then((response) =>
                    callback(
                        _map(_groupBy(response.data, "area"), (industries, area) => ({
                            options: industries,
                            label: area,
                        })),
                    ),
                )
                .catch((error) => {
                    error.response.status === 403 && history.push("/");
                });
        }
    }, 500);

    const columns = useMemo(
        () => [
            {
                Header: <span>&nbsp;</span>,
                accessor: "status",
                Cell: StatusCell,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/partnership.sub_partnerships_status"),
                    I18n.t("tables.tiered_diversity/partnership.headers.t2_status"),
                ),
                id: "t2_status",
                flexClass: "start",
                Cell: SubPartnershipsStatus,
                isSorted: false,
            },
            {
                Header: I18n.t("activerecord.attributes.tiered_diversity/partnership.supplier_name"),
                accessor: "supplier_name",
                Cell: SupplierNameCell,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/partnership.industry_id"),
                    I18n.t("tables.tiered_diversity/partnership.headers.supplier_category"),
                ),
                flexClass: "start",
                accessor: "industry.name",
                class: "w-25",
                Cell: (props) => <IndustryCell {...props} loadOptions={loadOptions} updateData={updateData} />,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/monthly_partnership_stats.total"),
                    I18n.t("tables.tiered_diversity/partnership.headers.spend"),
                ),
                flexClass: "start",
                id: "total",
                Cell: TotalAmountCell,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/partnership.certificate.name"),
                    I18n.t("tables.tiered_diversity/partnership.headers.certificate_type"),
                ),
                flexClass: "start",
                accessor: "certificate.name",
                Cell: CertificateNameCell,
            },
            {
                Header: HeaderWithToolTip(
                    I18n.t("activerecord.attributes.tiered_diversity/partnership.certificate.expiration_date"),
                    I18n.t("tables.tiered_diversity/partnership.headers.certificate_expiration_date"),
                ),
                flexClass: "start",
                accessor: "certificate.expiration_date",
                Cell: CertificateExpirationDateCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} updateData={updateData} />,
            },
        ],
        [],
    );

    const fetchIdRef = useRef(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    useChannel({
        channelParams: { channel: "TieredDiversity::Dashboard::ExportChannel" },
        onReceived: ({ type, progress }) => {
            type === "filling" && setProgress(progress);
            if (type === "finished") {
                setShowDownloadButton(true);
                setProgress(0);
                setIsExporting(false);
            }
        },
    });

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || { id: "status", desc: false };
            setLoading(true);
            setParams({
                column: sort.id,
                order: sort.desc ? "desc" : "asc",
                from: from,
                to: to,
                statuses: statuses,
            });

            getDashboardPartnerships(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc", from, to, statuses)
                .then((response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    error.response.status === 403 && history.push("/");
                });
        },
        [from, to, statuses],
    );

    const updateData = (rowIndex, newRow) => {
        setData((oldRows) =>
            oldRows.map((oldRow, index) => {
                if (index === rowIndex) {
                    return { ...oldRow, ...newRow };
                }
                return oldRow;
            }),
        );
    };

    const renderRowSubComponent = useCallback(
        ({
            row: {
                original: { currency, sub_partnerships },
            },
        }) => (
            <tr>
                <td colSpan={columns.length} className="bg-light">
                    <Card>
                        <Card.Body>
                            <SubPartnershipsTable currency={currency} subPartnerships={sub_partnerships} />
                        </Card.Body>
                    </Card>
                </td>
            </tr>
        ),
        [],
    );

    const onAutomate = () => history.push("/tiered_diversity/pricing");

    return (
        <Card.Body>
            <Row>
                <Col className="mr-auto">
                    <Row className="mb-2">
                        <Col>
                            <div className="d-inline-flex mr-2">
                                <MonthPicker
                                    onChange={(event) => setFrom(moment(event.date).format(DATE_FORMAT))}
                                    date={moment(from, DATE_FORMAT)}
                                />
                            </div>
                            <div className="d-inline-flex mr-2">
                                <MonthPicker
                                    onChange={(event) => setTo(moment(event.date).format(DATE_FORMAT))}
                                    date={moment(to, DATE_FORMAT)}
                                />
                            </div>
                            <Dropdown as="div" className="d-inline-flex mr-2">
                                <Dropdown.Toggle variant="primary" className="text-white">
                                    {statuses.length > 0
                                        ? _map(statuses, (status) =>
                                              I18n.t(`tiered_diversity.dashboard.suppliers.buttons.statuses.${status}`),
                                          ).join("/")
                                        : I18n.t("tiered_diversity.dashboard.suppliers.buttons.status")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="dropdown-submenu">
                                        {_map(["completed", "not_completed"], (status) => (
                                            <div className="dropdown-item" key={status}>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    name="statuses"
                                                    id={`statuses-${status}`}
                                                    label={I18n.t(
                                                        `tiered_diversity.dashboard.suppliers.buttons.statuses.${status}`,
                                                    )}
                                                    value={status}
                                                    onChange={(event) =>
                                                        setStatuses((prevState) => {
                                                            if (event.target.checked) {
                                                                return [...prevState, status];
                                                            } else {
                                                                let result = [...prevState];
                                                                result.splice(result.indexOf(status), 1);
                                                                return result;
                                                            }
                                                        })
                                                    }
                                                    checked={statuses.indexOf(status) >= 0}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            {(currentActor.super_manager || currentActor.sd_manager) && (
                                <Button
                                    variant="primary"
                                    className="text-white mr-2"
                                    onClick={() => history.push("/tiered_diversity/partnerships/new")}
                                >
                                    {I18n.t("tiered_diversity.dashboard.suppliers.buttons.upload")}
                                </Button>
                            )}
                            {(currentActor.super_manager || currentActor.sd_manager) && (
                                <Button variant="primary" className="text-white" onClick={onAutomate}>
                                    {I18n.t("tiered_diversity.dashboard.suppliers.buttons.automate")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Col>
                {(currentActor.super_manager || currentActor.sd_manager) && (
                    <ExportButtons
                        params={params}
                        isExporting={isExporting}
                        setIsExporting={setIsExporting}
                        showDownloadButton={showDownloadButton}
                        setShowDownloadButton={setShowDownloadButton}
                    />
                )}
            </Row>
            {progress >= 1 && <ProgressBar className="download-progress" now={progress} />}
            <Row>
                <Col>
                    <Table
                        loading={loading}
                        data={data}
                        fetchData={fetchData}
                        updateData={updateData}
                        columns={columns}
                        pageCount={pageCount}
                        initialSortBy={[{ id: "status", desc: false }]}
                        renderRowSubComponent={renderRowSubComponent}
                    />
                </Col>
            </Row>
        </Card.Body>
    );
};

StatusCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    }),
};

SubPartnershipsStatus.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            sub_partnerships: PropTypes.shape({
                status: PropTypes.number,
            }),
        }),
    }),
};

SupplierNameCell.propTypes = {
    row: PropTypes.shape({
        getToggleRowExpandedProps: PropTypes.func.isRequired,
        isExpanded: PropTypes.bool,
        original: PropTypes.shape({
            supplier_name: PropTypes.string.isRequired,
            sub_partnerships: PropTypes.shape({
                count: PropTypes.number,
            }),
        }),
    }),
};

IndustryCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            industry: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                area: PropTypes.string,
                focus: PropTypes.string,
                specialty: PropTypes.string,
            }),
        }),
    }),
    loadOptions: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
};

TotalAmountCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            currency: PropTypes.shape({
                iso_code: PropTypes.string,
                symbol: PropTypes.string,
            }),
            total_amount: PropTypes.number,
            total_percentage: PropTypes.number,
        }),
    }),
};

CertificateNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            certificate: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                origin_download_path: PropTypes.string,
            }),
        }),
    }),
};

CertificateExpirationDateCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            certificate: PropTypes.shape({
                expiration_date: PropTypes.string,
            }),
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            certificate: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                origin_download_path: PropTypes.string,
                editable: PropTypes.bool,
            }),
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

export default PartnershipsTable;
