import qs from "qs";
import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Button } from "react-bootstrap";
import I18n from "../../../utils/i18n";

const TopBar = ({ applyFilter }) => {
    const url = `/organizations.xlsx?${qs.stringify(
        { q: applyFilter, page: 0, column: "name", order: "asc" },
        {
            arrayFormat: "brackets",
        },
    )}`;

    return (
        <div className="top-bar">
            <Container fluid className="py-3">
                <Col className="text-right">
                    <Button className="text-white" variant="warning" href={url} target="_blank">
                        {I18n.t("tables.organizations.export_button")}
                    </Button>
                </Col>
            </Container>
        </div>
    );
};

TopBar.propTypes = {
    applyFilter: PropTypes.object.isRequired,
};

export default TopBar;
