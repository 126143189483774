import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _find from "lodash/find";
import _isNull from "lodash/isNull";
import _indexOf from "lodash/indexOf";
import I18n from "../../../../utils/i18n";

const UserRoles = ({ roles, userRoles, setFieldValue }) => {
    const onChange = (event, userRole, index) => {
        setFieldValue(`actor[user_attributes][user_roles_attributes][${index}][role_id]`, userRole.role_id);
        setFieldValue(`actor[user_attributes][user_roles_attributes][${index}][_destroy]`, !event.target.checked);
    };

    return _map(roles, (role) => {
        const userRole = _find(userRoles, ["role_id", role.id]) || { id: null, role_id: role.id, _destroy: true };
        const userRoleIndex = _isNull(userRole.id) ? userRoles.length : _indexOf(userRoles, userRole);

        return (
            <Form.Group controlId={`actor_user_attributes_user_roles_attributes_${role.id}_role_id`} key={role.id}>
                <Form.Check
                    type="switch"
                    label={I18n.t(`activerecord.attributes.user.${role.name}`)}
                    name={`actor[user_attributes][user_roles_attributes][${userRoleIndex}][role_id]`}
                    onChange={(event) => onChange(event, userRole, userRoleIndex)}
                    checked={!userRole._destroy}
                />
            </Form.Group>
        );
    });
};

UserRoles.propTypes = {
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            context: PropTypes.string.isRequired,
        })
    ),
    userRoles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            role_id: PropTypes.number,
            _destroy: PropTypes.bool,
        })
    ),
    setFieldValue: PropTypes.func.isRequired,
};

export default UserRoles;
