import React from "react";
import PropTypes from "prop-types";
import _isNaN from "lodash/isNaN";
import I18n from "../../../../utils/i18n";
import FirmwideMirrorChart from "./FirmwideMirrorChart";

const DiverseLeaders = ({ inProgress, canvasRefs, chartOptions, chartData, position }) => (
    <FirmwideMirrorChart
        inProgress={inProgress}
        canvasRefs={canvasRefs}
        chartData={chartData}
        position={position}
        type="diverse_leaders"
        chartType="horizontalBar"
        chartOptions={{
            ...chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: I18n.t("diversity.axises.firmwide_mirror.diverse_leaders.x_axis"),
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
            tooltips: {
                filter: (item) => !_isNaN(item.xLabel),
                callbacks: {
                    label: (tooltipItem, data) =>
                        tooltipItem.value === "NaN"
                            ? ""
                            : `${data.datasets[tooltipItem.datasetIndex].label}: ${parseFloat(tooltipItem.value)
                                  .toFixed(1)
                                  .toLocaleString(I18n.locale)}`,
                },
            },
        }}
    />
);

DiverseLeaders.propTypes = {
    inProgress: PropTypes.bool,
    canvasRefs: PropTypes.object,
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    position: PropTypes.number,
};

export default DiverseLeaders;
