import {
    AverageYearsBeforeExitChart,
    AverageYearsCurrentRoleChart,
    CountByPracticeAreaChart,
    ComingAndGoingChart,
    DiverseLeaders,
    DiverseScore,
    EligibleAndPromotedChart,
    EmployeesAgainstAverageChart,
    EmployeesHiredChart,
    RelationshipAllocatedChart,
    ScoreByPracticeAreaChart,
} from "../pages/DiversityFirmwideMirrorPage/components/charts";

const FirmwideMirrorCharts = [
    [
        { type: "employees_hired", component: EmployeesHiredChart, position: 1 },
        { type: "employees_against_average", component: EmployeesAgainstAverageChart, position: 2 },
    ],
    [
        { type: "average_years_current_role", component: AverageYearsCurrentRoleChart, position: 3 },
        { type: "eligible_and_promoted", component: EligibleAndPromotedChart, position: 4 },
    ],
    [
        { type: "relationship_allocated", component: RelationshipAllocatedChart, position: 5 },
        { type: "diverse_leaders", component: DiverseLeaders, position: 6 },
    ],
    [
        { type: "coming_and_going", component: ComingAndGoingChart, position: 7 },
        { type: "average_years_before_exit", component: AverageYearsBeforeExitChart, position: 8 },
    ],
    [
        { type: "score_by_practice_area", component: ScoreByPracticeAreaChart, position: 9 },
        { type: "diverse_score", component: DiverseScore, position: 10 },
    ],
    [{ type: "count_by_practice_area", component: CountByPracticeAreaChart, position: 11 }],
];
export default FirmwideMirrorCharts;
