import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import _each from "lodash/each";
import _isEqual from "lodash/isEqual";
import * as Yup from "yup";
import moment from "moment/moment";
import Table from "../../../common/components/Table";
import I18n from "../../../utils/i18n";
import { getLogs } from "../../../api/activityApi";
import PageSizeSelector from "../../../common/components/PageSizeSelector";
import ActivityLogsFilter from "./ActivityLogsFilter";

const RecordTypeCell = ({
    row: {
        original: { record_type },
    },
}) => <div className="d-flex flex-column">{I18n.t(`activerecord.models.${record_type}.other`)}</div>;

const ActionCell = ({
    row: {
        original: { action, record_type },
    },
}) => (
    <div className="d-flex flex-column">{`${I18n.t(`activerecord.models.${record_type}.one`)} ${I18n.t(
        `activerecord.attributes.activity/log.actions.${action}`,
    )}`}</div>
);

const SourceCell = ({
    row: {
        original: { source },
    },
}) => <div className="d-flex flex-column">{I18n.t(`activerecord.attributes.activity/log.sources.${source}`)}</div>;

const ActorNameCell = ({
    row: {
        original: { actor_name, supporter_user_name },
    },
}) => (
    <div className="d-flex flex-column">
        {actor_name}
        <small className="text-muted">{supporter_user_name}</small>
    </div>
);

const CreatedAtCell = ({
    row: {
        original: { created_at },
    },
}) => <div className="d-flex flex-column">{I18n.l("time.formats.long", created_at)}</div>;

const ActivityLogsTable = () => {
    const [pageSize, setPageSize] = useState(10);

    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.activity/log.record_type"),
                id: "record_type",
                isSorted: false,
                Cell: RecordTypeCell,
            },
            {
                Header: I18n.t("activerecord.attributes.activity/log.action"),
                id: "action",
                isSorted: false,
                Cell: ActionCell,
            },
            {
                Header: I18n.t("activerecord.attributes.activity/log.source"),
                id: "source",
                isSorted: false,
                Cell: SourceCell,
            },
            {
                Header: I18n.t("activerecord.attributes.activity/log.record_name"),
                accessor: "record_name",
            },
            {
                Header: I18n.t("activerecord.attributes.activity/log.actor_name"),
                accessor: "actor_name",
                Cell: ActorNameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.activity/log.created_at"),
                accessor: "created_at",
                Cell: CreatedAtCell,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);
    const [filterQuery, setFilterQuery] = useState({});
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        ({ sortBy, pageIndex, pageSize }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || { id: "created_at", desc: true };
            setLoading(true);

            getLogs(pageIndex + 1, pageSize, sort.id, sort.desc ? "desc" : "asc", filterQuery).then((response) => {
                if (fetchId === fetchIdRef.current) {
                    setData(response.data.records);
                    setPageCount(response.data.page_count);
                    setLoading(false);
                }
            });
        },
        [filterQuery],
    );

    const getDirtyFiltersCount = (initialValues, values) => {
        let count = 0;

        _each(values, (value, key) => {
            if (!_isEqual(value, initialValues[key])) {
                count++;
            }
        });

        return count;
    };

    const initialFilterValues = useMemo(
        () => ({
            q: {
                actor_name: "",
                record_name: "",
                created_at_from: null,
                created_at_to: null,
                source: [],
                description: [],
            },
        }),
        [],
    );

    const validationSchema = Yup.object().shape({
        q: Yup.object().shape({
            created_at_from: Yup.date()
                .min(moment({ year: 1900 }).startOf("day"))
                .nullable()
                .when("created_at_to", (date, schema) => (date ? schema.max(date) : schema.max(moment().endOf("day")))),
            created_at_to: Yup.date()
                .min(moment({ year: 1900 }).startOf("day"))
                .max(moment().endOf("day"))
                .nullable(),
        }),
    });

    return (
        <>
            <Row>
                <Col>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialFilterValues}
                        onSubmit={(values) => setFilterQuery(values.q)}
                        validationSchema={validationSchema}
                    >
                        {({ values, handleChange, handleSubmit, handleReset }) => (
                            <>
                                <Row className="justify-content-between">
                                    <Col />
                                    <Col md={2}>
                                        <PageSizeSelector onChange={setPageSize} currentSize={pageSize} />
                                    </Col>
                                    <Col className="text-right">
                                        <Button
                                            type="reset"
                                            variant="light"
                                            className="mr-2"
                                            onClick={() => {
                                                setOpenFilter(false);
                                                handleReset();
                                                handleSubmit();
                                            }}
                                        >
                                            {I18n.t("common.clear")}
                                            <Badge variant="primary" className="text-white mx-1">
                                                {getDirtyFiltersCount(initialFilterValues.q, values.q)}
                                            </Badge>
                                        </Button>
                                        {openFilter && (
                                            <Button
                                                type="reset"
                                                variant="light"
                                                className="mr-2"
                                                onClick={() => {
                                                    setOpenFilter(false);
                                                    handleReset();
                                                }}
                                            >
                                                {I18n.t("common.links.cancel")}
                                            </Button>
                                        )}
                                        {!openFilter && (
                                            <Button
                                                variant="primary"
                                                className="text-white"
                                                onClick={() => {
                                                    setOpenFilter(true);
                                                }}
                                                aria-controls="collapse-filter"
                                                aria-expanded={openFilter}
                                            >
                                                {I18n.t("common.links.filter")}
                                            </Button>
                                        )}
                                        {openFilter && (
                                            <Button
                                                variant="primary"
                                                className="text-white"
                                                onClick={() => {
                                                    setOpenFilter(false);
                                                    handleSubmit(values);
                                                }}
                                            >
                                                {I18n.t("common.links.apply")}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ActivityLogsFilter open={openFilter} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        pageCount={pageCount}
                        loading={loading}
                        pageSize={pageSize}
                        initialSortBy={[{ id: "created_at", desc: true }]}
                        globalFilters={filterQuery}
                    />
                </Col>
            </Row>
        </>
    );
};

RecordTypeCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            record_type: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

ActionCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            record_type: PropTypes.string.isRequired,
            action: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

SourceCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            source: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

ActorNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            actor_name: PropTypes.string.isRequired,
            supporter_user_name: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

CreatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default ActivityLogsTable;
