import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import DiversityClientSpecificMarketingPage from "../DiversityClientSpecificMarketingPage";
import {
    getClientSpecificFilters,
    newClientSpecificFirm,
    createClientSpecificFirm,
    updateClientSpecificCharts,
} from "../../api/diversityApi";
import DiversityClientSpecificFiltersBar from "./DiversityClientSpecificFiltersBar";
import AddFirmModal from "./components/AddFirmModal";
import ExportButton from "../../common/components/DiversityPage/ExportButton";
import I18n from "../../utils/i18n";
import ClientSpecificCharts from "../../utils/clientSpecificCharts";
import ViewDashboardPage from "../../common/components/DashboardPage/ViewDashboardPage";

const DiversityClientSpecificPage = () => {
    const [addFirmModalShow, setAddFirmModalShow] = useState(false);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const [chartParams, setChartParams] = useState({});
    const [filter, setFilter] = useState({});
    const [changedFilters, setChangedFilters] = useState({});
    const canvasRefs = useRef({});

    if (!currentActor.organization.permissions.diversity_client_viewer_permission) {
        return <DiversityClientSpecificMarketingPage />;
    }

    return (
        <div id="client-specific-dashboard">
            <div className="top-bar">
                <Container fluid className="py-3">
                    <Row>
                        <Col className="mr-auto">
                            {(currentActor.super_manager || currentActor.diversity_manager) && (
                                <>
                                    <Button
                                        variant="warning"
                                        className="text-light m-1"
                                        onClick={() => setAddFirmModalShow(true)}
                                    >
                                        {I18n.t("diversity.buttons.add_firm")}
                                    </Button>

                                    <AddFirmModal
                                        show={addFirmModalShow}
                                        onHide={() => setAddFirmModalShow(false)}
                                        newFirm={newClientSpecificFirm}
                                        createFirm={createClientSpecificFirm}
                                    />
                                </>
                            )}
                            {!_isEmpty(filter) && !_isEmpty(chartParams) && (
                                <ExportButton
                                    canvasRefs={canvasRefs}
                                    title="sidebar.links.diversity.client_specific_dashboard"
                                    filter={filter}
                                    chartParams={chartParams}
                                    changedFilters={changedFilters}
                                />
                            )}
                        </Col>
                        <Col className="col-auto">
                            <DiversityClientSpecificFiltersBar
                                setChartParams={setChartParams}
                                getFilters={getClientSpecificFilters}
                                setFilter={setFilter}
                                setChangedFilters={setChangedFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="scrollable bg-white">
                <div className="overflow-auto">
                    <ViewDashboardPage
                        currentActor={currentActor}
                        canvasRefs={canvasRefs}
                        chartParams={chartParams}
                        chartsRoute={ClientSpecificCharts}
                        updateCharts={updateClientSpecificCharts}
                        channelName="Diversity::ClientSpecific::ChartsChannel"
                        namespace="client_specific"
                    />
                </div>
            </div>
        </div>
    );
};

export default DiversityClientSpecificPage;
