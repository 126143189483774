import React, { useState, useCallback, useMemo, useRef } from "react";
import _each from "lodash/each";
import Select from "react-select";
import PropTypes from "prop-types";
import I18n from "../../../../../utils/i18n";
import Table from "../../../../../common/components/Table";
import { getPreview } from "../../../../../api/listsApi";

const ActorName = (actor) =>
    actor ? `${actor.name} (${actor.email})` : I18n.t("companies.lists.sections.export.common.not_available");

const ActorSelect = ({ organization, setFieldValue }) => (
    <Select
        onChange={(option) => {
            setFieldValue(`organizations.${organization.uid}`, {
                email: option.email,
                company_name: organization.name,
            });
        }}
        options={organization.actors}
        getOptionLabel={(option) => ActorName(option)}
        getOptionValue={(option) => option.email}
        defaultValue={organization.actors[0]}
    />
);

ActorSelect.propTypes = {
    organization: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        actors: PropTypes.array.isRequired,
    }),
    setFieldValue: PropTypes.func.isRequired,
};

const ActorCell = ({ row: { original }, setFieldValue }) => (
    <div className="custom-input d-flex flex-column  w-100 position-relative">
        {original.actors.length > 1 ? (
            <ActorSelect setFieldValue={setFieldValue} organization={original} />
        ) : (
            ActorName(original.actors[0])
        )}
    </div>
);

ActorCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            actors: PropTypes.array.isRequired,
        }),
    }),
    setFieldValue: PropTypes.func.isRequired,
};

const PreviewTable = ({ setFieldValue, organizationIds }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.models.organization"),
                accessor: "name",
            },
            {
                Header: "User",
                Cell: (props) => <ActorCell {...props} setFieldValue={setFieldValue} />,
            },
        ],
        []
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);

        getPreview({ organizations: organizationIds }).then(({ data }) => {
            if (fetchId === fetchIdRef.current) {
                setData(data);
                _each(data, (organization) =>
                    setFieldValue(`organizations.${organization.uid}`, {
                        email: organization.actors[0]?.email,
                        company_name: organization.name,
                    })
                );
                setLoading(false);
            }
        });
    }, []);

    return <Table columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={0} />;
};

PreviewTable.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    organizationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PreviewTable;
