import {
    ScoreVsPeerAverageChart,
    KpiGoalsChart,
    DiversityOfHoursChart,
    DiversityOfFeesChart,
    CreditByAttributeChart,
    MatterLeadRolesByAttributeChart,
    GoalStrengthsTable,
    GoalImprovesTable,
} from "../pages/DiversityClientSpecificReportsPage/components/charts";

const ClientSpecificReportsCharts = [
    [
        { type: "score_vs_peer_average", component: ScoreVsPeerAverageChart, position: 1 },
        { type: "kpi_goals", component: KpiGoalsChart, position: 2 },
    ],
    [
        { type: "diversity_of_hours", component: DiversityOfHoursChart, position: 3 },
        { type: "diversity_of_fees", component: DiversityOfFeesChart, position: 4 },
    ],
    [
        { type: "credit_by_attribute", component: CreditByAttributeChart, position: 5 },
        { type: "matter_lead_roles_by_attribute", component: MatterLeadRolesByAttributeChart, position: 6 },
    ],
    [
        { type: "goal_strengths", component: GoalStrengthsTable, position: 7 },
        { type: "goal_improves", component: GoalImprovesTable, position: 8 },
    ],
];
export default ClientSpecificReportsCharts;
