import API from "../utils/api";

export function requestResetPassword(params) {
    return API.post("/users/password", params);
}

export function resetPassword(params) {
    return API.patch("/users/password", params);
}

export function unlockUser(params) {
    return API.post("/users/unlock", params);
}

export function startUnlockingUser(params) {
    return API.get("/unlock", { params });
}

export function acceptInvitation(params) {
    return API.patch("/users/invitation", params);
}

export function sendInvitation(params) {
    return API.post("/users/invitation", params);
}

export function renewExpiredPassword(params) {
    return API.patch("/users/password_expired", params);
}

export function twoFactorAuthentication(params) {
    return API.post("/users/two_factor_authentication", params);
}

export function editTwoFactorAuthentication() {
    return API.get("/users/two_factor_authentication/edit");
}

export function updateTwoFactorAuthentication(otpAttempt) {
    return API.patch("/users/two_factor_authentication", { otp_attempt: otpAttempt });
}

export function deleteTwoFactorAuthentication(currentPassword) {
    return API.delete("/users/two_factor_authentication", { data: { current_password: currentPassword } });
}
