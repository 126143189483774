import React from "react";
import { Line } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const Chart = ({ label, totalValue, chartData }) => (
        <Row>
            <Col xs={2}>{label}</Col>
            <Col xs={8}>{chartData ? <Line data={chartData.data} options={chartData.options} /> : ""}</Col>
            <Col xs={2} className="text-right">
                {totalValue}
            </Col>
        </Row>
    );

Chart.propTypes = {
    label: PropTypes.string,
    totalValue: PropTypes.string,
    chartData: PropTypes.object,
};

export default Chart;
