import React, { useEffect, Fragment, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTable, usePagination, useFilters, useSortBy, useExpanded } from "react-table";
import { Table as BootstrapTable } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import sortBy from "lodash/sortBy";
import sortedUniqBy from "lodash/sortedUniqBy";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";
import _map from "lodash/map";
import I18n from "../../../utils/i18n";
import LoadingBody from "./LoadingBody";

export const RemoteSelectFilter = ({ column: { id }, globalFilters, setGlobalFilters, globalFilterOptions }) => {
    const options = useMemo(
        () =>
            [{ label: I18n.t("common.links.all"), value: null }].concat(
                sortBy(
                    _map(globalFilterOptions[id], (value) =>
                        id === "firmwide_name" ? value : { label: value, value: value },
                    ),
                    "value",
                ),
            ),
        [id],
    );

    return (
        <Select
            menuPortalTarget={document.body}
            className="react-select flex-grow-1"
            classNamePrefix="react-select"
            options={options}
            onChange={(option) => setGlobalFilters((prevState) => ({ ...prevState, [id]: option.value }))}
            value={{ label: globalFilters[id] || I18n.t("common.links.all"), value: globalFilters[id] }}
        />
    );
};

RemoteSelectFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        setFilter: PropTypes.func,
        preFilteredRows: PropTypes.array,
        id: PropTypes.string,
    }).isRequired,
    setGlobalFilters: PropTypes.func.isRequired,
    globalFilters: PropTypes.object.isRequired,
    globalFilterOptions: PropTypes.object,
};

export const SelectFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const options = useMemo(
        () =>
            [{ label: I18n.t("common.links.all"), value: null }].concat(
                sortedUniqBy(
                    sortBy(
                        _filter(
                            preFilteredRows.map((row) => ({ label: row.values[id], value: row.values[id] })),
                            (row) => !_isEmpty(row.value) && row.value !== "",
                        ),
                        "value",
                    ),
                    "value",
                ),
            ),
        [id, preFilteredRows],
    );

    return (
        <Select
            menuPortalTarget={document.body}
            className="react-select flex-grow-1"
            classNamePrefix="react-select"
            options={options}
            onChange={(option) => setFilter(option.value)}
            value={{ label: filterValue || I18n.t("common.links.all"), value: filterValue }}
        />
    );
};

SelectFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        setFilter: PropTypes.func.isRequired,
        preFilteredRows: PropTypes.array,
        id: PropTypes.string.isRequired,
    }),
};

const Table = ({
    columns,
    data,
    fetchData,
    updateData,
    loading,
    renderRowSubComponent,
    initialSortBy,
    manualSortBy = true,
    pageCount: controlledPageCount,
    hideTable = false,
    pageSize = 10,
    globalFilters,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        state: { pageIndex, sortBy },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: manualSortBy,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount,
            disableMultiSort: true,
            defaultColumn: React.useMemo(
                () => ({
                    Filter: () => <div />,
                }),
                [],
            ),
            initialState: {
                sortBy: initialSortBy || [],
            },
            updateData,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const didMountRef = useRef(false);

    useEffect(
        () => fetchData({ pageIndex, pageSize, sortBy, initialSortBy, globalFilters }),
        manualSortBy ? [fetchData, pageIndex] : [fetchData],
    );

    useEffect(
        () => {
            if (didMountRef.current) {
                if (pageIndex === 0) {
                    fetchData({ pageIndex, pageSize, sortBy, initialSortBy, globalFilters });
                } else {
                    gotoPage(0);
                }
            }

            didMountRef.current = true;
        },
        manualSortBy ? [sortBy, globalFilters, pageSize] : [pageSize],
    );

    return (
        <div className={classNames("table-wrapper", { "d-none": hideTable })}>
            <BootstrapTable borderless hover {...getTableProps()} className={classNames({ sticky: pageCount < 0 })}>
                <thead className={classNames({ "border-bottom": pageCount >= 0 })}>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                            {headerGroup.headers.map((column, headerColumnIndex) => {
                                let headerJustifyClass = _isEmpty(column.flexClass)
                                    ? null
                                    : `justify-content-${column.flexClass}`;
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={headerColumnIndex}
                                        className={classNames("align-baseline", column.class)}
                                    >
                                        <div
                                            {...column.getSortByToggleProps()}
                                            title={column.title}
                                            className={classNames(
                                                "text-uppercase d-flex align-items-center",
                                                headerJustifyClass,
                                                {
                                                    "justify-content-end":
                                                        _isEmpty(headerJustifyClass) && column.type === "number",
                                                    "justify-content-between":
                                                        _isEmpty(headerJustifyClass) && column.type !== "number",
                                                },
                                            )}
                                        >
                                            {column.render("Header")}
                                            <span className="ml-2">
                                                {column.isSorted &&
                                                    (column.isSortedDesc ? (
                                                        <FontAwesomeIcon icon={faSortDown} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSortUp} />
                                                    ))}
                                            </span>
                                        </div>
                                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="border-bottom">
                    {loading ? (
                        <LoadingBody columnCount={columns.length} />
                    ) : (
                        page.map((row, pageIndex) => {
                            prepareRow(row);
                            return (
                                <Fragment key={pageIndex}>
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, cellId) => (
                                            <td {...cell.getCellProps()} key={cellId} className="align-middle">
                                                <div
                                                    className={classNames("d-flex align-items-center", {
                                                        "justify-content-end": cell.column.type === "number",
                                                    })}
                                                >
                                                    {cell.render("Cell")}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                    {row.isExpanded && renderRowSubComponent && renderRowSubComponent({ row })}
                                </Fragment>
                            );
                        })
                    )}
                </tbody>
            </BootstrapTable>
            {pageCount > 1 && (
                <>
                    <div className="pagination justify-content-center mt-3">
                        <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={({ selected }) => gotoPage(selected)}
                            containerClassName="pagination pagination-sm"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            nextClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            forcePage={pageIndex}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    fetchData: PropTypes.func.isRequired,
    updateData: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    renderRowSubComponent: PropTypes.func,
    pageCount: PropTypes.number.isRequired,
    initialSortBy: PropTypes.array,
    manualSortBy: PropTypes.bool,
    hideTable: PropTypes.bool,
    pageSize: PropTypes.number,
    globalFilters: PropTypes.object,
};

export default Table;
