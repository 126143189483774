import React, { useState } from "react";
import { Col, Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { buildPartnershipsExport } from "../../../../api/tieredDiversityApi";
import I18n from "../../../../utils/i18n";

const ExportButtons = ({ params, isExporting, setIsExporting, showDownloadButton, setShowDownloadButton }) => {
    const [jobId, setJobId] = useState();

    const onSubmit = () => {
        setIsExporting(true);
        buildPartnershipsExport(params).then(({ data }) => setJobId(data.job_id));
    };

    return (
        <Col className="col-auto">
            <Button
                disabled={isExporting}
                variant="warning"
                className="text-white mr-2"
                onClick={() => {
                    setShowDownloadButton(false);
                    onSubmit();
                }}
            >
                {isExporting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
                )}
                {I18n.t("common.links.export")}
            </Button>
            {showDownloadButton && (
                <Button
                    variant="primary"
                    className="text-white"
                    target="_blank"
                    href={`/tiered_diversity/dashboard/partnerships_export.xlsx?job_id=${jobId}`}
                >
                    {I18n.t("common.links.download")}
                </Button>
            )}
        </Col>
    );
};

ExportButtons.propTypes = {
    params: PropTypes.shape({
        page: PropTypes.number,
        column: PropTypes.string,
        order: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
        statuses: PropTypes.array,
    }),
    isExporting: PropTypes.bool.isRequired,
    setIsExporting: PropTypes.func.isRequired,
    showDownloadButton: PropTypes.bool.isRequired,
    setShowDownloadButton: PropTypes.func.isRequired,
};

export default ExportButtons;
