import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import _toString from "lodash/toString";
import DisplayField from "../content/DisplayField";
import I18n from "../../../../utils/i18n";

const CompanyStructure = ({ values }) => (
        <Row>
            <Col>
                <DisplayField title={I18n.t("activerecord.attributes.company_structure.duns")} value={values.duns} />
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.doing_business_as")}
                    value={values.doing_business_as}
                />
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.year_founded")}
                    value={_toString(values.year_founded)}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.legal_status")}
                    value={
                        values.legal_status
                            ? I18n.t(`activerecord.attributes.company_structure.legal_statuses.${values.legal_status}`)
                            : ""
                    }
                />
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.parent_name")}
                    value={values.parent_name}
                />
            </Col>
            <Col>
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.company_type")}
                    value={
                        values.company_type
                            ? I18n.t(`activerecord.attributes.company_structure.company_types.${values.company_type}`)
                            : ""
                    }
                />
                <DisplayField
                    title={I18n.t("activerecord.attributes.company_structure.parent_duns")}
                    value={values.parent_duns}
                />
            </Col>
        </Row>
    );

CompanyStructure.propTypes = {
    values: PropTypes.shape({
        duns: PropTypes.string,
        doing_business_as: PropTypes.string,
        year_founded: PropTypes.number,
        legal_status: PropTypes.string,
        parent_name: PropTypes.string,
        company_type: PropTypes.string,
        parent_duns: PropTypes.string,
    }).isRequired,
};

export default CompanyStructure;
