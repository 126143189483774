import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import I18n from "../../../utils/i18n";
import LoadingBody from "../../../common/components/Table/LoadingBody";
import { calculateTotalScore } from "../../../utils/diversityCalculators";

const TotalScoringTable = ({ keyIndicators, currentGoals, scores, weights, partialFirmwide }) => (
        <Table bordered size="sm" className="diversity-scoring-table text-uppercase">
            <tbody>
                {_.isEmpty(keyIndicators) ? (
                    <LoadingBody columnCount={2} rowCount={2} />
                ) : (
                    <>
                        <tr>
                            <td scope="row">{I18n.t("diversity.columns.jb_score")}</td>
                            <td scope="row">
                                {_.isNil(scores.total_score) ? I18n.t("common.not_available") : scores.total_score}
                            </td>
                        </tr>
                        <tr className="font-weight-bold">
                            <td scope="row">{I18n.t("diversity.columns.total_score")}</td>
                            <td scope="row">
                                {_.isEmpty(currentGoals) || _.isEmpty(scores) || _.isEmpty(weights)
                                    ? I18n.t("common.not_available")
                                    : calculateTotalScore(
                                          keyIndicators,
                                          currentGoals,
                                          scores,
                                          weights,
                                          partialFirmwide
                                      ).toFixed(2)}
                            </td>
                        </tr>
                    </>
                )}
            </tbody>
        </Table>
    );

TotalScoringTable.propTypes = {
    keyIndicators: PropTypes.array,
    currentGoals: PropTypes.object,
    scores: PropTypes.object,
    weights: PropTypes.object,
    partialFirmwide: PropTypes.bool.isRequired,
};

export default TotalScoringTable;
