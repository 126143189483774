import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { fetchCurrentUser } from "../../../state/currentUser/currentUserActions";
import { AppLoader } from "../../components";
import LayoutContainer from "../LayoutContainer";
import LayoutWithSidebarContainer from "../LayoutWithSidebarContainer";
import { fetchCurrentWhitelabel } from "../../../state/currentWhitelabel/currentWhitelabelActions";
import { setAlert } from "../../../state/alert/alertActions";

const ContentContainer = ({ currentUser, fetchCurrentUser, fetchCurrentWhitelabel, setAlert }) => {
    const handleSavedAlert = () => {
        const alert = JSON.parse(localStorage.getItem("alert"));
        alert && setAlert(alert);
    };

    useEffect(() => {
        handleSavedAlert();
        fetchCurrentUser();
        fetchCurrentWhitelabel();
    }, []);

    return (
        <>
            {currentUser.fetching ? (
                <AppLoader />
            ) : isEmpty(currentUser.resource) || isEmpty(currentUser.resource.email) ? (
                <LayoutContainer />
            ) : (
                <LayoutWithSidebarContainer />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    fetchCurrentWhitelabel: () => dispatch(fetchCurrentWhitelabel()),
    setAlert: (alert) => dispatch(setAlert(alert)),
});

ContentContainer.propTypes = {
    currentUser: PropTypes.shape({
        fetching: PropTypes.bool.isRequired,
        resource: PropTypes.object,
    }),
    fetchCurrentUser: PropTypes.func.isRequired,
    fetchCurrentWhitelabel: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
