import React, { useEffect, useState } from "react";
import Steps from "rc-steps";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Formik, getIn } from "formik";
import _isEmpty from "lodash/isEmpty";
import _set from "lodash/set";
import _isNumber from "lodash/isNumber";
import _isEqual from "lodash/isEqual";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _findIndex from "lodash/findIndex";
import _values from "lodash/values";
import _omit from "lodash/omit";
import _filter from "lodash/filter";
import _merge from "lodash/merge";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IndustryStep, UsersStep, FinishStep, CompanyStep } from "./steps/index";
import { getOrganizationForWizard, updateOrganization } from "../../api/organizationApi";
import { wizardCreateActor, wizardUpdateActor } from "../../api/actorApi";
import { createCertificate, updateCertificate } from "../../api/diversityApi";
import { getRoles } from "../../api/roleApi";
import { setAlert } from "../../state/alert/alertActions";
import ConfirmAction from "../../common/components/ConfirmAction";
import { CertificateValidation } from "../../common/components/DiversityPage/CertificateValidationSchema";
import { sanitizeLink, sanitizeText } from "../../utils/sanitizeText";
import I18n from "../../utils/i18n";
import useChannel from "../../utils/channels/useChannel";

const WizardPage = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [organization, setOrganization] = useState({});
    const [certificateId, setCertificateId] = useState(undefined);
    const [showFinishAlert, setShowFinishAlert] = useState({ show: false });
    const [actorRoles, setActorRoles] = useState([]);

    const history = useHistory();
    const whitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const dispatch = useDispatch();
    const industryStepId = 0;
    const companyStepId = 1;
    const userStepId = 2;
    const finishStepId = 3;

    const onReceived = (data) => {
        if (data.actor_uid !== currentActor.uid) {
            switch (data.type) {
                case "new_connection":
                    dispatch(
                        setAlert({
                            text: I18n.t("wizard.new_connection", { email: data.email }),
                            variant: "warning",
                        }),
                    );
                    break;
                case "finish": {
                    setShowFinishAlert({ show: true, email: data.email });
                    break;
                }
            }
        }
    };

    useChannel({
        channelParams: { channel: "WizardChannel", uid: currentActor.organization.uid },
        onReceived: onReceived,
    });

    useEffect(() => {
        getOrganizationForWizard()
            .then((response) => {
                setOrganization(response.data);
                setCertificateId(response.data.diversity_certificate?.id);
            })
            .catch(() => history.push("/"));
        getRoles().then((response) => {
            setActorRoles([..._filter(response.data, ["context", "actor"])]);
        });
    }, []);

    const initialValues = () => ({
        organization: {
            lock_version: organization.lock_version,
            industry_relations_attributes: organization.industry_relations_attributes.length
                ? organization.industry_relations_attributes
                : [{ primary: true, industry_id: undefined }],
            ownership_attributes: organization.ownership_attributes,
            showOwnership:
                !_isEmpty(organization.diversity_certificate) || _includes(organization.ownership_attributes, true),
        },
        diversity_certificate: organization.diversity_certificate,
        actors: [],
        showCertificate: !_isEmpty(organization.diversity_certificate),
    });

    const validationSchema = Yup.object().shape({
        diversity_certificate: Yup.object().when("showCertificate", {
            is: true,
            then: CertificateValidation.test(
                "fill_certificate",
                I18n.t("wizard.steps.company.certificate_error"),
                function (certificate) {
                    if (_includes(_values(certificate), undefined)) {
                        return this.createError({ path: "showCertificate" });
                    }

                    return true;
                },
            ),
        }),

        organization: Yup.object().shape({
            industry_relations_attributes: Yup.array()
                .of(
                    Yup.object().shape({
                        industry_id: Yup.string().required(),
                    }),
                )
                .uniqueProperty("industry_id", I18n.t("errors.messages.taken"))
                .test("at_least_one_primary", I18n.t("errors.messages.blank"), (items) =>
                    items.some((item) => item.primary === true),
                ),
            ownership_attributes: Yup.object()
                .shape({
                    ethnicity: Yup.string().when("minority_owned", {
                        is: true,
                        then: Yup.string().typeError(I18n.t("errors.messages.cant_be_blank")),
                        otherwise: Yup.string().nullable(),
                    }),
                })
                .when("showOwnership", {
                    is: true,
                    then: Yup.object().test(
                        "selectSomething",
                        I18n.t("wizard.steps.company.global_error"),
                        function (company_ownership) {
                            return (
                                _includes(_values(company_ownership), true) ||
                                this.createError({ path: "organization.showOwnership" })
                            );
                        },
                    ),
                }),
        }),
        actors: Yup.array()
            .of(
                Yup.object().shape({
                    uid: Yup.string().nullable(),
                    role_ids: Yup.array().of(Yup.number().nullable()),
                    user_attributes: Yup.object().shape({
                        email: Yup.string().email().max(255).required(),
                        first_name: Yup.string().max(255).required(),
                        last_name: Yup.string().max(255).required(),
                    }),
                }),
            )
            .uniqueProperty("user_attributes.email", I18n.t("activerecord.errors.models.user.attributes.email.taken")),
    });

    const onSubmit = (values, setErrors, setFieldValue) => {
        const commonValues = { organization: { lock_version: organization.lock_version } };

        switch (currentStep) {
            case industryStepId:
                !_isEqual(
                    values.organization.industry_relations_attributes,
                    organization.industry_relations_attributes,
                ) &&
                    updateOrganization(
                        organization?.uid,
                        _set(
                            commonValues,
                            "organization[industry_relations_attributes]",
                            values.organization.industry_relations_attributes,
                        ),
                    ).then(({ data }) => {
                        setFieldValue("organization.industry_relations_attributes", data.industry_relations_attributes);
                        setOrganization((prev) => ({
                            ...prev,
                            industry_relations_attributes: data.industry_relations_attributes,
                        }));
                    });
                setCurrentStep((prevState) => prevState + 1);
                break;
            case companyStepId:
                var company =
                    !_isEqual(values.organization.ownership_attributes, organization.ownership_attributes) &&
                    Promise.resolve(
                        updateOrganization(
                            organization?.uid,
                            _set(
                                commonValues,
                                "organization[ownership_attributes]",
                                values.organization.ownership_attributes,
                            ),
                        ).then((response) =>
                            setOrganization((prev) => ({
                                ...prev,
                                lock_version: response.data.lock_version,
                                ownership_attributes: values.organization.ownership_attributes,
                            })),
                        ),
                    );

                var certificate =
                    values.showCertificate &&
                    !_isEqual(values.diversity_certificate, organization.diversity_certificate) &&
                    Promise.resolve(
                        _isNumber(certificateId)
                            ? updateCertificate(certificateId, organization?.uid, {
                                  diversity_certificate: {
                                      expiration_date: values.diversity_certificate.expiration_date,
                                      origin: values.diversity_certificate.origin,
                                  },
                              }).then(({ data }) => {
                                  const ownershipAttributes = _merge(
                                      organization.ownership_attributes,
                                      data.ownership_attributes,
                                  );

                                  setOrganization((prev) => ({
                                      ...prev,
                                      lock_version: data.lock_version,
                                      diversity_certificate: values.diversity_certificate,
                                      organization: {
                                          ...prev.organization,
                                          ownership_attributes: ownershipAttributes,
                                      },
                                  }));
                              })
                            : createCertificate({
                                  values: {
                                      uid: organization?.uid,
                                      diversity_certificate: values.diversity_certificate,
                                  },
                              }).then((response) => {
                                  const ownershipAttributes = _merge(
                                      organization.ownership_attributes,
                                      response.data?.ownership_attributes,
                                  );

                                  setOrganization((prev) => ({
                                      ...prev,
                                      organization: {
                                          ...prev.organization,
                                          ownership_attributes: ownershipAttributes,
                                      },
                                  }));
                                  setCertificateId(response.data?.id);
                              }),
                    );

                Promise.all([company, certificate])
                    .then(() => setCurrentStep((prevState) => prevState + 1))
                    .catch(({ response }) => setErrors(response.data.errors));
                break;
            case userStepId:
                _forEach(values.actors, (actor) => {
                    const actorParams = { ..._omit(actor, ["super_manager", "roles", "uid"]) };
                    _isEmpty(actor.uid)
                        ? wizardCreateActor({ values: actorParams, organization_uid: organization.uid })
                        : wizardUpdateActor(actor.uid, { actor: actorParams });
                });
                setFieldValue("actors", []);
                setCurrentStep((prevState) => prevState + 1);
                break;
        }
    };

    const disabledNext = (errors, values, status) => {
        switch (currentStep) {
            case industryStepId:
                return (
                    _findIndex(
                        values.organization.industry_relations_attributes,
                        (industry) => !industry._destroy && industry.primary && _isNumber(industry.industry_id),
                    ) < 0 || getIn(errors, "organization.industry_relations_attributes")?.length
                );
            case companyStepId:
                return (
                    getIn(errors, "organization.showOwnership") ||
                    !_isEmpty(errors?.organization?.ownership_attributes) ||
                    getIn(errors, "showCertificate") ||
                    (values.showCertificate && !_isEmpty(getIn(errors, "diversity_certificate")))
                );
            case userStepId:
                return !(_isEmpty(getIn(errors, "actors")) && _isEmpty(status.invalidActors));
        }
    };

    const returnAfterFinish = () => {
        location.replace(`/organizations/${currentActor.organization.uid}`);
    };

    return (
        <>
            {!_isEmpty(organization) && (
                <Formik
                    initialValues={initialValues()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    initialStatus={{ invalidActors: [] }}
                >
                    {({ values, status, errors, setErrors, setFieldValue }) => (
                        <Container fluid className="d-flex flex-column content-with-navbar overflow-auto">
                            <Row className="vh-100">
                                <Col sm={2}>
                                    <div className="d-flex align-items-start flex-column h-100">
                                        <div className="mt-3 pt-3">
                                            <h2>{I18n.t("wizard.title")}</h2>
                                        </div>
                                        <div className="h-100 fill-vertical-height">
                                            <Steps
                                                direction="vertical"
                                                current={currentStep}
                                                className="pt-5"
                                                items={[
                                                    {
                                                        title: I18n.t("activerecord.models.industry.one"),
                                                    },
                                                    {
                                                        title: I18n.t("wizard.steps.company.title"),
                                                    },
                                                    {
                                                        title: I18n.t("forms.organization.edit.tabs.users"),
                                                    },
                                                    {
                                                        title: I18n.t("wizard.steps.finish.title"),
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div
                                            className="mt-5"
                                            dangerouslySetInnerHTML={sanitizeText(I18n.t("wizard.text"))}
                                        />
                                        <div
                                            className="align-self-end mb-3 mt-5"
                                            dangerouslySetInnerHTML={sanitizeLink(
                                                I18n.t("wizard.help_link", {
                                                    whitelabel_mail_to: whitelabel.mail_to,
                                                }),
                                            )}
                                        />
                                    </div>
                                </Col>
                                <Col sm={10} className="bg-white overflow-auto">
                                    <div className="d-flex justify-content-between flex-column h-100">
                                        {currentStep === industryStepId && (
                                            <IndustryStep whitelabel={whitelabel.name} />
                                        )}
                                        {currentStep === companyStepId && (
                                            <CompanyStep certificateId={certificateId} uid={organization.uid} />
                                        )}
                                        {currentStep === userStepId && (
                                            <UsersStep organizationUid={organization.uid} actorRoles={actorRoles} />
                                        )}
                                        {currentStep === finishStepId && <FinishStep />}

                                        <div className="align-self-end p-2">
                                            {!_includes([industryStepId, finishStepId], currentStep) && (
                                                <Button
                                                    variant="outline-secondary"
                                                    className="mr-1"
                                                    onClick={() => setCurrentStep((prevState) => prevState - 1)}
                                                >
                                                    {I18n.t("common.links.previous")}
                                                </Button>
                                            )}
                                            {currentStep !== finishStepId ? (
                                                <Button
                                                    variant="primary"
                                                    className="text-white ml-1"
                                                    onClick={() => onSubmit(values, setErrors, setFieldValue)}
                                                    disabled={disabledNext(errors, values, status)}
                                                >
                                                    {I18n.t("common.links.next")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="primary"
                                                    className="text-white ml-1"
                                                    onClick={() => location.replace("/")}
                                                >
                                                    {I18n.t("wizard.steps.finish.title")}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Formik>
            )}
            <ConfirmAction
                hideCancel
                confirm="common.links.text_yes"
                title={I18n.t("wizard.steps.finish.title")}
                text={I18n.t("wizard.finish_by_other", { email: showFinishAlert?.email })}
                onSubmit={returnAfterFinish}
                onHide={returnAfterFinish}
                show={showFinishAlert.show}
            />
        </>
    );
};

export default WizardPage;
