import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import _get from "lodash/get";
import I18n from "../../../../../utils/i18n";
import { sendReportCertificateIssue } from "../../../../../api/diversityApi";
import { setAlert } from "../../../../../state/alert/alertActions";

const CertificateReportIssueModal = ({ show, onHide, setShowModal, certificateId }) => {
    const dispatch = useDispatch();
    const whitelabelName = useSelector((state) => state.currentWhitelabel.resource.name);
    const onSubmit = (values, { setSubmitting, resetForm }) => {
        sendReportCertificateIssue(values)
            .then(() => {
                dispatch(
                    setAlert({
                        translationKey: "diversity.certificates.mailer.report_issue.successfully_sent",
                        variant: "info",
                    }),
                );

                resetForm();
                setSubmitting(false);
                setShowModal(false);
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(
                    setAlert({
                        translationKey: "diversity.certificates.mailer.report_issue.sending_failed",
                        variant: "danger",
                    }),
                );
            });
    };
    const validationSchema = Yup.object().shape({
        report_issue: Yup.object().shape({
            message: Yup.string().max(5000).required(),
        }),
    });

    return (
        <Formik
            initialValues={{
                report_issue: { message: "", certificate_id: certificateId },
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, handleChange, handleReset, handleSubmit, isSubmitting }) => (
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={() => {
                        handleReset();
                        onHide();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{I18n.t("diversity.certificates.report_issue_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    {I18n.t("diversity.certificates.report_issue_modal.label", {
                                        whitelabel: whitelabelName,
                                    })}
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="report_issue[message]"
                                    placeholder={I18n.t("diversity.certificates.report_issue_modal.placeholder")}
                                    onChange={handleChange}
                                    value={_get(values, "report_issue[message]")}
                                    isInvalid={_get(errors, "report_issue[message]")}
                                    className="min-height-150"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {_get(errors, "report_issue[message]")}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                handleReset();
                                onHide();
                            }}
                        >
                            {I18n.t("diversity.certificates.report_issue_modal.cancel")}
                        </Button>
                        <Button variant="primary" className="text-white" disabled={isSubmitting} onClick={handleSubmit}>
                            {isSubmitting && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2"
                                />
                            )}
                            {I18n.t("diversity.certificates.report_issue_modal.send")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
};

CertificateReportIssueModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    certificateId: PropTypes.number.isRequired,
};

export default CertificateReportIssueModal;
