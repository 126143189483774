import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { Container, Form } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { useFormikContext } from "formik";
import I18n from "../../../../utils/i18n";
import { getWhitelabelsByTerm } from "../../../../api/searchApi";

const SettingsTab = ({ enableWhitelabel, errors }) => {
    const { values, handleChange, setFieldValue } = useFormikContext();

    const currentUser = useSelector((state) => state.currentUser.resource);
    const [whitelabels, setWhitelabels] = useState([]);

    useEffect(() => {
        values.organization.whitelabel &&
            enableWhitelabel &&
            getWhitelabelsByTerm().then((response) => setWhitelabels(response.data));
    }, []);

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <Form.Group controlId="organization_email_from">
                <Form.Label>{I18n.t("activerecord.attributes.organization.email_from")}</Form.Label>
                <Form.Control
                    type="text"
                    name="organization[email_from]"
                    onChange={handleChange}
                    value={values.organization.email_from || ""}
                    isInvalid={errors.email_from}
                />
                <Form.Control.Feedback type="invalid">{errors.email_from}</Form.Control.Feedback>
            </Form.Group>

            {values.organization.whitelabel &&
                (enableWhitelabel ? (
                    <Form.Group controlId="organization_whitelabel_id">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.whitelabel_id")}</Form.Label>
                        <Select
                            name="whitelabel_id"
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder={I18n.t("common.placeholders.search")}
                            options={whitelabels}
                            onChange={(option) => {
                                setFieldValue("whitelabel_id", option.value);
                                setFieldValue("whitelabel", option.label);
                            }}
                            value={whitelabels.find((whitelabel) => whitelabel.value === values.whitelabel_id)}
                        />
                    </Form.Group>
                ) : (
                    <Form.Group controlId="organization_whitelabel_id">
                        <Form.Label>{I18n.t("activerecord.attributes.organization.whitelabel_id")}</Form.Label>
                        <Form.Control type="text" disabled={true} value={values.organization.whitelabel} />
                    </Form.Group>
                ))}

            {!_isEmpty(values.organization.diversity_firm_attributes) && (
                <Form.Group controlId="organization_diversity_firm_attributes_partial_firmwide">
                    <Form.Label>{I18n.t("activerecord.attributes.diversity/firm.partial_firmwide")}</Form.Label>
                    <Form.Check
                        type="switch"
                        name="organization[diversity_firm_attributes][partial_firmwide]"
                        onChange={handleChange}
                        checked={values.organization.diversity_firm_attributes.partial_firmwide}
                    />
                </Form.Group>
            )}

            {(currentUser.superadmin || currentUser.admin) && (
                <Form.Group controlId="organization_searchable">
                    <Form.Label>{I18n.t("activerecord.attributes.organization.searchable")}</Form.Label>
                    <Form.Check
                        type="switch"
                        name="organization[searchable]"
                        onChange={(event) => setFieldValue("organization[searchable]", !event.target.checked)}
                        checked={!values.organization.searchable}
                    />
                </Form.Group>
            )}
        </Container>
    );
};

SettingsTab.propTypes = {
    errors: PropTypes.object.isRequired,
    enableWhitelabel: PropTypes.bool,
};

export default SettingsTab;
