import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faEnvelope, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import _isUndefined from "lodash/isUndefined";
import _filter from "lodash/filter";
import I18n from "../../../../utils/i18n";
import { getOrganizationUsers } from "../../../../api/organizationApi";
import Table from "../../Table";
import { CurrentSignInAtCell, NameWithAvatarCell, RoleName } from "../../Table/cells";
import ThreeDotsToggle from "../../Table/ThreeDotsToggle";
import { resentInvitation } from "../../../../api/actorApi";
import DeactivateActor from "../../DeactivateActor";

const ActionsCell = ({ row: { original }, organizationUid, data, setReloadTable }) => {
    const [modalShow, setModalShow] = useState(false);
    const currentActor = useSelector((state) => state.currentActor.resource);
    const currentUser = useSelector((state) => state.currentUser.resource);
    const ableToManage = currentUser.superadmin || currentUser.admin || currentActor.super_manager;
    const actors = _filter(data, (actor) => actor.id !== original.id);

    if (ableToManage) {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={ThreeDotsToggle} />
                    <Dropdown.Menu size="sm" title="">
                        <>
                            <Dropdown.Item
                                as={Link}
                                to={{
                                    pathname: `/organizations/${organizationUid}/users/${original.uid}/edit`,
                                    state: { prevPath: `/organizations/${organizationUid}/users` },
                                }}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                {I18n.t("common.links.edit")}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} onClick={() => resentInvitation(original.uid)} to="#">
                                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                {I18n.t("tables.users.resend_invitation")}
                            </Dropdown.Item>
                        </>
                        {original.can_deactivate ? (
                            <Dropdown.Item as={Link} onClick={() => setModalShow(true)} to="#">
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                {I18n.t("common.links.delete")}
                            </Dropdown.Item>
                        ) : (
                            <></>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <DeactivateActor
                    currentActor={original}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    actors={actors}
                    setReloadTable={setReloadTable}
                />
            </>
        );
    } else return <></>;
};

const UserStatisticsCell = ({ row: { original } }) => (
    <div className="d-flex flex-column text-right">
        {I18n.t("tables.users.hosts_count", { count: original.count_host_event })}
        <small className="text-muted">
            {I18n.t("tables.users.bidders_count", { count: original.count_bidder_event })}
        </small>
    </div>
);

const OtpRequiredForLoginCell = ({ row: { original } }) =>
    original.otp_required_for_login && <FontAwesomeIcon icon={faCheck} className="mr-2" />;

const UsersTable = ({ organizationUid }) => {
    const [data, setData] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("tables.users.user"),
                accessor: "name",
                Cell: NameWithAvatarCell,
            },
            {
                Header: I18n.t("tables.users.role"),
                accessor: "role_name",
                Cell: RoleName,
            },
            {
                Header: I18n.t("tables.users.statistics"),
                id: "count_host_event",
                type: "number",
                isSorted: false,
                Cell: UserStatisticsCell,
            },
            {
                Header: I18n.t("activerecord.attributes.user.otp_required_for_login"),
                accessor: "otp_required_for_login",
                Cell: OtpRequiredForLoginCell,
            },
            {
                Header: I18n.t("activerecord.attributes.user.current_sign_in_at"),
                accessor: "current_sign_in_at",
                Cell: CurrentSignInAtCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => (
                    <ActionsCell
                        {...props}
                        organizationUid={organizationUid}
                        data={data}
                        setReloadTable={setReloadTable}
                    />
                ),
            },
        ],
        [data.length],
    );
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || { id: "id", desc: false };

            if (_isUndefined(organizationUid)) {
                setData([]);
                setPageCount(0);
            } else {
                setLoading(true);

                getOrganizationUsers(organizationUid, pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then(
                    (response) => {
                        if (fetchId === fetchIdRef.current) {
                            setData(response.data.records);
                            setPageCount(response.data.page_count);
                            setLoading(false);
                        }
                    },
                );
            }
        },
        [reloadTable],
    );

    return <Table columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />;
};

UsersTable.propTypes = {
    ableToManage: PropTypes.bool,
    organizationUid: PropTypes.string,
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            uid: PropTypes.string.isRequired,
            can_deactivate: PropTypes.bool,
        }),
    }),
    organizationUid: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
    setReloadTable: PropTypes.func.isRequired,
};

UserStatisticsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            count_host_event: PropTypes.number,
            count_bidder_event: PropTypes.number,
        }),
    }),
};

OtpRequiredForLoginCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            otp_required_for_login: PropTypes.bool,
        }),
    }),
};

export default UsersTable;
