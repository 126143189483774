import React from "react";
import { Container } from "react-bootstrap";
import DashboardForm from "../../common/components/DashboardPage/DashboardForm";
import I18n from "../../utils/i18n";
import { getClientSpecificMirrorFilters } from "../../api/diversityApi";
import ClientSpecificMirrorCharts from "../../utils/clientSpecificMirrorCharts";

const DiversityClientSpecificMirrorDashboardPage = () => (
    <div id="client-specific-dashboard">
        <div className="top-bar">
            <Container fluid className="py-3" />
        </div>

        <DashboardForm
            backlink="/diversity/dashboards/client_specific_mirror"
            chartsRow={ClientSpecificMirrorCharts}
            namespace="diversity/client_specific_mirror"
            description={I18n.t("diversity.charts.client_specific_mirror_dashboard.description")}
            getFilters={getClientSpecificMirrorFilters}
            channelName="Diversity::ClientSpecificMirror::ChartsChannel"
        />
    </div>
);

export default DiversityClientSpecificMirrorDashboardPage;
