import React from "react";
import PropTypes from "prop-types";

const Avatar = ({ preview, size, name }) => {
    const initials = () => name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

    return preview ? (
        <div className={`avatar avatar-round ${size}`}>
            <div className="avatar-image avatar-image-round" style={{ backgroundImage: `url("${preview}")` }} />
        </div>
    ) : (
        <div className={`avatar avatar-round ${size}`}>
            <div className="avatar-letters">{initials() ? initials().join("") : " "}</div>
        </div>
    );
};

Avatar.propTypes = {
    preview: PropTypes.string,
    size: PropTypes.string,
    name: PropTypes.string,
};

export default Avatar;
