import React from "react";
import PropTypes from "prop-types";
import _round from "lodash/round";
import I18n from "../../../../utils/i18n";
import TieredDiversityChart from "../../../../common/components/TieredDiversityChart";
import { getShortLabelForValue } from "../../../../common/components/DiversityPage/ChartComponent";

const Tier1SpendPercentageByCategory = ({ params, canvasRefs, getChart }) => (
        <TieredDiversityChart
            params={params}
            type="tier1_spend_percentage_by_category"
            chartType="bar"
            canvasRefs={canvasRefs}
            getChart={getChart}
            chartOptions={{
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                callback: getShortLabelForValue,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                callback: (value) => `${value} %`,
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let dataLabel = data.labels[tooltipItem.index];
                            let value = _round(
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                2
                            ).toLocaleString(I18n.locale);
                            return `${dataLabel}: ${value}%`;
                        },
                    },
                },
            }}
        />
    );

Tier1SpendPercentageByCategory.propTypes = {
    params: PropTypes.object,
    canvasRefs: PropTypes.object,
    getChart: PropTypes.func.isRequired,
};

export default Tier1SpendPercentageByCategory;
