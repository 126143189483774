import React from "react";
import PropTypes from "prop-types";
import DiversityClientSpecificProgressChart from "./DiversityClientSpecificProgressChart";

const CountOverTimeTable = ({ chartData, chartOptions, inProgress, canvasRefs }) => (
    <DiversityClientSpecificProgressChart
        inProgress={inProgress}
        chartData={chartData}
        chartOptions={chartOptions}
        chartType="table"
        type="count_over_time_table"
        canvasRefs={canvasRefs}
        useCss
    />
);

CountOverTimeTable.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    inProgress: PropTypes.bool.isRequired,
    canvasRefs: PropTypes.object,
};

export default CountOverTimeTable;
