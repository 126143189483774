import React from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _range from "lodash/range";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import I18n from "../../../../utils/i18n";
import ProfileCard from "./ProfileCard";
import ProfileCardLoader from "./ProfileCardLoader";

const ProfilesSection = ({ profilesFetching, profiles, page, pageCount, recordsCount, goToPage, sort, applySort }) => (
    <Card className="border-0">
        <Card.Body>
            <Card.Title>
                <h3>{I18n.t("sidebar.links.companies_profiles.title")}</h3>
            </Card.Title>
            <Card.Body className="p-0">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="text-muted">
                        {I18n.t("companies.profiles.sections.profiles.results_found", { count: recordsCount })}
                    </div>
                    <span>
                        <div className="dropdown">
                            <div
                                className={classNames("rotate-arrow cursor-pointer", {
                                    "opposite-collapsed": true,
                                })}
                                id="sorting-by-dropdown-menu-button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="d-flex align-items-center">
                                    <span className="mr-1">
                                        {I18n.t("companies.profiles.sections.profiles.sort.title")}
                                    </span>
                                    {I18n.t(`companies.profiles.sections.profiles.sort.columns.${sort.column}`)}
                                    <FontAwesomeIcon
                                        icon={sort.order === "desc" ? faChevronUp : faChevronDown}
                                        className="ml-2 align-baseline arrow"
                                        size="xs"
                                    />
                                </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="sorting-by-dropdown-menu-button">
                                {_map(["score", "name", "employees", "sales", "updated_at"], (item) => (
                                    <a
                                        className={classNames("dropdown-item", { active: sort.column === item })}
                                        href="#"
                                        key={item}
                                        onClick={() =>
                                            applySort((prevState) => ({
                                                column: item,
                                                order:
                                                    prevState.column === item
                                                        ? prevState.order === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                        : prevState.order,
                                            }))
                                        }
                                    >
                                        {I18n.t(`companies.profiles.sections.profiles.sort.columns.${item}`)}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </span>
                </div>
                {profilesFetching
                    ? _map(_range(1), (item) => <ProfileCardLoader key={`loader-${item}`} />)
                    : _map(profiles, (profile) => <ProfileCard key={`profile-${profile.uid}`} {...profile} />)}
                {pageCount >= 0 && (
                    <div className="pagination justify-content-center mb-0">
                        <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={goToPage}
                            containerClassName="pagination pagination-sm mb-0"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            nextClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            forcePage={page - 1}
                        />
                    </div>
                )}
            </Card.Body>
        </Card.Body>
    </Card>
);

ProfilesSection.propTypes = {
    profilesFetching: PropTypes.bool,
    profiles: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    recordsCount: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    sort: PropTypes.shape({
        column: PropTypes.string.isRequired,
        order: PropTypes.string.isRequired,
    }),
    applySort: PropTypes.func.isRequired,
};

export default ProfilesSection;
