import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import I18n from "../../../utils/i18n";
import { setAlert } from "../../../state/alert/alertActions";
import NavigationPrompt from "../NavigationPrompt";
import NewPageTitle from "../NewPageTitle";
import EditPageTitle from "../EditPageTitle";

const LegalAttorneyForm = ({ organizationUid, attorneyId = null, initialValues, createOrUpdateLegalAttorney }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().max(255).required(),
        last_name: Yup.string().max(255).required(),
        title: Yup.string().max(255).required(),
        graduation_year: Yup.number().min(1900).max(moment().year()).required(),
    });

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createOrUpdateLegalAttorney(organizationUid, attorneyId, { attorney: values })
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                resetForm(values);
                history.push(`/organizations/${organizationUid}/attorneys`);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit, isSubmitting, dirty }) => (
                <>
                    <NavigationPrompt when={dirty} />
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Col>
                            <Container fluid className="py-3">
                                {attorneyId ? (
                                    <EditPageTitle resource={I18n.t("activerecord.models.legal/attorney")} />
                                ) : (
                                    <NewPageTitle resource={I18n.t("activerecord.models.legal/attorney")} />
                                )}
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/attorney.first_name")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="first_name"
                                                        onChange={handleChange}
                                                        value={values.first_name}
                                                        isInvalid={errors.first_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.first_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/attorney.last_name")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="last_name"
                                                        onChange={handleChange}
                                                        value={values.last_name}
                                                        isInvalid={errors.last_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.last_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.legal/attorney.title")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="title"
                                                        onChange={handleChange}
                                                        value={values.title}
                                                        isInvalid={errors.title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t(
                                                            "activerecord.attributes.legal/attorney.graduation_year",
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="graduation_year"
                                                        onChange={handleChange}
                                                        value={values.graduation_year}
                                                        isInvalid={errors.graduation_year}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.graduation_year}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </>
            )}
        </Formik>
    );
};

LegalAttorneyForm.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    attorneyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValues: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        title: PropTypes.string,
        graduation_year: PropTypes.number,
    }),
    createOrUpdateLegalAttorney: PropTypes.func.isRequired,
};

export default LegalAttorneyForm;
