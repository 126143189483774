import React from "react";
import PropTypes from "prop-types";
import { Button, Form, FormControl, Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import I18n from "../../../utils/i18n";
import { updatePartnership } from "../../../api/tieredDiversityApi";

const validationSchema = Yup.object().shape({
    partnership: Yup.object().shape({
        supplier_email: Yup.string().email().max(255).required(),
    }),
});

const AddAlternativeContactModal = ({ partnershipId, show, setShow }) => {
    const onSubmit = (values) => {
        updatePartnership(partnershipId, values).finally(() => {
            setShow(false);
        });
    };

    return (
        <Modal
            id={`add-alternative-contact-modal-${partnershipId}`}
            show={show}
            onHide={() => setShow(false)}
            size="mb"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase">
                    {`${I18n.t("common.links.add")} ${I18n.t(
                        "activerecord.attributes.tiered_diversity/partnership.supplier_email"
                    )}`}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{ partnership: { supplier_email: "" } }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group controlId="partnership_supplier_email">
                                <Form.Control
                                    type="email"
                                    name="partnership[supplier_email]"
                                    placeholder={I18n.t("activerecord.attributes.user.email")}
                                    onChange={handleChange}
                                    value={values.partnership.supplier_email}
                                    isInvalid={errors.partnership && errors.partnership.supplier_email}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.partnership && errors.partnership.supplier_email}
                                </FormControl.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setShow(false)}
                                className="text-white"
                            >
                                {I18n.t("common.links.cancel")}
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                className="text-white"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2"
                                    />
                                )}
                                {I18n.t("common.links.add")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

AddAlternativeContactModal.propTypes = {
    partnershipId: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default AddAlternativeContactModal;
