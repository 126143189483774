import { SET_ALERT, CLEAR_ALERT } from "./types";

export function setAlert({ translationKey, text, variant }) {
    window.localStorage.setItem("alert", JSON.stringify({ translationKey, text, variant }));
    return { type: SET_ALERT, payload: { translationKey, text, variant } };
}

export function clearAlert() {
    window.localStorage.removeItem("alert");
    return { type: CLEAR_ALERT };
}
