import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Avatar from "../../../../common/components/Avatar";

const ProfileCardLoader = () => (
        <Card className="mb-3">
            <Card.Body>
                <Container>
                    <Row>
                        <Col sm={2} className="d-flex align-items-center justify-content-center">
                            <Avatar name="L" />
                        </Col>
                        <Col sm={6}>
                            <div>
                                <h3>
                                    <Skeleton />
                                </h3>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={2}>
                            <div>
                                <small className="text-muted">JB/Total Score</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <div>
                                <small className="text-muted">Doing Business As</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <small className="text-muted">Industry</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <small className="text-muted">Certificate</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <small className="text-muted">Sales</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <small className="text-muted">Employees</small>
                                <div>
                                    <Skeleton />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer className="text-muted">
                <Skeleton />
            </Card.Footer>
        </Card>
    );

export default ProfileCardLoader;
