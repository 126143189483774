import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, Form, Spinner } from "react-bootstrap";
import get from "lodash/get";
import { Formik } from "formik";
import * as Yup from "yup";
import I18n from "../../../utils/i18n";
import { updateFirm } from "../../../api/diversityApi";
import { setAlert } from "../../../state/alert/alertActions";
import NavigationPrompt from "../../../common/components/NavigationPrompt";

const DiversityFirmwideUploadAutoApproveForm = ({ firm, setFirm }) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        firm: Yup.object().shape({
            auto_approve_enabled: Yup.bool().required(),
            auto_approve_comment: Yup.string().max(5000),
        }),
    });

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        updateFirm(values.firm)
            .then((response) => {
                setFirm((prevState) => ({ ...prevState, ...response.data }));
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={{
                firm: {
                    auto_approve_enabled: firm.auto_approve_enabled,
                    auto_approve_comment: firm.auto_approve_comment || "",
                },
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ values, errors, handleChange, handleSubmit, isSubmitting, dirty }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <NavigationPrompt when={dirty} />
                    <div className="d-inline">
                        <div className="d-flex">
                            <Form.Label className="mr-2">
                                {I18n.t("activerecord.attributes.diversity/firm.auto_approve_enabled")}
                            </Form.Label>
                            <Form.Check
                                custom
                                id="firm_auto_approve_enabled"
                                type="switch"
                                name="firm[auto_approve_enabled]"
                                checked={values.firm.auto_approve_enabled}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <Form.Group controlId="firm_auto_approve_comment">
                        <Form.Label>{I18n.t("activerecord.attributes.diversity/firm.auto_approve_comment")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="firm[auto_approve_comment]"
                            onChange={handleChange}
                            value={values.firm.auto_approve_comment}
                            isInvalid={get(errors, "firm.auto_approve_comment") || get(errors, "auto_approve_comment")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {get(errors, "firm.auto_approve_comment") || get(errors, "auto_approve_comment")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                        className="ml-2 text-white float-right"
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                            />
                        )}
                        {I18n.t("common.links.save")}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

DiversityFirmwideUploadAutoApproveForm.propTypes = {
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        auto_approve_enabled: PropTypes.bool.isRequired,
        auto_approve_comment: PropTypes.string,
    }),
    setFirm: PropTypes.func.isRequired,
};

export default DiversityFirmwideUploadAutoApproveForm;
